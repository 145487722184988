import { CLOSE_TAB, TOGGLE_TAB } from '../actions/TourDetail/tourDetail';

const initialState = {
  term: false,
  disableClosing: false,
};

const filter = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_TAB:
      if (!state.disableClosing) {
        return { ...state, term: false };
      } else {
        return { ...state, disableClosing: false };
      }

    case TOGGLE_TAB:
      if (state.term) {
        return { ...state, term: false, disableClosing: false };
      } else {
        return { ...state, term: true, disableClosing: true };
      }

    default:
      return state;
  }
};

export default filter;
