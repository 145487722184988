import { FETCH_TILE } from '../actions/HP/defaultTourlist';
const initialState = [];

const tourDetail = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TILE:
      return action.result;

    default:
      return state;
  }
};

export default tourDetail;
