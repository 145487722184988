import { getJSON } from '../../utils/fetch';

export const SET_CMS = 'SET_CMS';

export const fetchCMS = () => {
  return (dispatch) => {
    getJSON('page/').then((result) => {
      dispatch({
        type: SET_CMS,
        result,
      });
    });
  };
};
