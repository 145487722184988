import createLogger from 'redux-logger';
import Flexbox from 'flexbox-react';
import Footer from './components/Footer/Footer';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import Header from './components/Header/Header';
import AdvertisingMessage from './components/AdvertisingMessage/AdvertisingMessage.jsx';
import ErrorMessage from './components/Error/ErrorMessage/ErrorMessage';
import constants from './styles/constants.js';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ScrollToTop from 'react-scroll-up';
import containsServer from './components/shared/getServer';
import serverState from './utils/config'; // eslint-disable-line
import reducers from './reducers';
import style from './styles/style.scss'; // eslint-disable-line
import thunk from 'redux-thunk';
import upArrow from './img/up-arrow.png'; // eslint-disable-line
import { Provider } from 'react-redux';
import { CLOSE_SEARCH } from './actions/Search/search';
import { StyleRoot } from 'radium';
import { applyMiddleware, createStore } from 'redux';

const logger = createLogger({
  predicate: () => {
    return false;
  },
});

const store = createStore(reducers, applyMiddleware(thunk, logger));

const styles = {
  content: {
    float: 'left',
    width: '100%',
    margin: '80px 0 0 0',
    position: 'relative',
    flex: '1 0 auto',

    [constants.breakpoint600]: {
      margin: '57px 0 0 0',
    },
  },
  arrowUp: {
    width: '40px',
    height: '40px',
    opacity: '0.6',
    bottom: '-20px',
    position: 'relative',
  },
  scrollTop: {
    position: 'fixed',
    bottom: 40,
    right: 20,
    cursor: 'pointer',
    transitionDuration: '0.2s',
    transitionTimingFunction: 'linear',
    transitionDelay: '0s',
    zIndex: '9',
  },
};

const muiTheme = getMuiTheme({
  slider: {
    trackColor: 'rgb(255, 255, 255)',
    selectionSize: '20px',
    selectionColor: 'rgb(237, 183, 0)',
  },
  raisedButton: {
    height: '40px !important',
    fontSize: '1em',
    margin: '0 1px',
    width: 'auto',
  },
  datePicker: {
    primary1Color: '#004691',
    background: '#004691',
  },
  dialog: {
    padding: '15px',
  },
  fontFamily: 'Open Sans',
  palette: {
    primary1Color: '#287fb8',
    primary2Color: '#287fb8',
    primary3Color: '#287fb8',
    accent1Color: [constants.yellow],
  },
});

class App extends Component {
  render() {
    const { children, location } = this.props;

    if (
      location.pathname.indexOf('onlyPriceList') >= 0 ||
      location.pathname.indexOf('printPriceList') >= 0 ||
      location.pathname.indexOf('printCompletePriceList') >= 0 ||
      location.pathname.indexOf('not-found') >= 0 ||
      location.pathname.indexOf('printSeparatedPriceList') >= 0 ||
      location.pathname === '/' ||
      location.pathname.indexOf('instructions/printing') >= 0
    ) {
      return this.renderLayout(children, 'blank');
    }

    return this.renderLayout(children, 'full');
  }

  renderLayout(children, type = null) {
    return (
      <StyleRoot>
        <Provider store={store}>
          <MuiThemeProvider muiTheme={muiTheme}>
            <div onClick={() => store.dispatch({ type: CLOSE_SEARCH })}>
              <link
                href="https://fonts.googleapis.com/icon?family=Material+Icons"
                rel="stylesheet"
              />
              <ErrorMessage />
              <AdvertisingMessage />
              {type === 'full' ? this.renderFullLayout(children) : children}
            </div>
          </MuiThemeProvider>
        </Provider>
      </StyleRoot>
    );
  }

  renderFullLayout(children) {
    const { location } = this.props;

    return (
      <div>
        <Flexbox flexDirection="column" minHeight="100vh">
          <Flexbox element="header">
            <Header location={location} />
          </Flexbox>
          <Flexbox flexGrow={1}>
            <div style={styles.content}>{children}</div>
          </Flexbox>
          <Flexbox element="footer">
            <Footer />
          </Flexbox>
          <ScrollToTop style={styles.scrollTop} showUnder={160}>
            <span>
              <img src={upArrow} style={styles.arrowUp} />
            </span>
          </ScrollToTop>
        </Flexbox>
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.any,
  location: PropTypes.any,
};

export default App;
