import getLengthInDaysAndNights from '../../shared/getLengthInDaysAndNights';
import constants from '../../../styles/constants.js';
import formatDateLong from '../../shared/Moment/formatDateLong';
import crosLineImg from '../../../img/crossLine.png'; // eslint-disable-line
import formatDateShort from '../../shared/Moment/formatDateShort';
import getHeaders from '../../shared/getUniqueHeaders';
import formatCurrency from '../../shared/formatCurrency';
import getPriceFromCode from '../../shared/getPriceFromCode';
import createFilterRequest from '../../shared/RequestCreator/createFilterRequest';
import { fetchTerms } from '../../../actions/TourList/fetchTourList';
import { pickTour } from '../../../actions/TourDetail/tourDetail';
import { employee } from '../../../actions/HP/defaultTourlist';
import StyledCircleLoader from '../../shared/Loading/StyledCircleLoader';
import { fetchTourDetail } from '../../../actions/TourDetail/fetchTourDetail';
import { generatePDF } from '../../../actions/PDF/generatePDF';
import cookie from 'react-cookie';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

const specialOffer = 'Výhodná nabídka';
const soldOut = 'Vyprodáno';
const subsidy = 'dotovany';

export const getPDFContent = (
  photos,
  strippedInstructions,
  elementIdentifier = 'hotel-pdf-description',
) => {
  const hrefContent = [];
  const transferedHrefs = [];
  const height = document.getElementById(elementIdentifier).clientHeight;
  let instructions = `<div>${strippedInstructions}`;
  let renderImages = 3;
  let heightModifier = 2;

  if (height < 750) {
    if (height < 350) {
      heightModifier = 1.5;
    }

    const divHeight = (750 - height) / heightModifier;
    instructions += '<p style="height:' + divHeight + '"></p>';
  } else {
    instructions += '<p style="height: 5px"></p>';
  }

  if (height >= 912) {
    renderImages = 2;
  }

  if (height >= 1080) {
    renderImages = 1;
  }

  if (height >= 1080) {
    renderImages = 1;
  }

  if (height >= 1270) {
    renderImages = 0;
  }
  photos.slice(1, renderImages + 1).map((photo) => {
    instructions += renderHotelPhoto(photo);
  });

  instructions += `</div>`;

  return instructions;
};

const renderHotelPhoto = (photo) => {
  const { imgSrc } = photo;
  const formattedImgSrc = imgSrc.replace('amazonaws.com/', 'amazonaws.com/370x180/');

  return (
    '<img style="margin: 0 10px 10px; width: 370px; height: 180px;" src="' + formattedImgSrc + '"/>'
  );
};

export const renderPDFHeaderIcons = (capacities, transport, tripTypes) => {
  let headerIcons = '';
  const images = [];
  let imagesContainsAirplane = false;

  capacities
    .concat(transport)
    .concat(tripTypes)
    .forEach((headerIcon) => {
      const { imgSrc } = headerIcon;
      if (images.indexOf(imgSrc) < 0) {
        images.push(imgSrc);
      }
    });

  const formattedImages = images
    .map((image) => {
      if (!/A[0-9]\.png/.test(image)) {
        return image;
      } else if (!imagesContainsAirplane) {
        imagesContainsAirplane = true;
        return image;
      }
    })
    .filter((x) => x != null);

  formattedImages.forEach((img) => {
    headerIcons += renderPDFHeaderIcon(img);
  });

  return headerIcons;
};

const renderPDFHeaderIcon = (img) => {
  return '<img style="height: 15px; width: auto; margin-right: 10px;" src="' + img + '"/>';
};
