import constants from '../../../../../../styles/constants.js';
import crossImg from '../../../../../../img/icons/cancel.png'; // eslint-disable-line
import checkImg from '../../../../../../img/icons/checked-symbol.png'; // eslint-disable-line
import React, { Component } from 'react';

const styles = {
  wrapper: {
    position: 'relative',
    width: '100%',
    maxWidth: '700px',
    overflow: 'auto',

    [constants.breakpointTablet]: {},
  },
  iconBox: {
    width: 'auto',
    float: 'left',
    paddingLeft: '10px',
    paddingRight: '15px',
    textAlign: 'center',
    display: 'inline-block',

    icon: {
      width: '20px',
      marginLeft: '10px',
      position: 'relative',
      top: '4px',
    },

    text: {
      fontSize: '1.1em',
    },
  },
};

export default class OccupationLegend extends Component {
  render() {
    return (
      <div style={styles.wrapper}>
        <div style={styles.iconBox}>
          <span style={styles.iconBox.text}>Volno</span>
          <img src={checkImg} style={styles.iconBox.icon} />
        </div>
        <div style={styles.iconBox}>
          <span style={styles.iconBox.text}>Obsazeno</span>
          <img src={crossImg} style={styles.iconBox.icon} />
        </div>
      </div>
    );
  }
}
