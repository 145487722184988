import Radium from 'radium';
import selectArrow from '../../../img/select-arrow2.png'; // eslint-disable-line
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import constants from '../../../styles/constants';
import { connect } from 'react-redux';
import { toggle, selecting } from '../../../actions/TourList/filterOpenStatus';
import { toggleSelectedLocations, resetLocations } from '../../../actions/TourList/tourFilter';
import {
  fetchOccupation,
  setOccupationHotelState,
} from '../../../actions/Occupation/fetchOccupation';
import { fetchFilteredList } from '../../../actions/TourList/fetchTourList';
import { toggleTiles } from '../../../actions/TourList/paginator';
import { saveFilter } from '../../../actions/TourList/filter';

const styles = {
  wrapper: {
    width: '100%',
    overflow: 'auto',
  },
  inputBox: {
    width: '30.3%',
    margin: '1% 1.5%',
    float: 'left',
    position: 'relative',

    [constants.breakpointTablet]: {
      width: '100%',
      margin: '1% 0',
    },
  },
  selectBox: {
    width: 'calc(100% - 53px)',
    height: 32,
    overflow: 'hidden',
    padding: '0 42px 0 11px',
    lineHeight: '34px',
    border: '1px solid' + [constants.mainBlue],
    display: 'inline-block',
    backgroundColor: '#fff',
    backgroundImage: 'url(' + selectArrow + ')',
    backgroundPosition: 'right center',
    backgroundRepeat: 'no-repeat',
    color: [constants.mainBlue],
    fontSize: '0.9em',
    fontWeight: '500',
    cursor: 'pointer',
  },
  dropDown: {
    background: [constants.dropDownBackground],
    position: 'absolute',
    top: '53px',
    left: 0,
    width: 'calc(100% + 2px)',
    zIndex: '2',
    fontSize: '0.9em',
    borderRadius: '4px',
    boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.11)',

    item: {
      padding: '8px 5px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
      cursor: 'pointer',
      margin: '0 7px',
      position: 'relative',
      bottom: '-1px',
    },
    checkbox: {
      marginRight: '10px',
      position: 'relative',
      top: '1px',
    },
    caret: {
      display: 'block',
      position: 'absolute',
      top: '-8px',
      left: '7px',
      width: '0',
      height: '0',
      content: '',
      verticalAlign: 'middle',
      borderBottom: '10px dashed' + [constants.dropDownBackground],
      borderRight: '10px solid transparent',
      borderLeft: '10px solid transparent',
    },
  },
};

class MultiCheckbox extends Component {
  state = {
    selectedLocations: [],
    text: '',
    opened: false,
    checked: false,
  };

  componentWillReceiveProps(props) {
    this.countriesText(props);
  }

  render() {
    const { defaultText, data, filterOpen, type, setOccupationHotelState, textState } = this.props;
    const { checked, selectedLocations, text } = this.state;
    if (!checked) {
      this.countriesText(this.props);
      this.setState({ checked: true });
    }
    if (text !== textState) {
      setOccupationHotelState(text);
    }

    return (
      <div style={styles.inputBox}>
        <div onClick={() => this.toggleOpen(type)} style={styles.selectBox}>
          {text || defaultText}
        </div>

        <div style={styles.dropDown}>
          {filterOpen[type] && !selectedLocations.length && <div style={styles.dropDown.caret} />}
          {filterOpen[type] && (
            <div
              className="item"
              style={styles.dropDown.item}
              onClick={() => this.setState({ text: '' })}
            >
              {defaultText}
            </div>
          )}
          {filterOpen[type] && selectedLocations.length
            ? selectedLocations.map((location) => this.renderCheckBox(location, data))
            : null}
        </div>
      </div>
    );
  }

  countriesText(props) {
    const {
      tourFilter: { countrySortedByName },
      selectedLocations,
    } = props;
    const selectedLocationCodes = [];

    countrySortedByName.forEach((country) => {
      if (country.selected) {
        selectedLocations[country.value].forEach((location) => {
          if (location.selected) {
            selectedLocationCodes.push(location.code);
          }
        });
      }
    });

    !selectedLocationCodes.length && this.setState({ text: '' });
    this.setState({ selectedLocations: selectedLocationCodes });
  }

  renderCheckBox(location, data) {
    return <div>{data[location].map((hotels) => this.renderBox(hotels))}</div>;
  }

  renderBox(hotels) {
    const { value, hotelId } = hotels;

    return (
      <div style={styles.dropDown.item} onClick={() => this.fetchOccupationHotel(hotelId, value)}>
        {value}
        <br />
      </div>
    );
  }

  fetchOccupationHotel(hotelId, value) {
    const { fetchOccupation } = this.props;

    this.setState({ text: value });
    fetchOccupation(hotelId);
  }

  reset() {
    const { resetLocations } = this.props;
    const { countryText } = this.state;

    resetLocations(countryText);
  }

  toggleOpen(designation) {
    const { toggle, filterOpen, type, closeTabs } = this.props;
    if (!filterOpen[type]) {
      toggle(designation);
    } else {
      closeTabs();
    }
  }
}

MultiCheckbox.propTypes = {
  defaultText: PropTypes.any,
  data: PropTypes.any,
  filterOpen: PropTypes.any,
  type: PropTypes.any,
  setOccupationHotelState: PropTypes.any,
  textState: PropTypes.any,
  fetchOccupation: PropTypes.any,
  resetLocations: PropTypes.any,
  toggle: PropTypes.any,
  closeTabs: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  toggle: (designation) => dispatch(toggle(designation)),
  selecting: () => dispatch(selecting()),
  setOccupationHotelState: (text) => dispatch(setOccupationHotelState(text)),
  fetchOccupation: (id) => dispatch(fetchOccupation(id)),
  resetLocations: (designation) => dispatch(resetLocations(designation)),
  fetchFilteredList: (filters) => dispatch(fetchFilteredList(filters)),
  toggleTiles: (nextState) => dispatch(toggleTiles(nextState)),
  saveFilter: (filter, designation) => dispatch(saveFilter(filter, designation)),
  toggleSelectedLocations: (code, designation) =>
    dispatch(toggleSelectedLocations(code, designation)),
});

const mapStateToProps = (state) => ({
  filter: state.filter,
  paginator: state.paginator,
  fetchState: state.fetchState,
  selectedLocations: state.selectedLocations,
  tourFilter: state.tourFilter,
  filterOpen: state.filterOpen,
  tourList: state.tourList,
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(MultiCheckbox));
