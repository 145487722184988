import aboutSectionImage from '../../../img/section-headers/together.jpg'; // eslint-disable-line
import Radium from 'radium';
import SectionImage from '../../shared/SectionImage';
import okIcon from '../../../img/icons/error.svg';
import React, { Component } from 'react';
import Helmet from 'react-helmet';

const styles = {
  sectionHead: {
    width: '100%',
  },
  contentWrapper: {
    overflow: 'hidden',
    background: '#fff',
    zIndex: 6,
    position: 'relative',
    padding: '50px 0',
  },
  contentBox: {
    margin: '0 auto',
    padding: '0 15px',
    maxWidth: '500px',
    textAlign: 'center',
  },
  image: {
    marginTop: '25px',
    width: '100%',
  },
  icon: {
    width: '90px',
    heignht: '90px',
    marginTop: '50px',
    opacity: 0.7,
  },
};

class PaymentError extends Component {
  render() {
    return (
      <div style={styles.borderBottom} className="cms-page">
        <Helmet title="Online prodej" />
        <SectionImage actualImage={aboutSectionImage} actualTitle="Online prodej" />

        <div style={styles.contentWrapper}>
          <div style={styles.contentBox}>
            <h2>
              Litujeme, ale platba neproběhla správně. Pro úspěšné zaplacení prosím platbu zopakujte
              znovu.
            </h2>
            <img style={styles.icon} src={okIcon} alt="Ok" />
          </div>
        </div>
      </div>
    );
  }
}

export default Radium(PaymentError);
