import formatCurrency from './formatCurrency';
import { employee, szEmployee } from "../../actions/HP/defaultTourlist";

const getPriceFromCode = (termCode, termPrices, type) => {
  const termPrice = termPrices.filter(({ code }) => code === termCode);

  if (termPrice.length) {
    const { price, subsidy, szSubsidy } = termPrice[0];
    let totalPrice;

    if(type === szEmployee){
      totalPrice =  price - szSubsidy
    } else {
      totalPrice = type === employee ? price - subsidy : price
    }

    return formatCurrency(totalPrice);
  }

  return '';
};

export default getPriceFromCode;
