import emailVerify from '../../../../../shared/emailVerify';
import FormGroup from '../../../../../shared/forms/FormGroup';
import Dialog from 'material-ui/Dialog';
import InputModalAlert from '../../../../../shared/InputModalAlert';
import OrangeButton from '../../../../../shared/OrangeButton';
import Radium from 'radium';
import SelectBox from '../../../../../shared/forms/SelectBox';
import styles from '../../../../../shared/forms/modalFormStyles/modalFormStyles';
import { connect } from 'react-redux';
import { postReview, postSucceeded } from '../../../../../../actions/TourDetail/postTourDetail';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ReviewForm extends Component {
  state = {
    status: '',
    alertOpened: false,
    listeningToPropsStatus: false,
    email: '',
    note: '',
    name: '',
    living: 0,
    food: 0,
    location: 0,
    services: 0,
    opened: false,
  };

  componentWillReceiveProps(props) {
    const {
      opened,
      postFormStatus: { postNewReview },
    } = props;
    const { listeningToPropsStatus } = this.state;

    this.setState({ opened });
    if (listeningToPropsStatus) {
      this.setState({ status: postNewReview });
    }
  }

  render() {
    const { opened, status, alertOpened } = this.state;

    return (
      <Dialog
        title="Recenze"
        actions={this.getActions()}
        modal={false}
        open={opened}
        autoScrollBodyContent="true"
        onRequestClose={this.handleClose}
        titleStyle={{ borderBottom: 'none' }}
        bodyStyle={{
          borderBottom: '3px solid #fff',
          bottom: '-1px',
          position: 'relative',
        }}
        contentClassName="dialog-box"
        actionsContainerClassName="actions-box"
        bodyClassName="content-box"
        titleClassName="title-box"
      >
        <InputModalAlert
          opened={alertOpened}
          onClick={this.handleOpenAlert.bind(this)}
          message="Zkontrolujte, prosím, zda jsou všechny položky vyplněné a jsou vyplněné správně."
        />
        <div style={styles.wrapper}>
          <p style={styles.status}>{status}</p>
          {this.renderDialogItems(status)}
        </div>
      </Dialog>
    );
  }

  renderDialogItems(status) {
    if (status === postSucceeded) {
      return null;
    }

    return (
      <div>
        <div style={styles.col}>
          <div>
            <FormGroup title="* Ubytování">{this.renderChoicesSelect('living')}</FormGroup>
            <FormGroup title="* Strava">{this.renderChoicesSelect('food')}</FormGroup>
            <FormGroup title="* Poloha">{this.renderChoicesSelect('location')}</FormGroup>
            <FormGroup title="* Služby">{this.renderChoicesSelect('services')}</FormGroup>
          </div>
        </div>
        <div style={styles.col}>
          <div>
            <FormGroup title="Komentář">
              <textarea
                style={styles.area}
                onChange={(event) => {
                  this.setState({ note: event.target.value });
                }}
              />
            </FormGroup>
            <FormGroup title="E-Mail">
              <input
                style={styles.input}
                onChange={(event) => {
                  this.setState({ email: event.target.value });
                }}
              />
            </FormGroup>
            <FormGroup title="* Jméno">
              <input
                style={styles.input}
                onChange={(event) => {
                  this.setState({ name: event.target.value });
                }}
              />
            </FormGroup>
          </div>
        </div>
      </div>
    );
  }

  close() {
    this.setState({ status: '', opened: false, listeningToPropsStatus: false });
  }

  handleOpenAlert(alertOpened) {
    this.setState({ alertOpened });
  }

  getActions() {
    const { status } = this.state;

    if (status === postSucceeded) {
      return (
        <OrangeButton onClick={() => this.close()} submit="submit" type="inversion">
          Zavřít
        </OrangeButton>
      );
    }

    return (
      <div>
        <OrangeButton onClick={() => this.close()} submit="submit" type="inversion">
          Zrušit
        </OrangeButton>
        <OrangeButton onClick={() => this.uploadReview()} submit="submit" type="normal">
          Potvrdit
        </OrangeButton>
      </div>
    );
  }

  renderChoicesSelect(name) {
    const { tourDetailReview } = this.props;

    return (
      <SelectBox
        name={name}
        options={tourDetailReview}
        defaultOption={Boolean(true)}
        onClick={this.handleSelect.bind(this)}
      />
    );
  }

  uploadReview() {
    const { email, note, name, living, food, location, services } = this.state;
    const {
      postReview,
      tourDetail: { idHotel },
    } = this.props;
    const requestData = {
      idHotel,
      email,
      name,
      accommodationIndex: living,
      dietIndex: food,
      locationIndex: location,
      serviceIndex: services,
      note,
    };
    const requiredFields = [name, living, food, location, services];

    let error = false;
    requiredFields.forEach((fieldName) => {
      if (fieldName === 0 || fieldName === '') {
        error = true;
      }
    });

    if (error || (email !== '' && !emailVerify(email))) {
      this.setState({ alertOpened: true });
    } else {
      this.setState({
        status: 'Odesílám formulář',
        listeningToPropsStatus: true,
        email: '',
        name: '',
        living: 0,
        food: 0,
        services: 0,
        location: 0,
        note: '',
      });
      postReview(requestData);
    }
  }

  handleSelect(value, name) {
    this.setState({ [name]: value });
  }
}

ReviewForm.propTypes = {
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  opened: PropTypes.object.isRequired,
  toggle: PropTypes.object.isRequired,
  postReview: PropTypes.any,
  tourDetail: PropTypes.any,
  tourDetailReview: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetailReview: state.tourDetailReview,
  tourDetail: state.tourDetail,
  postFormStatus: state.postFormStatus,
});

const mapDispatchToProps = (dispatch) => ({
  postReview: (requestData) => dispatch(postReview(requestData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(ReviewForm));
