import constants from '../../../../../styles/constants.js';
import DatePicker from 'material-ui/DatePicker';
import Radium from 'radium';
import { connect } from 'react-redux';
import { saveFilter } from '../../../../../actions/TourList/filter';
import { toggleTiles } from '../../../../../actions/TourList/paginator';
import createFilterRequest from '../../../../shared/RequestCreator/createFilterRequest';
import { fetchFilteredList } from '../../../../../actions/TourList/fetchTourList';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import arrowDown from '../../../../../img/down-arrow.svg';

const styles = {
  inputBox: {
    width: '100%',
    marginBottom: '0.5%',
    float: 'left',
    position: 'relative',

    datePickerWrapper: {
      width: 'calc(100% - 20px)',
      height: 40,
      overflow: 'hidden',
      padding: '0 10px 0 10px',
      lineHeight: '40px',
      borderRadius: '5px',
      border: '1px solid' + [constants.inputBorder],
      display: 'inline-block',
      backgroundColor: '#fff',
      backgroundSize: 20,
      backgroundPosition: 'right center',
      backgroundRepeat: 'no-repeat',
      color: [constants.black],
      fontSize: '0.9em',
      fontWeight: '500',
      cursor: 'pointer',
    },
    datePickerInputWrapperleft: {
      width: '49%',
      display: 'inline-block',
      position: 'relative',
    },
    datePickerInputWrapperRight: {
      width: '45%',
      marginLeft: '5%',
      display: 'inline-block',
    },
    datePicker: {
      text: {
        color: [constants.mainBlue],
        fontSize: '13px',
        letterSpacing: '0px',
        left: 0,
        top: -6,
        cursor: 'pointer',
        width: 'auto',
        maxWidth: '250px',
      },
    },
    resetDate: {
      position: 'absolute',
      top: 5,
      right: 5,
      cursor: 'pointer',
    },
  },
};

class FromToDatePicker extends Component {
  componentDidMount() {
    const {
      filter: { dateTo },
    } = this.props;

    if (dateTo) {
      this.setState({ dateTo });
    }
  }

  componentWillReceiveProps(props) {
    const {
      filter: { dateTo, dateFrom },
    } = props;

    if (dateTo && dateFrom > dateTo) {
      const {
        saveFilter,
        fetchFilteredList,
        filter,
        paginator: { itemsPerPage, minCost, maxCost, page },
      } = this.props;
      const optional = { changedDateFrom: dateTo };

      saveFilter(dateTo, 'dateFrom');
      fetchFilteredList(
        createFilterRequest(filter, page, itemsPerPage, minCost, maxCost, optional),
      );
    }
  }

  render() {
    const {
      filter: { dateFrom },
    } = this.props;
    const { dateTo } = this.state;

    return (
      <div style={styles.inputBox}>
        <div style={styles.inputBox.datePickerWrapper} className="select-box">
          <div
            className="datepicker-input-first"
            style={styles.inputBox.datePickerInputWrapperleft}
          >
            <DatePicker
              value={dateFrom}
              minDate={new Date()}
              onChange={(x, event) => this.setDate(event, 'from')}
              hintText="Odjezd"
              mode="portrait"
              key="from"
              cancelLabel="Zrušit"
              className="datepicker"
              okLabel="Potvrdit"
              DateTimeFormat={Intl.DateTimeFormat}
              formatDate={this.formatDateLong}
              locale="cs"
              autoOk="true"
              textFieldStyle={styles.inputBox.datePicker.text}
            />
            {dateFrom && (
              <i
                style={styles.inputBox.resetDate}
                className="material-icons "
                onClick={() => this.setDate(null, 'from')}
              >
                close
              </i>
            )}
          </div>
          <div
            style={styles.inputBox.datePickerInputWrapperRight}
            onClick={() => this.setDateToValue()}
          >
            <DatePicker
              value={dateTo}
              minDate={dateFrom || new Date()}
              onChange={(x, event) => this.setDate(event, 'to')}
              onDismiss={() => this.setDateToValue(false)}
              hintText="Návrat"
              mode="portrait"
              key="from"
              cancelLabel="Zrušit"
              className="datepicker"
              okLabel="Potvrdit"
              DateTimeFormat={Intl.DateTimeFormat}
              formatDate={this.formatDateLong}
              locale="cs"
              autoOk="true"
              textFieldStyle={styles.inputBox.datePicker.text}
            />
            {dateTo && (
              <i
                style={styles.inputBox.resetDate}
                className="material-icons"
                onClick={() => this.setDate(null, 'to')}
              >
                close
              </i>
            )}
          </div>
        </div>
      </div>
    );
  }

  setDateToValue(setNew = true) {
    const {
      filter: { dateFrom, dateTo },
    } = this.props;

    if (dateFrom && !dateTo) {
      this.setState({ dateTo: setNew ? dateFrom : null });
    }
  }

  setDate(event, state) {
    const {
      saveFilter,
      fetchFilteredList,
      toggleTiles,
      filter,
      paginator: { itemsPerPage, minCost, maxCost, page },
    } = this.props;
    const optional = {};
    if (state === 'to') {
      this.setState({ dateTo: event });
      saveFilter(event, 'dateTo');
      optional.changedDateTo = event;
    } else {
      saveFilter(event, 'dateFrom');
      optional.changedDateFrom = event;
    }

    const sortByDate = { value: 'dle datumu', code: 'date', type: 'ASC' };

    if (!filter.sortBy) {
      optional.changedSortBy = sortByDate;
      optional.changedShowTermList = true;
      saveFilter(sortByDate, 'sortBy');
      saveFilter(true, 'showTermList');
    }

    fetchFilteredList(createFilterRequest(filter, page, itemsPerPage, minCost, maxCost, optional));
    toggleTiles(false);
  }
}

FromToDatePicker.propTypes = {
  saveFilter: PropTypes.any,
  fetchFilteredList: PropTypes.any,
  toggleTiles: PropTypes.any,
  filter: PropTypes.any,
  paginator: PropTypes.any,
};

const mapStateToProps = (state) => ({
  filter: state.filter,
  filteredTours: state.filteredTours,
  paginator: state.paginator,
});

const mapDispatchToProps = (dispatch) => ({
  toggleTiles: (nextState) => dispatch(toggleTiles(nextState)),
  saveFilter: (filter, designation) => dispatch(saveFilter(filter, designation)),
  fetchFilteredList: (filters) => dispatch(fetchFilteredList(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(FromToDatePicker));
