import headImage from '../../../../img/bcg-tours.jpg'; // eslint-disable-line
import styles from '../../../shared/TourListStyles/TourListHeadStyles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import Radium from 'radium';

class TourListHead extends Component {
  state = {
    max: 0,
    min: 0,
    setted: false,
    fetched: false,
    actualMin: 0,
    actualMax: 0,
  };

  componentWillReceiveProps(props) {
    const {
      tourList: { list },
    } = props;
    if (list) {
      this.setState({ fetched: true });
    }
  }

  render() {
    const { title } = this.props;

    return (
      <div style={styles.sectionHead}>
        <div style={styles.wrapperSmall}>
          <img style={styles.image} src={headImage} />
          <div style={styles.contentBox}>
            <h1 style={styles.title}>{title}</h1>
          </div>
        </div>
      </div>
    );
  }
}

TourListHead.propTypes = {
  title: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourList: state.tourList,
});

export default connect(mapStateToProps)(Radium(TourListHead));
