import cookie from 'react-cookie';

const createSpecialToursRequest = (page, type, itemsPerPage) => {
  const request = {
    type,
    page: page - 1,
    user: cookie.load('user'),
    itemsPerPage,
  };

  return request;
};

export default createSpecialToursRequest;
