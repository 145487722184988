import React, { Component } from 'react';
import PropTypes from 'prop-types';

const styles = {
  onlyText: {
    padding: '9px 0 10px 0',
    fontSize: '0.9em',
  },
};

export default class OnlyText extends Component {
  render() {
    const { children } = this.props;

    return <div style={styles.onlyText}>{children}</div>;
  }
}

OnlyText.propTypes = {
  children: PropTypes.string.isRequired,
};
