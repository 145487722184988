import constants from '../../../../../styles/constants';
import Radium from 'radium';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { loadSeznamScripts } from '../../../../shared/loadMap';
import './Map.scss';
import HotelsMapInfoWindow from './HotelsMapInfoWindow';
import { getMapCenterOffset } from './utils';

const styles = {
  wrapper: {
    textAlign: 'center',
    width: '100%',
    height: '500px',
    overflow: 'hidden',
    position: 'relative',
    [constants.breakpointTablet]: {
      marginTop: '0',
    },
  },
};

class Map extends Component {
  state = {
    shouldUpdateMapMarkers: true,
  };

  componentDidMount() {
    loadSeznamScripts(this);
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('keyup', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keyup', this.handleKeyDown);
    document.removeEventListener('wheel', this.handleScroll);
  }

  componentDidUpdate(props, state) {
    if (!state.mapLoaded && this.state.mapLoaded) {
      this.createMap(props);
    }
  }

  componentWillReceiveProps(props) {
    const { mapLoaded } = this.state;
    const {
      hotelsMap: { additionalDataFetched },
    } = props;

    if (additionalDataFetched && mapLoaded) {
      this.renderAdditionalData(props);
    }
  }

  handleMapCreate(props) {
    const {
      hotelsMap: { data },
    } = props;
    const { SMap, JAK } = window;

    let options = {};
    let markers = [];
    let cards = [];

    const mapData = Object.values(data);

    const center = SMap.Coords.fromWGS84(14.4228, 50.085);
    const map = new SMap(JAK.gel('map'), center, 5);

    map.addDefaultLayer(SMap.DEF_BASE).enable();
    map.addDefaultControls();

    map.setZoomRange(5, 15);

    const globalLayer = new SMap.Layer.Marker();

    map.addLayer(globalLayer);
    globalLayer.enable();

    const signals = map.getSignals();
    signals.addListener(this, 'card-open', (event) => this.handleCardOpen(event));

    const controls = map.getControls();

    controls.forEach((control) => {
      if (control instanceof SMap.Control.Mouse) {
        control.configure(SMap.MOUSE_ZOOM | SMap.MOUSE_PAN);
        this.setState({ mouseControl: control });
        document.addEventListener('wheel', this.handleScroll);
      }
    });

    mapData.forEach((markerData) => {
      if (!markerData.gps.length) return;

      const marker = new SMap.Marker(
        SMap.Coords.fromWGS84(markerData.gps[1], markerData.gps[0]),
        markerData.idTour,
        options,
      );

      cards.push(new SMap.Card());
      markers.push(marker);
      globalLayer.addMarker(marker);
    });

    this.setState({ mapInstance: map, markers, cards, globalLayer });

    return map;
  }

  handleCardOpen({ target }) {
    const { mapInstance } = this.state;
    const { SMap } = window;

    const centerOffset = getMapCenterOffset(target._owner._zoom);

    mapInstance.setCenter(
      SMap.Coords.fromWGS84(
        parseFloat(target.data.gps[1]) - centerOffset,
        parseFloat(target.data.gps[0]) + centerOffset,
      ),
    );

    ReactDOM.render(
      <HotelsMapInfoWindow data={target.data} />,
      document.getElementById(target.getBody().id),
    );
  }

  handleFilterRender(props) {
    const { SMap } = window;
    const {
      hotelsMap: { data, additionalDataFetched },
    } = props;
    const { mapInstance, globalLayer, lastLayer } = this.state;

    const mapData = Object.values(data);

    if (!additionalDataFetched) {
      return;
    }

    globalLayer.disable();

    if (lastLayer) {
      mapInstance.removeLayer(lastLayer);
    }

    const layer = new SMap.Layer.Marker();
    const countryToCenter = [];

    mapInstance.addLayer(layer);
    layer.enable();

    mapData.forEach((markerData) => {
      if (!markerData.gps.length || markerData.isHidden) {
        return;
      }

      if (!countryToCenter.length) {
        countryToCenter.push(markerData);

        if (mapInstance.getCard()) {
          mapInstance.removeCard();
        }
      }

      const newMarker = new SMap.Marker(
        SMap.Coords.fromWGS84(markerData.gps[1], markerData.gps[0]),
        markerData.idTour.toString(),
        {},
      );

      const newCard = new SMap.Card();
      newCard.getBody().id = markerData.idTour;
      newCard.data = markerData;
      newMarker.decorate(SMap.Marker.Feature.Card, newCard);

      layer.addMarker(newMarker);
    });

    if (countryToCenter.length) {
      mapInstance.setCenterZoom(
        SMap.Coords.fromWGS84(countryToCenter[0].gps[1], countryToCenter[0].gps[0]),
        4,
      );
    }

    this.setState({ lastLayer: layer });
  }

  renderAdditionalData(props) {
    const { SMap } = window;
    const { shouldUpdateMapMarkers } = this.state;
    const {
      hotelsMap: { data, additionalDataFetched },
    } = props;

    const mapData = Object.values(data);

    this.handleFilterRender(props);

    if (!additionalDataFetched) {
      return;
    }

    if (shouldUpdateMapMarkers) {
      this.setState({ shouldUpdateMapMarkers: false });
      const { cards, markers } = this.state;

      mapData.forEach((markerData) => {
        if (!markerData.gps.length || markerData.isHidden) {
          return;
        }

        const index = markers.findIndex((marker) => marker._id === markerData.idTour);

        if (index === -1) {
          return;
        }

        cards[index].getBody().id = data.idTour;
        cards[index].data = markerData;
        markers[index].decorate(SMap.Marker.Feature.Card, cards[index]);
      });
    }
  }

  createMap(props) {
    const { mapInstance } = this.state;

    if (mapInstance) {
      this.handleFilterRender(props);
      return mapInstance;
    }

    this.handleMapCreate(props);
  }

  handleKeyDown = ({ key, type }) => {
    const { mouseControl } = this.state;
    const { SMap } = window;

    if (type === 'keydown' && key === 'Alt') {
      document.removeEventListener('keydown', this.handleKeyDown);
      mouseControl.configure(SMap.MOUSE_WHEEL | SMap.MOUSE_PAN);

      window.unlockMap = true;
    } else if (type === 'keyup' && key === 'Alt') {
      document.addEventListener('keydown', this.handleKeyDown);
      mouseControl.configure(SMap.MOUSE_ZOOM | SMap.MOUSE_PAN);

      window.unlockMap = false;
    }
  };

  handleScroll = () => {
    clearTimeout(window.scrolling);

    window.scrolling = setTimeout(() => {
      document.getElementById('map-overlay').style.visibility = 'hidden';
    }, 500);

    if (!window.unlockMap) {
      document.getElementById('map-overlay').style.visibility = 'visible';
    }
  };

  render() {
    return (
      <div style={styles.wrapper}>
        <div className="map-overlay" style={{ visibility: 'hidden' }} id="map-overlay">
          Velikost zobrazení mapy změňíte podržením klávesy Alt a posouváním kolečka myši
        </div>
        <div id="map" className="detail-map" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  hotelsMap: state.hotelsMap,
  filter: state.filter,
});

export default connect(mapStateToProps)(Radium(Map));
