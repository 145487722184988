import { postJSON } from '../../utils/fetch';

export const SAVE_SEARCH_TERM = 'SAVE_SEARCH_TERM';
export const SAVE_REQUEST_SEARCH_TERM = 'SAVE_REQUEST_SEARCH_TERM';
export const SEARCH_FETCHING = 'SEARCH_FETCHING';
export const SEARCH = 'SEARCH';
export const SET_SEARCH_LIST = 'SET_SEARCH_LIST';
export const AUTOCOMPLETE = 'AUTOCOMPLETE';
export const AUTOCOMPLETE_FETCHING = 'AUTOCOMPLETE_FETCHING';
export const CLOSE_SEARCH = 'CLOSE_SEARCH';
export const fetching = 'fetching';
export const nonFetchable = 'nonFetchable';
export const fetchingSearch = 'fetchingSearch';

export const saveSearchTerm = (searchTerm) => {
  return {
    type: SAVE_SEARCH_TERM,
    searchTerm,
  };
};

export const saveRequestSearchTerm = (searchTerm) => {
  return {
    type: SAVE_REQUEST_SEARCH_TERM,
    searchTerm,
  };
};

export const getAutocomplete = (params) => {
  return (dispatch) => {
    dispatch({
      type: AUTOCOMPLETE_FETCHING,
    });
    postJSON('autocomplete/', params).then((result) => {
      dispatch({
        type: AUTOCOMPLETE,
        result,
      });
    });
  };
};

export const fetchSearchList = (searchParams) => {
  return (dispatch) => {
    dispatch({
      type: SEARCH_FETCHING,
    });
    postJSON('search-in-tours/', searchParams).then((result) => {
      dispatch({
        type: SEARCH,
        result,
      });
    });
  };
};
