import moment from 'moment';
import 'moment/locale/cs';

const getDayOfWeek = (date) => {
  moment.locale('cs');

  return moment(date).format('dddd');
};

export default getDayOfWeek;
