import { SAVE_LOCATIONS_HOTELS } from '../actions/TourList/fetchTourList';
const initialState = [];

const tourDetail = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOCATIONS_HOTELS:
      return action.result;

    default:
      return state;
  }
};

export default tourDetail;
