import { SET_REVIEW_OPTIONS } from '../actions/TourDetail/fetchTourDetail';
const initialState = [];

const tourDetailReview = (state = initialState, action) => {
  switch (action.type) {
    case SET_REVIEW_OPTIONS:
      return action.result;

    default:
      return state;
  }
};

export default tourDetailReview;
