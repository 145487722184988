import { postJSON } from '../../utils/fetch';

export const SET_TOGETHER_TRAVELING_POST_FORM = 'SET_TOGETHER_TRAVELING_POST_FORM';
export const postSucceeded = 'Formulář byl odeslán';
export const postFailed = 'Formulář nebyl odeslán';

export const replyRequest = (requestData) => {
  return (dispatch) => {
    postJSON('reply-form/', requestData).then((result) => {
      dispatch({
        type: result.success ? SET_TOGETHER_TRAVELING_POST_FORM : SET_TOGETHER_TRAVELING_POST_FORM,
        result: postSucceeded,
      });
    });
  };
};

export const postNewInsertion = (requestData) => {
  return (dispatch) => {
    postJSON('new-insertion/', requestData).then((result) => {
      dispatch({
        type: result.success ? SET_TOGETHER_TRAVELING_POST_FORM : SET_TOGETHER_TRAVELING_POST_FORM,
        result: postSucceeded,
      });
    });
  };
};
