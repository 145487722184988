import OrangeButton from '../shared/OrangeButton';
import SectionImage from '../shared/SectionImage';
import greyBg from '../../img/grey-bg.png'; // eslint-disable-line
import { Container } from '../../libs/radium-grid'; // eslint-disable-line
import contactSectionImage from '../../img/section-headers/contact.jpg';
import Helmet from 'react-helmet';
import React, { Component } from 'react';

const styles = {
  contentWrapper: {
    overflow: 'hidden',
    zIndex: 6,
    position: 'relative',
    textAlign: 'center',
    padding: '50px 0 50px 0',
    minHeight: 'calc(100vh - 394px)',
    backgroundImage: 'url(' + greyBg + ')',
    boxShadow: '0 8px 12px rgba(0, 0, 0, .15)',
  },
  text: {
    marginBottom: '60px',
  },
};

export default class NotFound extends Component {
  render() {
    return (
      <div>
        <Helmet title="Stránka nenalezena" />
        <SectionImage actualImage={contactSectionImage} actualTitle={'Stránka nenalezena'} />
        <div style={styles.contentWrapper}>
          <Container>
            <p style={styles.text}>
              Požadovaná stránka nebyla nalezena. Prosíme, přejděte na hlavní stranu a zkuste akci
              znova.
            </p>

            <OrangeButton link={'/'}>Přejít na hlavní stranu</OrangeButton>
          </Container>
        </div>
      </div>
    );
  }
}
