import constants from '../../../../../styles/constants.js';
import Radium from 'radium';
import ReactDOMServer from 'react-dom/server';
import { Provider } from 'react-redux';
import OrangeButton from '../../../../shared/OrangeButton';
import { apiUrl } from '../../../../../utils/config.local';
import tourTemplate from '../../../../shared/TourDetail/tourPDFTemplate';
import tourPriceListTemplate from '../../../../shared/TourDetail/tourPriceListTemplate';
import { renderPriceList } from '../../../../shared/TourDetail/renderPriceList';
import { renderPDFHeaderIcons, getPDFContent } from '../../../../shared/TourDetail/pdfHeaderUtils';
import { generatePdfWithCatch } from '../../../../../actions/PDF/generatePDF';
import PickerPriceList from './PickerPriceList';
import React, { Component } from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import base64 from 'base-64';
import utf8 from 'utf8';

import './HotelPreview.scss';

const styles = {
  block: {
    overflow: 'auto',
  },
  wrapper: {
    padding: '20px',
    background: '#fff',
    boxShadow: 'rgba(0, 0, 0, 0.13) 0px 0px 2px',
    margin: '20px 0 50px',

    [constants.breakpoint991]: {
      marginBottom: '20px',
    },
  },
  buttonMarged: {
    margin: '20px 10px 0px 10px',

    left: {
      margin: '20px 10px 0px 0',
    },

    [constants.breakpointMobile]: {
      position: 'relative',
      marginBottom: '10px',
    },
  },
  text: {
    fontSize: '1.1em',
    paddingBottom: '5px',
    textAlign: 'left',
    color: [constants.mainBlue],
  },
  mobileHide: {
    [constants.breakpoint600]: {
      display: 'none',
    },
  },
  buttonBox: {
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
  },
};

const url = apiUrl.substring(0, apiUrl.indexOf('www/api/v1/'));

const getFormattedPdfHtml = (params, fontSize = 13) => {
  const {
    hotelTitle,
    description,
    guarantee: { name, mobile },
    country,
    photos,
    capacities,
    transport,
    tripTypes,
    pdfProps: { strippedInstructions, croppedImage },
    terms,
  } = params;

  const headerIcons = renderPDFHeaderIcons(capacities, transport, tripTypes);

  const content = getPDFContent(photos, strippedInstructions, `hotel-pdf-description`);
  const priceList = ReactDOMServer.renderToString(renderPriceList(terms, hotelTitle));
  const pdf = tourTemplate
    .replace('{country}', country)
    .replace('{hotel}', hotelTitle)
    .replace('{mainImage}', croppedImage ? croppedImage : photos[0] ? photos[0].imgSrc : '')
    .replace('{availabilityIcons}', headerIcons)
    .replace('{guaranteeName}', name)
    .replace('{guaranteePhone}', mobile)
    .replace('{content}', content)
    .replace('{dynamicFontSize}', fontSize)
    .replace('{priceList}', priceList);

  const saveName = 'Zajezd_' + hotelTitle.replace(/ /g, '_').replace(/\*/g, '');

  return { pdf, saveName };
};

class HotelPreview extends Component {
  render() {
    const {
      tourDetail: {
        pdfProps: { strippedInstructions },
      },
    } = this.props;

    return (
      <div style={styles.wrapper}>
        <strong style={styles.text}> Informace o hotelu si můžete stáhnout v PDF</strong>
        {this.renderOrangeButtons()}

        <div
          className="hotel-pdf-description"
          id="hotel-pdf-description"
          dangerouslySetInnerHTML={{ __html: strippedInstructions }}
        />
      </div>
    );
  }

  renderOrangeButtons() {
    const {
      tourDetail: { id },
    } = this.props;
    const link = url + 'front/catalogue/entity-pdf?idHotel=' + id;
    return (
      <div>
        <OrangeButton
          extraStyle={styles.buttonMarged.left}
          onClick={() => this.generatePDFWithTemplate()}
          external={Boolean(true)}
          target="_blank"
          icon="picture_as_pdf"
        >
          PDF
        </OrangeButton>
      </div>
    );
  }

  unescapeHtml(html) {
    var el = document.createElement('div');
    return html.replace(/\&[#0-9a-z]+;/gi, function (enc) {
      el.innerHTML = enc;
      return el.innerText;
    });
  }

  strip(html) {
    var tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  generatePDFWithTemplate() {
    const {
      generatePdfWithCatch,
      tourDetail: {
        hotelTitle,
        description,
        guarantee: { name, mobile },
        country,
        photos,
        capacities,
        transport,
        tripTypes,
        pdfProps: { strippedInstructions, croppedImage },
      },
      termList: { terms },
    } = this.props;

    const params = {
      hotelTitle,
      description,
      guarantee: { name, mobile },
      country,
      photos,
      capacities,
      transport,
      tripTypes,
      pdfProps: { strippedInstructions, croppedImage },
      terms,
    };

    generatePdfWithCatch(getFormattedPdfHtml, params);
  }
}

HotelPreview.propTypes = {
  tourDetail: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
  termList: state.termList,
  filterSettings: state.filterSettings,
});

const mapDispatchToProps = (dispatch) => ({
  generatePdfWithCatch: (func, params) => dispatch(generatePdfWithCatch(func, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(HotelPreview));
