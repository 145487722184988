import Modal from 'react-modal';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { close } from '../../../actions/Error/error';
import { connect } from 'react-redux';

const customModalStyles = {
  overlay: {
    position: 'fixed',
    zIndex: '9999',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0, 0.75)',
  },
  content: {
    width: '600px',
    top: '30%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

class ErrorMessage extends Component {
  state = {
    modalOpened: false,
    countingStarted: false,
  };

  componentWillReceiveProps(props) {
    const {
      error: { closed },
    } = props;

    if (!closed) {
      this.show();
    }
  }

  render() {
    const {
      error: { closed, errorMessage },
    } = this.props;

    return (
      <Modal isOpen={!closed} style={customModalStyles}>
        <b> {errorMessage}</b>
      </Modal>
    );
  }

  hide() {
    this.props.close();
  }

  show() {
    const { countingStarted } = this.state;
    const self = this;
    if (!countingStarted) {
      this.setState({ countingStarted: true });
      setTimeout(() => {
        self._timer = setInterval(self.hide.bind(self), 1500);
      }, 1000);
    }
  }
}

ErrorMessage.propTypes = {
  error: PropTypes.any,
  advertising: PropTypes.any,
  close: PropTypes.any,
};

const mapStateToProps = (state) => ({
  error: state.error,
});

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(close()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessage);
