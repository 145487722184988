import getLengthInDaysAndNights from '../../shared/getLengthInDaysAndNights';
import constants from '../../../styles/constants.js';
import formatDateLong from '../../shared/Moment/formatDateLong';
import crosLineImg from '../../../img/crossLine.png'; // eslint-disable-line
import formatDateShort from '../../shared/Moment/formatDateShort';
import getHeaders from '../../shared/getUniqueHeaders';
import formatCurrency from '../../shared/formatCurrency';
import getPriceFromCode from '../../shared/getPriceFromCode';
import createFilterRequest from '../../shared/RequestCreator/createFilterRequest';
import { fetchTerms } from '../../../actions/TourList/fetchTourList';
import { pickTour } from '../../../actions/TourDetail/tourDetail';
import { employee, szEmployee } from "../../../actions/HP/defaultTourlist";
import StyledCircleLoader from '../../shared/Loading/StyledCircleLoader';
import { fetchTourDetail } from '../../../actions/TourDetail/fetchTourDetail';
import { generatePDF } from '../../../actions/PDF/generatePDF';
import cookie from 'react-cookie';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

const specialOffer = 'Výhodná nabídka';
const soldOut = 'Vyprodáno';
const subsidy = 'dotovany';

const pickerPrintTable = 'pickerPrintTable';

export const renderPriceList = (terms, hotelName = '') => {
  const user = cookie.load('user');

  const type = user;
  const { headers, headersCodes } = getHeaders(terms, type);

  return (
    <div className="pricelist-div-line" id="printed" ref="printed">
      <p style={{ textAlign: 'left', padding: 10 }}>{hotelName}</p>
      <table className="pricelist-table">
        <tbody>
          {terms.length && renderPriceListHead(headers, type)}

          {terms.length && terms.map((term) => renderPrices(term, headersCodes, type))}
        </tbody>
      </table>
    </div>
  );
};

function renderPrices(term, headersCodes, type) {
  const { dateFrom, dateTo, lengthInDays, prices, isSubsidy, isSoldOut } = term;
  if ((type === szEmployee || type === employee) && !isSubsidy) {
    return null;
  }

  return (
    <tr className={isSoldOut ? 'term-sold-out' : ''}>
      <td className="table-centered">
        {formatDateShort(dateFrom) + ' - ' + formatDateLong(dateTo)}{' '}
      </td>
      <td className="table-centered">{lengthInDays !== 0 && lengthInDays}</td>

      {headersCodes.map((code) => renderTable(code, prices, type, isSoldOut))}
    </tr>
  );
}

function renderTable(code, prices, type, isSoldOut) {
  return (
    <td className="table-centered price">
      {isSoldOut ? soldOut : getPriceFromCode(code, prices, type)}
    </td>
  );
}

function renderPriceListHead(headers, type) {
  return (
    <tr className={'table-head ' + (type)}>
      <th className="table-centered">Datum</th>
      <th className="table-centered days-count">Dnů</th>
      {headers.map((item) => renderTableHeaderItems(item))}
    </tr>
  );
}

function renderTableHeaderItems(item) {
  return <th className="table-centered">{item}</th>;
}
