import constants from '../../../../../../styles/constants.js';
import crosLineImg from '../../../../../../img/crossLine.png'; // eslint-disable-line
import getHeaders from '../../../../../shared/getUniqueHeaders';
import { fetchTourDetail } from '../../../../../../actions/TourDetail/fetchTourDetail';
import { fetchTerms } from '../../../../../../actions/TourList/fetchTourList';
import { generatePDF } from '../../../../../../actions/PDF/generatePDF';
import createFilterRequest from '../../../../../shared/RequestCreator/createFilterRequest';
import PickerPriceList from '../PickerPriceList';
import tourPriceListTemplate from '../../../../../shared/TourDetail/tourPriceListTemplate';
import StyledCircleLoader from '../../../../../shared/Loading/StyledCircleLoader';

import OrangeButton from '../../../../../shared/OrangeButton';
import { printPriceList } from '../../../../../../utils/routeConfig';
import base64 from 'base-64';
import utf8 from 'utf8';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const styles = {
  wrapper: {
    marginBottom: '30px',
    clear: 'both',
  },
  leftAlign: {
    textAlign: 'left',
  },
  rightAlign: {
    textAlign: 'center',
  },
  sectionTitle: {
    marginTop: '0px',
    marginBottom: '40px',
    textTransform: 'uppercase',
    lineHeight: '23px',
    fontWeight: '700',
    padding: '1px 5px 1px 4px',
    color: [constants.yellow],
    display: 'inline-block',
    fontSize: '1.1em',
    borderBottom: '2px solid #ededed',
    width: '100%',
  },
  table: {
    borderCollapse: 'collapse',
    fontSize: '1em',
    maxWidth: '1000px',
    width: '100%',
    margin: '0 auto',
    marginTop: '30px',

    [constants.breakpointMobile]: {
      fontSize: '0.8em',
    },
    tbody: {
      backgroundColor: 'white',
    },
    head: {
      textAlign: 'left',
    },
    subsidyHead: {
      textAlign: 'left',
      backgroundColor: [constants.green],
      color: '#fff',
      fontWeight: 'bold',
      paddingBottom: '10px',
    },
    subsidyTitleHead: {
      color: 'white',
      backgroundColor: [constants.green],
      fontSize: '1.1em',
      fontWeight: '500',
      position: 'fixed',
      boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
      top: '30px',
      left: '-10',
      zIndex: 999,
      textAlign: 'center',
      padding: '20px',
    },
    subsidyTitle: {
      fontSize: '13px',
      textTransform: 'uppercase',
      color: 'black',
    },
    line: {
      width: '780',
    },
    crossLine: {
      backgroundImage: `url(${crosLineImg})`,
      backgroundPositionY: '50%',
      backgroundRepeat: 'repeat-x',
      color: '#999',
    },
    printingCrossLine: {
      backgroundPositionY: '50%',
      backgroundRepeat: 'repeat-x',
      color: 'rgba(216, 216, 216, 0.5)',
    },
    description: {},
    priceFor: {
      textAlign: 'right',
      minWidth: '60px',
    },
    price: {
      textAlign: 'right',
      minWidth: '80px',
    },
    infoBottom: {
      marginTop: '5px',
      width: '700px',
      margin: '0 auto',
    },
    infoBottomWrapper: {
      marginTop: '20px',
    },
  },
  tourNotices: {
    padding: ' 0 10px',
  },
  tourHeader: {
    justifyContent: 'center',
  },
  loading: {
    display: 'block',
    width: '200px',
    margin: '0 auto',
    justifyContent: 'center',
    marginTop: 120,
  },
  buttonSubsidyMarged: {
    margin: '20px 10px 0px 10px',
    background: [constants.green],
    borderColor: [constants.green],
    [constants.breakpoint360]: {
      position: 'relative',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    ':hover': {
      background: 'transparent',
      borderColor: [constants.green],
      color: [constants.green],
    },
  },
  buttonMarged: {
    margin: '20px 10px 0px 10px',
    [constants.breakpoint360]: {
      position: 'relative',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  buttons: {
    position: 'relative',
    left: '50%',
    float: 'left',
    transform: 'translateX(-50%)',
  },
};

export const subsidy = 'dotovany';
export const soldOut = 'Vyprodáno';

class PriceList extends Component {
  state = {
    fetched: false,
  };

  componentDidMount() {
    this.initialize();
  }

  componentWillReceiveProps(props) {
    const { tourDetail, fetchTourDetail, termList } = props;
    const { idDetail, type, detailFetched, fetchingTerms } = this.state;

    if (
      idDetail !== props.params.idDetail &&
      typeof props.params.idDetail !== 'undefined' &&
      !detailFetched
    ) {
      fetchTourDetail(props.params.idDetail);

      if (!type && typeof this.props.params.type !== 'undefined') {
        this.setState({ type: this.props.params.type });
      }
    }

    if (!fetchingTerms) {
      const {
        fetchTerms,
        filter,
        paginator: { minCost, itemsPerPage, maxCost, page },
      } = this.props;
      const properties = createFilterRequest(filter, page, itemsPerPage, minCost, maxCost, {
        changedShowTermList: true,
      });
      properties.idTour = props.params.idDetail;
      properties.type = 'list';
      fetchTerms(properties);
      this.setState({ fetchingTerms: true });
    }

    if (this.props.location.pathname.indexOf('printPriceList') >= 0) {
      this.setState({ printing: true });
    }

    if (Object.keys(tourDetail).length !== 0) {
      this.setState({ detailFetched: true });
    }

    if (detailFetched && Object.keys(termList).length !== 0) {
      this.setState({ fetched: true });
    }
  }

  componentDidUpdate() {
    const { fetched, printing } = this.state;

    printing && fetched && setTimeout(() => window.print(), 1000);
  }

  render() {
    const {
      tourDetail: { id, hotelTitle },
      termList: { terms },
    } = this.props;
    const { fetched, type, printing } = this.state;

    if (!fetched) {
      return (
        <div style={styles.loading}>
          <StyledCircleLoader />
        </div>
      );
    }

    const { headers } = getHeaders(terms, type);

    typeof this.state.headersLength === 'undefined' &&
      this.setState({ headersLength: headers.length });

    return (
      <div
        className={printing ? 'tour-price-list tour-price-list-print' : 'tour-price-list'}
        style={styles.wrapper}
      >
        {printing && <h style={styles.tourHeader}>{hotelTitle}</h>}
        <div className="pricelist-div-line" id="printed" ref="printed">
          <PickerPriceList />
        </div>
        {!printing && this.renderOrangeButtons(id, type, hotelTitle)}
      </div>
    );
  }

  renderOrangeButtons(id, type) {
    return (
      <div style={styles.buttons}>
        <OrangeButton
          onClick={() => this.generatePDF(document.getElementById('printed'))}
          target="_blank"
          extraStyle={type === subsidy ? styles.buttonSubsidyMarged : styles.buttonMarged}
        >
          Vygenerovat PDF
        </OrangeButton>
      </div>
    );
  }

  initialize() {
    const { fetchTourDetail, dataFetched } = this.props;

    if (!dataFetched) {
      fetchTourDetail(this.props.params.idDetail);
    } else {
      this.setState({ fetched: true });
    }
  }

  generatePDF(html) {
    const { headersLength } = this.state;
    const {
      generatePDF,
      tourDetail: { hotelTitle },
    } = this.props;

    const pdf = tourPriceListTemplate.replace('{content}', html);

    const bytes = utf8.encode(pdf);

    const encoded = base64.encode(bytes);

    const requestData = {
      base64Encoded: encoded,
    };

    const name = 'Cenik_' + hotelTitle.replace(/ /g, '_').replace(/\*/g, '');

    generatePDF(requestData, name.endsWith('_') ? name.substr(0, name.length - 1) : name);
  }
}

PriceList.propTypes = {
  params: PropTypes.any,
  generatePDF: PropTypes.any,
  type: PropTypes.any,
  location: PropTypes.any,
  pathname: PropTypes.any,
  tourDetail: PropTypes.any,
  termList: PropTypes.any,
  fetchTourDetail: PropTypes.any,
  dataFetched: PropTypes.any,
  fetchTerms: PropTypes.any,
  filter: PropTypes.any,
  paginator: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
  termList: state.termList,
  paginator: state.paginator,
  filter: state.filter,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTourDetail: (id) => dispatch(fetchTourDetail(id)),
  fetchTerms: (id) => dispatch(fetchTerms(id)),
  generatePDF: (params, name) => dispatch(generatePDF(params, name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PriceList);
