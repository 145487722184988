import constants from '../../../../../../styles/constants.js';
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const styles = {
  wrapper: {
    clear: 'both',
    width: '92%',
    padding: '0 4%',
  },
  link: {
    color: '#444',
    textDecoration: 'none',
  },
  termLine: {
    color: [constants.mainBlue],
    fontWeight: '500',
  },
};

class Description extends Component {
  render() {
    const {
      tourDetail: { description },
    } = this.props;
    return (
      <div style={styles.wrapper}>
        <p dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    );
  }
}

Description.propTypes = {
  tourDetail: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
});

export default connect(mapStateToProps)(Radium(Description));
