import constants from '../../styles/constants.js';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

const styles = {
  wrapper: {
    width: '100%',
    overflow: 'visible',

    [constants.breakpointTablet]: {
      overflowX: 'auto',
    },
  },
  table: {
    width: '100%',
    minWidth: '720px',
    fontSize: '0.9em',
    borderCollapse: 'collapse',
    border: 'none',
    borderSpacing: 0,

    [constants.breakpointTablet]: {
      fontSize: '0.8em',
    },
  },
};

export default class TableBox extends Component {
  render() {
    const { children } = this.props;

    return (
      <div style={styles.wrapper}>
        <table style={styles.table}>{children}</table>
      </div>
    );
  }
}

TableBox.propTypes = {
  children: PropTypes.string.isRequired,
};
