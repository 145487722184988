import constants from '../../../../../styles/constants.js';
import leftArrow from '../../../../../img/icons/back-arrow.png'; // eslint-disable-line
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pickTour } from '../../../../../actions/TourDetail/tourDetail';
import Radium from 'radium';
import './BackToList.scss';

class BackToList extends Component {
  render() {
    return (
      <div className="back-to-list-wrapper">
        <Link className="back-to-list-link" to="/zajezdy/vypis">
          <span className="back-to-list-text">
            <img src={leftArrow} className="back-to-list-arrow" />
            přehled zájezdů
          </span>
        </Link>
      </div>
    );
  }
}

BackToList.propTypes = {
  tourDetail: PropTypes.any,
  pickTour: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
});

const mapDispatchToProps = (dispatch) => ({
  pickTour: (idTour) => dispatch(pickTour(idTour)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(BackToList));
