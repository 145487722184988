import PDF from 'react-pdf-js';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import StyledCircleLoader from '../../shared/Loading/StyledCircleLoader';
import tourTemplate from '../../shared/TourDetail/tourPDFTemplate';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';
import { renderPriceList } from '../../shared/TourDetail/renderPriceList';
import { getPDFContent, renderPDFHeaderIcons } from '../../shared/TourDetail/pdfHeaderUtils';
import base64 from 'base-64';
import { generatePdfWithCatch } from '../../../actions/PDF/generatePDF';
import utf8 from 'utf8';
import { getPdfDetail } from '../../../actions/PDF/generatePDF';
import Slider from './Slider';

import './PdfList.scss';

const getFormattedPdfHtml = (tourDetail, fontSize = 13) => {
  const {
    id,
    hotelTitle,
    guarantee: { name, mobile },
    country,
    photos,
    capacities,
    transport,
    tripTypes,
    pdfProps: { strippedInstructions, croppedImage },
    terms,
  } = tourDetail;

  const headerIcons = renderPDFHeaderIcons(capacities, transport, tripTypes);

  const content = getPDFContent(photos, strippedInstructions, `hotel-pdf-description-${id}`);
  const priceList = ReactDOMServer.renderToString(renderPriceList(terms, hotelTitle));
  const pdf = tourTemplate
    .replace('{country}', country)
    .replace('{hotel}', hotelTitle)
    .replace('{mainImage}', croppedImage ? croppedImage : photos[0] ? photos[0].imgSrc : '')
    .replace('{availabilityIcons}', headerIcons)
    .replace('{guaranteeName}', name)
    .replace('{guaranteePhone}', mobile)
    .replace('{content}', content)
    .replace('{dynamicFontSize}', fontSize)
    .replace('{priceList}', priceList);

  const saveName = 'Zajezd_' + hotelTitle.replace(/ /g, '_').replace(/\*/g, '');

  return { pdf, saveName };
};

class Widget extends Component {
  state = {
    selected: 0,
    loadedDocuments: 0,
  };

  constructor(props) {
    super(props);
  }

  goTo(selected) {
    this.slider.goTo(selected);
    this.setState({ selected });
  }

  componentWillReceiveProps(props) {
    const {
      catalogue: { pdfList },
    } = this.props;
    const overlay = document.getElementById('catalogue-loader');

    if (!props.catalogue.pdfList.length) {
      this.setState({ selected: 0 });
      overlay.style.visibility = 'visible';
    } else {
      overlay.style.visibility = 'hidden';
    }
  }

  render() {
    return (
      <div>
        {this.renderLoading()}
        {this.renderSlider()}
      </div>
    );
  }

  renderSlider() {
    const {
      catalogue: { pdfList },
    } = this.props;

    if (!pdfList.length) {
      return null;
    }

    return (
      <div className="rendered">
        {this.renderListing(true)}
        <div className="pdf-wrapper">
          <Slider onRef={(ref) => (this.slider = ref)} />

          <ReactTooltip html={Boolean(true)} />
        </div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="catalogue-loader" id="catalogue-loader">
        <p>Katalog se načítá, chvilku strpení</p>
        <StyledCircleLoader />
      </div>
    );
  }

  renderListing(loaded) {
    const {
      catalogue: { pdfList },
      getPdfDetail,
    } = this.props;
    const { selected, loadedDocuments } = this.state;

    const previousDisabled = loaded ? selected === 0 : true;
    const previousDisabledClass = previousDisabled ? ' disabled' : '';
    const nextDisabled = loaded ? selected === pdfList.length - 1 : true;
    const nextDisabledClass = nextDisabled ? ' disabled' : '';
    const selectedPdf = pdfList[selected];

    return (
      <div className="listing">
        <div className="navigation">
          <i
            data-tip={'předchozí'}
            onClick={() => !previousDisabled && this.goTo(selected - 1)}
            className={'material-icons navigate left ' + previousDisabledClass}
          >
            navigate_before
          </i>

          <div>{`${selected + 1} / ${pdfList.length}`}</div>

          <i
            data-tip={'další'}
            className={'material-icons navigate right' + nextDisabledClass}
            onClick={() => !nextDisabled && this.goTo(selected + 1)}
          >
            navigate_next
          </i>
        </div>

        <div className="pdf-controls">
          <div onClick={() => this.generatePDFWithTemplate(selectedPdf)} className="print-pdf">
            <i data-tip={'Vytisknout'} className="material-icons">
              print
            </i>
          </div>
        </div>
      </div>
    );
  }

  generatePDFWithTemplate(tourDetail) {
    const { generatePdfWithCatch } = this.props;

    generatePdfWithCatch(getFormattedPdfHtml, tourDetail);
  }
}

const mapStateToProps = (state) => ({
  catalogue: state.catalogue,
});

const mapDispatchToProps = (dispatch) => ({
  generatePdfWithCatch: (formatFunc, paramsObject) =>
    dispatch(generatePdfWithCatch(formatFunc, paramsObject)),
  getPdfDetail: (id, name) => dispatch(getPdfDetail(id, name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Widget);
