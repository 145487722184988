import constants from '../../../../../styles/constants.js';
import formatDateLong from '../../../../shared/Moment/formatDateLong';
import formatDateShort from '../../../../shared/Moment/formatDateShort';
import getInstructionsWithImg from '../../../../shared/TourDetail/getInstructionsWithImg';
import { fetchTourDetail } from '../../../../../actions/TourDetail/fetchTourDetail';
import { generatePDF } from '../../../../../actions/PDF/generatePDF';
import { printInstructions } from '../../../../../utils/routeConfig';
import instructionsTemplate from '../../../../shared/TourDetail/tourInstructionsTemplate';
import base64 from 'base-64';
import utf8 from 'utf8';
import OrangeButton from '../../../../shared/OrangeButton';
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StyledCircleLoader from '../../../../shared/Loading/StyledCircleLoader';
import { connect } from 'react-redux';

const styles = {
  wrapper: {
    clear: 'both',
    width: '92%',
    padding: '5px 4%',

    [constants.breakpointTablet]: {
      marginTop: '0',
    },
  },
  blue: {
    padding: 20,
    color: [constants.mainBlue],
  },
  wrapperPrint: {
    marginTop: '100px',
  },
  printingHeader: {
    marginBottom: '40px',
    borderBottom: 'solid 1px #d6d6d6',
    paddingBottom: '10px',
    position: 'relative',
  },
  link: {
    color: '#444',
    textDecoration: 'none',
  },
  termLine: {
    color: [constants.mainBlue],
    fontWeight: '500',
  },
  loading: {
    display: 'block',
    width: '200px',
    margin: '0 auto',
    justifyContent: 'center',
    marginTop: '120px',
  },
  buttonLeft: {
    margin: '0 15px 0 0 ',

    [constants.breakpointMobile]: {
      position: 'relative',
      marginBottom: '10px',
    },
  },
  logo: {
    textAlign: 'center',
    width: '200px',
    position: 'absolute',
    right: '0',
    top: '-80px',
  },
};
const printing = 'printing';

class Instructions extends Component {
  state = {
    fetched: false,
  };

  componentDidMount() {
    this.initialize();
  }

  componentWillReceiveProps(props) {
    const { tourDetail, fetchTourDetail } = props;
    const { params } = this.props;
    const { idDetail, fetched } = this.state;
    let loaded = fetched;

    if (idDetail !== params.idDetail && typeof paramsIdDetail !== 'undefined' && !fetched) {
      fetchTourDetail(props.params.idDetail);
    }

    if (window.location.href.indexOf('instructions') >= 0) {
      this.setState({ generating: true });
    }

    if (JSON.stringify(tourDetail) !== JSON.stringify({})) {
      this.setState({ fetched: true });
      loaded = true;
    }

    if (loaded && params.status === printing) {
      setTimeout(() => window.print(), 1000);
    }
  }

  render() {
    const {
      tourDetail: { instructions, id, hotelTitle },
    } = this.props;
    const { generating, fetched } = this.state;

    if (!fetched) {
      return (
        <div style={styles.loading}>
          <StyledCircleLoader />
        </div>
      );
    }

    const htmlInstructions = getInstructionsWithImg(instructions, false);

    if (instructions) {
      if (generating) {
        return (
          <div className="instructions-wrapper" id="printed" style={styles.wrapperPrint}>
            {this.renderHeader()}
            <p
              className="instructions-print-info"
              dangerouslySetInnerHTML={{ __html: htmlInstructions }}
            />
          </div>
        );
      } else {
        return (
          <div className="instructions-wrapper" style={styles.wrapper}>
            <p dangerouslySetInnerHTML={{ __html: htmlInstructions }} id="printed" />
            {this.renderPrintButton(id, hotelTitle)}
          </div>
        );
      }
    }

    return this.noInstructionsMessage();
  }

  noInstructionsMessage() {
    return <strong style={styles.blue}>Zájezd nemá uvedené pokyny</strong>;
  }

  renderHeader() {
    const {
      tourDetail: { country, title, location, dateFrom, dateTo },
    } = this.props;
    return (
      <div style={styles.printingHeader}>
        <img style={styles.logo} src={'//www.cdtravel.cz/www/images/logo.png'} />

        <span>
          <b>Destinace:</b> {country + ' ' + location + ' ' + title}
        </span>
      </div>
    );
  }

  renderPrintButton(id) {
    return (
      <div>
        <OrangeButton
          onClick={() => this.generatePDF()}
          target="_blank"
          extraStyle={styles.buttonLeft}
          icon="picture_as_pdf"
        >
          Vygenerovat PDF
        </OrangeButton>
      </div>
    );
  }

  strip(html) {
    var tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  generatePDF() {
    const {
      tourDetail: { instructions, country, title, location, dateFrom, dateTo },
    } = this.props;
    const {
      generatePDF,
      tourDetail: { hotelTitle },
    } = this.props;

    const preformattedInstructions = getInstructionsWithImg(instructions, false)
      .replace(/<br \/>/g, ' |br| ')
      .replace(/<a /g, ' |a| ')
      .replace(/<img /g, ' |img| ')
      .replace(/<\/a>/g, ' |/a| ')
      .replace(/<\/strong>/g, '|/strong| ')
      .replace(/<strong>/g, '|strong| ')
      .replace(/<p>/g, '|p| ')
      .replace(/<li.*?>/g, '|li| ')
      .replace(/<\/li>/g, '|/li| ')
      .replace(/<\/p>/g, '|/p| ')
      .replace(/<tr.*?>/g, '|tr| ')
      .replace(/<\/tr>/g, '|/tr| ')
      .replace(/<table.*?>/g, '|table| ')
      .replace(/<\/table>/g, '|/table| ')
      .replace(/<tbody.*?>/g, '|tbody| ')
      .replace(/<\/tbody.*?>/g, '|/tbody| ')
      .replace(/<td.*?>/g, '|td| ')
      .replace(/<\/td>/g, '|/td| ')
      .replace(/<h1.*?>/g, '|h1| ')
      .replace(/<\/h1>/g, '|/h1| ');

    const strippedInstructions = this.strip(preformattedInstructions);

    const formattedInstructions = strippedInstructions
      .replace(/\|br\|/g, '<br />')
      .replace(/\|p\|/g, '<p>')
      .replace(/\|img\|/g, '<img')
      .replace(/\|\/p\|/g, '</p>')
      .replace(/\|a\|/g, '<a')
      .replace(/\|\/a\|/g, '</a>')
      .replace(/\|\/strong\|/g, '</strong>')
      .replace(/\|strong\|/g, '<strong> ')
      .replace('<p>', '<p class="title">')
      .replace(/\|tr\|/g, '<tr>')
      .replace(/\|\/tr\|/g, '</tr>')
      .replace(
        /\|li\|/g,
        "<li style='list-style-type: disc; list-style-image: none; list-style-position: inside'>",
      )
      .replace(/\|\/li\|/g, '</li>')
      .replace(/\|table\|/g, "<table width='750' style='margin: 0 auto; color: #386696;'>")
      .replace(/\|\/table\|/g, '</table>')
      .replace(/\|tbody\|/g, '<tbody>')
      .replace(/\|\/tbody\|/g, '</tbody>')
      .replace(/\|td\|/g, "<td style='border-bottom: 1px solid #ccc; padding: 5px 0px;'>")
      .replace(/\|\/td\|/g, '</td>')
      .replace(/\|h1\|/g, '<h1>')
      .replace(/\|\/h1\|/g, '</h1>');

    const pdf = instructionsTemplate.replace('{content}', formattedInstructions);

    const bytes = utf8.encode(pdf);

    const encoded = base64.encode(bytes);

    const requestData = { base64Encoded: encoded };

    generatePDF(requestData, hotelTitle + ' instrukce');
    this.setState({ generated: true });
  }

  initialize() {
    const { fetchTourDetail, dataFetched } = this.props;

    if (!dataFetched) {
      fetchTourDetail(this.props.params.idDetail);
    } else {
      this.setState({ fetched: true });
    }
  }
}

Instructions.propTypes = {
  tourDetail: PropTypes.any,
  generatePDF: PropTypes.any,
  params: PropTypes.any,
  fetchTourDetail: PropTypes.any,
  dataFetched: PropTypes.any,
  location: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTourDetail: (id) => dispatch(fetchTourDetail(id)),
  generatePDF: (params, name) => dispatch(generatePDF(params, name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(Instructions));
