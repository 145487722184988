import formatCurrency from './formatCurrency';

const dataTipWithPrice = (dataTip, note) => {
  return dataTip + note.description + ' ' + formatCurrency(note.price) + '<br>';
};

const handleTourTipText = (foodDetail, dataTip) => {
  if (foodDetail.price) {
    dataTip = dataTipWithPrice(dataTip, foodDetail);
  } else {
    dataTip = dataTip + foodDetail.description + '<br>';
  }

  return dataTip;
};

const shouldRenderFood = (foodDetail, foodDetails) => {
  if (foodDetail.type !== 'children') {
    return true;
  }

  const adultFood = foodDetails.find((detail) => {
    return detail.code === foodDetail.baseCode;
  });

  if (!adultFood) {
    return true;
  }

  return adultFood.price !== foodDetail.price;
};

const getDataTip = (tourDetail) => {
  const { notes, transport } = tourDetail;
  const detailData = ['foodDetail', 'transport', 'tourDiscounts', 'foodExtras', 'notes'];

  let dataTip = '';

  detailData.forEach((detailItem) => {
    if (detailItem === 'transport' && typeof transport !== 'undefined') {
      transport.forEach((item) => {
        dataTip = dataTip + item.title + '<br>';
      });
    } else if (detailItem === 'foodDetail' && typeof tourDetail[detailItem] !== 'undefined') {
      tourDetail[detailItem].forEach((foodDetail) => {
        if (shouldRenderFood(foodDetail, tourDetail[detailItem])) {
          dataTip = handleTourTipText(foodDetail, dataTip);
        }
      });
    } else if (detailItem === 'notes' && typeof notes !== 'undefined') {
      notes.forEach((note) => {
        dataTip = handleTourTipText(note, dataTip);
      });
    } else if (typeof tourDetail[detailItem] !== 'undefined') {
      tourDetail[detailItem].forEach((note) => {
        dataTip = handleTourTipText(note, dataTip);
      });
    }
  });

  return dataTip;
};

export default getDataTip;
