import { SET_ERROR, CLOSE_ERROR } from '../actions/Error/error';

const initialState = {
  closed: true,
  errorMessage: {},
};

const filter = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return { ...state, errorMessage: action.message, closed: false };

    case CLOSE_ERROR:
      return { ...state, closed: true };

    default:
      return state;
  }
};

export default filter;
