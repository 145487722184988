import Radium from 'radium';
import ImageGallery from 'react-image-gallery';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HotelPreview from './HotelPreview';
import { connect } from 'react-redux';
import HotelInformations from './HotelInformations';

const styles = {
  openGalleryMessage: {
    position: 'absolute',
    left: '50%',
    top: 0,
    zIndex: 4,
    background: 'rgba(0, 0, 0, 0.7)',
    padding: 10,
    color: 'white',
    transform: 'translateX(-50%)',
  },
};

class RightDetailRow extends Component {
  state = {
    fixed: false,
  };

  render() {
    const {
      tourDetail: { photos },
      term,
    } = this.props;
    const { fullscreen } = this.state;
    const formattedPhotos = this.getFormattedPhotos(photos);

    return (
      <div className="sticking-gallery" onKeyDown={this.close.bind(this)}>
        {!fullscreen && <div style={styles.openGalleryMessage}>Dvojklikem otevřete galerii</div>}
        <div onDoubleClick={() => this.enterFullScreen()}>
          <ImageGallery
            ref={(i) => {
              this.imageGallery = i;
            }}
            onScreenChange={this.onScreenChange.bind(this)}
            items={formattedPhotos}
            slideInterval={5000}
            showPlayButton={false}
          />
        </div>
        <HotelInformations term={term} />
        <HotelPreview />
      </div>
    );
  }

  close(event) {
    if (event.keyCode === 27) {
      document.getElementsByClassName('image-gallery-fullscreen-button active')[0].click();
    }
  }

  onScreenChange(fullScreenElement) {
    this.setState({ fullscreen: fullScreenElement });
  }

  enterFullScreen() {
    this.imageGallery.fullScreen();
  }

  getFormattedPhotos(photos) {
    if (photos) {
      const formattedPhotos = photos.map((photo) => {
        const { imgSrc, preview } = photo;
        return { original: imgSrc, thumbnail: preview };
      });

      return formattedPhotos;
    }

    return null;
  }
}

RightDetailRow.propTypes = {
  tourDetail: PropTypes.any,
  term: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
});

export default connect(mapStateToProps)(Radium(RightDetailRow));
