import { getJSON } from '../../utils/fetch';

export const SET_PASSENGERS = 'SET_PASSENGERS';
export const SET_PASSENGERS_OPTIONS = 'SET_PASSENGERS_OPTIONS';
export const SET_SORTED_PASSENGERS = 'SET_SORTED_PASSENGERS';
export const SET_SORT = 'SET_SORT';

export const fetchPassengers = () => {
  return (dispatch) => {
    getJSON('passengers/').then((result) => {
      dispatch({
        type: SET_PASSENGERS,
        result,
      });
    });
  };
};

export const fetchPassengerOptions = () => {
  return (dispatch) => {
    getJSON('together-traveling-options/').then((result) => {
      dispatch({
        type: SET_PASSENGERS_OPTIONS,
        result,
      });
    });
  };
};
