import constants from '../../styles/constants.js';
import logo from '../../img/logo.png'; // eslint-disable-line

import React, { Component } from 'react';
import { Link } from 'react-router';
import Radium from 'radium';

const styles = {
  wrapper: {
    position: 'absolute',
    top: '11px',
    left: '32px',
    height: 'auto',
    textAlign: 'center',
    transform: 'none',
    zIndex: '9',

    [constants.breakpointLargeTablet]: {
      left: '11px',
    },

    [constants.breakpoint600]: {
      left: '10px',
      top: '7px',
    },

    [constants.breakpoint360]: {
      top: '9px',
    },
  },
  logo: {
    bottom: 0,
    width: '155px',

    [constants.breakpoint600]: {
      width: '120px',
    },

    [constants.breakpoint360]: {
      width: '103px',
    },
  },
};

class Logo extends Component {
  render() {
    return (
      <div style={styles.wrapper}>
        <Link to="/">
          <img style={styles.logo} src={logo} />
        </Link>
      </div>
    );
  }
}

export default Radium(Logo);
