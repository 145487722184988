import cookie from 'react-cookie';
import { employee, szEmployee } from "../actions/HP/defaultTourlist";
const user = cookie.load('user');
const isEmployee = user === employee;

const constants = {
  mainBlue: '#386696',
  grey: '#ccc',
  mainOrange: user === employee ? '#afc836' : user === szEmployee ? '#FF5200' : '#edb700',
  orangeLight: user === employee ? '#afc836' : user === szEmployee ? '#FF5200' : '#ffd133',
  btnHover: user === employee ? '#afc836' : user === szEmployee ? '#FF5200' : '#e6b200',
  littleGrayScale: 'rgba(0, 0, 0, 0.07)',
  yellow: user === employee ? '#afc836' : user === szEmployee ? '#FF5200' : '#edb700',
  green: 'green',
  halfTransparentOrange: user === employee ? 'rgba(175, 200, 54, .86)' : user === szEmployee ? 'rgba(255, 82, 0, .86)' : 'rgba(254, 197, 3, .86)',

  pink: '#fcefdc',
  black: '#222',

  inputBorder: '#ccc',
  transition: '0.3s',
};

export default constants;
