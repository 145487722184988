import { getJSON } from '../../utils/fetch';

export const SET_EMPLOYEE_INSTRUCTIONS = 'SET_EMPLOYEE_INSTRUCTIONS';

export const fetchEmployeeInstructions = () => {
  return (dispatch) => {
    getJSON('employee-instructions').then((result) => {
      dispatch({
        type: SET_EMPLOYEE_INSTRUCTIONS,
        result,
      });
    });
  };
};
