import { setInfantOccupation, resetOccupation } from '../../../../actions/Calculator/calculator';
import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { styles } from '../../Tours/ToursList/components/MultiCheckbox';

class InfantsPicker extends Component {
  render() {
    const {
      calculator: {
        data: { availableCustomers },
      },
    } = this.props;

    if (!availableCustomers) {
      return null;
    }

    const infants = availableCustomers.filter((customer) => {
      return !customer.fullCalculation;
    });

    if (!infants.length) {
      return (
        <div>
          {infants.map((infant) => this.renderInfantPicker(infant))}
          <div className="infants-info">
            <div className="info-text-wrapper">
              {this.renderSpecialPeoplePriceMessage(availableCustomers)}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        {infants.map((infant) => this.renderInfantPicker(infant))}
        <div className="infants-info">
          <div className="info-text-wrapper">
            {this.renderSpecialPeoplePriceMessage(availableCustomers)}
            <div>
              <i className="material-icons">info</i> <span>Infanti nezabírají plné místo</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSpecialPeoplePriceMessage() {
    const {
      calculator: { specialAdult },
    } = this.props;

    if (specialAdult) {
      return (
        <div>
          <i className="material-icons">info</i>
          <span>Stejné ceny u dětí i dospělých</span>
        </div>
      );
    }
  }

  renderInfantPicker(infant) {
    const {
      calculator: {
        settings: { infants },
      },
      setInfantOccupation,
    } = this.props;
    const { code } = infant;

    return (
      <div className="picker picker-small">
        <label>Infant</label>
        <input
          className="text-input"
          value={infants[code]}
          onChange={(event) => setInfantOccupation(code, event.target.value)}
          type="number"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  calculator: state.calculator,
});

const mapDispatchToProps = (dispatch) => ({
  setInfantOccupation: (occupationCode, count) =>
    dispatch(setInfantOccupation(occupationCode, count)),
  resetOccupation: (occupationCode) => dispatch(resetOccupation(occupationCode)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Radium(InfantsPicker));
