import constants from '../../../../../styles/constants';
import Radium from 'radium';
import selectArrow from '../../../../../img/select-arrow2.png'; // eslint-disable-line
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeTabs, selecting, toggle } from '../../../../../actions/TourList/filterOpenStatus';
import createFilterRequest from '../../../../shared/RequestCreator/createFilterRequest';
import {
  resetFilter,
  resetLocations,
  toggleResetingLocations,
  toggleSelected,
} from '../../../../../actions/TourList/tourFilter';
import { fetchFilteredList } from '../../../../../actions/TourList/fetchTourList';
import { toggleTiles } from '../../../../../actions/TourList/paginator';
import { saveFilter } from '../../../../../actions/TourList/filter';
import PropTypes from 'prop-types';
import arrowDown from '../../../../../img/down-arrow.svg';

export const styles = {
  wrapper: {
    width: '100%',
  },
  inputBoxShorter: {
    width: '100%',
    marginBottom: '0.5%',
    float: 'left',
    position: 'relative',
  },
  inputBox: {
    width: '100%',
    marginBottom: '0.5%',
    float: 'left',
    position: 'relative',
  },

  selectBox: {
    width: 'calc(100% - 40px)',
    height: 40,
    overflow: 'hidden',
    padding: '0 30px 0 10px',
    lineHeight: '40px',
    borderRadius: '5px',
    border: '1px solid' + [constants.inputBorder],
    display: 'inline-block',
    backgroundColor: '#fff',
    backgroundImage: 'url(' + arrowDown + ')',
    backgroundSize: 20,
    backgroundPosition: 'right center',
    backgroundRepeat: 'no-repeat',
    color: [constants.black],
    fontSize: '0.9em',
    fontWeight: '500',
    cursor: 'pointer',
  },
  dropDown: {
    background: [constants.dropDownBackground],
    position: 'absolute',
    top: 45,
    borderRadius: 3,
    left: 1,
    width: '100%',
    zIndex: 4,
    fontSize: '0.9em',
    boxShadow: 'rgba(0, 0, 0, 0.8) 0px 0px 1px',

    item: {
      padding: '8px 5px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
      cursor: 'pointer',
      margin: '0 7px',
      color: '[constants.dropDownText]',
    },
    checkbox: {
      marginRight: '10px',
      position: 'relative',
      top: '1px',
    },
    caret: {
      display: 'none',
      position: 'absolute',
      top: '-8px',
      left: '7px',
      width: '0',
      height: '0',
      content: '',
      verticalAlign: 'middle',
      borderBottom: '10px dashed' + [constants.dropDownBackground],
      borderRight: '10px solid transparent',
      borderLeft: '10px solid transparent',
    },
  },
};

const typeCountry = 'countrySortedByName';
const typeLocation = 'location';

class MultiCheckbox extends Component {
  state = {
    text: '',
    loading: true,
  };

  componentDidMount() {
    this.checkChange(this.props);
  }

  componentWillReceiveProps(props) {
    this.checkChange(props);
  }

  render() {
    const { defaultText, data, filterOpen, type, box, style } = this.props;
    const { text } = this.state;

    return (
      <div style={style ? styles[style] : styles.inputBox}>
        <div onClick={() => this.toggleOpen(type)} style={styles.selectBox} className="select-box">
          {text || defaultText}
        </div>
        <div style={styles.dropDown}>
          {filterOpen[type] && <div style={styles.dropDown.caret} />}
          {filterOpen[type] && (
            <div className="item" style={styles.dropDown.item} onClick={() => this.reset()}>
              {defaultText}
            </div>
          )}
          {filterOpen[type] && data.map((items) => this.renderBox(items, box))}
        </div>
      </div>
    );
  }

  toggleOpen(designation) {
    const { toggle, filterOpen, type, closeTabs } = this.props;
    if (!filterOpen[type]) {
      toggle(designation);
    } else {
      closeTabs();
    }
  }

  reset() {
    const { resetFilter, type, resetLocations, saveFilter } = this.props;
    const { text } = this.state;

    if (type === typeCountry) {
      resetLocations(text);
      saveFilter([], typeLocation);
    }

    resetFilter(type);
  }

  renderBox(items, box) {
    const { value, code, selected } = items;

    if (box === 'textbox') {
      return (
        <div
          className="item"
          style={styles.dropDown.item}
          value={code}
          onClick={() => this.toggleCheckbox(code, false)}
        >
          {value}
        </div>
      );
    } else {
      return (
        <div
          onClick={this.toggleCheckbox.bind(this, code, true)}
          className="item"
          style={styles.dropDown.item}
        >
          <input type="checkbox" style={styles.dropDown.checkbox} value={code} checked={selected} />
          <label style={styles.dropDown.label}>{value}</label>
        </div>
      );
    }
  }

  checkChange(props) {
    const { defaultText } = this.state;
    const {
      data,
      type,
      filter,
      saveFilter,
      paginator: { itemsPerPage, page, minCost, maxCost },
      fetchFilteredList,
    } = props;
    let checkboxText = '';
    const propertyFilter = [];

    try {
      data.forEach((data) => {
        if (data.selected) {
          checkboxText = checkboxText ? checkboxText + ' ' + data.value : data.value;
          propertyFilter.push(data.code);
        }
      });
      if (checkboxText === '') {
        checkboxText = defaultText;
      }
    } catch (err) {
      checkboxText = defaultText;
    }
    this.setState({ text: checkboxText });
    if (JSON.stringify(propertyFilter) !== JSON.stringify(filter[type])) {
      filter[type] = propertyFilter;

      fetchFilteredList(createFilterRequest(filter, page, itemsPerPage, minCost, maxCost));
      saveFilter(propertyFilter, type);
    }
  }

  toggleCheckbox(code, checkbox) {
    const { type, toggleSelected, selecting, toggleTiles, toggleResetingLocations } = this.props;
    const {
      paginator: { resetingLocations },
      onSelect,
    } = this.props;
    onSelect && onSelect();
    toggleSelected(code, type, checkbox);
    if (checkbox) {
      selecting();
    }
    if (!resetingLocations) {
      toggleResetingLocations(true);
    }
    toggleTiles(false);
  }
}

MultiCheckbox.propTypes = {
  defaultText: PropTypes.any,
  saveFilter: PropTypes.any,
  resetLocations: PropTypes.any,
  data: PropTypes.any,
  filterOpen: PropTypes.any,
  type: PropTypes.any,
  box: PropTypes.any,
  toggle: PropTypes.any,
  closeTabs: PropTypes.any,
  toggleSelected: PropTypes.any,
  toggleResetingLocations: PropTypes.any,
  paginator: PropTypes.any,
  resetFilter: PropTypes.any,
  selecting: PropTypes.any,
  toggleTiles: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  toggle: (designation) => dispatch(toggle(designation)),
  selecting: () => dispatch(selecting()),
  resetFilter: (designation) => dispatch(resetFilter(designation)),
  resetLocations: (designation) => dispatch(resetLocations(designation)),
  toggleResetingLocations: (status) => dispatch(toggleResetingLocations(status)),
  fetchFilteredList: (filters) => dispatch(fetchFilteredList(filters)),
  toggleTiles: (nextState) => dispatch(toggleTiles(nextState)),
  closeTabs: () => dispatch(closeTabs()),
  saveFilter: (filter, designation) => dispatch(saveFilter(filter, designation)),
  toggleSelected: (code, designation, checkbox) =>
    dispatch(toggleSelected(code, designation, checkbox)),
});

const mapStateToProps = (state) => ({
  filter: state.filter,
  paginator: state.paginator,
  filterOpen: state.filterOpen,
  tourList: state.tourList,
  tourFilter: state.tourFilter,
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(MultiCheckbox));
