import { SET_OCCUPATION_TEXT } from '../actions/Occupation/fetchOccupation';

const initialState = {
  textState: '',
};

const filter = (state = initialState, action) => {
  switch (action.type) {
    case SET_OCCUPATION_TEXT:
      return { ...state, textState: action.text };

    default:
      return state;
  }
};

export default filter;
