import Modal from 'react-modal';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import StyledCircleLoader from './StyledCircleLoader';

const customModalStyles = {
  overlay: {
    position: 'fixed',
    zIndex: '9999',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255,255,255, 0.75)',
  },
  content: {
    width: '200px',
    overflow: 'hidden',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    border: 'none',
    boxShadow: 'none',
    background: 'transparent',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

class LoaderInModalWindow extends Component {
  render() {
    return (
      <Modal isOpen={Boolean(true)} style={customModalStyles}>
        <StyledCircleLoader />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  fetchState: state.fetchState,
});

export default connect(mapStateToProps)(LoaderInModalWindow);
