export const CLOSE_TABS = 'CLOSE_TABS';
export const SELECTING_IN_CHECKBOX = 'SELECTING_IN_CHECKBOX';
export const TOGGLE_OPEN = 'TOGGLE_OPEN';

export const toggle = (designation) => {
  return (dispatch) => {
    dispatch({
      type: CLOSE_TABS,
    });
    dispatch({
      type: TOGGLE_OPEN,
      designation,
    });
  };
};

export const closeTabs = () => ({
  type: CLOSE_TABS,
});

export const selecting = () => ({
  type: SELECTING_IN_CHECKBOX,
});
