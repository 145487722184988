import React, { Component } from 'react';
import PropTypes from 'prop-types';

import constants from '../../../styles/constants.js';

const styles = {
  input: {
    padding: '10px 9px',
    background: '#fff',
    borderWidth: '1px',
    borderRadius: '5px',
    borderStyle: 'solid',
    borderColor: [constants.inputBorder],
    width: 'calc(100% - 20px)',
    fontSize: '0.9em',
    marginTop: '5px',
  },
  inputWrapper: {
    marginTop: '10px',
  },
};

export default class TextInput extends Component {
  render() {
    const { name, label, onChange, value } = this.props;

    return (
      <div style={styles.inputWrapper}>
        <label htmlFor={name}>
          <b>{label}</b>
        </label>

        <input
          type="text"
          name={name}
          id={name}
          value={value}
          onChange={({ target: { value } }) => onChange(value)}
          style={styles.input}
        />
      </div>
    );
  }
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
