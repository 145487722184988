import crossImg from '../../../img/icons/cancel.png'; // eslint-disable-line
import headImage from '../../../img/bcg-tours.jpg'; // eslint-disable-line
import styles from '../../shared/TourListStyles/TourListHeadStyles';
import { setCost, toggleTiles } from '../../../actions/TourList/paginator';
import { fetchFilteredList } from '../../../actions/TourList/fetchTourList';
import React, { Component } from 'react';
import Radium from 'radium';
import OccupationFilter from './OccupationFilter';
import OccupationLegend from './OccupationLegend';
import { connect } from 'react-redux';

class OccupationHeader extends Component {
  render() {
    return (
      <div style={styles.sectionHead}>
        <div style={{ ...styles.wrapperBig, ...{ height: '300px' } }}>
          <img style={styles.image} src={headImage} />
          <div style={styles.contentBox}>
            <h2 style={styles.h2} className="tours-main-title">
              OBSAZENOST
            </h2>
            <OccupationFilter />
            <OccupationLegend />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tourList: state.tourList,
  tourFilter: state.tourFilter,
  filter: state.filter,
  paginator: state.paginator,
  fetchState: state.fetchState,
  defaultTourListFilter: state.defaultTourListFilter,
});

const mapDispatchToProps = (dispatch) => ({
  toggleTiles: (nextState) => dispatch(toggleTiles(nextState)),
  fetchFilteredList: (filters) => dispatch(fetchFilteredList(filters)),
  setCost: (min, max) => dispatch(setCost(min, max)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(OccupationHeader));
