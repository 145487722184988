import {
  SET_TOUR_CALCULATOR_SETINGS,
  SET_SETTINGS_PARAMETER,
  RESET_CALCULATOR_OCCUPATION,
  CLEAN_OCCUPATION,
  SET_CALCULATOR_OCCUPATION,
  TOGGLE_GRANT,
  RESET_CALCULATOR,
  SET_INFANTS_COUNT,
  SET_CHILDREN_AGE,
  TOGGLE_ADDITIONAL_PRICE,
  SENDING_RESERVATION,
  RESERVATION_SENT,
} from '../actions/Calculator/calculator';
import {
  adultPriceByCustomerIndex,
  childrenPriceByCustomerIndex,
  formatCurrency,
  isGrantUsed,
} from '../components/Pages/Calculator/calculations';
import { getEatingPrice } from '../components/Pages/Calculator/Summary/rowItems/Eating';

export const customerCombinationParam = 'customerCombination';
export const eatingParam = 'eating';
export const surchargeParam = 'surcharge';
export const optionalSurchargeParam = 'optionalSurcharge';
export const discountParam = 'discount';
export const transportParam = 'transport';
export const departureParam = 'departureFrom';

export const getOccupation = (state) => {
  const fullOccupation = {
    children: [],
    adult: [],
    infants: getInfants(state),
  };

  const {
    settings: { occupation },
  } = state;

  for (let i = 0; i < occupation['adult']; i++) {
    fullOccupation.adult.push(getSingleOccupation(i, state, 'adult'));
  }
  for (let i = 0; i < occupation['children']; i++) {
    fullOccupation.children.push(getSingleOccupation(i, state, 'children'));
  }

  return fullOccupation;
};

const getInfants = (state) => {
  const {
    settings: { infants },
    data: { availableCustomers },
  } = state;

  const infantCustomers = availableCustomers.filter((customer) => {
    return !customer.fullCalculation;
  });

  return Object.keys(infants).map((key) => {
    const timeUsed = infants[key];

    const infanetObject = infantCustomers.find((i) => {
      return i.code === key;
    });

    return {
      title: infanetObject.title,
      price: infanetObject.price,
      timeUsed: timeUsed,
      totalPrice: timeUsed * infanetObject.price,
    };
  });
};

const getSingleOccupation = (index, state, type) => {
  const {
    settings: { discount, surcharge, optionalSurcharge, eating, childrenAge },
    data,
  } = state;
  const grantUsed = isGrantUsed(index, type, state.grants);
  const { price, discountedPrice } =
    type === 'adult'
      ? adultPriceByCustomerIndex(state, index + 1, grantUsed)
      : childrenPriceByCustomerIndex(state, index + 1, grantUsed);

  const occupation = {
    id: btoa(`${type}-${index}`),
    type,
    isDiscounted: discountedPrice !== 0,
    finalPrice: discountedPrice || price,
    price,
    discountedPrice,
    eating: getEatingPrice(eating, data, type),
    grantUsed,
    discount: discount.map((discountObject) =>
      getAdditionalPrice(discountObject, 'discount', index, state, type),
    ),
    surcharge: surcharge.map((surchargeObject) =>
      getAdditionalPrice(surchargeObject, 'surcharge', index, state, type),
    ),
    optionalSurcharge: optionalSurcharge.map((optionalSurchargeObject) =>
      getAdditionalPrice(optionalSurchargeObject, 'optionalSurcharge', index, state, type),
    ),
  };

  if (type === 'children') {
    if (state.settings.occupation.children > 1 && index === 0) {
      occupation.age = highestChildAge(state);
    } else {
      occupation.age = childrenAge[index];
    }
  }

  return occupation;
};

const highestChildAge = (state) => {
  const {
    data: { availableCustomers },
  } = state;

  const highestAge = availableCustomers.reduce((max, customer) => {
    return max.maxAge > customer.maxAge ? max : customer;
  });

  return highestAge.maxAge - 1;
};

const getAdditionalPrice = (additionalPrice, additionalpriceType, index, state, type) => {
  if (additionalPrice.type !== 2) {
    return null;
  }

  return {
    id: additionalPrice.id,
    title: additionalPrice.title,
    price: additionalPrice.price,
    checked: getIsChecked(additionalPrice, additionalpriceType, index, state, type),
  };
};

const getIsChecked = (additionalPriceObject, additionalPriceType, index, state, type) => {
  const { additionalPrice } = state;

  const price = additionalPrice.find((price) => {
    return (
      price.index === index &&
      type === price.type &&
      additionalPriceObject.id === price.additionalPrice.id &&
      additionalPriceType === price.additionalPriceType
    );
  });

  if (!price) {
    return false;
  }

  return price.enabled;
};

const initialState = {
  sendingReservation: false,
  reservationSent: null,
  data: {},
  grants: [],
  additionalPrice: [],
  occupation: [],
  specialAdult: false,
  settings: {
    isSubsidy: false,
    discount: [],
    surcharge: [],
    optionalSurcharge: [],
    customerCombination: null,
    eating: null,
    childrenAge: {},
    occupation: {},
    infants: {},
    adultDotations: [],
  },
};

const filter = (state = initialState, action) => {
  switch (action.type) {
    case SENDING_RESERVATION:
      return { ...state, sendingReservation: true };

    case RESERVATION_SENT:
      return {
        ...state,
        reservationSent: action.result,
        sendingReservation: false,
      };

    case RESET_CALCULATOR_OCCUPATION:
      state.settings.occupation[action.customerType] = 0;

      state.additionalPrice = state.additionalPrice.filter((price) => {
        return price.type !== action.customerType;
      });

      getOccupation(state);

      return { ...state };

    case RESET_CALCULATOR:
      return {
        ...state,
        data: {},
        reservationSent: null,
        grants: [],
        occupation: {},
        settings: {
          discount: [],
          surcharge: [],
          optionalSurcharge: [],
          customerCombination: null,
          eating: null,
          childrenAge: {},
          occupation: {},
          infants: {},
          adultDotations: [],
        },
      };

    case CLEAN_OCCUPATION:
      state.settings.occupation = {};
      state.occupation = {};
      state.grants = [];
      state.additionalPrice = [];
      state.occupation = getOccupation(state);

      return { ...state };

    case TOGGLE_ADDITIONAL_PRICE:
      const priceToToggleIndex = state.additionalPrice.findIndex((price) => {
        return (
          price.type === action.userType &&
          price.index === action.index &&
          action.additionalPrice.id === price.additionalPrice.id &&
          action.additionalPriceType === price.additionalPriceType
        );
      });

      if (priceToToggleIndex === -1) {
        state.additionalPrice.push({
          index: action.index,
          type: action.userType,
          additionalPrice: action.additionalPrice,
          additionalPriceType: action.additionalPriceType,
          enabled: true,
        });
      } else {
        state.additionalPrice[priceToToggleIndex].enabled = !state.additionalPrice[
          priceToToggleIndex
        ].enabled;
      }

      state.occupation = getOccupation(state);

      return { ...state };

    case TOGGLE_GRANT:
      const activeGrants = state.grants;

      const grantToToggleIndex = state.grants.findIndex((grant) => {
        return grant.type === action.userType && grant.index === action.index;
      });

      if (grantToToggleIndex === -1) {
        state.grants.push({
          index: action.index,
          type: action.userType,
          enabled: true,
        });
      } else {
        state.grants[grantToToggleIndex].enabled = !state.grants[grantToToggleIndex].enabled;
      }

      state.occupation = getOccupation(state);

      return { ...state };

    case SET_CALCULATOR_OCCUPATION:
      state.settings.occupation[action.customerType] = action.count;

      state.occupation = getOccupation(state);

      return { ...state };

    case SET_CHILDREN_AGE:
      state.settings.childrenAge[action.index] = action.age;

      state.occupation = getOccupation(state);

      return { ...state };

    case SET_INFANTS_COUNT:
      state.settings.infants[action.customerCode] = action.count;

      state.occupation = getOccupation(state);
      return { ...state };

    case SET_TOUR_CALCULATOR_SETINGS:
      state.data = action.result;
      state.specialAdult = state.data.availableCustomers.find((customer) => {
        return customer.code.startsWith('OS');
      });

      state.occupation = getOccupation(state);

      return { ...state };

    case SET_SETTINGS_PARAMETER:
      state.settings[action.parameter] = action.object;

      state.occupation = getOccupation(state);

      return { ...state };

    default:
      return state;
  }
};

export default filter;
