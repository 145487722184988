import printHeaderTemplate from './printHeaderTemplate'; // eslint-disable-line
const getInstructionsWithImg = (instructions, exporting, destination, term, logoSource) => {
  const formattedInstructions = instructions
    .replace(
      '{img|',
      '<img height="auto" width="500" src="https://s3.eu-central-1.amazonaws.com/cdtravel-2/insurance/',
    )
    .replace('.jpg}', '.jpg">');

  if (exporting) {
    return createExporting(formattedInstructions, destination, term, logoSource);
  }

  return formattedInstructions;
};

const createExporting = (formattedInstructions, destination, term, logoSource) => {
  const header = printHeaderTemplate
    .replace('{destination}', destination)
    .replace('{logoSource}', logoSource)
    .replace('{term}', term);

  const footer = '</html>';

  return (
    header +
    formattedInstructions
      .replace(
        /class="header"/g,
        'style="font-weight: 600;font-size: 20px;color: rgb(56, 102, 150);line-height: 27px;margin: 20px 0;"',
      )
      .replace(
        /<a/g,
        '<a style="text-align: justify; font-size: 14px!important; font-family: odkaz; color: rgb(56, 102, 150)"',
      )
      .replace(
        /<p/g,
        '<p style="text-align: justify; margin-bottom: 10px;font-size: 14px;font-weight: 300;margin-top: 10px;"',
      )
      .replace(/<table/g, '<table style="border-collapse:collapse"')
      .replace(/<td/g, '<td style="border-bottom: solid 1px #d6d6d6; padding: 2px !important;"')
      .replace(
        /<p/,
        '<p style="text-align: justify; font-weight: 900 !important;font-size: 20px !important;line-height: 17px;margin: 26px 0 35px 0px !important;color: rgb(56, 102, 150) !important;"',
      )
      .replace(
        /<h1/g,
        '<h1 style="text-align: justify; margin-bottom: 3px; margin-top: 25px;font-size: 18px;font-weight: 600;margin-top: 0; color: #386696!important;"',
      ) +
    footer
  ).replace(
    /<\/style><\/head><p >/g,
    '</style></head><p style="text-align: justify; font-weight: 600;font-size: 20px;color: rgb(56, 102, 150);line-height: 27pxmargin: 20px 0;">',
  );
};

export default getInstructionsWithImg;
