import { getJSON } from '../../utils/fetch';

export const SAVE_FILTER_SETTINGS = 'SAVE_FILTER_SETTINGS';

export const fetchFilterSettings = () => {
  return (dispatch) => {
    getJSON('filter-settings/').then((result) => {
      dispatch({
        type: SAVE_FILTER_SETTINGS,
        result,
      });
    });
  };
};
