import ImageGallery from 'react-image-gallery';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { StyleRoot } from 'radium';
import { hashHistory, Link } from 'react-router';
import Radium from 'radium';

import constants from '../../../../../styles/constants';
import format from '../../../../shared/FormatDescription';
import formatCurrency from '../../../../shared/formatCurrency';
import styles from '../../../../shared/TourListStyles/TourListItemStyles';
import SimpleGallery from '../../../../shared/SimpleGallery/SimpleGallery';
import { zajezdyDetail } from '../../../../../utils/routeConfig';

const getFormattedPhotos = (photos) => {
  if (photos) {
    const formattedPhotos = photos.map((photo) => {
      const { imgSrc, preview } = photo;
      return { original: imgSrc, thumbnail: preview };
    });

    return formattedPhotos;
  }

  return null;
};

class HotelsMapInfoWindow extends Component {
  render() {
    const {
      data: {
        specialState,
        title,
        description,
        destination,
        country,
        lowestPrice,
        images,
        termSoldOut,
        idTour,
      },
      store,
      image,
      imageIndex,
    } = this.props;

    return (
      <div className="tour-info-window">
        <StyleRoot>
          <div>
            <SimpleGallery image={image} images={images} imageIndex={imageIndex} />
          </div>

          <div className="description-box">
            <div>
              <h2>
                <Link
                  style={styles.descriptionBox.link}
                  onClick={() => hashHistory.push(zajezdyDetail + idTour)}
                >
                  {title}
                  <div style={styles.descriptionBox.destination}>
                    {destination} {country}
                  </div>
                </Link>
              </h2>

              <div className="price">
                {termSoldOut ? 'Vyprodáno ' : 'cena od ' + formatCurrency(lowestPrice)}
              </div>
            </div>
          </div>
        </StyleRoot>
      </div>
    );
  }
}

export default Radium(HotelsMapInfoWindow);
