import aboutSectionImage from '../../../img/section-headers/about.jpg'; // eslint-disable-line
import Radium from 'radium';
import SectionImage from '../../shared/SectionImage';
import React, { Component } from 'react';
import Helmet from 'react-helmet';

const styles = {
  sectionHead: {
    width: '100%',
  },
  contentWrapper: {
    overflow: 'hidden',
    background: '#fff',
    zIndex: 6,
    position: 'relative',
    padding: '50px 0',
  },
  contentBox: {
    margin: '0 auto',
    padding: '0 15px',
    maxWidth: '800px',
  },
  image: {
    marginTop: '25px',
    width: '100%',
  },
};

class AboutUs extends Component {
  render() {
    return (
      <div style={styles.borderBottom} className="cms-page">
        <Helmet title="O společnosti" />
        <SectionImage actualImage={aboutSectionImage} actualTitle={'O naší společnosti'} />

        <div style={styles.contentWrapper}>
          <div style={styles.contentBox}>
            <p>
              <strong>ČD travel, s.r.o., cestovní kancelář</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <em>zapsanou v obchodním rejstříku vedeném u MS v Praze, odd.C, vložka 108644</em>
              <br />
              <br />
              <a
                href="https://or.justice.cz/ias/ui/rejstrik-dotaz?dotaz=27364976"
                target="_blank"
                title="Výpis z Obchodního rejstříku"
              >
                Výpis z Obchodního rejstříku
              </a>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              <a
                href="/userfiles/file/Vyrocni zpravy/Vyrocni zprava CD travel 2015.pdf"
                target="_blank"
                title="Výroční zpráva za rok 2015"
              >
                Výroční zpráva za rok 2015
              </a>
            </p>

            <p>&nbsp;</p>

            <p>
              <strong>
                ČD travel, s.r.o. je cestovní kanceláří NEJEN pro železničáře. Většinovým vlastníkem
                jsou České dráhy a.s.. Našich služeb využívají železničáři - proto se při
                sestavování nabídky orientujeme podle jejich požadavků, ale i ostatní široká
                klientela, která vyhledává kvalitní a cenově dostupné rekreace. Naše cestovní
                kancelář má již více než 20-letou tradici.
              </strong>
            </p>

            <h4>NABÍZÍME:</h4>
            <ul style={{ listStyleType: 'square' }}>
              <li>
                Rekreace tuzemské i zahraniční. Máme širokou nabídku wellness pobytů a pobytů pro
                seniory (od 55 let). Zahraniční pobyty jsou s dopravou leteckou, vlakovou,
                autobusovou i vlastní. Nabídka je široká zejména na zájezdy do Řecka, Bulharska,
                Španělska, Turecka, Chorvatska i na Slovensko.
              </li>
              <li>
                Nabízíme poznávací zájezdy do všech koutů Evropy (Evropské superexpresy, Anglické
                hity tunelem, Švýcarské Alpy vlakem).
              </li>
              <li>
                Zajišťujeme podniková školení v kvalitních a cenově dostupných zařízeních po celé
                České i Slovenské republice.
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Radium(AboutUs);
