import daySuffix from './daySuffix';
import nightSuffix from './nightSuffix';
import checkZero from './checkZero';

const getLengthInDaysAndNights = (lengthInDays, lengthInNights) => {
  return lengthInNights
    ? checkZero(lengthInNights) +
        nightSuffix(lengthInNights) +
        '; ' +
        checkZero(lengthInDays) +
        daySuffix(lengthInDays)
    : 'Dle vlastního výběru';
};

export default getLengthInDaysAndNights;
