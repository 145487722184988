import { getJSON } from '../../utils/fetch';

export const SET_HEADER = 'SET_HEADER';

export const fetchHeader = (user) => {
  return (dispatch) => {
    getJSON('header/?user=' + user).then((result) => {
      dispatch({
        type: SET_HEADER,
        result,
      });
    });
  };
};
