import { SET_POST_FORM_BOOKING, SET_POST_FORM_REVIEW } from '../actions/TourDetail/postTourDetail';
import { SET_TOGETHER_TRAVELING_POST_FORM } from '../actions/TogetherTraveling/postPassengers';

const initialState = {
  postNewBooking: '',
  postNewReview: '',
  postNewReply: '',
  postTogetherTraveling: '',
};

const filter = (state = initialState, action) => {
  const { result, type } = action;

  switch (type) {
    case SET_POST_FORM_BOOKING:
      return { ...state, postNewBooking: result };

    case SET_POST_FORM_REVIEW:
      return { ...state, postNewReview: result };

    case SET_TOGETHER_TRAVELING_POST_FORM:
      return { ...state, postTogetherTraveling: result };

    default:
      return state;
  }
};

export default filter;
