import React, { Component } from 'react';
import { Link } from 'react-router';
import Radium from 'radium';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import { formatCurrency } from '../calculations';

class RoomAdditionalPrices extends Component {
  render() {
    const {
      calculator: {
        settings: { discount, surcharge },
      },
    } = this.props;

    if (!this.isAdditional()) {
      return null;
    }

    return (
      <div className="room-additional-prices">
        <div>
          <strong>Příplatky na pokoj:</strong>
        </div>

        {discount.map((discountObject) => this.renderAdditionalPrice(discountObject, 'discount'))}

        {surcharge.map((surchargeObject) =>
          this.renderAdditionalPrice(surchargeObject, 'surcharge'),
        )}
      </div>
    );
  }

  isAdditional() {
    let isAdditional = false;
    const {
      calculator: {
        settings: { discount, surcharge },
      },
    } = this.props;

    [...surcharge, ...discount].forEach((surcharge) => {
      if (surcharge.type === 1) {
        isAdditional = true;
      }
    });

    return isAdditional;
  }

  renderAdditionalPrice(additionalPrice, additionalpriceType) {
    if (additionalPrice.type !== 1) {
      return null;
    }

    return (
      <div>
        {additionalPrice.title} ({additionalpriceType === 'discount' && '-'}
        {formatCurrency(additionalPrice.price)})
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  calculator: state.calculator,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(RoomAdditionalPrices));
