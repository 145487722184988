import constants from '../../../../../styles/constants.js';
import Radium from 'radium';
import { hashHistory } from 'react-router';
import OrangeButton from '../../../../shared/OrangeButton';
import { zajezdyDetail } from '../../../../../utils/routeConfig';
import React, { Component } from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import formatCurrency from '../../../../shared/formatCurrency';

const styles = {
  tile: {
    width: '33.33%',
    float: 'left',
    display: 'inline-block',
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'center',
    height: '280px',
    cursor: 'pointer',

    [constants.breakpointTablet]: {
      width: '100%',
      height: '250px',
    },

    image: {
      position: 'absolute',
      height: 'auto',
      width: '110%',
      left: '50%',
      transition: '0.3s',
      top: '50%',
      transform: 'translate(-50%, -50%) scale(1)',
      transformOrigin: 'center center',

      [constants.breakpoint1024]: {
        height: '110%',
        width: 'auto',
      },

      [constants.breakpointTablet]: {
        height: 'auto',
        width: '110%',
      },

      [constants.breakpoint360]: {
        height: '110%',
        width: 'auto',
      },

      hovered: {
        transform: 'translate(-50%, -50%) scale(1.2)',
      },
    },

    contentBox: {
      position: 'absolute',
      left: '50%',
      height: '100%',
      color: '#fff',
      lineHeight: '20px',
      fontSize: '1.3em',
      width: '100%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      background: 'rgba(40, 127, 184, 0.3)',

      title: {
        fontSize: '1.3em',
        marginBottom: '75px',
        padding: '15px 5px',
        display: 'block',
        background: 'rgba(40, 127, 184, 0.7)',
        fontWeight: 600,
        color: [constants.mainOrange],

        [constants.breakpoint1024]: {
          marginBottom: '50px',
          fontSize: '1.4em',
        },

        [constants.breakpoint400]: {
          marginBottom: '35px',
        },

        subtext: {
          display: 'block',
          fontSize: '0.65em',
          marginTop: '10px',
          color: '#fff',
        },
      },

      hotel: {
        fontWeight: '700',
        fontSize: '1.1em',
        padding: '0 20px',
        lineHeight: '1.2em',
        textShadow: '0 0 4px 2px rgba(0, 0, 0, 0.8)',

        [constants.breakpoint1024]: {
          fontSize: '1em',
        },
      },

      price: {
        position: 'absolute',
        bottom: '15px',
        right: '15px',
        transform: 'rotate(-3deg)',
        padding: '5px 12px',
        background: [constants.mainOrange],
      },
    },
  },
};

class SimilarTour extends Component {
  render() {
    const { similarTour } = this.props;
    const { hovered } = this.state;

    return (
      <div>
        <div
          style={styles.tile}
          onMouseEnter={() => this.setState({ hovered: true })}
          onMouseLeave={() => this.setState({ hovered: false })}
        >
          <img
            style={[styles.tile.image, hovered && styles.tile.image.hovered]}
            src={similarTour.imgSrc}
            key={similarTour.id}
          />

          <div style={styles.tile.contentBox} onClick={() => this.redirect(similarTour.id)}>
            <div style={styles.tile.contentBox.title}>
              {similarTour.country}

              <span style={styles.tile.contentBox.title.subtext}>{similarTour.location}</span>
            </div>

            <div style={styles.tile.contentBox.hotel}>{similarTour.hotel}</div>

            {similarTour.price && this.renderPrice(similarTour)}
          </div>
        </div>
      </div>
    );
  }

  renderPrice(similarTour) {
    return (
      <div style={styles.tile.contentBox.price}>
        <strong>{formatCurrency(similarTour.price)}</strong>
      </div>
    );
  }

  redirect(idTour) {
    hashHistory.push(zajezdyDetail + idTour);
    window.location.reload();
  }
}

SimilarTour.propTypes = {
  similarTour: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
});

export default connect(mapStateToProps)(Radium(SimilarTour));
