import { postJSON, getJSON, getWeather, requestWithPayload } from '../../utils/fetch';
import cookie from 'react-cookie';
import { hashHistory } from 'react-router';
import { SET_ERROR } from '../Error/error';
import { employeeOrVisitor } from "../HP/defaultTourlist";

export const SET_TOUR_DETAIL = 'SET_TOUR_DETAIL';
export const SET_REVIEW_OPTIONS = 'SET_REVIEW_OPTIONS';
export const SET_SIMILAR_TOURS = 'SET_SIMILAR_TOURS';
export const ADD_TOUR_DETAIL_FOR_COMPAREMENT = 'ADD_TOUR_DETAIL_FOR_COMPAREMENT';
export const SET_WEATHER = 'SET_WEATHER';
const TOUR_ERROR_MESSAGE = 'tento zájezd již není v nabídce';
export const loading = 'loading';

export const fetchCompleteTourTerms = (id) => {
  const request = {
    id,
    user:  employeeOrVisitor(cookie.load('user')),
  };

  return (dispatch) => {
    postJSON('complete-tour-terms/', request).then((result) => {
      handleTourDetailFetch(dispatch, result);
    });
  };
};

export const fetchTourDetail = (id) => {
  const request = {
    id,
    user:  employeeOrVisitor(cookie.load('user')),
    transport: [],
  };

  return (dispatch) => {
    postJSON('tour/', request).then((result) => {
      handleTourDetailFetch(dispatch, result);
    });
  };
};

export const addTourForComparement = (id) => {
  const request = {
    id,
    user:  employeeOrVisitor(cookie.load('user')),
    transport: [],
  };

  return (dispatch) => {
    postJSON('tour/', request).then((result) => {
      dispatch({
        type: ADD_TOUR_DETAIL_FOR_COMPAREMENT,
        result,
        idTour: id,
      });
    });
  };
};

export const fetchSimilarTours = (requestData) => {
  return (dispatch) => {
    dispatch({
      type: SET_SIMILAR_TOURS,
      result: loading,
    });

    postJSON('similar-tours/', requestData).then((result) => {
      dispatch({
        type: SET_SIMILAR_TOURS,
        result,
      });
    });
  };
};

export const fetchTourDetailWithFilters = (params) => {
  return (dispatch) => {
    requestWithPayload('tour/', params).then(({ response, jsonPromise }) => {
      if (response.status === 500) {
        hashHistory.push('/zajezdy/vypis/');
      }
      jsonPromise.then((json) => {
        handleTourDetailFetch(dispatch, json);
      });
    });
  };
};

function handleTourDetailFetch(dispatch, result) {
  if (result.error) {
    hashHistory.push('/');
    dispatch({
      type: SET_ERROR,
      message: TOUR_ERROR_MESSAGE,
    });
  } else {
    dispatch({
      type: SET_TOUR_DETAIL,
      result,
    });
  }
}

export const fetchWeather = (lat, lon) => {
  return (dispatch) => {
    getWeather(lat, lon).then((result) => {
      dispatch({
        type: SET_WEATHER,
        result,
      });
    });
  };
};

export const fetchReviewOptions = () => {
  return (dispatch) => {
    getJSON('review-options/').then((result) => {
      dispatch({
        type: SET_REVIEW_OPTIONS,
        result,
      });
    });
  };
};
