import Promise from 'bluebird';
import fetch from 'isomorphic-fetch';
import ES6Promise from 'es6-promise';
import { apiUrl } from '../utils/config.local';

ES6Promise.polyfill();

class ServerError extends Error {}

export const getJSON = (path) => {
  const promise = fetch(apiUrl + path).then((response) => response.json());

  return Promise.resolve(promise).catch(ServerError, (e) => {});
};

export const getWeather = (lat, lon) => {
  const promise = fetch(
    '//api.openweathermap.org/data/2.5/forecast/daily?lat=' +
      lat +
      '&lon=' +
      lon +
      '&cnt=15&mode=json&units=metric&APPID=aac29b3a44936b73b03129fbd36562cc',
  ).then((response) => response.json());

  return Promise.resolve(promise).catch(ServerError, (e) => {});
};

export function postJSON(path, params = {}) {
  const promise = fetch(apiUrl + path, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    },
    body: JSON.stringify(params),
  }).then((response) => response.json());

  return Promise.resolve(promise).catch(ServerError, (e) => {});
}

export function requestWithPayload(path, params = {}, method = 'POST') {
  const promise = fetch(apiUrl + path, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    },
    body: JSON.stringify(params),
  }).then((response) => {
    return { response, jsonPromise: response.json() };
  });

  return Promise.resolve(promise).catch(ServerError, (e) => {
    throw e;
  });
}
