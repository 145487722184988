import {
  SET_SPECIAL_LIST,
  SAVE_LOCATIONS,
  SAVE_LOCATIONS_HOTELS,
} from '../actions/TourList/fetchTourList';
import { SET_SEARCH_LIST } from '../actions/Search/search';
import { SET_HEADER } from '../actions/Header/fetchHeader';
import { GENERATING_PDF } from '../actions/PDF/generatePDF';
import { SET_CMS } from '../actions/CMS/fetchCMS';
import { SET_OCCUPATION, NULL_OCCUPATION_FETCHED } from '../actions/Occupation/fetchOccupation';
import { SAVE_CONTACTS } from '../actions/Contacts/fetchContacts';
import { SET_TOUR_FILTER } from '../actions/TourList/tourFilter';
import { SAVE_HOTELS_MAP_ESSENTIAL } from '../actions/TourList/Map/hotelsMap';

const initialState = {
  filterFetched: false,
  locationsFetched: false,
  specialListFetched: false,
  contactsFetched: false,
  occupationHotelsFetched: false,
  searchListFetched: false,
  occupationFetched: false,
  occupationEmpty: true,
  generatingPDF: false,
  CMSFetched: false,
  mapDataFetched: false,
  headerFetched: false,
  ToursCountFetched: false,
};

export const loading = 'načítám';

const filter = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOUR_FILTER:
      return { ...state, filterFetched: true };

    case SAVE_HOTELS_MAP_ESSENTIAL:
      return { ...state, mapDataFetched: true };

    case SET_HEADER:
      return { ...state, headerFetched: true };

    case GENERATING_PDF:
      return { ...state, generatingPDF: action.generating };

    case SAVE_CONTACTS:
      return { ...state, contactsFetched: true };

    case SAVE_LOCATIONS:
      return { ...state, locationsFetched: true };

    case SET_OCCUPATION:
      return { ...state, occupationFetched: true };

    case NULL_OCCUPATION_FETCHED:
      return { ...state, occupationFetched: false, occupationEmpty: false };

    case SAVE_LOCATIONS_HOTELS:
      return { ...state, occupationHotelsFetched: true };

    case SET_SPECIAL_LIST:
      return { ...state, specialListFetched: true };

    case SET_CMS:
      return { ...state, CMSFetched: true };

    case SET_SEARCH_LIST:
      return { ...state, searchListFetched: true };

    default:
      return state;
  }
};

export default filter;
