import React, { Component } from 'react';
import { Link } from 'react-router';
import Radium from 'radium';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import Infants from './Infants';
import Eating from './rowItems/Eating';
import AdditionalPrices from './rowItems/AdditionalPrices';
import {
  occupationWithDiscounts,
  formatCurrency,
  adultPriceByCustomerIndex,
  childrenPriceByCustomerIndex,
  isGrantUsed,
} from '../calculations';
import {
  toggleGrant,
  setChildrenAgeByRenderIndex,
} from '../../../../actions/Calculator/calculator';

class Adults extends Component {
  render() {
    const {
      calculator: {
        settings: { occupation },
      },
    } = this.props;

    const adults = [];

    for (let i = 0; i < occupation['adult']; i++) {
      adults.push(this.renderAdultRow(i));
    }

    return <div>{adults}</div>;
  }

  renderAdultRow(index) {
    const {
      calculator: {
        occupation: { adult },
        specialAdult,
      },
    } = this.props;

    return (
      <div className="sum-row">
        <div className="occupation-type-label-wrapper">
          {index + 1}. {specialAdult ? 'Osoba' : 'Dospělá osoba'}
        </div>

        {this.hiddingSubsidyCheckbox(index)}

        <AdditionalPrices type="adult" index={index} />

        <Eating type="adult" index={index} />

        {this.renderDiscountPrice(adult[index].price, adult[index].discountedPrice)}
      </div>
    );
  }

  hiddingSubsidyCheckbox(index) {
    const {
      calculator: {
        occupation: { adult },
      },
      toggleGrant,
      isSubsidy,
    } = this.props;

    if (isSubsidy) {
      return (
        <div className="subsidy-claim-wrapper">
          <label className="custom-checkbox subsidy">
            nárok na dotaci
            <input
              type="checkbox"
              checked={adult[index].grantUsed}
              onClick={() => toggleGrant('adult', index)}
            />
            <span className="checkmark" />
          </label>
        </div>
      );
    }

    return null;
  }

  renderDiscountPrice(price, discountPrice) {
    if (!discountPrice) {
      return (
        <div className="person-price-wrapper">
          <span className="label">cena</span>
          <span className="price">{formatCurrency(price)}</span>
        </div>
      );
    }

    return (
      <div className="person-price-wrapper">
        <span className="label">cena</span>

        <span className="row">
          <span className="crossed">{formatCurrency(price)}</span>
          <span className="price"> {formatCurrency(discountPrice)}</span>
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  calculator: state.calculator,
  isSubsidy: state.calculator.settings.isSubsidy,
});

const mapDispatchToProps = (dispatch) => ({
  toggleGrant: (occupation, index) => dispatch(toggleGrant(occupation, index)),
  setChildrenAgeByRenderIndex: (index, age) => dispatch(setChildrenAgeByRenderIndex(index, age)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Radium(Adults));
