import { SAVE_FILTER } from '../actions/TourList/filter';

const initialState = {
  isEmpty: true,
  showTermList: false,
  isLastMinuteOrCustom: false,
  isSpecialOffer: false,
  sortBy: '',
  dateFrom: '',
  dateTo: '',
  countrySortedByName: [],
  location: [],
  transport: [],
  days: [],
  diet: [],
  apartment: [],
  season: [],
};

const filter = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_FILTER:
      return { ...state, [action.designation]: action.filter, isEmpty: false };

    default:
      return state;
  }
};

export default filter;
