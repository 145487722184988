import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import './Reservation.scss';
import { hashHistory } from 'react-router';
import OrangeButton from '../../../shared/OrangeButton';
import { zajezdyDetail } from '../../../../utils/routeConfig';

class ReservationComplete extends Component {
  state = {};

  render() {
    return (
      <div className={'users-detail-wrapper'}>
        <div>
          <div className="reservation-sent">Objednávka byla odeslána</div>

          <div className="reservation-sent-text">
            Na Váší e-mailovou adresu jsme odeslali potvrzovací e-mail a předvyplněnou objednávku.
            Zkontrolujte prosím <strong>doručenou poštu</strong>.
            <br />
            <br />
            Rezervace má platnost 48 hodin
          </div>
        </div>

        {this.renderButtons()}
      </div>
    );
  }

  renderButtons() {
    const { idDetail } = this.props;

    return (
      <div className="reservation-customer-summary-finished">
        <OrangeButton onClick={() => hashHistory.push(zajezdyDetail + idDetail)}>
          Zpět na zájezd
        </OrangeButton>
      </div>
    );
  }
}

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(ReservationComplete));
