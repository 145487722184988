import Component from 'react-pure-render/component';
import Radium from 'radium';
import React from 'react';
import { column, columnHidden, columnOffset, columnPull, columnPush, columnWidth } from './grid';

class Column extends Component {
  columnOptions(size) {
    const hidden = this.props[`${size}Hidden`];
    const offset = this.props[`${size}Offset`];
    const pull = this.props[`${size}Pull`];
    const push = this.props[`${size}Push`];

    return [
      hidden && columnHidden[size],
      offset && columnOffset[size][offset],
      pull && columnPull[size][pull],
      push && columnPush[size][push],
    ];
  }

  render() {
    const { children, lg, md, ms, sm, style, xs, ...props } = this.props;

    return (
      <div
        style={[
          column,
          columnWidth['xs'][xs || 1],
          columnWidth['ms'][ms || xs || 1],
          columnWidth['sm'][sm || ms || xs || 1],
          columnWidth['md'][md || sm || ms || xs || 1],
          columnWidth['lg'][lg || md || sm || ms || xs || 1],
          this.columnOptions('xs'),
          this.columnOptions('ms'),
          this.columnOptions('sm'),
          this.columnOptions('md'),
          this.columnOptions('lg'),
          style,
        ]}
        {...props}
      >
        {children}
      </div>
    );
  }
}

export default Radium(Column);
