const format = (description) => {
  if (!description) {
    return '';
  }

  const length = 220;

  const shortenedDescription = description.substr(0, length);

  const indexOfLastFullWord = Math.min(
    shortenedDescription.length,
    shortenedDescription.lastIndexOf(' '),
  );

  return shortenedDescription.substr(0, indexOfLastFullWord) + ' ...';
};

export default format;
