import {
  CLOSE_TABS,
  TOGGLE_OPEN,
  SELECTING_IN_CHECKBOX,
} from '../actions/TourList/filterOpenStatus';

const initialState = {
  countrySortedByName: false,
  location: false,
  transport: false,
  days: false,
  season: false,
  apartment: false,
  termShow: false,
  capacity: false,
  showItemsPerPage: false,
  hotels: false,
  diet: false,
  disableClosing: false,
  sort: false,
};

const filter = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_TABS:
      if (state.disableClosing) {
        return { ...state, disableClosing: false };
      } else {
        return {
          ...state,
          countrySortedByName: false,
          location: false,
          transport: false,
          showItemsPerPage: false,
          termShow: false,
          hotels: false,
          days: false,
          season: false,
          apartment: false,
          sort: false,
          diet: false,
          capacity: false,
        };
      }

    case SELECTING_IN_CHECKBOX:
      return { ...state, disableClosing: true };

    case TOGGLE_OPEN:
      if (state[action.designation]) {
        return { ...state, [action.designation]: false, disableClosing: false };
      } else {
        return { ...state, [action.designation]: true, disableClosing: true };
      }

    default:
      return state;
  }
};

export default filter;
