import constants from '../../../styles/constants.js';
import { fetchCalculatorSettings } from '../../../actions/Calculator/calculator';
import React, { Component } from 'react';
import { Link } from 'react-router';
import Radium from 'radium';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import StyledCircleLoader from '../../shared/Loading/StyledCircleLoader';
import { visitor } from '../../../actions/HP/defaultTourlist';
import { styles } from '../../Pages/Tours/ToursList/components/MultiCheckbox';
import selectArrow from '../../../img/select-arrow2.png';
import arrowDown from '../../../img/down-arrow.svg';
import { generateUuid } from '../../shared/uuid';

export const customStyles = {
  selectBox: {
    width: '100%',
    height: 40,
    overflow: 'hidden',
    padding: '0 30px 0 10px',
    lineHeight: '40px',
    borderRadius: '5px',
    border: '1px solid' + [constants.inputBorder],
    display: 'inline-block',
    backgroundColor: '#fff',
    backgroundImage: 'url(' + arrowDown + ')',
    backgroundSize: 20,
    backgroundPosition: 'right center',
    backgroundRepeat: 'no-repeat',
    color: [constants.black],
    fontSize: '0.9em',
    fontWeight: '500',
    cursor: 'pointer',
  },
  disable: {
    opacity: 0.4,
    cursor: 'default',
  },
  inputBox: {
    width: 'calc(100% - 40px)',
    margin: '0.50% 0.5%',
    float: 'left',
    position: 'relative',
  },
};

const getParents = (elem) => {
  const parents = [];

  while (elem.parentNode && elem.parentNode.nodeName.toLowerCase() != 'body') {
    elem = elem.parentNode;
    parents.push(elem);
  }

  return parents;
};

class BaseSelect extends Component {
  state = {
    opened: false,
  };

  componentDidMount() {
    this.setState({ uuid: generateUuid() });
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  handleOutsideClick = (event) => {
    const parents = getParents(event.target);
    let close = true;

    parents.forEach((parent) => {
      if (parent.id === this.state.uuid) {
        close = false;
      }
    });

    if (close) {
      this.setState({ opened: false });
    }
  };

  render() {
    const { items, placeholder, isCheckBox, text, disabled } = this.props;
    const { opened } = this.state;

    if (disabled) {
      return (
        <div style={customStyles.inputBox}>
          <div style={[customStyles.selectBox, customStyles.disable]} className="select-box">
            -
          </div>
          <div style={styles.dropDown}>{opened && this.renderOpened()}</div>
        </div>
      );
    }
    return (
      <div style={customStyles.inputBox}>
        <div
          onClick={() => this.setState({ opened: !opened })}
          style={customStyles.selectBox}
          className="select-box"
        >
          {text || placeholder}
        </div>
        <div style={styles.dropDown}>{opened && this.renderOpened()}</div>
      </div>
    );
  }

  renderOpened() {
    const { items, placeholder, onReset } = this.props;
    const { uuid } = this.state;

    return (
      <div id={uuid}>
        <div style={styles.dropDown.caret} />

        <div className="item" onClick={() => this.reset()} style={styles.dropDown.item}>
          {placeholder}
        </div>

        {items.map((items) => this.renderBox(items))}
      </div>
    );
  }

  renderBox(item, box) {
    const { isCheckBox } = this.props;
    const { id, title, selected } = item;

    if (!isCheckBox) {
      return (
        <div
          className="item"
          style={styles.dropDown.item}
          value={id}
          onClick={() => this.select(id)}
        >
          {title}
        </div>
      );
    } else {
      return (
        <div onClick={() => this.select(id)} className="item" style={styles.dropDown.item}>
          <input type="checkbox" style={styles.dropDown.checkbox} value={id} checked={selected} />
          <label style={styles.dropDown.label}>{title}</label>
        </div>
      );
    }
  }

  reset() {
    const { onReset } = this.props;
    this.setState({ opened: false });

    onReset && onReset();
  }

  select(id) {
    const { onSelect } = this.props;
    this.setState({ opened: false });

    onSelect && onSelect(id);
  }
}

const mapStateToProps = (state) => ({
  calculator: state.calculator,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Radium(BaseSelect));
