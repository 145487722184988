import { postJSON } from '../../utils/fetch';
import cookie from 'react-cookie';
import { employeeOrVisitor } from "../HP/defaultTourlist";

export const SET_OCCUPATION = 'SET_OCCUPATION';
export const NULL_OCCUPATION_FETCHED = 'NULL_OCCUPATION_FETCHED';
export const SET_OCCUPATION_TEXT = 'SET_OCCUPATION_TEXT';

export const fetchOccupation = (id) => {
  const request = {
    id,
    user: employeeOrVisitor(cookie.load('user')),
  };

  return (dispatch) => {
    dispatch({
      type: NULL_OCCUPATION_FETCHED,
    });

    postJSON('occupation-in-hotels/', request).then((result) => {
      dispatch({
        type: SET_OCCUPATION,
        result,
      });
    });
  };
};

export const setOccupationHotelState = (text) => ({
  type: SET_OCCUPATION_TEXT,
  text,
});
