import { SEARCH, SEARCH_FETCHING, fetching, SAVE_SEARCH_TERM } from '../actions/Search/search';

const initialState = {
  searchTerm: '',
  items: '',
  state: '',
};

const tourDetail = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SEARCH_TERM:
      return { ...state, searchTerm: action.searchTerm };

    case SEARCH_FETCHING:
      return { ...state, state: fetching };

    case SEARCH:
      return { ...state, items: action.result, state: null };

    default:
      return state;
  }
};

export default tourDetail;
