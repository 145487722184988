import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingIndicator from 'react-loading-indicator';

class StyledCircleLoader extends Component {
  render() {
    return <LoadingIndicator segmentWidth="20" />;
  }
}

export default StyledCircleLoader;
