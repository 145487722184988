import React, { Component } from 'react';
import ArrowButton from '../ArrowButton';
import './SimpleGallery.scss';

class SimpleGallery extends Component {
  state = {
    selectedImage: 0,
  };

  leftArrowClick() {
    const { selectedImage } = this.state;
    this.setState({ selectedImage: selectedImage - 1 });
  }

  rightArrowClick() {
    const { selectedImage } = this.state;
    this.setState({ selectedImage: selectedImage + 1 });
  }

  renderImage() {
    const { images } = this.props;
    const { selectedImage } = this.state;

    if (!images.length) {
      return;
    }

    if (selectedImage < images.length && selectedImage > -1) {
      return <img className="image" src={images[selectedImage].imgSrc} />;
    } else {
      if (selectedImage < 0) {
        this.setState({ selectedImage: images.length - 1 });
      } else if (selectedImage >= images.length) {
        this.setState({ selectedImage: 0 });
      }
    }
  }

  render() {
    return (
      <div className="gallery-wrapper">
        <ArrowButton direction="left" customClass={''} onClick={() => this.leftArrowClick()} />
        {this.renderImage()}
        <ArrowButton direction="right" customClass={''} onClick={() => this.rightArrowClick()} />
      </div>
    );
  }
}

export default SimpleGallery;
