import { SET_SEARCH_LIST, SEARCH_FETCHING } from '../actions/Search/search';
import { loading } from './filteredTours';

const initialState = {
  toursCount: '0',
  tours: [],
};

const filter = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_LIST:
      return action.result;

    case SEARCH_FETCHING:
      return { ...state, dataLoading: loading };

    default:
      return state;
  }
};

export default filter;
