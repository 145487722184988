import { SET_SIMILAR_TOURS } from '../actions/TourDetail/fetchTourDetail';

const initialState = null;

const similarTours = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIMILAR_TOURS:
      return action.result;

    default:
      return state;
  }
};

export default similarTours;
