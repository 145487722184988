const headerTemplate = `<div style=" border-bottom: 1px solid rgb(214, 214, 214); padding-bottom: 10px; position: relative;">
    <img src={logoSource} style="text-align: center; width: 200px; position: absolute; right: 0px; top: -50px;">
    <div style="padding-top: 40px">
        <span>
            <b>Destinace:</b>
            {destination}
        </span>
    </div>
</div>

<html>
<head>
    <style>
        @page {
            margin-bottom: 80px;
            margin-top: 80px;
        }
        {
            line-height: 25px;
        }
    </style>
</head>`;

export default headerTemplate;
