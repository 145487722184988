import { SET_CATALOGUE_PDF } from '../actions/Catalogue/catalogue';

import { SET_CATALOGUE_FILTER } from '../actions/TourList/tourFilter';

const initialState = {
  pdfList: [],
  countries: [],
  locationsInCountries: [],
};

const filter = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATALOGUE_PDF:
      return { ...state, pdfList: action.result };

    case SET_CATALOGUE_FILTER:
      return {
        ...state,
        countries: action.result.countries,
        locationsInCountries: action.result.locationsInCountries,
      };

    default:
      return state;
  }
};

export default filter;
