const getItemsByHeaderId = (headerId, menuItems) => {
  let filteredItems;

  menuItems.forEach((menuItem) => {
    const { header, items } = menuItem;
    if (headerId === header) {
      filteredItems = items;
    }
  });

  return filteredItems;
};
export default getItemsByHeaderId;
