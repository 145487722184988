import { SET_PASSENGERS_OPTIONS } from '../actions/TogetherTraveling/fetchPassengers';
const initialState = {
  sex: [],
  country: [],
};

const tourDetail = (state = initialState, action) => {
  switch (action.type) {
    case SET_PASSENGERS_OPTIONS:
      return action.result;

    default:
      return state;
  }
};

export default tourDetail;
