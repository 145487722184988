import styles from '../../../../shared/TourListStyles/TourListItemStyles';
import formatDateLong from '../../../../shared/Moment/formatDateLong';
import formatDateShort from '../../../../shared/Moment/formatDateShort';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import getLengthInDaysAndNights from '../../../../shared/getLengthInDaysAndNights';
import TourSticker from '../components/TourSticker';
import { zajezdyDetail } from '../../../../../utils/routeConfig';
import { connect } from 'react-redux';
import { hashHistory } from 'react-router';
import { fetchTourDetail } from '../../../../../actions/TourDetail/fetchTourDetail';
import cookie from 'react-cookie';
import { employee, szEmployee } from "../../../../../actions/HP/defaultTourlist";
import { adultType } from '../components/PickerPriceList';
import formatCurrency from '../../../../shared/formatCurrency';

const byPlane = 'Letecky';

class ToursTermItem extends Component {
  state = {
    openModalTerms: false,
    pickerOpened: false,
  };

  render() {
    const {
      term,
      term: {
        isSoldOut,
        idTour,
        title,
        country,
        location,
        prices,
        dateFrom,
        dateTo,
        lengthInDays,
        lengthInNights,
        transport,
      },
    } = this.props;
    const {
      filterSettings: { primaryColor, secondaryColor },
    } = this.props;

    const { type, value } = this.getSticker(term);
    const user = cookie.load('user');
    let adultPrice;

    if (!prices) {
      return null;
    }

    if (primaryColor !== 'defaultColor') {
      styles.stickerTypes.term.background = primaryColor;
    }

    const adultPrices =
      !isSoldOut &&
      prices.filter((price) => {
        if (price.code === adultType) {
          return price;
        }
        return null;
      });

    if (adultPrices.length) {
      const price = adultPrices[0];
      if (user === szEmployee) {
        adultPrice = price.price - price.szSubsidy;
      } else {
        adultPrice = user === employee ? price.price - price.subsidy : price.price;
      }
    }

    return (
      <div style={[styles.wrapper]} onClick={() => this.redirect(idTour)}>
        <div style={styles.descriptionBox.term}>
          <div style={styles.toursTermsItems.topInfo}>
            <div style={styles.toursTermsItems.topInfo.date}>
              {formatDateShort(dateFrom) + ' - ' + formatDateLong(dateTo)}
            </div>

            <div style={styles.toursTermsItems.topInfo.size}>
              <span style={styles.toursTermsItems.topInfo.mobileHidden}>| </span>délka{' '}
              {getLengthInDaysAndNights(lengthInDays, lengthInNights)}
            </div>
          </div>

          <div style={styles.toursTermsItems.transport}>
            {this.getFormatedTransports(transport)}
          </div>

          <div>
            <div style={styles.toursTermsItems.country}>{country}</div>
            <span style={styles.toursTermsItems.title}>{title}</span>,
            <span style={styles.toursTermsItems.location}> {location}</span>
          </div>

          <div style={styles.priceBox}>
            {type && (
              <TourSticker
                type={[styles.stickerTypes.term, styles.stickerTypes.normal]}
                secondaryColor={secondaryColor}
              >
                {value}
              </TourSticker>
            )}
            <div
              style={[
                styles.toursTermsItems.price,
                styles.toursTermsItems.price.term,
                isSoldOut && styles.toursTermsItems.price.soldOut,
              ]}
            >
              {isSoldOut ? 'Vyprodáno ' : 'cena ' + formatCurrency(adultPrice)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  redirect(idTour) {
    hashHistory.push(zajezdyDetail + idTour);
  }

  getSticker(term) {
    const {
      filterSettings: { filterName },
    } = this.props;

    if (term.isLastMinuteOrCustom) {
      return { type: 'lastminute', value: filterName };
    } else if (term.isSpecialOffer) {
      return { type: 'offer', value: 'Výhodná nabídka' };
    }

    return { type: null, value: null };
  }

  getFormatedTransports(transport) {
    let transportMessage = '';

    transport.forEach((transportItem, i) => {
      transportMessage += transportItem.title + (i !== transport.length - 1 ? ', ' : '');
    });

    transportMessage = transportMessage.split(byPlane);
    transportMessage =
      transportMessage.shift() +
      (transportMessage.length ? byPlane + transportMessage.join('') : '');

    return transportMessage;
  }
}

ToursTermItem.propTypes = {
  term: PropTypes.any.isRequired,
  filterSettings: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.filter,
  filterSettings: state.filterSettings,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTourDetail: (id) => dispatch(fetchTourDetail(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(ToursTermItem));
