import MultiCheckbox from '../Tours/ToursList/components/MultiCheckbox';
import CountryDependentCheckboxfrom from '../Tours/ToursList/components/CountryDependentCheckbox';
import LocationDependentCheckboxfrom from './LocationDependentCheckbox';
import constants from '../../../styles/constants';
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { COUNTRIES_SORTED_BY_NAME } from '../../../reducers/tourFilter';
import { toggle, selecting, closeTabs } from '../../../actions/TourList/filterOpenStatus';
import { saveFilter } from '../../../actions/TourList/filter';

const styles = {
  wrapper: {
    width: '100%',
    minHeight: '60px',
  },
  inputBox: {
    width: '30.3%',
    margin: '1% 1.5%',
    float: 'left',
    position: 'relative',

    [constants.breakpointTablet]: {
      width: '100%',
      margin: '1% 0',
    },
  },
};

class OccupationFilter extends Component {
  componentDidMount() {
    const { closeTabs } = this.props;

    closeTabs();
  }

  componentWillReceiveProps(props) {
    const { selectedLocations, tourFilter } = props;
    if (tourFilter && selectedLocations) {
      this.setState({ fetched: true });
    }
  }

  render() {
    const { selectedLocations, selectedHotels } = this.props;
    const {
      tourFilter: { countrySortedByName },
      fetchState: { filterFetched },
    } = this.props;
    const { fetched } = this.state;

    return (
      <div style={styles.wrapper}>
        {fetched && (
          <MultiCheckbox
            data={filterFetched ? countrySortedByName : []}
            type={COUNTRIES_SORTED_BY_NAME}
            box="textbox"
            defaultText="země"
          />
        )}

        {fetched && (
          <CountryDependentCheckboxfrom
            data={filterFetched ? selectedLocations : []}
            type="location"
            defaultText="oblast"
          />
        )}

        {fetched && (
          <LocationDependentCheckboxfrom
            data={filterFetched ? selectedHotels : []}
            type="hotels"
            defaultText="hotel"
          />
        )}
      </div>
    );
  }
}

OccupationFilter.propTypes = {
  closeTabs: PropTypes.any,
  selectedLocations: PropTypes.any,
  selectedHotels: PropTypes.any,
  tourFilter: PropTypes.any,
  fetchState: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  toggle: (designation) => dispatch(toggle(designation)),
  selecting: () => dispatch(selecting()),
  saveFilter: (filter) => dispatch(saveFilter(filter)),
  closeTabs: () => dispatch(closeTabs()),
});

const mapStateToProps = (state) => ({
  filter: state.filter,
  defaultTourListFilter: state.defaultTourListFilter,
  selectedLocations: state.selectedLocations,
  selectedHotels: state.selectedHotels,
  filterOpen: state.filterOpen,
  tourList: state.tourList,
  fetchState: state.fetchState,
  tourFilter: state.tourFilter,
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(OccupationFilter));
