import { subsidy } from '../../components/Pages/Tours/TourDetail/Panels/PriceList/PriceList';

const getHeaders = (terms, subsidyType = '') => {
  const headers = [];
  const headersCodes = [];

  terms.forEach((term) => {
    const { prices, isSubsidy } = term;
    let priceIsIncluded = true;
    if (subsidyType === subsidy && !isSubsidy) {
      priceIsIncluded = false;
    }

    prices.forEach((price) => {
      const { description, code } = price;

      if (!headersCodes.includes(code) && priceIsIncluded) {
        headers.push(description);
        headersCodes.push(code);
      }
    });
  });

  return { headers, headersCodes };
};

export default getHeaders;
