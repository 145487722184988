import { maxPagesCount } from '../../../utils/config';

const getLimitedPages = (pages, actualPage) => {
  const limitedPages = [];
  let nextButtonNeeded = false;
  const pageSize = actualPage - maxPagesCount / 2;
  const page = pageSize > 0 ? pageSize : 1;

  if (page > 1) {
    limitedPages.push('<');
  }

  if (page - 1 + maxPagesCount < pages.length) {
    nextButtonNeeded = true;
  }

  for (let i = 0; i < maxPagesCount; i++) {
    if (page + i < pages.length + 1) {
      limitedPages.push(page + i);
    }
  }
  if (nextButtonNeeded) {
    limitedPages.push('>');
  }

  return limitedPages;
};
export default getLimitedPages;
