import { newAdvertising } from '../../components/Pages/TogetherTravelling/TogetherTravelling';
export const SET_PASSENGER_FILTER = 'SET_PASSENGER_FILTER';
export const SAVE_PASSENGER_FILTER = 'SAVE_PASSENGER_FILTER';
export const SET_REPLY_FORM = 'SET_REPLY_FORM';
export const SET_NEW_INSERTION_FORM = 'SET_NEW_INSERTION_FORM';
export const TOGGLE_NEW_AD_TOGETHER_TRAVELING_FORM = 'TOGGLE_NEW_AD_TOGETHER_TRAVELING_FORM';
export const TOGGLE_REPLY_TOGETHER_TRAVELING_FORM = 'TOGGLE_REPLY_TOGETHER_TRAVELING_FORM';

export const toggleTogetherTravelingForm = (formType, isFormOpened) => {
  return (dispatch) => {
    dispatch({
      type:
        formType === newAdvertising
          ? TOGGLE_NEW_AD_TOGETHER_TRAVELING_FORM
          : TOGGLE_REPLY_TOGETHER_TRAVELING_FORM,
      isFormOpened,
    });
  };
};

export const setFilter = (sex, country, dateFrom, dateTo) => {
  return {
    type: 'SET_PASSENGER_FILTER',
    sex,
    country,
    dateFrom,
    dateTo,
  };
};

export const saveFilter = (designation, state) => {
  return {
    type: 'SAVE_PASSENGER_FILTER',
    designation,
    state,
  };
};

export const setReplyForm = (status) => {
  return {
    type: 'SET_REPLY_FORM',
    status,
  };
};

export const setNewInsertionForm = (status) => {
  return {
    type: 'SET_NEW_INSERTION_FORM',
    status,
  };
};
