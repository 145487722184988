const headerTemplate = `<!DOCTYPE html>
<html>
<head>
    <meta http-equiv="content-type" content="text/html; charset=utf-8">
</head>

<style>
    @page {
        size: auto;
        margin: 0;
        letter-spacing: -1px;
    }

    * {
        padding: 0;
        margin: 0;
    }

    body {
        font-size: 14px !important;
        margin: 0;
         font-family: 'Open Sans', sans-serif;
    }

    p {
        padding: 0px 10px;
        font-size: 12px;
        color: #444;
    }

    table {
       
        font-weight: 300 !important;
        width: 100%;
        font-size: 14px !important;
    }

    table td {
        color: #555;
        font-weight: normal;
        text-align: center;
    }

    table td,
    table th {
        font-weight: normal;
        border-bottom: solid 1px #dddddd;
        padding: 12px 3px;
    }

    table th {
        color: #fff;
        font-weight: bold;
    }

    table td:first-child {
        font-weight: bold;
    }
  
    .soldOut {
        background: black;
        color: white;
    }
    
    .lastMinute {
        color: #ff0123;
    }
    
    .specialOffer {
       color: rgb(40, 127, 184);
    }
</style>

<body>

{header}

{content}

</body>
</html>`;

export default headerTemplate;
