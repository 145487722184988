import { getJSON } from '../../utils/fetch';

export const SAVE_CONTACTS = 'SAVE_CONTACTS';

export const fetchContacts = () => {
  return (dispatch) => {
    getJSON('contacts/').then((result) => {
      dispatch({
        type: SAVE_CONTACTS,
        result,
      });
    });
  };
};
