import { SET_TOUR_DETAIL } from '../actions/TourDetail/fetchTourDetail';
import { PICK_TOUR } from '../actions/TourDetail/tourDetail';
const initialState = {};

const tourDetail = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOUR_DETAIL:
      return action.result;

    case PICK_TOUR:
      return { ...state, pickedTour: action.idTour };

    default:
      return state;
  }
};

export default tourDetail;
