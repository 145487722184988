import { setOccupation, resetOccupation } from '../../../../actions/Calculator/calculator';
import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import BaseSelect from '../../../shared/BaseSelect/BaseSelect';

export const types = [
  {
    title: 'Dospělí',
    type: 'adult',
  },
  {
    title: 'Děti',
    type: 'children',
  },
];

class PeoplePicker extends Component {
  render() {
    return (
      <div className="persons-picker">
        {types.map((availableCustomer) => this.renderCustomerPick(availableCustomer))}

        {this.isSelectedCustomersOkByCombinations()}
      </div>
    );
  }

  renderCustomerPick(customer) {
    const {
      setOccupation,
      resetOccupation,
      calculator: {
        settings: { occupation, customerCombination },
      },
    } = this.props;
    const { type, title } = customer;

    if (!customerCombination) {
      return this.disabledSelect(title);
    }

    const maxOccupants = this.getMaxAvailableOcupants(type);
    const minOccupants = this.getMinAvailableOcupants(type, maxOccupants);

    if (!maxOccupants) {
      return this.disabledSelect(title);
    }

    return (
      <div className="picker picker-small">
        <label>{title}</label>

        <BaseSelect
          isCheckBox={Boolean(false)}
          items={this.inputsByMaxOccupants(maxOccupants, minOccupants)}
          placeholder={'-'}
          text={occupation[type]}
          onReset={() => resetOccupation(type)}
          onSelect={(count) => setOccupation(type, count)}
        />
      </div>
    );
  }

  getMinAvailableOcupants(type, max) {
    const {
      calculator: {
        data: { customerCombinations },
        settings: { customerCombination },
      },
    } = this.props;

    let minOccupants = max;
    const checkParam = type === 'adult' ? 'adultsCount' : 'childCount';

    customerCombinations[customerCombination].forEach((combination) => {
      if (combination[checkParam] < minOccupants && this.isCombinationSuitable(combination, type)) {
        minOccupants = combination[checkParam];
      }
    });

    return minOccupants;
  }

  getMaxAvailableOcupants(type) {
    const {
      calculator: {
        data: { customerCombinations },
        settings: { customerCombination },
      },
    } = this.props;

    let maxOccupants = 0;
    const checkParam = type === 'adult' ? 'adultsCount' : 'childCount';

    customerCombinations[customerCombination].forEach((combination) => {
      if (combination[checkParam] > maxOccupants && this.isCombinationSuitable(combination, type)) {
        maxOccupants = combination[checkParam];
      }
    });

    return maxOccupants;
  }

  isCombinationSuitable(combination, checkParam) {
    const {
      calculator: {
        settings: { occupation },
      },
    } = this.props;
    let childCount = 0;
    let adultCount = 0;

    Object.keys(occupation).forEach((key) => {
      if (occupation[key]) {
        const count = occupation[key];
        const type = key;

        type === 'adult' ? (adultCount += count) : (childCount += count);
      }
    });

    if (checkParam === 'adult') {
      return childCount <= combination.childCount;
    } else {
      return adultCount <= combination.adultsCount;
    }
  }

  inputsByMaxOccupants(maxOccupants, minOccupants) {
    const options = [];
    const realMinimum = minOccupants === 0 ? 0 : minOccupants - 1;

    for (let i = realMinimum; i < maxOccupants; i++) {
      options.push({
        id: i + 1,
        title: i + 1,
      });
    }

    return options;
  }

  isSelectedCustomersOkByCombinations() {
    const { combinationAllowed } = this.props;
    const {
      calculator: {
        settings: {
          occupation: { adult, children },
        },
      },
    } = this.props;

    if (combinationAllowed === true || (!adult && !children)) {
      return null;
    }

    return <div className="combination-not-allowed">Tato kombinace neni povolena</div>;
  }

  disabledSelect(title) {
    return (
      <div className="picker picker-small">
        <label>{title}</label>

        <BaseSelect disabled />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  calculator: state.calculator,
});

const mapDispatchToProps = (dispatch) => ({
  setOccupation: (occupationCode, count) => dispatch(setOccupation(occupationCode, count)),
  resetOccupation: (occupationCode) => dispatch(resetOccupation(occupationCode)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Radium(PeoplePicker));
