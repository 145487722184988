import constants from '../../../styles/constants.js';
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleTiles } from '../../../actions/TourList/paginator';
import { resetAllFilters } from '../../../actions/TourList/tourFilter';
import { Link } from 'react-router';
import getItemsByHeaderId from '../../shared/Header/getItemsByHeaderId';

const styles = {
  dropDown: {
    list: {
      desktop: {
        width: '290px',
        position: 'absolute',
        right: '-5px',
        background: '#f1f1f1',
        listStyle: 'none',
        padding: 0,
        top: '47px',
        fontSize: '1.2em',
        fontWeight: '400',
      },
      responsive: {
        width: '100%',
        position: 'relative',
        top: '5px',
        left: '15px',
        padding: 0,
        background: 'rgba(255, 255, 255, 0.1)',
      },
    },
    item: {
      display: 'block',
      listStyle: 'none',
      textAlign: 'left',
      color: '#404040',
      paddingLeft: 0,
      transition: '0.2s',

      ':hover': {
        background: '#eaeaea',
        paddingLeft: '2px',
      },

      [constants.breakpointLargeTablet]: {
        color: constants.black,
        paddingRight: '15px',

        ':hover': {
          background: 'none',
        },
      },
    },
    link: {
      width: '100%',
      padding: '10px 15px',
      display: 'block',
      position: 'relative',
      left: 0,
      textDecoration: 'none',
      color: 'inherit',
    },
    arrow: {
      position: 'absolute',
      top: '-22px',
      right: '11px',
      height: '12px',
      width: 0,
      display: 'block',
      borderRight: '8px solid transparent',
      borderBottom: '10px solid #f1f1f1',
      borderLeft: '8px solid transparent',
      content: '',
      zIndex: 9,

      [constants.breakpointTablet]: {
        display: 'none',
      },
    },
  },
};

class DropDown extends Component {
  render() {
    const {
      type,
      parent,
      header: { menuItems },
    } = this.props;
    const filteredMenuItems = getItemsByHeaderId(parent, menuItems);

    return (
      <ul style={styles.dropDown.list[type]}>
        {filteredMenuItems.map((menuItem) => this.renderItem(menuItem))}
        <div style={styles.dropDown.arrow} />
      </ul>
    );
  }

  renderItem(headerItem) {
    const { id, newWindow, title, link } = headerItem;

    switch (newWindow) {
      case 1:
        return this.renderExternalLink(title, link, id);

      case 0:
        return this.renderDropDownItem(title, link, id);

      default:
        return '';
    }
  }

  renderDropDownItem(title, url, key) {
    return (
      <li style={styles.dropDown.item} key={key}>
        <Link style={styles.dropDown.link} key={key} to={url}>
          {title}
        </Link>
      </li>
    );
  }

  renderExternalLink(title, url, key) {
    return (
      <li style={styles.dropDown.item} key={key}>
        <a href={url} target="_blank" style={styles.dropDown.link} key={key}>
          {title}
        </a>
      </li>
    );
  }
}

DropDown.propTypes = {
  title: PropTypes.object,
  parent: PropTypes.object,
  url: PropTypes.string,
  key: PropTypes.string,
  type: PropTypes.string,
  header: PropTypes.any,
};

const mapStateToProps = (state) => ({
  header: state.header,
});

const mapDispatchToProps = (dispatch) => ({
  toggleTiles: (nextState) => dispatch(toggleTiles(nextState)),
  resetAllFilters: () => dispatch(resetAllFilters()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(DropDown));
