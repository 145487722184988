import { postJSON } from '../../utils/fetch';

export const SET_POST_FORM_BOOKING = 'SET_POST_FORM_BOOKING';
export const SET_POST_FORM_REVIEW = 'SET_POST_FORM_REVIEW';
export const postSucceeded = 'Formulář byl odeslán';
export const postFailed = 'Formulář nebyl odeslán';

export const postReview = (requestData) => {
  return (dispatch) => {
    postJSON('post-review', requestData).then((result) => {
      if (result.success) {
        dispatch({
          type: SET_POST_FORM_REVIEW,
          result: postSucceeded,
        });
      } else {
        dispatch({
          type: SET_POST_FORM_REVIEW,
          result: postFailed,
        });
      }
    });
  };
};

export const postNewBooking = (requestData) => {
  return (dispatch) => {
    postJSON('post-booking', requestData).then((result) => {
      if (result.success) {
        dispatch({
          type: SET_POST_FORM_BOOKING,
          result: postSucceeded,
        });
      } else {
        dispatch({
          type: SET_POST_FORM_BOOKING,
          result: postFailed,
        });
      }
    });
  };
};
