import 'babel-polyfill';
import AboutUs from './components/Pages/AboutUs/AboutUs';
import App from './App';
import Contact from './components/Pages/Contact/Contact';
import CMSPage from './components/Pages/CMS/CMSPage';
import SubsidyInstructions from './components/Pages/CMS/Instructions';
import TogetherTravelling from './components/Pages/TogetherTravelling/TogetherTravelling';
import SpecialOffer from './components/Pages/Tours/SpecialOffers/SpecialOffer';
import CrossRoad from './components/Pages/FrontCrossRoad/CrossRoad';
import ToursList from './components/Pages/Tours/ToursList/ToursList';
import PriceList from './components/Pages/Tours/TourDetail/Panels/PriceList/PriceList';
import CompletePriceList from './components/Pages/Tours/TourDetail/Panels/PriceList/CompletePriceList';
import PickerPriceList from './components/Pages/Tours/TourDetail/Panels/PickerPriceList';
import Instructions from './components/Pages/Tours/TourDetail/Panels/Instructions';
import Search from './components/Pages/Search/Search';
import Occupation from './components/Pages/Occupation/Occupation';
import ToursTiles from './components/Pages/Tours/ToursList/Tiles';
import TourDetail from './components/Pages/Tours/TourDetail/TourDetail';
import Calculator from './components/Pages/Calculator/Calculator';
import NotFound from './components/Error/NotFound';
import Catalogue from './components/Catalogue/Catalogue';
import Comparement from './components/Pages/Comparement/Comparement';
import React from 'react';

import { hashHistory, Route, Router } from 'react-router';
import Payment from './components/Pages/Payment/Payment';
import PaymentThankYouPage from './components/Pages/Payment/PaymentThankYouPage';
import PaymentError from './components/Pages/Payment/PaymentError';

export default class extends React.Component {
  render() {
    return (
      <Router history={hashHistory}>
        <Route component={App}>
          <Route path="/" component={CrossRoad} />
          <Route path="/cms-page(/:idCms)" component={CMSPage} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/zajezdy" component={ToursTiles} />
          <Route path="/katalog" component={Catalogue} />
          <Route path="/obsazenost" component={Occupation} />
          <Route path="/porovnani(/:r)" component={Comparement} />
          <Route path="/onlyPriceList(/:idDetail)(/:type)" component={CompletePriceList} />
          <Route path="/instructions(/:status)(/:idDetail)" component={Instructions} />
          <Route path="/printPriceList(/:idDetail)(/:type)" component={PriceList} />
          <Route path="/printCompletePriceList(/:idDetail)(/:type)" component={CompletePriceList} />
          <Route path="/printSeparatedPriceList(/:idDetail)(/:type)" component={PickerPriceList} />
          <Route path="/zajezdy/vypis(/:type)" component={ToursList} />
          <Route path="/zajezdy(/:type)" component={SpecialOffer} />
          <Route path="/zajezdy/detail(/:idDetail)(/:openPicker)" component={TourDetail} />
          <Route path="/zajezdy/kalkulacka(/:idDetail)" component={Calculator} />
          <Route path="/search(/:searchTerm)" component={Search} />
          <Route path="/together-travelling" component={TogetherTravelling} />
          <Route path="/contact" component={Contact} />
          <Route path="/online-prodej" component={Payment} />
          <Route path="/online-prodej/dekujeme" component={PaymentThankYouPage} />
          <Route path="/online-prodej/chyba-platby" component={PaymentError} />
          <Route path="/instructions-employees" component={SubsidyInstructions} />
          <Route path="/not-found" component={NotFound} />
        </Route>
      </Router>
    );
  }
}
