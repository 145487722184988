import {
  SAVE_PAGE,
  SET_COST,
  TOGGLE_TILES,
  SAVE_TOUR_SEARCH_PAGE,
} from '../actions/TourList/paginator';
import { TOGGLE_RESETING_LOCATIONS, SET_TOUR_FILTER } from '../actions/TourList/tourFilter';
import { SAVE_FILTERED_FETCHING_FILTER } from '../actions/TourList/fetchTourList';
import { itemsPerPage } from '../utils/config';
import { SET_TOTAL_TOURS_COUNT } from '../actions/HP/defaultTourlist';

const initialState = {
  page: 1,
  totalToursCount: '',
  offerPage: 1,
  searchTourPage: 1,
  searchArticlePage: 1,
  itemsPerPage,
  minCost: -1,
  maxCost: -1,
  tilesVisible: true,
  resetingLocations: false,
};

export const loading = 'načítám';

const filter = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PAGE:
      if (action.paginatorType === 'offer') {
        return { ...state, offerPage: action.page };
      }
      return { ...state, page: action.page };

    case SAVE_TOUR_SEARCH_PAGE:
      if (action.search === 'tour') {
        return { ...state, searchTourPage: action.page };
      }
      return { ...state, searchArticlePage: action.page };

    case TOGGLE_TILES:
      return { ...state, tilesVisible: action.nextState };

    case SET_TOUR_FILTER:
      return {
        ...state,
        minCost: action.result.minPrice,
        maxCost: action.result.maxPrice,
      };

    case TOGGLE_RESETING_LOCATIONS:
      return { ...state, resetingLocations: action.status };

    case SAVE_FILTERED_FETCHING_FILTER:
      return { ...state, page: 1 };

    case SET_TOTAL_TOURS_COUNT:
      return { ...state, totalToursCount: action.result };

    case SET_COST:
      return { ...state, minCost: action.min, maxCost: action.max };

    default:
      return state;
  }
};

export default filter;
