import emailIcon from '../../../../../img/mail-icon-grey.png'; // eslint-disable-line
import constants from '../../../../../styles/constants.js';
import phoneIcon from '../../../../../img/phone-grey.png'; // eslint-disable-line
import phone2Icon from '../../../../../img/phone2.png'; // eslint-disable-line
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const styles = {
  wrapper: {
    textAlign: 'center',
    marginTop: '30px',
    paddingTop: '30px',
    clear: 'both',
    paddingBottom: '15px',
    overflow: 'auto',
    borderTop: '1px solid rgb(204, 204, 204)',

    [constants.breakpointMobile]: {
      paddingBottom: '0',
    },
  },
  link: {
    color: '#444',
    textDecoration: 'none',
  },
  content: {
    position: 'relative',
    width: '92%',
  },
  photoBox: {
    float: 'left',
    width: '100px',
    height: '100px',
    marginTop: '-4px',
    position: 'relative',

    [constants.breakpointMobile]: {
      width: '100%',
      margin: '15px auto 0 auto',
      left: '50%',
      transform: 'translateX(-50%)',
    },

    photoContent: {
      width: '89px',
      height: '89px',
      textAlign: 'center',
      overflow: 'hidden',
      borderRadius: '50px',
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',

      [constants.breakpointMobile]: {
        width: '90px',
        height: '90px',
      },
    },

    image: {
      width: '100%',
      height: 'auto',
    },
  },
  descriptionBox: {
    width: '262px',
    float: 'left',

    [constants.breakpointMobile]: {
      fontSize: '0.9em',
      width: '100%',
    },
    guaranteeTitle: {
      textAlign: 'left',
    },
    name: {
      fontSize: '1em',
      paddingBottom: '5px',
      textAlign: 'left',
      color: [constants.mainBlue],
    },

    item: {
      paddingLeft: '27px',
      margin: '6px 0',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left center',
      backgroundSize: '18px',
      textAlign: 'left',

      email: {
        backgroundImage: 'url(' + emailIcon + ')',
      },
      phone: {
        backgroundImage: 'url(' + phoneIcon + ')',
      },
      phone2: {
        backgroundImage: 'url(' + phone2Icon + ')',
      },
    },
  },
};

class Guarantee extends Component {
  render() {
    const {
      tourDetail: {
        guarantee: { name, email, phone, mobile, photo },
      },
    } = this.props;

    return (
      <div style={styles.wrapper}>
        <div style={styles.content}>
          <div style={styles.descriptionBox}>
            <div style={styles.descriptionBox.name}>
              <strong>{name}</strong> | garant zájezdu
            </div>
            <div
              style={{
                ...styles.descriptionBox.item,
                ...styles.descriptionBox.item.email,
              }}
            >
              <a style={styles.link} href="mailto: {email}">
                {email}
              </a>
            </div>
            <div
              style={{
                ...styles.descriptionBox.item,
                ...styles.descriptionBox.item.phone,
              }}
            >
              {phone + ',  ' + mobile}
            </div>
          </div>
          <div style={styles.photoBox}>
            <div style={styles.photoBox.photoContent}>
              <img style={styles.photoBox.image} src={photo} width="80px" height="80px" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Guarantee.propTypes = {
  tourDetail: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
});

export default connect(mapStateToProps)(Radium(Guarantee));
