const initialState = {
  priceList: [
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Albanie_2018.pdf',
      title: 'Albánie',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Bulharsko_2018.pdf',
      title: 'Bulharsko',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Ceska_republika_2018.pdf',
      title: 'Česká Republika',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Chorvatsko_2018.pdf',
      title: 'Chorvatsko',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Finsko_2018.pdf',
      title: 'Finsko',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Indonesie_2018.pdf',
      title: 'Indonesie',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Italie_2018.pdf',
      title: 'Itálie',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Kanarske_ostrovy_2018.pdf',
      title: 'Kanárské ostrovy',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Kypr_2018.pdf',
      title: 'Kypr',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Madarsko_2018.pdf',
      title: 'Maďarsko',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Malta_2018.pdf',
      title: 'Malta',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Polsko_2018.pdf',
      title: 'Polsko',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Poznavaci_zajezdy_2018.pdf',
      title: 'Poznávací zájezdy',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Poznavaci_zajezdy_2018.pdf',
      title: 'Poznávací zájezdy',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Poznavaci_zajezdy_2018.pdf',
      title: 'Poznávací zájezdy',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Poznavaci_zajezdy_2018.pdf',
      title: 'Poznávací zájezdy',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Poznavaci_zajezdy_2018.pdf',
      title: 'Poznávací zájezdy',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Rakousko_2018.pdf',
      title: 'Rakousko',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Recko_Chalkidiki_2018.pdf',
      title: 'Řecko Chalkidiki',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Recko_Ithaka_2018.pdf',
      title: 'Řecko Ithaka',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Recko_Karpathos_2018.pdf',
      title: 'Řecko Karpathos',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Recko_Kefalonie_2018.pdf',
      title: 'Řecko Kefalonie',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Recko_Kos_2018.pdf',
      title: 'Řecko Kos',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Recko_Kreta_2018.pdf',
      title: 'Řecko Kréta',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Recko_Lefkada_2018.pdf',
      title: 'Řecko Lefkada',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Recko_Leptokarie_2018.pdf',
      title: 'Řecko Leptokarie',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Recko_Preveza_2018.pdf',
      title: 'Řecko Preveza',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Recko_Rhodos_2018.pdf',
      title: 'Řecko Rhodos',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Recko_Samos_2018.pdf',
      title: 'Řecko Samos',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Recko_Santorini_2018.pdf',
      title: 'Řecko Santorini',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Recko_Thassos_2018.pdf',
      title: 'Řecko Thassos',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Recko_Zakynthos_2018.pdf',
      title: 'Řecko Zakynthos',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Seniorske_pobyty_55plus_2018.pdf',
      title: 'Seinorské pobyty 55 +',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Slovensko_2018.pdf',
      title: 'Slovensko',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Slovinsko_2018.pdf',
      title: 'Slovinsko',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Spanelsko_2018.pdf',
      title: 'Španelsko',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Sri_lanka_2018.pdf',
      title: 'Sri Lanka',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Thajsko_2018.pdf',
      title: 'Thajsko',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/ceniky/Turecko_2018.pdf',
      title: 'Turecko',
    },
  ],
  instructions: [
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Albanie_2018.pdf',
      title: 'Albánie',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Bulharsko_2018.pdf',
      title: 'Bulharsko',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Cesko_2018.pdf',
      title: 'Česko',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Chorvatsko_2018.pdf',
      title: 'Chorvatsko',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Finsko_2018.pdf',
      title: 'Finsko',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Indonesie_2018.pdf',
      title: 'Indonésie',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Kanarske_ostrovy_2018.pdf',
      title: 'Kanárské ostrovy',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Kapverdy_2018.pdf',
      title: 'Kapverdy',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Kypr_2018.pdf',
      title: 'Kypr',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Maďarsko_2018.pdf',
      title: 'Albánie',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Malta_2018.pdf',
      title: 'Malta',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Polsko_2018.pdf',
      title: 'Polsko',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Poznavaci_zajezdy_2018.pdf',
      title: 'Poznávací zájezdy',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Rakousko_2018.pdf',
      title: 'Rakousko',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Recko_Chalkidiki_2018.pdf',
      title: 'Řecko Chalkidiki',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Recko_Ithaka_2018.pdf',
      title: 'Řecko Ithaka',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Recko_Karpathos_2018.pdf',
      title: 'Řecko Karpathos',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Recko_Kos_2018.pdf',
      title: 'Řecko Kefalonie',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Recko_Kreta_2018.pdf',
      title: 'Řecko Kréta',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Recko_Lefkada_2018.pdf',
      title: 'Řecko Lefkada',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Recko_Leptokarie_2018.pdf',
      title: 'Řecko Leptokarie',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Recko_Pelopones_2018.pdf',
      title: 'Řecko Pelopones',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Recko_Preveza_2018.pdf',
      title: 'Řecko Preveza',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Recko_Rhodos_2018.pdf',
      title: 'Řecko Rhodos',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Recko_Samos_2018.pdf',
      title: 'Řecko Samos',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Recko_Santorini_2018.pdf',
      title: 'Řecko Santorini',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Recko_Thassos_2018.pdf',
      title: 'Řecko Thassos',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Recko_Zakynthos_2018.pdf',
      title: 'Řecko Zakynthos',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Seniorske_pobyty_55_2018.pdf',
      title: 'Seniorské pobyty',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Slovensko_2018.pdf',
      title: 'Slovensko',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Slovinsko_2018.pdf',
      title: 'Slovinsko',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Spanelsko_2018.pdf',
      title: 'Španelsko',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Sri_Lanka_2018.pdf',
      title: 'Sri_Lanka',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Thajsko_2018.pdf',
      title: 'Thajsko',
    },
    {
      url: 'http://82.117.128.140//www/files/pdf/popisy/P_Turecko_2018.pdf',
      title: 'Turecko',
    },
  ],
};

const pdfDownload = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default pdfDownload;
