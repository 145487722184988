import React, { Component } from 'react';
import { Link } from 'react-router';
import Radium from 'radium';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import {
  occupationWithDiscounts,
  formatCurrency,
  adultPriceByCustomerIndex,
  childrenPriceByCustomerIndex,
} from '../calculations';
import {
  toggleGrant,
  setChildrenAgeByRenderIndex,
} from '../../../../actions/Calculator/calculator';

class Infants extends Component {
  render() {
    const {
      calculator: {
        occupation: { infants },
      },
    } = this.props;

    return <div>{infants.map((infant) => this.renderInfant(infant))}</div>;
  }

  renderInfant(infant) {
    return (
      <div className="infants-summary">
        <strong>
          {infant.title} ({formatCurrency(infant.price)}):
        </strong>

        <div>
          ({infant.timeUsed}x) {formatCurrency(infant.totalPrice)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  calculator: state.calculator,
});

const mapDispatchToProps = (dispatch) => ({
  toggleGrant: (occupation, index) => dispatch(toggleGrant(occupation, index)),
  setChildrenAgeByRenderIndex: (index, age) => dispatch(setChildrenAgeByRenderIndex(index, age)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(Infants));
