import {
  SAVE_HOTELS_MAP_ESSENTIAL,
  SAVE_HOTELS_MAP_ADDITIONAL,
} from '../actions/TourList/Map/hotelsMap';
import { SAVE_FILTER } from '../actions/TourList/filter';
import { SAVE_FILTERED_TOURS_FOR_MAP } from '../actions/TourList/fetchTourList';

const initialState = {
  data: [],
  filters: {
    countrySortedByName: null,
    location: null,
  },
  additionalDataFetched: false,
};

const hotelsMap = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_HOTELS_MAP_ESSENTIAL:
      return { ...state, data: action.result };

    case SAVE_FILTERED_TOURS_FOR_MAP:
      Object.keys(state.data).forEach((key) => {
        const tour = state.data[key];

        tour.isHidden = !action.result.includes(parseInt(key));
      });

      return { ...state };

    case SAVE_HOTELS_MAP_ADDITIONAL:
      const updatedData = {};

      Object.keys(action.result).forEach((key) => {
        updatedData[key] = { ...state.data[key], ...action.result[key] };
      });

      return { ...state, data: updatedData, additionalDataFetched: true };

    default:
      return state;
  }
};

export default hotelsMap;
