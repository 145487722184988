import { postJSON } from '../../utils/fetch';

export const createNewPayment = (formData) => {
  const { specificSymbol, amount, firstAndSecondName } = formData;
  const data = { specificSymbol, amount, firstAndSecondName };

  postJSON('payment/', data).then(({ url }) => {
    window.location.href = url;
  });
};
