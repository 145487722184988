export const TOGGLE_TAB = 'TOGGLE_TAB';
export const PICK_TOUR = 'PICK_TOUR';
export const CLOSE_TAB = 'CLOSE_TAB';
export const ADD_TOUR_FOR_COMPAREMENT = 'ADD_TOUR_FOR_COMPAREMENT';
export const SET_COOKIE_COMPAREMENT_TOURS = 'SET_COOKIE_COMPAREMENT_TOURS';
export const REMOVE_COMPARED_TOUR = 'REMOVE_COMPARED_TOUR';

export const addTourForComparement = (term, tourDetail) => {
  return {
    type: ADD_TOUR_FOR_COMPAREMENT,
    term,
    tourDetail,
  };
};

export const removeComparedTour = (idTour) => {
  return {
    type: REMOVE_COMPARED_TOUR,
    idTour,
  };
};

export const setComparemetTours = () => {
  return {
    type: SET_COOKIE_COMPAREMENT_TOURS,
  };
};

export const toggleTab = () => {
  return {
    type: TOGGLE_TAB,
  };
};

export const pickTour = (idTour) => {
  return {
    type: PICK_TOUR,
    idTour,
  };
};

export const closeTab = () => {
  return {
    type: CLOSE_TAB,
  };
};
