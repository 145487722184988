import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

const styles = {
  sticker: {
    position: 'absolute',
    left: '-43px',
    top: '32px',
    transform: 'rotate(-45deg)',
    color: '#fff',
    padding: '4px 10px 5px 10px',
    textAlign: 'center',
    fontWeight: '500',
    letterSpacing: '0.03em',
    width: '165px',
    display: 'block',
    zIndex: 1,
    fontSize: '0.9em',
  },
};

class TourSticker extends Component {
  render() {
    const { children, type, primaryColor, secondaryColor } = this.props;

    if (primaryColor !== 'defaultColor') {
      type.background = primaryColor;
    }

    if (secondaryColor !== 'defaultColor') {
      styles.sticker.color = secondaryColor;
    }

    return <div style={[styles.sticker, type]}>{children}</div>;
  }
}

TourSticker.propTypes = {
  children: PropTypes.string.isRequired,
  type: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
};

export default Radium(TourSticker);
