export const itemArrayForSelet = (data, settingValue) => {
  return data.map((entity) => {
    const dataIndex = settingValue
      ? settingValue.findIndex((setting) => {
          return setting.id === entity.id;
        })
      : -1;

    return {
      selected: dataIndex > -1,
      id: entity.id,
      title: `${entity.title} (${entity.price})`,
    };
  });
};

export const selectedText = (settingValue) => {
  return settingValue
    .map((value) => {
      return ` ${value.title} (${value.price})`;
    })
    .join();
};

export const handleSelect = (id, data, settingValue, updateFunc, param) => {
  const surchargeArray = settingValue || [];
  const dataIndex = surchargeArray.findIndex((surcharge) => {
    return surcharge.id === id;
  });

  if (dataIndex < 0) {
    surchargeArray.push(entityById(id, data));
  } else {
    surchargeArray.splice(dataIndex, 1);
  }

  updateFunc(surchargeArray, param);
};

export const entityById = (id, data) => {
  return data.find((entity) => {
    return entity.id === id;
  });
};
