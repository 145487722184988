import { getJSON, postJSON } from '../../utils/fetch';
import cookie from 'react-cookie';
import { hashHistory } from 'react-router';

export const SET_TOUR_FILTER = 'SET_TOUR_FILTER';
export const SET_TOUR_LIST = 'SET_TOUR_LIST';
export const SET_TERMS = 'SET_TERMS';
export const SET_SPECIAL_LIST = 'SET_SPECIAL_LIST';
export const SAVE_LOCATIONS = 'SAVE_LOCATIONS';
export const SAVE_LOCATIONS_HOTELS = 'SAVE_LOCATIONS_HOTELS';
export const SAVE_FILTERED_LIST = 'SAVE_FILTERED_LIST';
export const SAVE_FILTERED_TOURS_FOR_MAP = 'SAVE_FILTERED_TOURS_FOR_MAP';
export const SAVE_FILTERED_FETCHING = 'SAVE_FILTERED_FETCHING';
export const SAVE_SPECIAL_FETCHING = 'SAVE_SPECIAL_FETCHING';
export const SAVE_FILTERED_FETCHING_FILTER = 'SAVE_FILTERED_FETCHING_FILTER';

export const fetchTourList = () => {
  return (dispatch) => {
    getJSON('list/').then((result) => {
      dispatch({
        type: SET_TOUR_LIST,
        result,
      });
    });
  };
};

export const fetchSpecialList = (pageProperties) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_SPECIAL_FETCHING,
    });
    postJSON('special-tours/', pageProperties).then((result) => {
      dispatch({
        type: SET_SPECIAL_LIST,
        result,
      });
    });
  };
};

export const fetchTerms = (properties) => {
  return (dispatch) => {
    dispatch({
      type: SET_TERMS,
      result: [],
    });
    postJSON('terms-by-tour/', properties).then((result) => {
      if (!result.terms.length) {
        hashHistory.push('/zajezdy/vypis/');
      }

      dispatch({
        type: SET_TERMS,
        result,
      });
    });
  };
};

export const fetchFilteredList = (filters, settingPage) => {
  return (dispatch) => {
    if (!settingPage) {
      /* postJSON("hotels-map-filter/", filters).then(result => {
        dispatch({
          type: SAVE_FILTERED_TOURS_FOR_MAP,
          result
        });
      });

      dispatch({
        type: SAVE_FILTERED_FETCHING_FILTER
      });*/
    }

    dispatch({
      type: SAVE_FILTERED_FETCHING,
      filters,
    });

    postJSON('filtered-tours/', filters).then((result) => {
      dispatch({
        type: SAVE_FILTERED_LIST,
        result,
        filters,
      });
    });
  };
};

export const fetchLocations = () => {
  return (dispatch) => {
    getJSON('locations-in-countries/?user=' + cookie.load('user')).then((result) => {
      dispatch({
        type: SAVE_LOCATIONS,
        result,
      });
    });
  };
};

export const fetchOccupationHotels = () => {
  return (dispatch) => {
    getJSON('hotels-in-locations/').then((result) => {
      dispatch({
        type: SAVE_LOCATIONS_HOTELS,
        result,
      });
    });
  };
};
