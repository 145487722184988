import Radium from 'radium';
import AdLine from './AdLine';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SelectBox from '../../shared/forms/SelectBox';
import TableBox from '../../shared/TableBox';
import formatDateLong from '../../shared/Moment/formatDateLong';
import 'moment/locale/cs';
import filter from './Sort';
import moment from 'moment';
import { connect } from 'react-redux';

const styles = {
  table: {
    width: '100%',
    fontSize: '0.85em',

    line: {
      boxShadow: '0px 1px 0px #e7e7e7',
    },

    head: {
      item: {
        fontWeight: '500',
        padding: '10px',
        textAlign: 'left',
      },
    },
    body: {
      item: {
        padding: '10px',
        textAlign: 'left',
      },
    },
  },
  sorterBox: {
    marginBottom: '10px',
  },
};

class AdList extends Component {
  state = {
    open: false,
    passengers: [],
    options: [
      {
        id: 1,
        value: 'nejnovějších',
      },
      {
        id: 2,
        value: 'datumu odjezdu',
      },
    ],
  };

  componentDidMount() {
    this.initialize();
  }

  componentWillReceiveProps(props) {
    const { passengers } = props;
    this.setState({ passengers });
  }

  render() {
    const { passengers, options } = this.state;

    return (
      <div>
        <div style={styles.sorterBox}>
          Řadit dle:
          <SelectBox
            name="sort"
            options={options}
            type={'inline'}
            onClick={this.handleSelectBox.bind(this)}
          />
        </div>
        <TableBox>
          <tbody>
            <tr style={styles.table.line}>
              <th style={styles.table.head.item}>
                Datum od
                <br />
                Datum do
              </th>
              {this.renderHeadItem('Země')}
              {this.renderHeadItem('Pohlaví')}
              {this.renderHeadItem('Věk')}
              {this.renderHeadItem('Informace')}
              {this.renderHeadItem('')}
            </tr>

            {passengers.map((item) => this.renderRow(item))}
          </tbody>
        </TableBox>
      </div>
    );
  }

  initialize() {
    const { passengers } = this.props;
    this.setState({ passengers });
  }

  handleSelectBox(value) {
    const { passengers } = this.state;

    this.setState({ passengers: filter(passengers, value) });
  }

  renderHeadItem(title) {
    return <th style={styles.table.head.item}>{title}</th>;
  }

  renderRow(item) {
    moment.locale('cs');

    if (item.id !== 'empty') {
      const {
        passengersFilter: { dateFrom, dateTo, sex, country },
      } = this.props;
      const itemDateFrom = item.dateFrom;
      const itemDateTo = item.dateTo;

      if (sex.toString() !== item.idSex.toString() && sex !== '') {
        return null;
      }
      if (country !== item.idCountry && country !== '') {
        return null;
      }

      if (dateFrom && moment(dateFrom).isAfter(moment(itemDateFrom))) {
        return null;
      }
      if (dateTo && moment(dateTo).isBefore(itemDateTo)) {
        return null;
      }
      return (
        <AdLine
          dateFrom={formatDateLong(itemDateFrom)}
          dateTo={formatDateLong(itemDateTo)}
          item={item}
        />
      );
    }
    return null;
  }
}

AdList.propTypes = {
  passengers: PropTypes.any,
  passengersFilter: PropTypes.any,
};

const mapStateToProps = (state) => ({
  passengers: state.passengers,
  passengersFilter: state.passengersFilter,
});

export default connect(mapStateToProps)(Radium(AdList));
