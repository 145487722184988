import { getJSON, postJSON } from '../../../utils/fetch';
import cookie from 'react-cookie';

export const SAVE_HOTELS_MAP_ESSENTIAL = 'SAVE_HOTELS_MAP_ESSENTIAL';
export const SAVE_HOTELS_MAP_ADDITIONAL = 'SAVE_HOTELS_MAP_ADDITIONAL';

export const fetchEssentialData = () => {
  return (dispatch) => {
    getJSON(`hotels-map/?user=${cookie.load('user')}`).then((result) => {
      dispatch({
        type: SAVE_HOTELS_MAP_ESSENTIAL,
        result,
      });
    });
  };
};

export const fetchAdditionalData = () => {
  return (dispatch) => {
    getJSON(`hotels-map/?user=${cookie.load('user')}&getFull=1`).then((result) => {
      dispatch({
        type: SAVE_HOTELS_MAP_ADDITIONAL,
        result,
      });
    });
  };
};
