const headerTemplate = `
<!DOCTYPE html>
<html>
<head>
    <meta http-equiv="content-type" content="text/html; charset=utf-8">
</head>


<style>
    * {
        padding: 0;
        margin: 0;
    }

    .pricelist-table {
        margin: 0 auto;
        margin-top: 5px;
        width: 100%;
    }
    
.slick-slide img{
display: inline-block;
}


#catalogue-wrapper {
width: 895px;
position: relative;
transform: scale(1);
transform-origin: left top;
}

@media only screen and (max-width: 1024px) {
    #catalogue-wrapper {
     margin-top: 30px;
   
    }
}

@media only screen and (max-width: 920px) {
    #catalogue-wrapper {
     transform: scale(0.85) translateX(-50%);
      left: 50%;
    }
}

@media only screen and (max-width: 790px) {
    #catalogue-wrapper {
     transform: scale(0.7) translateX(-50%);
    }
}

@media only screen and (max-width: 650px) {
    #catalogue-wrapper {
     transform: scale(0.55) translateX(-50%);
    }
}

@media only screen and (max-width: 520px) {
    #catalogue-wrapper {
     transform: scale(0.40) translateX(-50%);
    }
}

@media only screen and (max-width: 385px) {
    #catalogue-wrapper {
     transform: scale(0.35) translateX(-50%);
    }
}

@media only screen and (max-width: 350px) {
    #catalogue-wrapper {
     transform: scale(0.33) translateX(-50%);
    }
}



.content-wrapper img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
    a {
        text-decoration: underline;
    }

    .table-tbody {
        background-color: white;
        color: #287fb8;
    }

    .table-tourPrice {
        text-align: center;
        font-weight: bold;
        color: #edb700
    }

    .table-centered {
        text-align: center;
    }

    body {
        font-family: Open Sans, sans-serif;
      font-size: 14px;
        line-height: 16px;
        margin: 0;
    }

    p {
        padding: 0px 10px;
        font-size: 12px;
        color: #444;
    }

    table {

        font-weight: 300 !important;
        font-size: 13px;

    }

    .pricelist-table td {
        color: #555;
    }

    .table-head th {
    }

    table td,
    table th {
        border-bottom: solid 1px #dddddd;
        padding: 10px 6px;
    }

    table th {
        color: #fff;
        font-weight: bold;
        border: none;
    }

    table td:first-child {
        font-weight: bold;
    }

    .subsidy  {
        background-color: #afc836 !important;
        color: white;
    }
    .not-subsidy {
        background-color: #edb700 !important;
        color: white;
    }
    
    .visitor {
        background-color: #edb700 !important;
        color: white;
    }
    
    .employee  {
        background-color: #afc836 !important;
        color: white;
    }
    
    .szEmployee {
        background-color: #FF5200 !important;
        color: white;
    }

    .term-sold-out td {
        color: #999;
    }

    .term-sold-out .price {

    }

    .td-soldOut {
        width: 500px;
    }

    .days-count {
        width: 40px;
    }
    
    .pricelist-table th {
    text-align: center !important;
    }
</style>


<div id="catalogue-wrapper">
<div>
    <div style="height: 407px; background-repeat: no-repeat; background-image: url({mainImage});
background-size: 100%;">
        <div style="height: 30px;">
            <div style=" line-height: 30px; font-weight: bold;">
                <table style="padding: 10px; margin: 0px; background: rgba(51,93,135,0.71); color: white">
                    <tr>
                        <th style=" border-bottom: none; padding: 0px 0px 5px; font-size: 21px; text-align: left;">
                            {country}
                        </th>
                    </tr>

                    <tr>
                        <th style="border-bottom: none; padding: 0px; font-weight: normal; font-size: 21px; text-align: left;">
                            {hotel}
                        </th>
                    </tr>
                </table>
            </div>
        </div>
    </div>

    <div style="height: 40px; background: linear-gradient(to left, #e4e1ed 0%,#dce3e8 37%,#f8f2ff 93%);">
        <div style="display: inline-block; float: left; padding-top: 14px; margin-left: 10px">
            {availabilityIcons}
        </div>

        <div style="display: inline-block; width: 260px; float: right; padding-bottom: 6px;">
            <div style="margin-left: 10px; width: 120px; float: left;">
                <div style="font-size: 12px; height: 30px; padding-top: 5px;text-transform: uppercase; color: #335d87">
                    Garant zájezdu:
                </div>
                <div style="font-size: 12px; height: 12px;  margin-top: -14px;"><strong>{guaranteeName}</strong></div>
            </div>
            <div style="height: 0px; width: 120px; float: left; margin-top: -2px">
                <div style="height: 20px; margin-top: 13px; margin-right: 10px; width: 30px;float: left; display: inline-block;">
                    <img style="height: 20px; width: auto;"
                         src="//www.cdtravel.cz/www/images/detail/icons/telephone.png"/>
                </div>

                <div style="float: left; display: inline-block; font-weight: bold; margin-top: 15px;">{guaranteePhone}
                </div>
            </div>

        </div>
    </div>

    <div style="margin-bottom: 15px; height: 685px; background-color: #fff;">
     <div style="column-count: 2; column-fill: auto; padding: 15px; height: 670px; text-align: left;">
            <div class="content-wrapper" style="background: white; height: 685px; width: 410px">
                {content}
            </div>
      </div>  
    </div>
</div>

 <div style="background-color: #fff;">
    <columns style="margin-top: -10px; " column-count="1" vAlign="J" column-gap=0/>
    {priceList}
    </columns>
</div>
</div>
</body>
</html>
`;

export default headerTemplate;
