import { setSettingsParameter } from '../../../../actions/Calculator/calculator';
import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import BaseSelect from '../../../shared/BaseSelect/BaseSelect';
import { itemArrayForSelet, handleSelect, selectedText } from './multiSelect';
import { surchargeParam } from '../../../../reducers/calculator';

class SurchargesPicker extends Component {
  render() {
    const {
      calculator: {
        data: { surcharges },
        settings: { surcharge },
      },
      setSettingsParameter,
    } = this.props;

    if (surcharges.length === 0) {
      return null;
    }

    return (
      <div className="picker picker-big">
        <label>Příplatky</label>
        {/*   <p>{surcharge && surcharge.length ? selectedText(surcharge) : null}</p>*/}

        <BaseSelect
          isCheckBox={Boolean(true)}
          items={itemArrayForSelet(surcharges, surcharge)}
          placeholder={'-'}
          text={surcharge && surcharge.length ? selectedText(surcharge) : null}
          onReset={() => setSettingsParameter([], surchargeParam)}
          onSelect={(id) =>
            handleSelect(id, surcharges, surcharge, setSettingsParameter, surchargeParam)
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  calculator: state.calculator,
});

const mapDispatchToProps = (dispatch) => ({
  setSettingsParameter: (object, parameter) => dispatch(setSettingsParameter(object, parameter)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Radium(SurchargesPicker));
