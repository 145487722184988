import PropTypes from 'prop-types';
import React, { Component } from 'react';
import constants from '../../styles/constants';
import selectArrow from '../../img/select-arrow2.png'; // eslint-disable-line
import { connect } from 'react-redux';
import { toggle, selecting } from '../../actions/TourList/filterOpenStatus';
import { toggleSelectedLocations, resetLocations } from '../../actions/TourList/tourFilter';
import createFilterRequest from '../shared/RequestCreator/createFilterRequest';
import { fetchFilteredList } from '../../actions/TourList/fetchTourList';
import { toggleTiles } from '../../actions/TourList/paginator';
import { saveFilter } from '../../actions/TourList/filter';
import { closeTabs } from '../../actions/TourList/filterOpenStatus';
import Radium from 'radium';

const styles = {
  wrapper: {
    width: '100%',
    overflow: 'auto',
  },
  inputBox: {
    width: '32.3%',
    margin: '0.50% 0.5%',
    float: 'left',
    position: 'relative',

    [constants.breakpointTablet]: {
      width: '47%',
      margin: '4px 1.5%',
    },

    [constants.breakpointMobile]: {
      width: '100%',
      margin: '4px 0',
    },
  },

  selectBox: {
    width: 'calc(100% - 53px)',
    height: 40,
    overflow: 'hidden',
    padding: '0 42px 0 11px',
    lineHeight: '40px',
    border: '1px solid' + [constants.mainBlue],
    display: 'inline-block',
    backgroundColor: '#fff',
    backgroundImage: 'url(' + selectArrow + ')',
    backgroundPosition: 'right center',
    backgroundRepeat: 'no-repeat',
    color: [constants.mainBlue],
    fontSize: '0.9em',
    fontWeight: '500',
    cursor: 'pointer',
  },
  dropDown: {
    background: [constants.dropDownBackground],
    position: 'absolute',
    top: 54,
    left: 0,
    width: 'calc(100% + 2px)',
    zIndex: '2',
    fontSize: '0.9em',
    boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.11)',

    item: {
      padding: '8px 5px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
      cursor: 'pointer',
      margin: '0 7px',
      position: 'relative',
      bottom: '-1px',
    },
    checkbox: {
      marginRight: '10px',
      position: 'relative',
      top: '1px',
    },
    caret: {
      display: 'block',
      position: 'absolute',
      top: '-8px',
      left: '7px',
      width: '0',
      height: '0',
      content: '',
      verticalAlign: 'middle',
      borderBottom: '10px dashed' + [constants.dropDownBackground],
      borderRight: '10px solid transparent',
      borderLeft: '10px solid transparent',
    },
  },
};

const countryDefault = 'země';

class MultiCheckbox extends Component {
  state = {
    countryText: 'země',
    text: '',
    opened: false,
    checked: false,
  };

  componentDidMount() {
    const { onRef } = this.props;

    onRef && onRef(this);
  }

  componentWillUnmount() {
    const { onRef } = this.props;

    onRef && onRef(undefined);
  }

  componentWillReceiveProps(props) {
    this.countriesText(props);
  }

  open() {
    const { type } = this.props;

    this.toggleOpen(type);
  }

  render() {
    const {
      defaultText,
      data,
      filterOpen,
      type,
      countries,
      selectedCountry,
      selectedLocationName,
      filter: { countrySortedByName },
    } = this.props;

    const { checked, countryText, text } = this.state;
    if (!checked) {
      this.countriesText(this.props);
      this.setState({ checked: true });
    }

    return (
      <div style={styles.inputBox}>
        <div onClick={() => this.toggleOpen(type)} style={styles.selectBox}>
          {selectedLocationName || defaultText}
        </div>
        <div style={styles.dropDown}>
          {filterOpen[type] && <div style={styles.dropDown.caret} />}
          {filterOpen[type] && (
            <div className="item" style={styles.dropDown.item} onClick={() => this.reset()}>
              {this.renderDefaultText()}
            </div>
          )}
          {filterOpen[type] && selectedCountry !== countryDefault && data[selectedCountry]
            ? data[selectedCountry].map((items) => this.renderCheckbox(items, selectedCountry))
            : null}
        </div>
      </div>
    );
  }

  renderDefaultText() {
    const { defaultTextBold, defaultText } = this.props;

    if (!defaultTextBold) {
      return defaultText;
    }

    return <strong>{defaultText}</strong>;
  }

  countriesText(props) {
    const {
      tourFilter: { countrySortedByName },
      resetLocations,
      countries,
      paginator: { resetingLocations },
    } = props;

    const countriesToUse = countries ? countries : countrySortedByName;
    const { countryText } = this.state;
    let countrySelected = false;
    countriesToUse.forEach((country) => {
      if (country.selected) {
        countrySelected = true;
        if (countryText !== country.value) {
          this.setState({ countryText: country.value, opened: false });
          if (resetingLocations) {
            resetLocations(country.value);
          }
        }
        this.checkChange(props, country.value);
      }

      !countrySelected && this.setState({ text: '' });
    });
  }

  reset() {
    const { reset } = this.props;

    reset();
  }

  toggleOpen(designation) {
    const { toggle, filterOpen, type, closeTabs } = this.props;
    if (!filterOpen[type]) {
      toggle(designation);
    } else {
      closeTabs();
    }
  }

  renderCheckbox(items, designation) {
    const { value, code, selected } = items;
    const { box } = this.props;

    if (box === 'textbox') {
      return (
        <div
          className="item"
          style={styles.dropDown.item}
          value={code}
          onClick={this.toggleCheckbox.bind(this, code, designation, true)}
        >
          {value}
        </div>
      );
    } else {
      return (
        <div
          onClick={this.toggleCheckbox.bind(this, code, designation, false)}
          style={styles.dropDown.item}
        >
          <input type="checkbox" style={styles.dropDown.checkbox} value={code} checked={selected} />
          {value}
          <br />
        </div>
      );
    }
  }

  checkChange(props, countryText) {
    const { defaultText } = this.state;
    const {
      data,
      type,
      filter,
      saveFilter,
      paginator: { page, itemsPerPage, minCost, maxCost },
      fetchFilteredList,
    } = props;
    let checkboxText = '';
    const newFilter = [];

    try {
      data[countryText].forEach((data) => {
        if (data.selected) {
          checkboxText = checkboxText + ' ' + data.value;
          newFilter.push(data.code);
        }
      });
      if (checkboxText === '') {
        checkboxText = defaultText;
      }
    } catch (err) {
      checkboxText = defaultText;
    }

    this.setState({ text: checkboxText });
    if (JSON.stringify(newFilter) !== JSON.stringify(filter[type])) {
      filter[type] = newFilter;
      fetchFilteredList(createFilterRequest(filter, page, itemsPerPage, minCost, maxCost));
      saveFilter(newFilter, type);
    }
  }

  toggleCheckbox(code, designation, textBox) {
    const { toggleSelectedLocations, selecting, toggleTiles, onSelect } = this.props;

    onSelect(code, designation);
  }
}

MultiCheckbox.propTypes = {
  filter: PropTypes.any,
  defaultText: PropTypes.any,
  data: PropTypes.any,
  filterOpen: PropTypes.any,
  type: PropTypes.any,
  resetLocations: PropTypes.any,
  toggle: PropTypes.any,
  closeTabs: PropTypes.any,
  toggleSelectedLocations: PropTypes.any,
  selecting: PropTypes.any,
  toggleTiles: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  toggle: (designation) => dispatch(toggle(designation)),
  selecting: () => dispatch(selecting()),
  closeTabs: () => dispatch(closeTabs()),
  resetLocations: (designation) => dispatch(resetLocations(designation)),
  fetchFilteredList: (filters) => dispatch(fetchFilteredList(filters)),
  toggleTiles: (nextState) => dispatch(toggleTiles(nextState)),
  saveFilter: (filter, designation) => dispatch(saveFilter(filter, designation)),
  toggleSelectedLocations: (code, designation, textBox) =>
    dispatch(toggleSelectedLocations(code, designation, textBox)),
});

const mapStateToProps = (state) => ({
  filter: state.filter,
  paginator: state.paginator,
  tourFilter: state.tourFilter,
  filterOpen: state.filterOpen,
  tourList: state.tourList,
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(MultiCheckbox));
