import constants from '../../styles/constants.js';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Parallax } from 'react-parallax';

const styles = {
  section: {
    background: '#000',
  },
  wrapper: {
    width: '100%',
    height: '294px',

    [constants.breakpointTablet]: {
      height: '130px',
    },
  },
  image: {
    minWidth: '100%',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    height: 'auto',
    opacity: '0.7',

    [constants.breakpointDesktopMenu]: {
      height: '100%',
      width: 'auto',
    },
    [constants.breakpointLargeTablet]: {
      height: '100%',
      width: 'auto',
    },
  },
  sectionHead: {
    width: '100%',
  },
  title: {
    position: 'absolute',
    top: '110px',
    maxWidth: '800px',
    transform: 'translateY(-50%)',
    left: '8%',
    fontSize: '3.3em',
    lineHeight: '1.2em',
    color: '#fff',
    zIndex: '6',
    textTransform: 'uppercase',
    fontWeight: '700',
    minWidth: 'auto',

    [constants.breakpointLargeTablet]: {
      fontSize: '2.8em',
    },
    [constants.breakpointTablet]: {
      position: 'relative',
      left: '50%',
      top: '50%',
      marginTop: '9px',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      minWidth: '200px',
      fontSize: '2em',
    },
  },
};

class SectionImage extends Component {
  render() {
    const { actualImage, actualTitle } = this.props;

    return (
      <Parallax bgImage={actualImage} strength={400}>
        <div style={styles.wrapper} className="section-image">
          <h1 style={styles.title}>{actualTitle}</h1>
        </div>
      </Parallax>
    );
  }
}

SectionImage.propTypes = {
  actualImage: PropTypes.string,
  actualTitle: PropTypes.string,
};

export default SectionImage;
