import {
  ADD_TOUR_FOR_COMPAREMENT,
  SET_COOKIE_COMPAREMENT_TOURS,
  REMOVE_COMPARED_TOUR,
} from '../actions/TourDetail/tourDetail';

import { ADD_TOUR_DETAIL_FOR_COMPAREMENT } from '../actions/TourDetail/fetchTourDetail';
import cookie from 'react-cookie';

import formatDateShort from '../components/shared/Moment/formatDateShort';
import formatDateLong from '../components/shared/Moment/formatDateLong';

const initialState = {
  tours: [],
  tourDetails: [],
};

const addTourDetailForComparement = (state, action) => {
  const tourDetails = state.tourDetails;
  let tourAlreadyAdded = false;

  state.tourDetails.forEach((tour) => {
    if (tour.id === action.idTour) {
      tourAlreadyAdded = true;
    }
  });

  if (!tourAlreadyAdded) {
    tourDetails.push(action.result);
  }

  return { ...state, tourDetails };
};

const addTourForComparement = (state, action) => {
  let tourIsBeignAlreadyCompared = false;
  const tours = state.tours;

  if (state.tours.length >= 3) {
    return { ...state };
  }

  state.tours.forEach((tour) => {
    const { idTour } = tour;

    if (idTour === action.term.idTour) {
      tourIsBeignAlreadyCompared = true;
    }
  });

  if (!tourIsBeignAlreadyCompared) {
    const { idTour, dateFrom, dateTo } = action.term;
    const { hotelTitle } = action.tourDetail;

    const dates = formatDateShort(dateFrom) + ' - ' + formatDateLong(dateTo);

    tours.push({ idTour, hotelTitle, dates });
  }

  cookie.save('compareTours', JSON.stringify(tours));

  return { ...state, tours };
};

const tourComparement = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TOUR_DETAIL_FOR_COMPAREMENT:
      return addTourDetailForComparement(state, action);

    case REMOVE_COMPARED_TOUR:
      let removeIndex = 0;

      state.tours.forEach((tour, index) => {
        if (tour.idTour === action.idTour) {
          removeIndex = index;
        }
      });

      state.tours.splice(removeIndex, 1);
      const tours = state.tours;
      cookie.save('compareTours', JSON.stringify(tours));

      return { ...state, tours };

    case SET_COOKIE_COMPAREMENT_TOURS:
      const jsonTours = cookie.load('compareTours');

      if (jsonTours) {
        const tours = jsonTours;

        return { ...state, tours };
      }

      return { ...state };

    case ADD_TOUR_FOR_COMPAREMENT:
      return addTourForComparement(state, action);

    default:
      return state;
  }
};

export default tourComparement;
