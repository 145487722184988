import PDF from 'react-pdf-js';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import StyledCircleLoader from '../../shared/Loading/StyledCircleLoader';
import ReactTooltip from 'react-tooltip';
import Slider from 'react-slick';
import PdfItem from './PdfItem';

import './PdfList.scss';

class Widget extends Component {
  state = {
    selected: 0,
  };

  constructor(props) {
    super(props);
    this.goTo = this.goTo.bind(this);
    this.props.onRef(this);
  }

  goTo(index) {
    window.clearTimeout(this.scrolling);

    this.scrolling = setTimeout(() => {
      this.slider.slickGoTo(index);
    }, 400);
  }

  shouldComponentUpdate(props, state) {
    return false;
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  render() {
    const {
      catalogue: { pdfList },
    } = this.props;

    if (!pdfList.length) {
      return (
        <div className="catalogue-loader">
          <StyledCircleLoader />
        </div>
      );
    }

    return (
      <div className="inside-pdf-wrapper" onRef={(ref) => (this.Pdf = ref)}>
        <Slider
          ref={(c) => (this.slider = c)}
          arrows={Boolean(false)}
          draggable={Boolean(false)}
          infinite={Boolean(false)}
          dots={Boolean(false)}
        >
          {pdfList.map((pdf) => this.renderSlide(pdf))}
        </Slider>
      </div>
    );
  }

  renderSlide(pdf) {
    const { onDocumentComplete } = this.props;

    return (
      <div>
        <PdfItem pdfData={pdf} onDocumentComplete={() => onDocumentComplete()} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  catalogue: state.catalogue,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Widget);
