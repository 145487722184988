import React, { Component } from 'react';
import Radium from 'radium';
import DatePicker from 'material-ui/DatePicker';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { setParam } from '../../../../actions/Calculator/reservation';
import './Reservation.scss';
import OrangeButton from '../../../shared/OrangeButton';
import UsersDetail from './UsersDetail';
import ReservationComplete from './ReservationComplete';
import constants from '../../../../styles/constants';
import moment from 'moment';

class Agreement extends Component {
  state = {};

  render() {
    const { trans, values, param, isSubsidy, onChange } = this.props;

    return (
      <label className={'custom-checkbox agreement' + (isSubsidy ? ' subsidy' : '')}>
        {trans[param]}
        <input type="checkbox" checked={values[param]} onChange={() => onChange(!values[param])} />
        <span className="checkmark" />
      </label>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Radium(Agreement));
