import styles from '../../../../shared/TourListStyles/TourListItemStyles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import format from '../../../../shared/FormatDescription';
import TourSticker from '../components/TourSticker';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { fetchTourDetail } from '../../../../../actions/TourDetail/fetchTourDetail';
import TermPicker from '../components/TermPicker';
import formatCurrency from '../../../../shared/formatCurrency';

const soldOut = 'soldOut';
const typeOffer = 'offer';

class ToursListItem extends Component {
  state = {
    openModalTerms: false,
    pickerOpened: false,
  };

  render() {
    const {
      title,
      description,
      destination,
      image,
      tour,
      tour: { specialState, lowestPrice },
    } = this.props;
    const { pickerOpened } = this.state;
    const termSoldOut = lowestPrice === soldOut;

    return (
      <div style={styles.wrapper} onClick={() => this.setState({ pickerOpened: !pickerOpened })}>
        {specialState && this.renderSticker(specialState)}
        <div style={styles.imageBox}>
          <Link style={styles.imageBox.link}>
            <img style={styles.imageBox.image} src={image} alt={title} />
          </Link>
        </div>

        <div style={styles.descriptionBox}>
          <h2 style={styles.descriptionBox.title}>
            <Link style={styles.descriptionBox.link}>
              {title},{' '}
              <span style={styles.descriptionBox.destination}>
                {destination} {tour.country}
              </span>
            </Link>
          </h2>
          <p
            style={styles.descriptionBox.description}
            dangerouslySetInnerHTML={{ __html: format(description) }}
          />

          <div
            style={[
              styles.toursTermsItems.price,
              termSoldOut && styles.toursTermsItems.price.soldOut,
            ]}
          >
            {termSoldOut ? 'Vyprodáno ' : 'cena od ' + formatCurrency(lowestPrice)}
          </div>
          {pickerOpened && (
            <TermPicker tour={tour} onClick={this.handlePickerPriceListClose.bind(this)} />
          )}
        </div>
      </div>
    );
  }

  handlePickerPriceListClose(value) {
    this.setState({ pickerOpened: value });
  }

  renderSticker(specialState) {
    const { type, value } = specialState;
    const {
      filterSettings: { filterName, primaryColor, secondaryColor },
    } = this.props;

    return (
      <TourSticker
        type={styles.stickerTypes.hotel}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
      >
        {type === typeOffer ? 'Výhodná nabídka' : filterName}
      </TourSticker>
    );
  }

  isAvailableRowItems(isSpecialOffer, tourLength) {
    return (
      <div style={styles.descriptionBox.termItem.termText.length}>
        {isSpecialOffer ? (
          <span style={styles.descriptionBox.termItem.termSpecialOffer}>Výhodná nabídka</span>
        ) : (
          <span>
            <strong>Délka:</strong> {tourLength}
          </span>
        )}
      </div>
    );
  }
}

ToursListItem.propTypes = {
  title: PropTypes.any.isRequired,
  link: PropTypes.string.isRequired,
  description: PropTypes.string,
  destination: PropTypes.string,
  tour: PropTypes.string,
  image: PropTypes.string,
  id: PropTypes.string,
  termList: PropTypes.array,
  filter: PropTypes.any,
  stickerPriority: PropTypes.any,
  filterSettings: PropTypes.any,
};

const mapStateToProps = (state) => ({
  filter: state.filter,
  filterSettings: state.filterSettings,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTourDetail: (id) => dispatch(fetchTourDetail(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(ToursListItem));
