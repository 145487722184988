import constants from '../../../../../styles/constants';
import cookie from 'react-cookie';
import { employee } from '../../../../../actions/HP/defaultTourlist';
import Instructions from '../Panels/Instructions';
import Description from '../Panels/Informations/Description';
import Occupation from '../Panels/Occupation/Occupation';
import { pickTour } from '../../../../../actions/TourDetail/tourDetail';
import Radium from 'radium';
import Departure from '../Panels/Departure/Departure';
import crosLineImg from '../../../../../img/crossLine.png'; // eslint-disable-line
import Reviews from '../Panels/Reviews/Reviews';
import WeatherForecast from '../Panels/WeatherForecast/WeatherForecast';
import Measure from 'react-measure';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import windowDimensions from 'react-window-dimensions';

const user = cookie.load('user');

const styles = {
  table: {
    borderCollapse: 'collapse',
    fontSize: '1em',
    margin: '0 auto',
    marginTop: '5px',
    width: '100%',

    [constants.breakpointMobile]: {
      fontSize: '0.8em',
    },
    tbody: {
      backgroundColor: 'white',
    },
    head: {
      textAlign: 'left',
    },
    subsidyHead: {
      textAlign: 'left',
      backgroundColor: [constants.green],
      color: '#fff',
      fontWeight: 'bold',
      paddingBottom: '10px',
    },
    subsidyTitleHead: {
      color: 'white',
      backgroundColor: [constants.green],
      fontSize: '1.1em',
      fontWeight: '500',
      position: 'fixed',
      boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
      top: '30px',
      left: '-10',
      zIndex: 999,
      textAlign: 'center',
      padding: '20px',
    },
    subsidyTitle: {
      fontSize: '13px',
      textTransform: 'uppercase',
      color: 'black',
    },
    line: {
      width: '780',
    },
    description: {},
    priceFor: {
      textAlign: 'right',
      minWidth: '60px',
    },
    crossLine: {
      backgroundImage: `url(${crosLineImg})`,
      backgroundPositionY: '50%',
      backgroundRepeat: 'repeat-x',
      color: '#999',
    },
    price: {
      textAlign: 'right',
      minWidth: '80px',
    },
    infoBottom: {
      marginTop: '5px',
      width: '700px',
      margin: '0 auto',
    },
    infoBottomWrapper: {
      marginTop: '20px',
    },
  },
  backToList: {
    position: 'relative',
    left: '-40px',

    [constants.breakpoint1300]: {
      left: '0px',
    },

    link: {
      textDecoration: 'none',
    },
  },
  buttonInline: {
    margin: '0 10px 0px 4%',
  },
  tabsWrapper: {
    background: '#fff',
    boxShadow: 'rgba(0, 0, 0, 0.13) 0px 0px 2px',
    marginTop: 20,
    padding: '10px 0 20px',
    desktop: {
      width: '100%',
      zIndex: 7,
      border: 'none',
      borderBottom: '1px solid #ccc',
    },
    tablet: {
      width: '100%',
      position: 'relative',
      zIndex: 7,
      border: 'none',
      paddingBottom: '5px',
      borderBottom: '1px solid #ccc',
      left: 0,
      right: 0,
      top: '24px',
    },
    phone: {
      width: '100%',
      position: 'relative',
      zIndex: 7,
      border: 'none',
      paddingBottom: '30px',
      top: '24px',
    },
    tab: {
      width: 'auto',
      textAlign: 'center',
      margin: '10px 2px 5px 10px',
      marginBottom: '5px',
      borderRadius: 0,
      position: 'relative',
      top: '0px',
      transition: '0.2s',
      padding: 0,
      border: 'none',

      text: {
        background: 'transparent',
        padding: '8px 8px',
        transition: '0.2s',
        fontWeight: 'bold',
        color: '#484848',
        borderBottomWidth: '4px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'transparent',

        ':hover': {
          borderBottomColor: [constants.mainOrange],
        },
      },
      textSelected: {
        background: 'transparent',
        padding: '8px 8px',
        transition: '0.2s',
        fontWeight: 'bold',
        color: '#484848',
        borderBottomWidth: '4px',
        borderBottomStyle: 'solid',
        borderBottomColor: [constants.mainOrange],

        ':hover': {
          borderBottomColor: [constants.mainOrange],
        },
      },
      yellowText: {
        background: 'transparent',
        padding: '8px 8px',
        transition: '0.2s',
        fontWeight: 'bold',
        color: [constants.mainOrange],
        borderBottomWidth: '4px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'transparent',

        ':hover': {
          borderBottomColor: [constants.mainOrange],
        },
      },
    },
  },
};

class HotelTabs extends Component {
  render() {
    const {
      tourDetail: {
        rating: { reviewsCount },
      },
      term,
      params,
    } = this.props;

    return (
      <div style={styles.tabsWrapper} className={user === employee ? 'employee' : 'normal'}>
        <Tabs>
          <TabList style={this.getWrapperStyle()}>
            {this.renderTab('Popis')}
            {this.renderTab('Obsazenost')}
            {this.renderTab('Počasí')}
            {this.renderTab('Recenze (' + reviewsCount + ')')}
            {this.renderTab('Pokyny')}
            {this.renderTab('Doprava')}
          </TabList>
          <TabPanel>
            <Description />
          </TabPanel>
          <TabPanel>
            <Occupation />
          </TabPanel>
          <TabPanel>
            <WeatherForecast />
          </TabPanel>
          <TabPanel>
            <Reviews />
          </TabPanel>
          <TabPanel>
            <Instructions dataFetched={Boolean(true)} params={params} />
          </TabPanel>
          <TabPanel>
            <Departure term={term} />
          </TabPanel>
        </Tabs>
      </div>
    );
  }

  getWrapperStyle() {
    const { width } = this.props;

    if (width < 571) {
      return styles.tabsWrapper.phone;
    } else if (width < 750) {
      return styles.tabsWrapper.tablet;
    } else {
      return styles.tabsWrapper.desktop;
    }
  }

  renderTab(title) {
    return (
      <Tab style={styles.tabsWrapper.tab}>
        <div style={styles.tabsWrapper.tab.text} key={title}>
          {title}
        </div>
      </Tab>
    );
  }
}

HotelTabs.propTypes = {
  tourDetail: PropTypes.any,
  term: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  pickTour: (idTour) => dispatch(pickTour(idTour)),
});

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
});

export default connect(mapStateToProps, mapDispatchToProps)(windowDimensions()(Radium(HotelTabs)));
