import { getJSON } from '../../utils/fetch';

export const SET_ADVERTISING = 'SET_ADVERTISING';
export const CLOSE_ADVERTISING = 'CLOSE_ADVERTISING';

export const fetchAdvertising = () => {
  return (dispatch) => {
    getJSON('news/').then((result) => {
      dispatch({
        type: SET_ADVERTISING,
        result,
      });
    });
  };
};

export const close = () => {
  return {
    type: 'CLOSE_ADVERTISING',
  };
};
