import { SAVE_LOCATIONS } from '../actions/TourList/fetchTourList';
import {
  LOCATIONS,
  SET_INCLUDED_DEPENDENT_FILTER,
  LOCATIONS_TEXT_BOX,
  RESET_LOCATIONS,
} from '../actions/TourList/tourFilter';

const initialState = [];

const selectedLocations = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOCATIONS:
    case SET_INCLUDED_DEPENDENT_FILTER:
      return action.result;

    case LOCATIONS:
      return {
        ...state,
        [action.designation]: [
          ...state[action.designation].map((toggled) => {
            if (toggled.code.toString() === action.code.toString()) {
              return { ...toggled, selected: !toggled.selected };
            }
            return toggled;
          }),
        ],
      };

    case LOCATIONS_TEXT_BOX:
      return {
        ...state,
        [action.designation]: [
          ...state[action.designation].map((toggled) => {
            if (toggled.code.toString() === action.code.toString()) {
              return { ...toggled, selected: !toggled.selected };
            }

            return { ...toggled, selected: false };
          }),
        ],
      };

    case RESET_LOCATIONS:
      if (!state[action.designation]) {
        return state;
      }

      return {
        ...state,
        [action.designation]: [
          ...state[action.designation].map((toggled) => {
            return { ...toggled, selected: false };
          }),
        ],
      };

    default:
      return state;
  }
};

export default selectedLocations;
