import constants from '../../../styles/constants';
import projectColors from '../../../styles/projectColors';

const styles = {
  wrapper: {
    width: '70%',
    marginLeft: '15%',
    maxWidth: 'initial',
    marginBottom: '20px',
    background: '#fff',
    padding: 0,
    overflow: 'auto',
    position: 'relative',
    boxShadow: 'rgba(0, 0, 0, 0.31) 0px 1px 4px',
    cursor: 'pointer',

    [constants.breakpoint991]: {
      width: '100%',
      marginLeft: 0,
      margin: '0 auto 20px auto',
    },
    [constants.breakpointTablet]: {
      maxWidth: '450px',
      margin: '0 auto 20px auto',
    },
  },
  priceBox: {
    position: 'absolute',
    bottom: 15,
    right: 15,
  },
  stickerTypes: {
    hotel: {
      background: [constants.mainOrange],
    },
    term: {
      background: [constants.mainOrange],
      transform: 'rotate(-12deg) translateY(50%)',
      padding: 5,
      top: 'inherit',
      bottom: '50%',
      left: '50%',
      fontSize: '1em',
      width: 'inherit',
      display: 'inline-table',
      maxWidth: 150,
      position: 'absolute',
    },
    normal: {
      transform: 'rotate(0) translateY(0)',
      bottom: '0',
      position: 'relative',
      right: 'inherit',
      left: 'inherit',
      padding: '7px 10px',

      [constants.breakpointTablet]: {
        padding: '5px 10px',
      },

      [constants.breakpointMobile]: {
        padding: '5px',
      },
    },
  },
  imageBox: {
    overflow: 'hidden',
    width: '33.3333%',
    padding: 0,
    float: 'left',
    position: 'relative',
    background: '#ccc',
    minHeight: '203px',

    [constants.breakpointLargeTablet]: {
      minHeight: '240px',
    },

    [constants.breakpointTablet]: {
      width: '100%',
      clear: 'both',
    },

    link: {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'block',
      width: '100%',
      height: '100%',
    },

    image: {
      transform: 'translate(-50%, -50%)',
      left: '50%',
      top: '50%',
      position: 'relative',
      minWidth: '304px',
      minHeight: '228px',
      width: 'auto',
      height: '100%',
      zIndex: 0,

      [constants.breakpointTablet]: {
        width: '100%',
        height: 'auto',
      },

      [constants.breakpointMobile]: {
        width: 'auto',
        height: '150%',
      },

      sticker: {
        position: 'absolute',
        fontSize: '1.2em',
        textAlign: 'center',
        fontWeight: '500',
        color: '#fff',
        left: 0,
        bottom: 0,
        background: [projectColors.halfTransparentOrange],
        zIndex: 1,
        width: 'calc(100% - 10px)',
        padding: '15px 5px 14px',
        textTransform: 'uppercase',
      },
    },
  },
  infoWindow: {
    height: 250,
    width: 300,
  },
  descriptionBox: {
    width: 'calc(66.6666% - 32px)',
    padding: '14px 16px',
    float: 'left',
    minHeight: '160px',
    position: 'relative',

    [constants.breakpointLargeTablet]: {
      minHeight: '200px',
    },

    [constants.breakpointTablet]: {
      width: 'calc(100% - 40px)',
      clear: 'both',
      minHeight: '190px',
    },

    term: {
      position: 'relative',
      width: 'calc(100% - 32px)',
      padding: '14px 16px 14px 16px',

      [constants.breakpointTablet]: {
        width: 'calc(100% - 32px)',
        clear: 'both',
        minHeight: '130px',
        paddingBottom: 65,
      },
    },

    description: {
      color: [projectColors.blue],
      fontSize: '1em',
      marginTop: '0px',
      paddingBottom: 20,
    },
    title: {
      textTransform: 'uppercase',
      marginTop: 0,
      marginBottom: '10px',
    },
    link: {
      textDecoration: 'none',
      color: [constants.mainBlue],

      ':hover': {
        color: [projectColors.blue],
      },
    },
    destination: {
      color: '#8A8A8A',
      fontSize: '0.8em',
    },

    termList: {
      margin: 0,
      padding: 0,
      marginBottom: '13px',
    },

    termItem: {
      listStyle: 'none',
      fontSize: '0.91em',
      width: 'calc(100% - 160px)',
      minHeight: '19px',
      [constants.breakpointLargeTablet]: {
        width: '100%',
      },
      termText: {
        width: 'calc(100% - 100px)',
        float: 'left',

        term: {
          marginRight: '5px',
          float: 'left',
        },

        length: {
          float: 'left',
          marginLeft: '7px',
          [constants.breakpointMobile]: {
            display: 'none',
          },
        },
      },
      termSpecialOffer: {
        color: 'red',
        margin: '0 10px',
        fontWeight: '500',
        marginRight: '5px',
      },

      price: {
        float: 'right',
        fontWeight: '500',
        fontSize: '1.1em',
        textAlign: 'right',
        width: '92px',
        color: [projectColors.yellow],
      },
      priceCrossed: {
        backgroundPositionY: '50%',
        textDecoration: ' line-through',
        backgroundRepeat: 'repeat-x',
        float: 'right',
        fontWeight: '500',
        fontSize: '1.1em',
        textAlign: 'right',
        width: '92px',
        color: 'red',
      },
      [constants.breakpointTablet]: {
        fontSize: '0.8em',
        width: '100%',
      },
    },
    moreTermsBox: {
      height: '5px',

      [constants.breakpointTablet]: {
        height: '34px',
      },
    },
    moreTermsBtn: {
      fontSize: '1.2em',
      cursor: 'pointer',
      padding: '5px',
      background: [projectColors.yellow],
      float: 'left',
      color: '#fff',
      position: 'absolute',
      bottom: '0px',
      right: 0,
      textAlign: 'center',

      [constants.breakpointTablet]: {
        bottom: 0,
      },
    },
  },
  toursTermsItems: {
    topInfo: {
      width: 'auto',
      paddingBottom: '10px',
      display: 'inline-block',

      date: {
        color: [constants.mainBlue],
        fontWeight: 'bold',
        fontSize: '1.5em',
        float: 'left',
      },
      size: {
        color: [constants.mainBlue],
        fontSize: '1.3em',
        float: 'left',
        paddingLeft: 10,
        textTransform: 'lowercase',

        [constants.breakpointTablet]: {
          fontSize: '1.1em',
          paddingTop: '3px',
        },
        [constants.breakpointMobile]: {
          paddingLeft: 0,
          display: 'block',
          width: '100%',
        },
      },
      mobileHidden: {
        display: 'inline',

        [constants.breakpointMobile]: {
          display: 'none',
        },
      },
    },
    transport: {
      color: [constants.mainBlue],
      border: 'solid 1px' + [constants.mainBlue],
      padding: 5,
      fontSize: '1em',
      clear: 'both',
      float: 'right',
      display: 'inline-block',
      marginTop: 3,

      [constants.breakpointTablet]: {
        float: 'none',
        width: 'auto',
        marginTop: '-3px',
        marginBottom: '10px',
      },
    },
    country: {
      fontWeight: 'bold',
      fontSize: '1.4em',
      lineHeight: '25px',
      color: [constants.black],
    },
    title: {
      fontSize: '1.1em',
      lineHeight: '25px',
      color: [constants.black],
    },
    location: {
      fontSize: '1.1em',
      lineHeight: '25px',
      color: [constants.black],
    },
    price: {
      position: 'absolute',
      right: 15,
      bottom: 1,
      border: 'solid 1px' + [constants.mainOrange],
      fontSize: '1.2em',
      padding: 10,
      color: [constants.mainOrange],
      fontWeight: 'bold',
      display: 'inline-table',
      soldOut: {
        color: [constants.black],
        border: 'solid 1px' + [constants.black],
      },
      term: {
        padding: '4px 10px',
        position: 'relative',
        right: 1,
        bottom: 0,

        [constants.breakpointTablet]: {
          right: 1,
          bottom: 0,
        },
      },

      [constants.breakpointTablet]: {
        fontSize: '1em',
        bottom: 18,
        right: 10,
      },
    },
  },
};

export default styles;
