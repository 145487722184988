import styles from '../../../shared/TourListStyles/TourListPaginatorStyles';
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getLimitedPages from '../../../shared/dynamicPaginator/getLimitedPages';
import getNextPage from '../../../shared/dynamicPaginator/getNextPage';
import TourListSettings from './components/TourListSettings';
import createFilterRequest from '../../../shared/RequestCreator/createFilterRequest';
import { loading } from '../../../../reducers/filteredTours';
import createSpecialToursRequest from '../../../shared/RequestCreator/createSpecialToursRequest';
import { fetchFilteredList, fetchSpecialList } from '../../../../actions/TourList/fetchTourList';
import { savePage } from '../../../../actions/TourList/paginator';
import { connect } from 'react-redux';

class ToursPaginator extends Component {
  state = {
    toggle: false,
  };

  render() {
    const { showTourSettings, tripCount, pages, actualPage, filterLoading } = this.props;
    const {
      filterSettings: { filterName },
      filter: { isLastMinuteOrCustom, showTermList },
    } = this.props;

    return (
      <div style={styles.wrapper}>
        <div style={styles.sumOfTours}>
          {filterLoading !== loading ? this.getloading(showTermList, tripCount) : filterLoading}
        </div>

        {showTourSettings && <TourListSettings />}

        <div style={styles.content}>
          <ul style={styles.listWrapper}>
            {filterLoading !== loading &&
              getLimitedPages(pages, actualPage).map((item) => this.renderPaginator(item))}
          </ul>
        </div>

        <div style={styles.lastMinuteSearchText}>
          {isLastMinuteOrCustom && <p style={styles.lastMinuteInfo}>{filterName}</p>}
        </div>
      </div>
    );
  }

  getloading(showTermList, tripCount) {
    return (
      <div>
        {showTermList ? 'Celkem termínů: ' : 'Celkem hotelů: '}
        <strong id="up">{tripCount}</strong>
      </div>
    );
  }

  selectPage(page) {
    const {
      savePage,
      actualPage,
      paginatorType,
      filter,
      paginator: { minCost, maxCost, itemsPerPage },
      fetchFilteredList,
      specialOffer,
      fetchSpecialList,
      type,
    } = this.props;
    const nextPage = getNextPage(page, actualPage);
    const optional = { settingPage: true };
    savePage(nextPage, paginatorType);
    specialOffer
      ? fetchSpecialList(createSpecialToursRequest(nextPage, type, itemsPerPage))
      : fetchFilteredList(
          createFilterRequest(filter, nextPage, itemsPerPage, minCost, maxCost, optional),
          true,
        );
  }

  renderPaginator(item) {
    const { actualPage } = this.props;
    const style = item === actualPage ? styles.selected : styles.link;

    return (
      <li style={styles.item} onClick={() => this.selectPage(item)}>
        <a style={style} key={item}>
          <span>{item}</span>
        </a>
      </li>
    );
  }
}

ToursPaginator.propTypes = {
  tripCount: PropTypes.any.isRequired,
  showTourSettings: PropTypes.any,
  filterLoading: PropTypes.any.isRequired,
  pages: PropTypes.any.isRequired,
  actualPage: PropTypes.any.isRequired,
  paginatorType: PropTypes.any.isRequired,
  specialOffer: PropTypes.any.isRequired,
  savePage: PropTypes.any,
  filter: PropTypes.any,
  type: PropTypes.any,
  paginator: PropTypes.any,
  fetchFilteredList: PropTypes.any,
  fetchSpecialList: PropTypes.any,
  filterSettings: PropTypes.any,
};

const mapStateToProps = (state) => ({
  filter: state.filter,
  filterSettings: state.filterSettings,
  paginator: state.paginator,
});

const mapDispatchToProps = (dispatch) => ({
  savePage: (page, paginatorType) => dispatch(savePage(page, paginatorType)),
  fetchFilteredList: (filters, settingPage) => dispatch(fetchFilteredList(filters, settingPage)),
  fetchSpecialList: (pageProperties) => dispatch(fetchSpecialList(pageProperties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(ToursPaginator));
