import footerLogo from '../../img/logo-white.png'; // eslint-disable-line
import React, { Component } from 'react';

const styles = {
  wrapper: {
    width: '100%',
    clear: 'both',
    color: 'rgb(227, 227, 227)',
    fontSize: '0.9em',
    textAlign: 'center',
    padding: '18px 0',
    background: '#212121',
    flex: 'none',
  },
  logo: {
    width: '130px',
    height: 'auto',
    opacity: 0.8,
    paddingBottom: '10px',
  },
  link: {
    color: '#549181',
    paddingLeft: '5px',
    textDecoration: 'none',
  },
};

export default class Footer extends Component {
  render() {
    return (
      <div style={styles.wrapper}>
        <div>
          <img src={footerLogo} style={styles.logo} />
        </div>
        {'© ' +
          new Date().getFullYear() +
          ' | powered by ČD – Informační Systémy, a.s. | webdesign by'}
        <a href="//www.incolor.cz" target="_blank" style={styles.link}>
          #InColor studio
        </a>
      </div>
    );
  }
}
