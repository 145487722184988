import constants from '../../../../styles/constants';
import TourHeader from './TourHeader';
import TourPicker from './Panels/TourPicker';
import tourDetailBgImg from '../../../../img/grey-bg.png'; // eslint-disable-line
import Back from './BackToList/BackToList';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Column } from '../../../../libs/radium-grid'; // eslint-disable-line
import { Container } from '../../../../libs/radium-grid'; // eslint-disable-line
import Sticky from 'react-sticky-el';
import { connect } from 'react-redux';
import HotelTabs from './Tabs/HotelTabs';
import createFilterRequest from '../../../shared/RequestCreator/createFilterRequest';
import { fetchTerms } from '../../../../actions/TourList/fetchTourList';
import TripInformations from './Panels/TripInformations/TripInformations';
import cookie from 'react-cookie';
import ReactTooltip from 'react-tooltip';
import { hashHistory } from 'react-router';
import { visitor } from '../../../../actions/HP/defaultTourlist';
import { CDNameTitle } from '../../../../utils/titleConfig';
import Gallery from './Panels/RightDetailRow';
import SimilarTour from './Panels/SimilarTour';
import BookingForm from './Panels/Informations/BookingForm';
import Maps from './Panels/Maps/Maps';
import OrangeButton from '../../../shared/OrangeButton';
import LoaderInModalWindow from '../../../shared/Loading/LoaderInModalWindow';
import StyledCircleLoader from '../../../shared/Loading/StyledCircleLoader';
import {
  fetchTourDetail,
  fetchSimilarTours,
  fetchReviewOptions,
  fetchWeather,
  fetchTourDetailWithFilters,
  loading,
} from '../../../../actions/TourDetail/fetchTourDetail';
import { addTourForComparement } from '../../../../actions/TourDetail/tourDetail';
import Helmet from 'react-helmet';
import Radium from 'radium';

const customStyles = {
  floatLeft: {
    float: 'left',
    marginRight: 10,
    marginBottom: 10,
  },
};

const styles = {
  similarTourWrapper: {
    margin: '0',
    height: 260,

    title: {
      textAlign: 'center',
      marginBottom: 0,
      padding: '15px 5px',
    },

    [constants.breakpoint991]: {
      marginBottom: '20px',
    },
  },
  loader: {
    position: 'absolute',
    left: '50%',
    marginLeft: '-55px',
  },
  wrapper: {
    backgroundImage: 'url(' + tourDetailBgImg + ')',
    backgroundAttachment: 'fixed',
  },
  headerWrapper: {
    width: '100%',
    height: '200px',
    marginTop: '32px',
    overflow: 'visible',
    fontSize: '1.3em',
    position: 'fixed',
    zIndex: '7',

    [constants.breakpoint600]: {
      height: '140px',
    },
  },
  contentBox: {
    overflow: 'auto',
    boxShadow: '0px 2px 25px rgba(0, 0, 0, 0.4)',
    zIndex: '1',
  },
  col: {
    padding: '0',

    [constants.breakpointLargeTablet]: {
      padding: '0 10px 0 0',
      width: 'calc(50% - 10px)',
    },

    [constants.breakpointTablet]: {
      padding: 'inherit',
    },

    [constants.breakpoint991]: {
      padding: '0 0 0 0',
      width: '100%',
    },
  },
  leftBox: {
    position: 'relative',
    float: 'left',

    innerBox: {
      marginBottom: '30px',
      paddingBottom: '17px',

      [constants.breakpointTablet]: {
        paddingRight: 'inherit',
      },
    },

    innerBoxWhiteBck: {
      background: '#fff',
      boxShadow: 'rgba(0, 0, 0, 0.13) 0px 0px 2px',
    },
  },
  rightBox: {
    position: 'relative',
    float: 'right',
  },
  tabsSection: {
    position: 'relative',
    minHeight: '570px',

    [constants.breakpointTablet]: {
      marginTop: '10px 0 0 0',
    },
  },
  backToList: {
    position: 'absolute',
    top: '85px',
    left: '-30px',

    [constants.breakpointLargeTablet]: {
      left: '5px',
    },

    link: {
      textDecoration: 'none',
    },

    text: {
      textDecoration: 'none',
      color: '#444',

      ':hover': {
        color: [constants.mainBlue],
      },
    },
  },
  contentWrapper: {
    marginTop: '40px',
    color: '#484848',
    overflow: 'visible',

    [constants.breakpoint768Min]: {
      marginTop: '190px',
    },

    [constants.breakpointTablet]: {
      marginTop: '15px',
    },
  },
  contentBoxes: {},
  loading: {
    display: 'block',
    width: '200px',
    margin: '0 auto',
    justifyContent: 'center',
    marginTop: 120,
  },
  pricelist: {
    background: [constants.mainOrange],
    marginLeft: '35px',
    fontSize: '1.2em',
    width: 'calc(100% - 35px)',
    marginBottom: '10px',

    [constants.breakpointLargeTablet]: {
      width: '100%',
      marginLeft: 0,
    },
  },
  scrolledHeader: {
    position: 'relative',
    transform: 'none',
  },
};

class TourDetail extends Component {
  state = {
    idDetail: '',
    fetched: false,
    tourDetailFetched: false,
    fetchingTerms: false,
    weatherFetched: false,
    term: {
      idTour: 0,
    },
  };

  componentDidMount() {
    this.initialize();
  }

  componentWillReceiveProps(props) {
    const {
      tourDetail,
      fetchTourDetail,
      fetchWeather,
      tourDetail: { gps },
      termList,
    } = props;
    const {
      fetchTourDetailWithFilters,
      filter: { transport },
    } = this.props;
    const { weatherFetched, idDetail, fetched, fetchingTerms, tourDetailFetched } = this.state;
    const id = props.params.idDetail;

    if (idDetail !== id && typeof props.params.idDetail !== 'undefined') {
      if (transport) {
        const params = { id, transport, user: cookie.load('user') };

        fetchTourDetailWithFilters(params);
      } else {
        fetchTourDetail(id);
      }
      this.fetchOptions();
      this.setState({ idDetail: props.params.idDetail });
    }

    if (parseInt(id) === tourDetail.id) {
      if (Object.keys(tourDetail).length !== 0 && !fetched) {
        !fetchingTerms && this.initializeTerms(props);

        if (termList && termList.name === tourDetail.title) {
          this.setState({ fetched: true });
          this.setTerm(props);
          if (gps && gps.length > 0 && !weatherFetched) {
            fetchWeather(gps[0], gps[1]);
            this.setState({ weatherFetched: true });
          }
        }
      }
    }

    if (fetched) {
      this.setTerm(props);
    }
  }

  render() {
    const { fetched, term } = this.state;
    const {
      fetchState: { generatingPDF },
      similarTours,
      params,
      addTourForComparement,
      tourComparement: { tours },
    } = this.props;
    const user = cookie.load('user');

    if (!fetched) {
      return this.loader();
    }

    const {
      tourDetail,
      tourDetail: { title, pickedTour, isSubsidy, isSoldOut },
      params: { openPicker },
    } = this.props;

    if (!isSubsidy && user !== visitor) {
      hashHistory.push('/');
    }

    return (
      <div style={styles.wrapper}>
        <Helmet title={title + ' | ' + CDNameTitle} />
        <div style={styles.contentBox} id="content">
          <Sticky className="tour-scroll-head" stickyClassName="scrolled">
            <TourHeader term={term} />
          </Sticky>

          <Container>
            {generatingPDF && this.loader()}

            <div style={styles.contentWrapper}>
              <Back />

              <Column xs={12} md={6} style={[styles.col, styles.rightBox]} className="right-float ">
                <div style={styles.contentBoxes}>
                  <TourPicker invertedShow={Boolean(true)} extraStyle={styles.pricelist} />
                  <Gallery term={term} />
                </div>
              </Column>

              <Column xs={12} md={6} style={[styles.col, styles.leftBox]}>
                <div className="tour-info-wrapper" style={styles.contentBoxes}>
                  <div
                    style={
                      !pickedTour
                        ? [styles.leftBox.innerBox, styles.leftBox.innerBoxWhiteBck]
                        : styles.leftBox.innerBox
                    }
                  >
                    <div>
                      <TourPicker openByDefault={openPicker} />

                      <BookingForm term={term} />

                      <div
                        data-tip={
                          tours.length >= 3
                            ? 'V porovnání máte vybraný maximální počet zájezdů'
                            : ''
                        }
                      >
                        <OrangeButton
                          onClick={() => addTourForComparement(term, tourDetail)}
                          disabled={tours.length >= 3}
                          extraStyle={customStyles.floatLeft}
                        >
                          Přidat k porovnání
                        </OrangeButton>

                        <OrangeButton
                          dataTooltip="Zájezd je vyprodán"
                          icon="keyboard"
                          disabled={isSoldOut}
                          extraStyle={customStyles.floatLeft}
                          onClick={() => hashHistory.push(`/zajezdy/kalkulacka/${term.idTour}`)}
                        >
                          Kalkulačka
                        </OrangeButton>
                      </div>
                    </div>
                    <TripInformations term={term} />

                    <HotelTabs term={term} params={params} />
                  </div>
                </div>
              </Column>
            </div>
            <ReactTooltip html={Boolean(true)} />
          </Container>
          {similarTours && this.renderSimilarTours(similarTours)}
        </div>

        <Maps />

        <ReactTooltip html={Boolean(true)} />
      </div>
    );
  }

  renderSimilarTours(similarTours) {
    return (
      <div style={styles.similarTourWrapper}>
        <h2 style={styles.similarTourWrapper.title}>Podobné zájezdy</h2>

        {similarTours === loading
          ? this.similarToursLoader()
          : similarTours.map((similarTour) => <SimilarTour similarTour={similarTour} />)}
      </div>
    );
  }

  similarToursLoader() {
    return (
      <div style={styles.loader}>
        <StyledCircleLoader />
      </div>
    );
  }

  loader() {
    return (
      <div>
        <Helmet title="Detail zájezdu" />
        <LoaderInModalWindow />
      </div>
    );
  }

  initialize() {
    const {
      fetchTourDetail,
      fetchTourDetailWithFilters,
      filter: { transport },
    } = this.props;
    const currentLocation = hashHistory.getCurrentLocation();
    const params = currentLocation.query;
    const user = cookie.load('user');

    if (!params.user || params.user !== user) {
      hashHistory.push(`${currentLocation.pathname}?user=${user}`);
      cookie.save('ignoreUrlUser', false);
    }

    const id = this.props.params.idDetail;

    if (transport) {
      const params = { id, transport, user: user };

      fetchTourDetailWithFilters(params);
    } else {
      fetchTourDetail(id);
    }

    this.fetchOptions();
    this.setState({ idDetail: id });
  }

  setTerm(props) {
    const {
      termList: { terms },
      tourDetail: { pickedTour },
      fetchSimilarTours,
    } = props;
    const filteredTerms = terms.filter(
      (term) => term.idTour === pickedTour && this.state.term.idTour !== term.idTour,
    );

    if (filteredTerms.length) {
      const requestData = {
        tour: filteredTerms[0].idTour,
        user: cookie.load('user'),
      };

      fetchSimilarTours(requestData);
      this.setState({ term: filteredTerms[0] });
    }
  }

  initializeTerms(props) {
    const {
      termList,
      tourDetail: { title, id },
    } = props;
    this.setState({ fetchingTerms: true });

    if (!termList || termList.name !== title) {
      const {
        fetchTerms,
        filter,
        paginator: { minCost, itemsPerPage, maxCost, page },
      } = this.props;
      const properties = createFilterRequest(filter, page, itemsPerPage, minCost, maxCost, {
        changedShowTermList: true,
      });
      properties.idTour = id;
      properties.type = 'list';
      fetchTerms(properties);
    }
  }

  fetchOptions() {
    const { tourDetailReview, fetchReviewOptions } = this.props;

    if (tourDetailReview.length < 1) {
      fetchReviewOptions();
    }
  }

  close() {
    const { closeTab } = this.props;

    closeTab();
  }
}

TourDetail.propTypes = {
  params: PropTypes.any,
  filter: PropTypes.any,
  termList: PropTypes.any,
  idDetail: PropTypes.any,
  similarTours: PropTypes.any,
  fetchTerms: PropTypes.any,
  tour: PropTypes.any,
  paginator: PropTypes.any,
  tourDetail: PropTypes.any,
  fetchState: PropTypes.any,
  tourDetailReview: PropTypes.any,
  fetchReviewOptions: PropTypes.any,
  closeTab: PropTypes.any,
  fetchTourDetail: PropTypes.any,
  fetchTourDetailWithFilters: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
  fetchState: state.fetchState,
  similarTours: state.similarTours,
  termList: state.termList,
  paginator: state.paginator,
  filter: state.filter,
  tourComparement: state.tourComparement,
  tourDetailReview: state.tourDetailReview,
});

const mapDispatchToProps = (dispatch) => ({
  fetchWeather: (lat, lon) => dispatch(fetchWeather(lat, lon)),
  fetchTourDetail: (id) => dispatch(fetchTourDetail(id)),
  addTourForComparement: (tour, tourDetail) => dispatch(addTourForComparement(tour, tourDetail)),
  fetchSimilarTours: (requestData) => dispatch(fetchSimilarTours(requestData)),
  fetchTerms: (parameters) => dispatch(fetchTerms(parameters)),
  fetchTourDetailWithFilters: (params) => dispatch(fetchTourDetailWithFilters(params)),
  fetchReviewOptions: () => dispatch(fetchReviewOptions()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Radium(TourDetail));
