import { connect } from 'react-redux';
import Dialog from 'material-ui/Dialog';
import { hashHistory } from 'react-router';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import constants from '../../../../../styles/constants.js';
import crosLineImg from '../../../../../img/crossLine.png'; // eslint-disable-line
import OrangeButton from '../../../../shared/OrangeButton';
import { zajezdyDetail } from '../../../../../utils/routeConfig';
import { generatePDF } from '../../../../../actions/PDF/generatePDF';
import { pickTour } from '../../../../../actions/TourDetail/tourDetail';
import styles from '../../../../shared/forms/modalFormStyles/modalFormStyles';

const customStyles = {
  buttonTourPickerWrapper: {
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    paddingTop: 80,

    [constants.breakpointMobile]: {
      width: 300,
    },
  },

  buttonTourPicker: {
    padding: '10px 20px',
    position: 'relative',
    margin: '0px 10px',
    fontSize: '1.2em',
    width: 'calc(50% - 62px)',
    fontWeight: 'bold',

    [constants.breakpointMobile]: {
      width: 'calc(100% - 42px)',
      margin: '10px 0',
    },
  },

  mobileHide: {
    [constants.breakpoint600]: {
      display: 'none',
    },
  },
  closeModal: {
    padding: 0,
    margin: 0,
  },

  wrapper: {
    clear: 'both',
    width: '99%',
    maxWidth: '600px',
    marginTop: '10px',
  },
  table: {
    borderCollapse: 'collapse',
    fontSize: '1em',
    margin: '0 auto',
    marginTop: '5px',
    width: '100%',

    [constants.breakpointMobile]: {
      fontSize: '0.8em',
    },
    tbody: {
      backgroundColor: 'white',
      color: [constants.mainBlue],
    },
    head: {
      textAlign: 'left',
      backgroundColor: [constants.mainBlue],
      color: '#fff',
    },
    subsidyHead: {
      textAlign: 'left',
      backgroundColor: [constants.green],
      color: '#fff',
      fontWeight: 'bold',
      paddingBottom: '10px',
    },
    subsidyTitleHead: {
      color: 'white',
      backgroundColor: [constants.green],
      fontSize: '1.1em',
      fontWeight: '500',
      position: 'fixed',
      boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
      top: '30px',
      left: '-10',
      zIndex: 999,
      textAlign: 'center',
      padding: '20px',
    },
    subsidyTitle: {
      fontSize: '13px',
      textTransform: 'uppercase',
      color: 'black',
    },
    line: {
      width: '780',
    },
    description: {},
    priceFor: {
      textAlign: 'right',
      minWidth: '60px',
    },
    crossLine: {
      backgroundImage: `url(${crosLineImg})`,
      backgroundPositionY: '50%',
      backgroundRepeat: 'repeat-x',
      color: '#999',
    },
    price: {
      textAlign: 'right',
      minWidth: '80px',
    },
    tourPrice: {
      fontWeight: 'bold',
      color: [constants.mainOrange],
    },
    tagWrapper: {
      position: 'absolute',
      marginTop: -2,
    },
    tagLastMinuteWapper: {
      width: 87,
      position: 'relative',
      right: 10,
    },
    tagSpecialOfferWapper: {
      width: 115,
      position: 'relative',
      right: 25,
    },
    tagBothWrapper: {
      width: 200,
      position: 'relative',
      right: 65,
    },
    tagLastMinute: {
      position: 'relative',
      transform: 'rotate(-3deg)',
      display: 'inline-block',
      margin: 1,
      marginRight: 5,
    },
    tagSpecialOffer: {
      position: 'relative',
      transform: 'rotate(-3deg)',
      display: 'inline-block',
    },
    infoBottom: {
      marginTop: '5px',
      width: '700px',
      margin: '0 auto',
    },
    infoBottomWrapper: {
      marginTop: '20px',
    },
  },
  floatLeft: {
    float: 'left',
    marginRight: 10,
    marginBottom: 10,
  },
  stick: {
    padding: '3px 6px',
    fontSize: '0.8em',
    color: '#fff',
    display: 'inline-block',

    lastMinute: {
      background: '#e21a22',
      marginBottom: '1px',
    },
    soldOut: {
      background: '#000',
      marginBottom: '1px',
    },

    specialOffer: {
      background: [constants.green],
    },
  },

  icon: {
    marginRight: '7px',
  },
};

class TermPicker extends Component {
  render() {
    const {
      tour: { idTour },
    } = this.props;

    return (
      <Dialog
        onRequestClose={() => this.close()}
        actions={this.getActions(idTour)}
        modal={false}
        open={Boolean(true)}
        autoScrollBodyContent="true"
        titleStyle={{ borderBottom: 'none' }}
        footerStyle={{ background: 'red' }}
        bodyStyle={{
          borderBottom: '3px solid #fff',
          bottom: '-1px',
          position: 'relative',
          paddingTop: '10px',
          overflowX: 'hidden',
        }}
        contentClassName="dialog-box"
        actionsContainerClassName="actions-box"
        bodyClassName="content-box"
        titleClassName="title-box"
      >
        <div style={customStyles.buttonTourPickerWrapper}>
          <span onClick={() => this.handleChoose(idTour)}>
            <OrangeButton
              onClick={() => this.close()}
              submit="submit"
              extraStyle={customStyles.buttonTourPicker}
            >
              Popis hotelu
            </OrangeButton>
          </span>
          <span onClick={() => this.handleChoose(idTour, true)}>
            <OrangeButton
              onClick={() => this.close()}
              submit="submit"
              extraStyle={customStyles.buttonTourPicker}
            >
              Vybrat termín
            </OrangeButton>
          </span>
        </div>
      </Dialog>
    );
  }

  getActions() {
    return (
      <div>
        <OrangeButton
          onClick={() => this.close()}
          submit="submit"
          extraStyle={styles.buttonMarged}
          type="inversion"
          icon="close"
        >
          Zavřít
        </OrangeButton>
      </div>
    );
  }

  close() {
    this.setState({ opened: false });
    this.props.onClick(false);
  }

  handleChoose(idTour, tourPicker = false) {
    this.props.onClick(false);
    const tourPickerUri = tourPicker ? '/true' : '';
    hashHistory.push(zajezdyDetail + idTour + tourPickerUri);
  }
}

TermPicker.propTypes = {
  tour: PropTypes.any,
  generatePDF: PropTypes.any,
  postNewBooking: PropTypes.any,
  pickTour: PropTypes.any,
  tourDetail: PropTypes.any,
  withoutAction: PropTypes.any,
  invertedShow: PropTypes.any,
  onClick: PropTypes.any,
  termList: PropTypes.any,
  extraStyle: PropTypes.any,
  defaultState: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
  termList: state.termList,
  postFormStatus: state.postFormStatus,
});

const mapDispatchToProps = (dispatch) => ({
  pickTour: (idTour) => dispatch(pickTour(idTour)),
  generatePDF: (params, name) => dispatch(generatePDF(params, name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermPicker);
