import ModalWindow from 'react-modal';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OrangeButton from './OrangeButton';

const customModalStyles = {
  overlay: {
    zIndex: '9999',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'transparent',
  },
  content: {
    position: 'absolute',
    maxWidth: 400,
    top: 0,
    left: '50%',
    color: 'white',
    right: 'auto',
    bottom: 'auto',
    border: 'none',
    borderRadius: 0,
    backgroundColor: 'white',
    marginRight: '-50%',
    transform: 'translateX(-50%)',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.3)',
  },
};

export default class InputModalAlert extends Component {
  render() {
    const { opened, message } = this.props;

    return (
      <ModalWindow isOpen={opened} style={customModalStyles}>
        <p>{message}</p>

        <OrangeButton onClick={() => this.props.onClick(false)} submit="submit" type="alert">
          Rozumím
        </OrangeButton>
      </ModalWindow>
    );
  }
}

InputModalAlert.propTypes = {
  onClick: PropTypes.any,
  opened: PropTypes.any,
  message: PropTypes.any,
};
