import constants from "../../styles/constants.js";
import cookie from "react-cookie";
import { employee, szEmployee, visitor } from "../../actions/HP/defaultTourlist";
import { hashHistory } from "react-router";

import React, { Component } from "react";
import Radium from "radium";

const styles = {
  aligner: {
  },
};

const colors = {
  employee: constants.green,
  szEmployee: constants.szEmployeePrimary,
  visitor: constants.orange
}

const titles = {
  employee: "Zaměstnanec skupiny ČD",
  szEmployee: "Zaměstnanec SZ",
  visitor: "Běžný uživatel"
};

const userTypes = [
  employee,
  szEmployee,
  visitor
];

class SectionType extends Component {
  render() {
    const user = cookie.load("user");
    const types = userTypes.filter(e => e !== user);

    return (
      <div className="button-section-type">
        {types.map((type, index) =>
          <a style={[styles.aligner, {background: [colors[type]]}]} onClick={() => this.changeUser(type)}>
            {titles[type]}
          </a>
        )}
      </div>
    );
  }

  changeUser(user) {
    const {
      location: { pathname }
    } = this.props;

    const isCatalogue = pathname.indexOf("katalog") > 0;
    const isDetail = pathname.indexOf("zajezdy/detail/") > 0;

    cookie.save("userChangedFromMenu", false);
    cookie.save("user", user);
    cookie.save("ignoreUrlUser", true);

    if (isCatalogue || isDetail) {
      isCatalogue && hashHistory.push("/katalog");
    }

    window.location.reload();
  }
}

export default Radium(SectionType);
