import constants from '../../../styles/constants';
import mobileBg from '../../../img/bcg-mobile.jpg'; // eslint-disable-line
import logo from '../../../img/logo-white.png'; // eslint-disable-line
import facebook from '../../../img/facebook.svg'; // eslint-disable-line
import {toggleTiles} from '../../../actions/TourList/paginator';
import cookie from 'react-cookie';
import {fetchHeader} from '../../../actions/Header/fetchHeader';
import {employee, visitor, szEmployee} from '../../../actions/HP/defaultTourlist';
import {resetAllFilters} from '../../../actions/TourList/tourFilter';
import {Link, hashHistory} from 'react-router';
import Radium from 'radium';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

const styles = {
  wrapper: {
    width: '100%',
    height: '100vh',
    position: 'relative',
    overflow: 'hidden',
    background: '#000',

    [constants.breakpointTablet]: {
      backgroundImage: 'url(' + mobileBg + ')',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    },

    [constants.breakpointMobile]: {
      height: 'calc(100vh - 55px)',
    },
  },
  video: {
    width: '100%',
    height: 'auto',
    left: '-1px',
    top: '0',
    transform: 'none',
    overflow: 'hidden',
    opacity: '0.8',
    zIndex: 0,
    position: 'absolute',
    display: 'block',

    [constants.breakpoint1650]: {
      height: '100vh',
      width: 'auto',
      marginTop: '0px',
      left: '50%',
      top: 0,
      transform: 'translateX(-50%)',
    },

    [constants.breakpointTablet]: {
      display: 'none',
    },
  },
  facebookButton: {
    width: 50,
    height: 50,
    marginTop: 40,
  },
  greenButton: {
    color: '#fff',
    lineHeight: '50px',
    width: '46%',
    float: 'left',
    margin: '0 1%',
    fontSize: '1.3em',
    fontWeight: '600',
    background: '#afc836',
    overflow: 'auto',
    textAlign: 'center',
    padding: '10px 0.5%',
    transition: '.3s',
    border: '3px solid #afc836',
    textTransform: 'uppercase',
    textDecoration: 'none',
    cursor: 'pointer',

    ':hover': {
      opacity: '0.9',
      border: '3px solid #afc836',
    },

    [constants.breakpoint1024]: {
      top: '5px',
    },
    [constants.breakpointTablet]: {
      width: '90%',
      display: 'block',
      padding: '0 0.5%',
      margin: '1.2% 3.5%',
    },
  },
  darkOrangeButton: {
    color: '#fff',
    lineHeight: '50px',
    width: '46%',
    float: 'left',
    margin: '0 1%',
    fontSize: '1.3em',
    fontWeight: '600',
    background: '#FF5200',
    overflow: 'auto',
    textAlign: 'center',
    padding: '10px 0.5%',
    transition: '.3s',
    border: '3px solid #FF5200',
    textTransform: 'uppercase',
    textDecoration: 'none',
    cursor: 'pointer',

    ':hover': {
      opacity: '0.9',
      border: '3px solid #FF5200',
    },

    [constants.breakpoint1024]: {
      top: '5px',
    },
    [constants.breakpointTablet]: {
      width: '90%',
      display: 'block',
      padding: '0 0.5%',
      margin: '1.2% 3.5%',
    },
  },
  contentBox: {
    zIndex: 2,
    position: 'relative',
    overflow: 'auto',
    textAlign: 'center',
    color: '#fff',
    maxWidth: '800px',
    width: '98%',
    margin: '0 auto',
    top: '50%',
    transform: 'translateY(-50%)',

    [constants.breakpointMobile]: {
      padding: '25px 0',
    },
  },
  h1: {
    fontSize: '5em',
    letterSpacing: '-1px',
    padding: '15px',
    fontWeight: '600',
    marginBottom: 0,
    position: 'relative',
    textTransform: 'uppercase',

    [constants.breakpoint1024]: {
      fontSize: '4em',
    },
    [constants.breakpointTablet]: {
      fontSize: '2.5em',
      lineHeight: '1em',
    },
    [constants.breakpointMobile]: {
      fontSize: '2.2em',
    },
  },
  h2: {
    fontSize: '3.5em',
    position: 'relative',
    marginBottom: '120px',
    fontWeight: '600',

    [constants.breakpointTablet]: {
      fontSize: '1.4em',
      marginTop: 0,
      marginBottom: '78px',
    },
  },

  logoBox: {
    position: 'relative',
    margin: '0 auto 80px auto',
    width: '300px',
    height: 'auto',
    textAlign: 'center',
    transform: 'none',
    zIndex: '9',
  },
  logo: {
    width: '100%',
    height: 'auto',
  },
};

export const cdtravelFb = 'https://www.facebook.com/cdtravel.cz/';

class VideoBox extends Component {
  componentDidMount() {
    const userChanged = cookie.load('userChanged');
    const userChangedFromMenu = cookie.load('userChangedFromMenu');
    const backLink = cookie.load('backLink');

    if (userChangedFromMenu !== 'false') {
      cookie.save('userChangedFromMenu', false);
      cookie.save('user', userChangedFromMenu);
      window.location.reload();
    }

    if (userChanged === 'true') {
      cookie.save('userChanged', false);
      if (backLink) {
        hashHistory.push(backLink);
      } else {
        hashHistory.push('/zajezdy/vypis/');
      }
    }
  }

  render() {
    return (
      <div style={styles.wrapper} className="video-wrapper">
        <video style={styles.video} muted="true" loop="true" autoPlay="true">
          {/* <source
            src="https://cdtravel-2.s3.eu-central-1.amazonaws.com/video/video_hp.mp4"
            type="video/mp4"
          />*/}
        </video>

        <div style={styles.contentBox}>
          <div style={styles.logoBox}>
            <Link to="/">
              <img style={styles.logo} src={logo}/>
            </Link>
          </div>

          <div style={styles.h2}>Cestovní kancelář</div>

          <div className="hp-buttons-wrapper">
            <Link onClick={() => this.showTiles(visitor)}>
              <div className="button big user" key={3}>
                Běžný uživatel
              </div>
            </Link>

            <Link onClick={() => this.showTiles(employee)}>
              <div className="button big employee-1" key={2}>
                Zaměstnanec skupiny ČD
              </div>
            </Link>

            <Link onClick={() => this.showTiles(szEmployee)}>
              <div className="button big employee-2" key={5}>
                Zaměstnanec SŽ
              </div>
            </Link>
          </div>
          <a href={cdtravelFb} target="_empty">
            <img style={styles.facebookButton} src={facebook}/>
          </a>
        </div>
      </div>
    );
  }

  showTiles(user) {
    const {toggleTiles, resetAllFilters, fetchHeader} = this.props;

    resetAllFilters();
    toggleTiles(true);
    fetchHeader(user);
    cookie.save('user', user);
    cookie.save('userChanged', true);
    window.location.reload();
  }
}

VideoBox.propTypes = {
  toggleTiles: PropTypes.any,
  fetchHeader: PropTypes.any,
  resetAllFilters: PropTypes.any,
};

const mapStateToProps = (state) => ({
  paginator: state.paginator,
});

const mapDispatchToProps = (dispatch) => ({
  toggleTiles: (nextState) => dispatch(toggleTiles(nextState)),
  resetAllFilters: () => dispatch(resetAllFilters()),
  fetchHeader: (user) => dispatch(fetchHeader(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(VideoBox));
