const getPages = (itemsCount, itemsPerPage) => {
  const pageCount = itemsCount / itemsPerPage;
  const pages = [];
  for (let i = 0; i < pageCount; i++) {
    pages.push(i + 1);
  }

  return { pages, pageCount };
};

export default getPages;
