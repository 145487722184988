import moment from 'moment';

const filter = (passengers, value) => {
  let objectItem;

  switch (value) {
    case '1':
      objectItem = 'added';
      break;
    case '2':
      objectItem = 'dateFrom';
      break;
    default:
      objectItem = 'added';
      break;
  }

  passengers.sort((a, b) => {
    if (moment(a[objectItem]).isAfter(b[objectItem])) {
      return -1;
    }
    if (moment(a[objectItem]).isBefore(b[objectItem])) {
      return 1;
    }
    return 0;
  });

  return passengers;
};

export default filter;
