import React from 'react';
import ReactDOM from 'react-dom';
import Router from './router';
import * as Sentry from '@sentry/browser';
import { unregister } from './registerServiceWorker';

Sentry.init({
  dsn: 'https://cff130c862cd43d5aae85d9996922932@sentry.io/1442020',
});

ReactDOM.render(<Router />, document.getElementById('root'));
unregister();
