import CloseButton from './CloseButton';
import constants from '../../../styles/constants.js';
import hamburger from '../../../img/hamburger.png'; // eslint-disable-line
import MenuItems from './MenuItems';

import React, { Component } from 'react';
import Radium from 'radium';

const styles = {
  section: {
    zIndex: '99999',
  },
  wrapper: {
    width: '300px',
    position: 'fixed',
    right: -300,
    top: 0,
    listStyle: 'none',
    background: '#fff',
    height: '100vh',
    transition: 'right 0.3s',
    zIndex: '9999999',
  },
  wrapperOpened: {
    width: '300px',
    position: 'fixed',
    right: 0,
    top: 0,
    listStyle: 'none',
    background: '#fff',
    height: '100vh',
    zIndex: '9999999',
    transition: 'right 0.3s',
    boxShadow: '-4px 0px 25px',
  },
  overlay: {
    width: 'calc(100vw)',
    height: '100vh',
    position: 'fixed',
    left: 0,
    top: 0,
    float: 'left',
    background: '#000',
    opacity: '0.8',
    zIndex: '9999',
    display: 'none',

    [constants.breakpoint1040]: {
      display: 'block',
    },
  },
  openToggle: {
    position: 'fixed',
    top: '39px',
    right: '14px',
    width: '30px',
    height: '20px',
    textAlign: 'center',
    padding: '5px 5px',
    background: '#fff',
    cursor: 'pointer',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: [constants.mainBlue],
    transition: '0.2s',
    display: 'none',
    zIndex: '9',

    image: {
      width: '28px',
      height: '19px',
    },

    [constants.breakpoint1040]: {
      display: 'block',
    },
    [constants.breakpoint600]: {
      right: '10px',
      top: '13px',
    },
  },
  closeToggle: {
    position: 'absolute',
    top: '2%',
    right: '2%',
    opacity: '0.9',
    background: 'transparent',
    transition: '0.2s',
    cursor: 'pointer',

    ':hover': {
      opacity: '1',
      transform: 'rotate(90deg)',
    },
  },
};

class MenuResponsive extends Component {
  state = {
    opened: false,
  };

  render() {
    return (
      <div>
        <div style={styles.openToggle} onClick={() => this.setState({ opened: true })}>
          <img style={styles.openToggle.image} src={hamburger} />
        </div>
        {this.renderResponsiveMenu()}
      </div>
    );
  }

  renderResponsiveMenu() {
    const { opened } = this.state;

    return (
      <div style={styles.section}>
        <div className="mainmenu-responsive" style={opened ? styles.wrapperOpened : styles.wrapper}>
          <div onClick={this.handleCloseMenu.bind(this)}>
            <CloseButton />
          </div>
          <MenuItems
            closeMenu={this.handleCloseMenu.bind(this)}
            opened={this.state.opened}
            type="responsive"
          />
        </div>
        {opened && <div style={styles.overlay} onClick={() => this.setState({ opened: false })} />}
      </div>
    );
  }

  handleCloseMenu() {
    this.setState({ opened: false });
  }
}

export default Radium(MenuResponsive);
