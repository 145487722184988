import constants from '../../../styles/constants';

const styles = {
  wrapper: {
    width: '96%',
    marginLeft: '2%',
    display: 'block',
    marginBottom: 35,
    minHeight: 48,
    marginTop: 0,
    position: 'relative',
    paddingBottom: 0,
    borderBottom: '1px solid' + [constants.grey],

    [constants.breakpoint991]: {
      minHeight: 90,
      width: '100%',
      marginLeft: 0,
    },

    [constants.breakpoint600]: {
      borderBottom: 'none',
    },

    [constants.breakpointMobile]: {
      marginBottom: 75,
    },
  },
  lastMinuteSearchText: {
    position: 'absolute',
    top: '-9px',
    right: '493px',

    [constants.breakpointTablet]: {
      top: '40px',
      right: 0,
    },
    [constants.breakpoint600]: {
      top: '80px',
      right: 'inherit',
      left: 0,
    },
    [constants.breakpointMobile]: {
      top: '114px',
    },
  },
  content: {
    position: 'relative',
    left: 0,

    [constants.breakpoint991]: {
      top: 50,
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  sumOfTours: {
    position: 'absolute',
    width: 155,
    textAlign: 'right',
    right: 0,
    paddingTop: 5,

    [constants.breakpoint600]: {
      top: 93,
    },
  },
  listWrapper: {
    margin: 0,
    paddingLeft: 0,
  },
  lastMinuteInfo: {
    marginBottom: 20,
    color: [constants.mainBlue],
    fontSize: '1.2em',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  item: {
    listStyle: 'none',
    minWidth: '0',
    float: 'left',
    position: 'relative',
    display: 'inline-block',
    width: '30px',
    height: '29px',
    border: '1px solid #ccc',
    borderRadius: '20px',
    overflow: 'hidden',
    marginBottom: 9,
    marginRight: '3px',
  },
  link: {
    position: 'absolute',
    textDecoration: 'none',
    color: '#444',
    background: 'transparent',
    transition: '0.2s',
    cursor: 'pointer',
    padding: '15px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  selected: {
    position: 'absolute',
    textDecoration: 'none',
    color: '#fff',
    background: [constants.mainOrange],
    transition: '0.2s',
    cursor: 'pointer',
    padding: '15px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
};

export default styles;
