import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';

class Icons extends Component {
  render() {
    const {
      calculator: {
        data: { customerCombinations },
      },
    } = this.props;

    return (
      <div className="availability-icon-wrapper">
        {Object.keys(customerCombinations).map((key) =>
          this.renderIcons(customerCombinations[key]),
        )}
      </div>
    );
  }

  renderIcons(combinations) {
    return combinations.map((combination) => this.renderTravelIcon(combination));
  }

  renderTravelIcon(icon) {
    const { title, imgSrc } = icon;

    return <img src={imgSrc} className="availability-icon" alt={title} key={title} />;
  }
}

const mapStateToProps = (state) => ({
  calculator: state.calculator,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Radium(Icons));
