const headerTemplate = `<!DOCTYPE html>
<html>
<head>
    <meta http-equiv="content-type" content="text/html; charset=utf-8">
</head>
<style>

    @page {
        size: auto;
        margin: 8mm 8mm 8mm 8mm;
    }

    * {
        padding: 0;
        margin: 0;
    }

    body {
        font-family: Open Sans, sans-serif;
        font-size: 14px;
        margin: 0;
    }
    
    p {
    margin-top: 5px;
    }

    h1, p h1, p h1 span {
        font-weight: 400 !important;
        font-size: 18px !important;
        line-height: 17px;
        margin: 25px 0 0px !important;
        color: #386696 !important;
        padding-top: 10px;
    }

    .instructions-wrapper p img {
        width: 100%;
        height: auto;
        text-align: center;
    }
    
    .instructions-wrapper p h1 span strong, 
    .instructions-wrapper p h1 strong {
    font-weight: 400!important;
}

    a {
        color: #386696;
        border-bottom: 1px solid #386696;
        padding-bottom: 2px;
        text-decoration: none;
    }

    p, li {
        font-size: 15px !important;
        color: #333;
    }

    p:first-child {
        color: red;
    }

    .title {
        font-size: 18px !important;
        color: #386696;
        font-weight: bold;
    }
    .strong {  
    font-weight: bold; color: red
    }
td:nth-child(2) {
text-align: right;
}
    
</style>

<div class="instructions-wrapper">
    {content}
</div>`;

export default headerTemplate;
