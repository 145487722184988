import {
  SET_PASSENGER_FILTER,
  SET_REPLY_FORM,
  SET_NEW_INSERTION_FORM,
  SAVE_PASSENGER_FILTER,
} from '../actions/TogetherTraveling/passengerFilter';

const initialState = {
  sex: '',
  country: '',
  dateFrom: '',
  dateTo: '',
  sort: '',
  replyForm: false,
  newInsertionForm: false,
};

const tourDetail = (state = initialState, action) => {
  const { sex, country, dateFrom, dateTo, designation, status } = action;
  switch (action.type) {
    case SET_PASSENGER_FILTER:
      return { ...state, sex, country, dateFrom, dateTo };

    case SAVE_PASSENGER_FILTER:
      return { ...state, [designation]: action.state };

    case SET_REPLY_FORM:
      return { ...state, replyForm: status };

    case SET_NEW_INSERTION_FORM:
      return { ...state, newInsertionForm: status };

    default:
      return state;
  }
};

export default tourDetail;
