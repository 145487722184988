import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Column, Container, Row } from '../../../libs/radium-grid'; // eslint-disable-line
import greyBg from '../../../img/grey-bg.png'; // eslint-disable-line

const styles = {
  contentWrapper: {
    overflow: 'hidden',
    background: '#fff',
    zIndex: 6,
    position: 'relative',
  },
  section: {
    imageGrey: {
      padding: '35px 0',
      background: 'url(' + greyBg + ')',
    },
  },
};

export default class RowImageGrey extends Component {
  render() {
    const { children } = this.props;

    return (
      <div style={styles.section.imageGrey}>
        <Container>{children}</Container>
      </div>
    );
  }
}

RowImageGrey.propTypes = {
  children: PropTypes.any,
};
