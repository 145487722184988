import crossImg from '../../../img/icons/cancel.png'; // eslint-disable-line
import checkImg from '../../../img/icons/checked-symbol.png'; // eslint-disable-line
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import constants from '../../../styles/constants';
import OccupationHeader from './OccupationHeader';
import { fetchLocations, fetchOccupationHotels } from '../../../actions/TourList/fetchTourList';
import { resetAllFilters, fetchTourFilter } from '../../../actions/TourList/tourFilter'; // eslint-disable-line
import { closeTabs } from '../../../actions/TourList/filterOpenStatus';
import LoaderInModalWindow from '../../shared/Loading/LoaderInModalWindow';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import Radium from 'radium';

const styles = {
  wrapper: {
    marginBottom: '30px',
  },
  leftAlign: {
    textAlign: 'left',
  },
  rightAlign: {
    textAlign: 'center',
  },
  sectionTitle: {
    marginTop: '30px',
    marginBottom: '40px',
    textTransform: 'uppercase',
    lineHeight: '23px',
    fontWeight: '700',
    padding: '1px 5px 1px 4px',
    color: [constants.yellow],
    display: 'inline-block',
    fontSize: '1.1em',
    borderBottom: '2px solid #ededed',
    width: '100%',
  },
  table: {
    borderCollapse: 'collapse',
    fontSize: '1em',
    maxWidth: '1000px',
    margin: '40px auto 50px',
    minWidth: '500px',

    [constants.breakpointMobile]: {
      fontSize: '0.8em',
    },
    tbody: {
      backgroundColor: 'white',
    },
    head: {
      textAlign: 'left',
      backgroundColor: [constants.yellow],
      color: '#fff',
    },

    line: {
      width: '780',
      borderBottom: '1px solid rgba(194, 194, 194, .17)',
    },
    infoBottom: {
      marginTop: '5px',
      width: '700px',
      margin: '0 auto',
    },
    infoBottomWrapper: {
      marginTop: '20px',
    },
  },
  tourNotices: {
    padding: ' 0 10px',
  },
  loading: {
    display: 'block',
    width: '200px',
    margin: '0 auto',
    justifyContent: 'center',
    marginTop: 120,
  },
  hotelTitle: {
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '1.5em',
    marginTop: '40px',
  },
  chooseHotel: {
    textAlign: 'center',
    fontWeight: '600',
    marginTop: '100px',
    fontSize: '1.2em',
  },
  statusIcon: {
    width: '17px',
  },
};

class Occupation extends Component {
  componentDidMount() {
    const {
      fetchState: { occupationHotelsFetched, filterFetched, locationsFetched },
      fetchOccupationHotels,
      fetchLocations,
      fetchTourFilter,
      resetAllFilters,
    } = this.props;
    !occupationHotelsFetched && fetchOccupationHotels();
    !filterFetched && fetchTourFilter();
    !locationsFetched && fetchLocations();
    resetAllFilters();
  }

  render() {
    const {
      fetchState: { occupationFetched, occupationEmpty },
      occupationHotelState: { textState },
    } = this.props;

    return (
      <div style={styles.borderBottom} className="cms-page" onClick={() => this.closeAllTabs()}>
        <Helmet title="Obsazenost" />
        <OccupationHeader />
        {textState ? (
          <p style={styles.hotelTitle}>{textState}</p>
        ) : (
          <p style={styles.chooseHotel}>Vyberte hotel</p>
        )}
        {occupationFetched
          ? textState && this.renderHotel(this.props.occupation)
          : !occupationEmpty && <LoaderInModalWindow />}
      </div>
    );
  }

  closeAllTabs() {
    const { closeTabs } = this.props;
    closeTabs();
  }

  renderHotel(occupationHotel) {
    const { tours, headerCodes } = occupationHotel;

    return (
      <div className="occupation">
        <table style={styles.table}>
          <tbody style={styles.table.tbody}>
            <tr style={styles.table.head}>
              <th>poznámka</th>
              <th>termín</th>
              {headerCodes.map((headerCode) => this.renderHeader(headerCode))}
            </tr>
            {tours.map((tour) => this.renderRow(tour, headerCodes))}
          </tbody>
        </table>
      </div>
    );
  }

  renderHeader(headerCode) {
    return <th style={styles.rightAlign}>{headerCode}</th>;
  }

  renderRow(tour, headerCodes) {
    const {
      notes,
      termFrom,
      termTo,
      codes: { code, occupied },
    } = tour;

    return (
      <tr>
        <th>{notes}</th>
        <th>{termFrom + ' - ' + termTo}</th>
        {headerCodes.map((headerCode, i) => this.renderTableColumns(headerCode, i, code, occupied))}
      </tr>
    );
  }

  renderTableColumns(headerCode, i, codes, occupied) {
    if (codes.includes(headerCode) && occupied[codes.indexOf(headerCode)]) {
      return (
        <th>
          <img src={crossImg} style={styles.statusIcon} />
        </th>
      );
    }

    return (
      <th>
        <img src={checkImg} style={styles.statusIcon} />
      </th>
    );
  }
}

Occupation.propTypes = {
  fetchState: PropTypes.any,
  fetchOccupationHotels: PropTypes.any,
  fetchLocations: PropTypes.any,
  fetchTourFilter: PropTypes.any,
  resetAllFilters: PropTypes.any,
  occupationHotelState: PropTypes.any,
  occupation: PropTypes.any,
  closeTabs: PropTypes.any,
};

const mapStateToProps = (state) => ({
  fetchState: state.fetchState,
  occupationHotelState: state.occupationHotelState,
  occupation: state.occupation,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTourFilter: () => dispatch(fetchTourFilter()),
  resetAllFilters: () => dispatch(resetAllFilters()),
  fetchOccupationHotels: () => dispatch(fetchOccupationHotels()),
  fetchLocations: () => dispatch(fetchLocations()),
  closeTabs: () => dispatch(closeTabs()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(Occupation));
