const getDaysSuffix = (days) => {
  if (days === 0) {
    return '';
  }
  if (days === 1) {
    return ' noc';
  } else if (days < 5) {
    return ' noci';
  } else {
    return ' nocí';
  }
};

export default getDaysSuffix;
