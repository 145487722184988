import React, { Component } from 'react';

class ArrowButton extends Component {
  render() {
    const { direction, onClick, customClass } = this.props;

    return (
      <button
        id={'next-' + direction}
        className={'arrow-button material-icons md-48 arrow-button ' + (customClass && customClass)}
        onClick={onClick}
      >
        {'keyboard_arrow_' + direction}
      </button>
    );
  }
}

export default ArrowButton;
