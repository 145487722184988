import constants from '../../../styles/constants.js';
import Radium from 'radium';
import { Column } from '../../../libs/radium-grid'; // eslint-disable-line
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const styles = {
  wrapper: {
    width: '29.33%',

    classic: {
      margin: '2%',
      float: 'left',
    },
    alone: {
      margin: '2% auto',
      float: 'none',
    },
    timeless: {
      margin: '2%',
      float: 'left',
    },

    [constants.breakpointTablet]: {
      width: '46%',
    },
    [constants.breakpointMobile]: {
      width: '96%',
    },
  },
  item: {
    background: '#fff',
    boxShadow: '0 0 1px rgba(0, 0, 0, 0.47)',
    overflow: 'auto',
    padding: '2%',
    textAlign: 'center',
    fontSize: '0.85em',
    height: '330px',

    [constants.breakpointTablet]: {
      fontSize: '0.8em',
    },

    photo: {
      width: '155px',
      height: '155px',
      overflow: 'hidden',
      borderRadius: '120px',
      margin: '10px auto 7px auto',
    },
    description: {
      margin: 0,

      name: {
        padding: '3px 0',
        fontSize: '1.1em',
        fontWeight: '500',
      },
      destination: {
        fontSize: '1em',
        fontWeight: '500',
        padding: '3px 0 0',
      },
      email: {
        margin: 0,

        link: {
          textDecoration: 'none',
          color: '#444',
        },
      },
      phone: {
        padding: '3px 0',
        fontSize: '1.1em',
      },
      time: {
        fontSize: '1.1em',
        padding: '1px 0',
      },
    },
  },
};

class ContactTile extends Component {
  render() {
    const { type, photo, name, destinations, phone, phone2, email, time } = this.props;
    const emailLink = 'mailto:' + email + '?Subject=Zpráva z webu ČD Travel';

    return (
      <div key={name} style={{ ...styles.wrapper, ...styles.wrapper[type] }}>
        <div style={styles.item}>
          <div style={styles.item.photo}>
            <img src={photo} />
          </div>
          <div style={styles.item.description}>
            <div style={styles.item.description.name}>{name}</div>
            <div style={styles.item.description.destination}>{destinations}</div>
            <div style={styles.item.description.phone}>
              <span>
                Tel.{phone}, {phone2}
              </span>
            </div>
            <div style={styles.item.description.email}>
              <a style={styles.item.description.email.link} href={emailLink} target="_top">
                {email}
              </a>
            </div>

            {type === 'classic' && (
              <div style={styles.item.description.time}>
                {time} {time && 'hod'}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ContactTile.propTypes = {
  type: PropTypes.any.isRequired,
  photo: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  destinations: PropTypes.string,
  phone: PropTypes.string,
  phone2: PropTypes.string,
  email: PropTypes.string,
  time: PropTypes.string,
};

export default Radium(ContactTile);
