import constants from '../../styles/constants';
import Radium from 'radium';
import selectArrow from '../../img/select-arrow2.png'; // eslint-disable-line
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeTabs, selecting, toggle } from '../../actions/TourList/filterOpenStatus';
import createFilterRequest from '../shared/RequestCreator/createFilterRequest';
import {
  resetFilter,
  toggleResetingLocations,
  toggleSelected,
} from '../../actions/TourList/tourFilter';
import { fetchFilteredList } from '../../actions/TourList/fetchTourList';
import { toggleTiles } from '../../actions/TourList/paginator';
import { saveFilter } from '../../actions/TourList/filter';
import PropTypes from 'prop-types';

const styles = {
  wrapper: {
    width: '100%',
  },
  inputBoxShorter: {
    width: '24%',
    margin: '0.50% 0.5%',
    float: 'left',
    position: 'relative',

    [constants.breakpointTablet]: {
      width: '47%',
      margin: '4px 1.5%',
    },

    [constants.breakpointMobile]: {
      width: '100%',
      margin: '4px 0',
    },
  },
  inputBox: {
    width: '32.3%',
    margin: '0.50% 0.5%',
    float: 'left',
    position: 'relative',

    [constants.breakpointTablet]: {
      width: '47%',
      margin: '4px 1.5%',
    },

    [constants.breakpointMobile]: {
      width: '100%',
      margin: '4px 0',
    },
  },

  selectBox: {
    width: 'calc(100% - 53px)',
    height: 40,
    overflow: 'hidden',
    padding: '0 42px 0 11px',
    lineHeight: '40px',
    border: '1px solid' + [constants.mainBlue],
    display: 'inline-block',
    backgroundColor: '#fff',
    backgroundImage: 'url(' + selectArrow + ')',
    backgroundPosition: 'right center',
    backgroundRepeat: 'no-repeat',
    color: [constants.mainBlue],
    fontSize: '0.9em',
    fontWeight: '500',
    cursor: 'pointer',
  },
  dropDown: {
    background: [constants.dropDownBackground],
    position: 'absolute',
    top: 54,
    left: 0,
    width: 'calc(100% + 2px)',
    zIndex: 4,
    fontSize: '0.9em',
    boxShadow: 'rgba(0, 0, 0, 0.31) 1px 2px 4px',

    item: {
      padding: '7px 5px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
      cursor: 'pointer',
      margin: '0 7px',
      lastChild: {
        borderBottom: 'none',
      },
    },
    checkbox: {
      marginRight: '10px',
      position: 'relative',
      top: '1px',
    },
    caret: {
      display: 'block',
      position: 'absolute',
      top: '-8px',
      left: '7px',
      width: '0',
      height: '0',
      content: '',
      verticalAlign: 'middle',
      borderBottom: '10px dashed' + [constants.dropDownBackground],
      borderRight: '10px solid transparent',
      borderLeft: '10px solid transparent',
    },
  },
};

const typeCountry = 'countrySortedByName';
const typeLocation = 'location';

class MultiCheckbox extends Component {
  state = {
    text: '',
    loading: true,
  };

  render() {
    const { defaultText, data, filterOpen, type, box, style, selected, selectedText } = this.props;
    const { text } = this.state;

    return (
      <div style={style ? styles[style] : styles.inputBox}>
        <div onClick={() => this.toggleOpen(type)} style={styles.selectBox}>
          {selected ? selectedText : defaultText}
        </div>
        <div style={styles.dropDown}>
          {filterOpen[type] && <div style={styles.dropDown.caret} />}
          {filterOpen[type] && (
            <div className="item" style={styles.dropDown.item} onClick={() => this.reset()}>
              {defaultText}
            </div>
          )}
          {filterOpen[type] && data.map((items) => this.renderBox(items, box))}
        </div>
      </div>
    );
  }

  toggleOpen(designation) {
    const { toggle, filterOpen, type, closeTabs } = this.props;
    if (!filterOpen[type]) {
      toggle(designation);
    } else {
      closeTabs();
    }
  }

  reset() {
    const { reset } = this.props;

    reset();
  }

  renderBox(items, box) {
    const { value, code, selected } = items;

    if (box === 'textbox') {
      return (
        <div
          className="item"
          style={styles.dropDown.item}
          value={code}
          onClick={() => this.toggleCheckbox(code, false)}
        >
          {value}
        </div>
      );
    } else {
      return (
        <div
          onClick={this.toggleCheckbox.bind(this, code, true)}
          className="item"
          style={styles.dropDown.item}
        >
          <input type="checkbox" style={styles.dropDown.checkbox} value={code} checked={selected} />
          <label style={styles.dropDown.label}>{value}</label>
        </div>
      );
    }
  }

  toggleCheckbox(code, checkbox) {
    const { type, toggleSelected, selecting, toggleTiles, toggleResetingLocations } = this.props;
    const { onSelect } = this.props;

    onSelect(code);
  }
}

MultiCheckbox.propTypes = {
  defaultText: PropTypes.any,
  saveFilter: PropTypes.any,
  resetLocations: PropTypes.any,
  data: PropTypes.any,
  filterOpen: PropTypes.any,
  type: PropTypes.any,
  box: PropTypes.any,
  toggle: PropTypes.any,
  closeTabs: PropTypes.any,
  toggleSelected: PropTypes.any,
  toggleResetingLocations: PropTypes.any,
  paginator: PropTypes.any,
  resetFilter: PropTypes.any,
  selecting: PropTypes.any,
  toggleTiles: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  toggle: (designation) => dispatch(toggle(designation)),
  selecting: () => dispatch(selecting()),
  resetFilter: (designation) => dispatch(resetFilter(designation)),
  toggleResetingLocations: (status) => dispatch(toggleResetingLocations(status)),
  fetchFilteredList: (filters) => dispatch(fetchFilteredList(filters)),
  toggleTiles: (nextState) => dispatch(toggleTiles(nextState)),
  closeTabs: () => dispatch(closeTabs()),
  saveFilter: (filter, designation) => dispatch(saveFilter(filter, designation)),
  toggleSelected: (code, designation, checkbox) =>
    dispatch(toggleSelected(code, designation, checkbox)),
});

const mapStateToProps = (state) => ({
  filter: state.filter,
  paginator: state.paginator,
  filterOpen: state.filterOpen,
  tourList: state.tourList,
  tourFilter: state.tourFilter,
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(MultiCheckbox));
