import {
  AUTOCOMPLETE,
  AUTOCOMPLETE_FETCHING,
  fetching,
  CLOSE_SEARCH,
  SEARCH_FETCHING,
  nonFetchable,
} from '../actions/Search/search';

const initialState = {
  items: '',
};

const tourDetail = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_SEARCH:
      return { ...state, items: '' };

    case AUTOCOMPLETE_FETCHING:
      return { ...state, items: fetching };

    case SEARCH_FETCHING:
      return { ...state, items: nonFetchable };

    case AUTOCOMPLETE:
      if (state.items !== nonFetchable) {
        return { ...state, items: action.result };
      }

      return state;

    default:
      return state;
  }
};

export default tourDetail;
