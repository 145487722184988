import React, { Component } from 'react';

const styles = {
  caret: {
    display: 'inline-block',
    width: '0',
    height: '0',
    marginRight: '-1px',
    marginTop: '-1px',
    verticalAlign: 'middle',
    borderTop: '4px dashed',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
  },
};

export default class Caret extends Component {
  render() {
    return <span style={styles.caret} />;
  }
}
