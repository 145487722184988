import { SET_DEFAULT_FILTER } from '../actions/HP/defaultTourlist';

const initialState = {
  isRequested: false,
  filter: {
    country: [],
    transport: [],
    season: [],
  },
};

const filter = (state = initialState, action) => {
  const { isRequested, filter } = action;

  switch (action.type) {
    case SET_DEFAULT_FILTER:
      return { ...state, isRequested, filter };

    default:
      return state;
  }
};

export default filter;
