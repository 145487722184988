import Caret from '../../shared/Caret';
import constants from '../../../styles/constants.js';
import DropDown from './DropDown';
import Radium from 'radium';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

const styles = {
  link: {
    types: {
      desktop: {
        width: 'auto',
        float: 'left',
        position: 'relative',
        padding: '15px 9px 21px 9px',
        margin: '0',
        cursor: 'pointer',
        transition: '0.2s',
        textDecoration: 'none',
        borderBottom: 'none',
        color: constants.black,
        fontWeight: '400',
        fontSize: '1.2em',
      },
      responsive: {
        width: '90%',
        padding: '15px 0',
        overflow: 'auto',
        display: 'block',
        cursor: 'pointer',
        transition: '0.2s',
        color: constants.black,
        textDecoration: 'none',
        background: 'transparent',
        borderBottom: 'solid 1px' + constants.grey,
        position: 'relative',
        left: '5%',
      },
    },
  },
  item: {
    listStyle: 'none',
    height: 'auto',
    position: 'relative',
    float: 'left',
    transition: '0.1s',

    types: {
      desktop: {
        display: 'inline-block',
        padding: '0 3px',
        margin: 0,

        ':hover': {
          color: [constants.mainBlue],
        },

        [constants.breakpointLargeTablet]: {
          padding: '0',
        },
      },
      responsive: {
        display: 'block',
        clear: 'both',
        position: 'relative',
        width: '100%',
        padding: '0 0 0 0',
        marginBottom: '1px',
      },
    },
  },
};

class DropdownMenuItem extends Component {
  state = {
    hovered: false,
  };

  render() {
    const { hovered } = this.state;
    const { type, title, link, key, parent } = this.props;

    return (
      <li
        style={{ ...styles.item, ...styles.item.types[type] }}
        key={key}
        onClick={this.handleItemClick.bind(this)}
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
      >
        <div onClick={(event) => event.stopPropagation()}>
          <Link style={styles.link.types[type]} key={2} to={link}>
            {title} <Caret />
          </Link>
        </div>

        {hovered && this.renderDropDownMenu(parent)}
      </li>
    );
  }

  handleItemClick() {
    this.setState({ hovered: true, opened: true });
  }

  renderDropDownMenu(parent) {
    const { type } = this.props;
    return <DropDown type={type} parent={parent} />;
  }
}

DropdownMenuItem.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  key: PropTypes.string.isRequired,
  parent: PropTypes.string.isRequired,
};

export default Radium(DropdownMenuItem);
