import aboutSectionImage from '../../../img/section-headers/together.jpg'; // eslint-disable-line
import Radium from 'radium';
import SectionImage from '../../shared/SectionImage';
import paymentMethodes from '../../../img/paymentMethodes.png';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import TextInput from '../../shared/forms/TextInput';
import { createNewPayment } from '../../../actions/Payment/payment';
import Agreement from '../Calculator/Reservation/Agreement';
import cookie from 'react-cookie';
import { employee } from '../../../actions/HP/defaultTourlist';
import OrangeButton from '../../shared/OrangeButton';
import ReactTooltip from 'react-tooltip';

const styles = {
  sectionHead: {
    width: '100%',
  },
  contentWrapper: {
    overflow: 'hidden',
    background: '#fff',
    zIndex: 6,
    position: 'relative',
    padding: '50px 0',
  },
  contentBox: {
    margin: '0 auto',
    padding: '30px',
    maxWidth: '400px',
    border: '1px solid #ccc',
    boxShadow: 'rgba(0, 0, 0, 0.13) 0px 0px 2px',
  },
  image: {
    marginTop: '25px',
    width: '100%',
  },
  submitWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paymentMethodes: {
    width: '100%',
    maxWidth: 270,
    marginBottom: 20,
  },
};

class Payment extends Component {
  state = {
    agreementChecked: false,
    firstAndSecondName: '',
    specificSymbol: '',
    amount: '',
  };

  SPECIFIC_SYMBOL_LENGTH = 10;

  onSubmit() {
    createNewPayment(this.state);
  }

  render() {
    const isSubsidy = cookie.load('user') === employee;
    return (
      <div style={styles.borderBottom} className="cms-page">
        <Helmet title="Online prodej" />
        <SectionImage actualImage={aboutSectionImage} actualTitle="Online prodej" />

        <div style={styles.contentWrapper}>
          <div style={styles.contentBox}>
            {this.renderFormInputs()}

            <div style={styles.submitWrapper}>
              <div>
                <p>
                  <b>Cena</b>
                </p>
                <p className={'price' + (isSubsidy ? ' subsidy' : '')}>{this.getPrice()} Kč</p>
              </div>

              <div>
                <OrangeButton
                  dataTooltip={this.getValidationMessage()}
                  type="whiteText"
                  disabled={this.isSubmitButtonDisabled()}
                  onClick={() => this.onSubmit()}
                >
                  Zaplatit
                </OrangeButton>

                <ReactTooltip html={Boolean(true)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderFormInputs() {
    const isSubsidy = cookie.load('user') === employee;

    return (
      <div>
        <div>
          <p>
            Po vyplnění a potvrzení formuláře budete přesměrováni na platební bránu <b>ČSOB</b>, kde
            proběhne platba kartou.
          </p>
        </div>

        <div>{this.renderTextInput('firstAndSecondName', 'Jméno a příjmení')}</div>

        <div>
          {this.renderTextInput('specificSymbol', 'Variabilní symbol')}
          <i>Desetimístné číslo, které jste od nás obdrželi.</i>
        </div>

        <div>{this.renderNumberInput('amount', 'Částka')}</div>

        <div>
          <Agreement
            isSubsidy={isSubsidy}
            trans={{
              agreementChecked:
                'Souhlasím se zpracováním osobních údajů v souladu s Nařízením EU 2016/679.',
            }}
            param="agreementChecked"
            values={this.state}
            onChange={(value) => this.setState({ agreementChecked: value })}
          />
        </div>

        <img style={styles.paymentMethodes} src={paymentMethodes} alt="Platební metody" />
      </div>
    );
  }

  renderTextInput(param, label) {
    return (
      <TextInput
        name={param}
        label={label}
        value={this.state[param]}
        onChange={(value) => this.setState({ [param]: value })}
      />
    );
  }

  renderNumberInput(param, label) {
    const handleOnChange = (value) => {
      if (value.match(/^[0-9]+$/) || value === '') {
        this.setState({ [param]: value });
      }
    };

    return (
      <TextInput
        name={param}
        label={label}
        value={this.state[param]}
        onChange={(value) => handleOnChange(value)}
      />
    );
  }

  getPrice() {
    const price = parseInt(this.state.amount);

    return isNaN(price) ? '0' : price.toLocaleString();
  }

  isSpecificSymbolValid() {
    const { specificSymbol } = this.state;

    return specificSymbol.length === this.SPECIFIC_SYMBOL_LENGTH;
  }

  isSubmitButtonDisabled() {
    const { amount, agreementChecked, firstAndSecondName } = this.state;

    return !(amount && agreementChecked && firstAndSecondName && this.isSpecificSymbolValid());
  }

  getValidationMessage() {
    const { agreementChecked } = this.state;

    if (!this.isSpecificSymbolValid()) {
      return 'VS musí být desetimístný';
    }

    if (!agreementChecked) {
      return 'Pro pokračování musíte souhlasit<br> se zpracováním os. údajú.';
    }

    return 'Vyplňte prosím všechny údaje';
  }
}

export default Radium(Payment);
