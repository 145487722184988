import { SET_EMPLOYEE_INSTRUCTIONS } from '../actions/EmployeeInstructions/fetch';

const initialState = {
  loaded: false,
  instructions: [],
};

const tourDetail = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMPLOYEE_INSTRUCTIONS:
      return { ...state, loaded: true, instructions: action.result };

    default:
      return state;
  }
};

export default tourDetail;
