const getCountries = (countries, passengers) => {
  const countriesIncluded = [];

  try {
    countries.forEach((item) => {
      const { code } = item;
      const countriesFiltered = passengers.filter((countries) => {
        return code.toString() === countries.idCountry.toString();
      });

      if (countriesFiltered.length > 0) {
        countriesIncluded.push(item);
      }
    });
  } catch (err) {
    return null;
  }

  return countriesIncluded;
};

export default getCountries;
