const getDaysSuffix = (days) => {
  if (days === 1) {
    return ' den';
  } else if (days < 5) {
    return ' dny';
  } else {
    return ' dnů';
  }
};

export default getDaysSuffix;
