export const loadSeznamScripts = (context) => {
  setTimeout(() => {
    const { Loader } = window;

    if (!Loader) {
      loadSeznamScripts(context);
    } else {
      Loader.async = true;
      Loader.load(null, { suggest: true }, loadMap(context));
    }
  }, 200);
};

const loadMap = (context, timeout = 200) => {
  setTimeout(() => {
    const { SMap, JAK } = window;

    if (!SMap || !JAK || !SMap.CONFIG || !SMap._) {
      loadMap(context, timeout + 100);
    } else {
      setTimeout(() => {
        context.setState({ mapLoaded: true });
      }, 500);
    }
  }, timeout);
};
