import constants from '../../../styles/constants';
import backgroundImg from '../../../img/bcg-tours.jpg';

const styles = {
  wrapperBig: {
    width: '100%',

    [constants.breakpointTablet]: {},
    [constants.breakpointMobile]: {},
  },
  wrapperSmall: {
    width: '100%',
    overflow: 'hidden',
    height: '300px',

    [constants.breakpointTablet]: {
      height: '230px',
    },
  },
  image: {
    minWidth: '100%',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    height: 'auto',

    [constants.breakpointDesktopMenu]: {
      height: '100%',
      width: 'auto',
    },
    [constants.breakpointLargeTablet]: {
      height: '100%',
      width: 'auto',
    },
  },
  sectionHead: {
    backgroundImage: 'url(' + backgroundImg + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    minheight: 300,
  },
  contentBox: {},
  h2: {
    textAlign: 'center',
    fontSize: '40px',
    color: '#fff',
    fontWeight: '700',
    marginBottom: '40px',
    marginTop: '15px',

    [constants.breakpointTablet]: {
      marginBottom: '15px',
      marginTop: 0,
    },
  },
  centered: {
    display: 'inline-block',
    textAlign: 'center',
    width: '100%',
    margin: '0 auto',
    justifyContent: 'center',
  },
  iconBox: {
    width: 'auto',
    float: 'left',
    paddingLeft: '10px',
    paddingRight: '15px',
    textAlign: 'center',
    display: 'inline-block',

    icon: {
      width: '20px',
      marginLeft: '10px',
      position: 'absolute',
      top: '50%',
      right: 12,
      marginTop: -12,
    },

    text: {
      fontSize: '1.1em',
    },
  },
  buttonlastMinute: {
    width: 'calc((100%/3.3333))',
    top: 10,
    minWidth: '145px',
    ':hover': {
      background: [constants.mainOrange],
      borderColor: [constants.mainOrange],
      color: 'white',
    },
  },
  title: {
    position: 'absolute',
    top: '70px',
    maxWidth: '800px',
    transform: 'translateY(-50%)',
    left: '1%',
    fontSize: '3.5em',
    lineHeight: '1.3em',
    color: '#fff',
    zIndex: '6',
    textTransform: 'uppercase',
    fontWeight: '700',
    minWidth: 'auto',

    [constants.breakpointLargeTablet]: {
      fontSize: '2.8em',
    },
    [constants.breakpointTablet]: {
      left: '50%',
      top: '40px',
      transform: 'translateX(-50%)',
      textAlign: 'center',
      minWidth: '200px',
      fontSize: '2.2em',
    },
  },
  value: {
    textAlign: 'center',
    position: 'relative',
    top: 17,
    margin: '15px 0 18px 0',
    fontSize: '1.3em',
    color: '#fff',
    clear: 'both',
  },
  slider: {
    position: 'relative',
  },
};

export default styles;
