import { getJSON } from "../../utils/fetch";

export const SET_DEFAULT_FILTER = "SET_DEFAULT_FILTER";
export const FETCH_TILE = "FETCH_TILE";
export const SET_TOTAL_TOURS_COUNT = "SET_TOTAL_TOURS_COUNT";
export const employee = "employee";
export const szEmployee = "szEmployee";
export const visitor = "visitor";

export const employeeOrVisitor = (user) => {
  if (user === szEmployee) {
    return employee;
  }

  return visitor;
};

export const setDefaultFilter = (isRequested, filter) => {
  return {
    type: "SET_DEFAULT_FILTER",
    isRequested,
    filter
  };
};

export const fetchTile = () => {
  return (dispatch) => {
    getJSON("hp-tile/").then((result) => {
      dispatch({
        type: FETCH_TILE,
        result
      });
    });
  };
};

export const fetchTotalToursCount = () => {
  return (dispatch) => {
    getJSON("total-tours-count/").then((result) => {
      dispatch({
        type: SET_TOTAL_TOURS_COUNT,
        result
      });
    });
  };
};
