import ToursListHead from './ToursListHead';
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RowImageGrey from '../../../shared/Rows/RowImageGrey';
import ToursListBody from './components/ToursListBody';
import HotelsMap from './HotelsMap/HotelsMap';
import { vypisTitle } from '../../../../utils/titleConfig';
import LoaderInModalWindow from '../../../shared/Loading/LoaderInModalWindow';
import { fetchTourList } from '../../../../actions/TourList/fetchTourList';
import windowDimensions from 'react-window-dimensions';
import {
  toggleSelected,
  fetchTourFilter,
  saveIncluded,
  saveIncludedSelectedLocations,
  toggleResetingLocations,
} from '../../../../actions/TourList/tourFilter';
import { fetchEssentialData } from '../../../../actions/TourList/Map/hotelsMap';
import { closeTabs } from '../../../../actions/TourList/filterOpenStatus';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import Tiles from './Tiles';

class ToursList extends Component {
  componentDidMount() {
    const {
      fetchState: { filterFetched, mapDataFetched },
      fetchTourFilter,
      fetchEssentialData,
      toggleResetingLocations,
    } = this.props;

    if (!filterFetched) {
      fetchTourFilter();
    }

    if (!mapDataFetched) {
      fetchEssentialData();
    }

    toggleResetingLocations(false);
  }

  render() {
    const {
      paginator: { tilesVisible },
      fetchState: { filterFetched, mapDataFetched },
      params,
    } = this.props;

    if (!filterFetched || !mapDataFetched)
      return (
        <div>
          <Helmet title={vypisTitle} />
          <ToursListHead />

          <RowImageGrey>
            <LoaderInModalWindow />
          </RowImageGrey>
        </div>
      );

    return (
      <div onClick={() => this.closeAllTabs()}>
        <Helmet title={vypisTitle} />

        <ToursListHead type={params.type} />

        <RowImageGrey>{tilesVisible ? <Tiles /> : <ToursListBody />}</RowImageGrey>

        {this.renderMap()}
      </div>
    );
  }

  renderMap() {
    const { width } = this.props;

    return null;

    if (width < 1024) {
      return null;
    }

    return <HotelsMap />;
  }

  closeAllTabs() {
    const { closeTabs } = this.props;
    closeTabs();
  }
}

ToursList.propTypes = {
  fetchState: PropTypes.any,
  fetchTourFilter: PropTypes.any,
  fetchLocations: PropTypes.any,
  toggleResetingLocations: PropTypes.any,
  paginator: PropTypes.any,
  params: PropTypes.any,
  closeTabs: PropTypes.any,
};

const mapStateToProps = (state) => ({
  filter: state.filter,
  fetchState: state.fetchState,
  paginator: state.paginator,
  tourList: state.tourList,
  tourFilter: state.tourFilter,
  selectedLocations: state.selectedLocations,
  defaultTourListFilter: state.defaultTourListFilter,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTourFilter: () => dispatch(fetchTourFilter()),
  fetchEssentialData: () => dispatch(fetchEssentialData()),
  saveIncluded: (desingation, filter) => dispatch(saveIncluded(desingation, filter)),
  saveIncludedSelectedLocations: (result) => dispatch(saveIncludedSelectedLocations(result)),
  toggleResetingLocations: (status) => dispatch(toggleResetingLocations(status)),
  fetchTourList: () => dispatch(fetchTourList()),
  toggleSelected: (code, designation, checkbox) =>
    dispatch(toggleSelected(code, designation, checkbox)),
  closeTabs: () => dispatch(closeTabs()),
});

export default connect(mapStateToProps, mapDispatchToProps)(windowDimensions()(Radium(ToursList)));
