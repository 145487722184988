import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SectionImage from '../../shared/SectionImage';
import LoaderInModalWindow from '../../shared/Loading/LoaderInModalWindow';
import about from '../../../img/about.jpg'; // eslint-disable-line
import faq from '../../../img/faq.jpg'; // eslint-disable-line
import tickets from '../../../img/tickets.jpg'; // eslint-disable-line
import croatiaTravel from '../../../img/croatiaTravel.jpg'; // eslint-disable-line
import { Container } from '../../../libs/radium-grid'; // eslint-disable-line
import Radium from 'radium';
import { fetchCMS } from '../../../actions/CMS/fetchCMS';
import { faqTitle, aboutTitle, ticketsTitle, croatiaTravelTitle } from '../../../utils/titleConfig';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

const styles = {
  wrapper: {
    padding: '50px 0 120px 0',
  },
  loading: {
    display: 'block',
    width: '200px',
    margin: '0 auto',
    justifyContent: 'center',
    marginTop: 120,
  },
  innerContentBox: {
    maxWidth: '900px',
    margin: '30px auto 0 auto',
  },
};

class CMSPage extends Component {
  state = {
    tickets,
    faq,
    about,
    'doprava-do-chorvatska': croatiaTravel,
  };

  componentDidMount() {
    const { fetchCMS } = this.props;

    fetchCMS();
  }

  render() {
    const { title } = this.getContent(this.props.params.idCms);

    let header = '';
    switch (this.props.params.idCms) {
      case 'faq':
        header = faqTitle;
        break;
      case 'about':
        header = aboutTitle;
        break;
      case 'tickets':
        header = ticketsTitle;
        break;
      case 'doprava-do-chorvatska':
        header = croatiaTravelTitle;
        break;
      default:
        header = '';
        break;
    }

    return (
      <div>
        <Helmet title={header} />
        <SectionImage actualImage={this.state[this.props.params.idCms]} actualTitle={title} />
        <div style={styles.wrapper}>{this.renderContent()}</div>
      </div>
    );
  }

  renderContent() {
    const { title, content } = this.getContent(this.props.params.idCms);

    if (title === '') {
      return (
        <Container>
          <div style={styles.loading}>
            <LoaderInModalWindow />
          </div>
        </Container>
      );
    }

    return (
      <Container>
        <div style={styles.innerContentBox} className="inner-cms-box">
          <p dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </Container>
    );
  }

  getContent(url) {
    const { CMS } = this.props;
    let title = '';
    let content = '';

    CMS.forEach((item) => {
      if (item.url === url) {
        title = item.title;
        content = item.content;
      }
    });
    return { title, content };
  }
}

CMSPage.propTypes = {
  CMS: PropTypes.any,
  idCms: PropTypes.any,
  params: PropTypes.any,
  fetchCMS: PropTypes.any,
};

const mapStateToProps = (state) => ({
  CMS: state.CMS,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCMS: () => dispatch(fetchCMS()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(CMSPage));
