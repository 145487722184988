export const cmsPage = '/cms-page';
export const search = '/search';
export const printPriceList = '/printPriceList/';
export const printCompletePriceList = '/printCompletePriceList/';
export const printSeparatedPriceList = '/printSeparatedPriceList/';
export const printInstructions = '/instructions/';
export const occupation = '/obsazenost';
export const zajezdyVypis = '/zajezdy/vypis/';
export const zajezdyOffer = '/zajezdy/offer/';
export const zajezdyDetail = '/zajezdy/detail/';
export const zajezdyLastMinute = '/zajezdy/LastMinute/';
export const togetherTravelling = '/together-travelling/';
export const contact = '/contact';
