import Helmet from 'react-helmet';
import ToursListHead from './SpecialOfferHead';
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RowImageGrey from '../../../shared/Rows/RowImageGrey';
import ToursListBody from './SpecialOfferBody';
import LoaderInModalWindow from '../../../shared/Loading/LoaderInModalWindow';
import { fetchSpecialList } from '../../../../actions/TourList/fetchTourList';
import createSpecialToursRequest from '../../../shared/RequestCreator/createSpecialToursRequest';
import { offerTitle, lastMinuteTitle } from '../../../../utils/titleConfig';
import { connect } from 'react-redux';

export const offerType = 'offer';
export const offerHeader = 'Výhodné nabídky';

class SpecialOffer extends Component {
  state = {
    showBody: false,
    fetched: false,
  };

  componentDidMount() {
    const {
      fetchSpecialList,
      params: { type },
      paginator: { itemsPerPage },
    } = this.props;

    fetchSpecialList(createSpecialToursRequest(1, type, itemsPerPage));
  }

  render() {
    const { type } = this.props.params;
    const {
      fetchState: { specialListFetched },
      filterSettings: { filterName },
    } = this.props;

    if (!specialListFetched)
      return (
        <div>
          <Helmet title={type === offerType ? offerTitle : lastMinuteTitle} />
          <ToursListHead title={type === offerType ? offerHeader : filterName} />
          <RowImageGrey>
            <LoaderInModalWindow />
          </RowImageGrey>
        </div>
      );

    return (
      <div>
        <Helmet title={offerTitle} />
        <ToursListHead title={type === offerType ? offerHeader : filterName} />
        <RowImageGrey>
          <ToursListBody type={type} />
        </RowImageGrey>
      </div>
    );
  }
}

SpecialOffer.propTypes = {
  fetchSpecialList: PropTypes.any,
  fetchState: PropTypes.any,
  paginator: PropTypes.any,
  params: PropTypes.any,
  closeTabs: PropTypes.any,
};

const mapStateToProps = (state) => ({
  fetchState: state.fetchState,
  paginator: state.paginator,
  filterSettings: state.filterSettings,
  specialTourList: state.specialTourList,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSpecialList: (pagePropertie) => dispatch(fetchSpecialList(pagePropertie)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(SpecialOffer));
