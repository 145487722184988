import Guarantee from './Guarantee';
import constants from '../../../../../styles/constants.js';
import Radium from 'radium';
import React, { Component } from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const styles = {
  block: {
    overflow: 'auto',
  },
  wrapper: {
    padding: '20px',
    background: '#fff',
    boxShadow: 'rgba(0, 0, 0, 0.13) 0px 0px 2px',
    margin: '20px 0 0px',

    [constants.breakpoint991]: {
      marginBottom: '20px',
    },
  },
  content: {
    width: '92%',
  },
  iconAvailability: {
    height: '30px',
    width: 'auto',
    padding: '3px 25px 3px 0',
  },
  iconType: {
    position: 'relative',
    top: '8px',
    height: '20px',
    width: 'auto',
    padding: '3px 10px 3px 0',
  },
  infoTable: {
    marginTop: '10px',
    title: {
      width: '20%',
      fontWeight: '600',
    },
    value: {
      width: '50%',
    },

    [constants.breakpointMobile]: {
      fontSize: '0.9em',
    },
  },
  availability: {
    fontSize: '0.8em',
    marginTop: '20px',

    image: {
      width: '35px',
      height: 'auto',
      paddingRight: '7px',
    },
    text: {
      padding: '3px 0',
      position: 'relative',
      top: '-6px',
    },
  },
};

class HotelInformations extends Component {
  render() {
    const {
      tourDetail: { pickedTour },
    } = this.props;

    return (
      <div style={styles.wrapper}>
        {pickedTour && this.renderBasicInfoTable()}

        <Guarantee />
      </div>
    );
  }

  renderBasicInfoTable() {
    const {
      term: { transport, tripTypes, capacities, tripType },
      tourDetail: { title, gps, location },
    } = this.props;
    let gpsFinal = '';

    if (gps.length > 0) {
      gpsFinal = gps[0] + ', ' + gps[1];
    }

    const renderList = [
      {
        title: 'Hotel',
        value: title,
      },
      {
        title: 'GPS hotelu',
        value: gpsFinal,
      },
      {
        title: 'Lokalita',
        value: location,
      },
      {
        title: 'Typ zájezdu',
        value: tripType,
      },
    ];

    return (
      <div style={styles.content}>
        {capacities && capacities.map((icon) => this.renderTravelIcon(icon))}

        <table style={styles.infoTable}>
          <tbody>{renderList.map((info) => this.renderTableRow(info.title, info.value))}</tbody>
        </table>

        {this.renderIcons(tripTypes)}
        {this.renderIcons(transport)}
      </div>
    );
  }

  renderIcons(items) {
    if (!items) {
      return null;
    }

    return items.map((trans) => this.renderIcon(trans));
  }

  renderIcon(transportItem) {
    return (
      <div>
        <img src={transportItem.imgSrc} style={styles.iconType} />
        {transportItem.title}
      </div>
    );
  }

  renderTravelIcon(icon) {
    const { title, imgSrc } = icon;

    return <img src={imgSrc} style={styles.iconAvailability} alt={title} key={title} />;
  }

  renderTableRow(title, value) {
    return (
      <tr key={title}>
        <td style={styles.infoTable.title}>{title}:</td>
        <td style={styles.infoTable.value}>{value}</td>
      </tr>
    );
  }
}

HotelInformations.propTypes = {
  tourDetail: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
});

export default connect(mapStateToProps)(Radium(HotelInformations));
