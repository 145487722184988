import constants from '../../../../../../styles/constants.js';
import formatDateLong from '../../../../../shared/Moment/formatDateLong';
import ReviewForm from './ReviewForm';
import OrangeButton from '../../../../../shared/OrangeButton';
import Radium from 'radium';
import starsBg from '../../../../../../img/tours-detail/reviews/bg-stars.png'; // eslint-disable-line
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const styles = {
  wrapper: {
    width: '92%',
    padding: '0 4%',

    [constants.breakpointTablet]: {
      marginTop: '0',
    },
  },
  blue: {
    color: [constants.mainBlue],
  },
  reviewBox: {
    padding: '20px 0',
    margin: '15px auto 0',
    borderTop: '1px solid #f2f2f2',
    overflow: 'auto',
    maxWidth: '700px',

    time: {
      fontSize: '0.9em',
      marginBottom: '15px',
    },

    ratingWrapper: {
      marginBottom: '15px',
      overflow: 'auto',
    },
  },
  starsWrapper: {
    width: '50%',
    float: 'left',
    margin: '1px 0',
    listStyle: 'none',

    titleBox: {
      width: '90px',
      float: 'left',
      fontSize: '1em',

      [constants.breakpointMobile]: {
        fontSize: '0.9em',
      },
    },

    starsBox: {
      width: '90px',
      float: 'left',

      starsOver: {
        backgroundImage: 'url(' + starsBg + ')',
        backgroundRepeat: 'no-repeat',
        width: '80px',
        height: '16px',
        display: 'inline-block',
      },
      stars: {
        backgroundImage: 'url(' + starsBg + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '0 -16px',
        height: '16px',
        display: 'inline-block',
      },
    },
  },
  newReviewBtnBox: {
    marginTop: '15px',
    padding: '10px 0',
  },
  col: {
    width: '50%',
    float: 'left',

    [constants.breakpointTablet]: {
      width: '100%',
    },
  },
};

class Reviews extends Component {
  state = {
    opened: false,
    toggle: false,
  };

  render() {
    const {
      tourDetail: { reviews },
    } = this.props;
    const { opened, toggle } = this.state;

    return (
      <div style={styles.wrapper}>
        <ReviewForm opened={opened} toggle={toggle} />
        {!reviews.length && this.renderNoReviewMessage()}
        <div onClick={() => this.setState({ opened: !opened })} style={styles.newReviewBtnBox}>
          <OrangeButton
            onClick={() => {
              this.setState(
                toggle ? { opened: true, toggle: false } : { opened: true, toggle: true },
              );
            }}
            key="1"
          >
            Přidat vlastní recenzi
          </OrangeButton>
        </div>

        {reviews.map((item) => this.renderReviewsBox(item))}
      </div>
    );
  }

  renderNoReviewMessage() {
    return <strong style={styles.blue}>Zájezd nemá žádné recenze</strong>;
  }

  renderReviewsBox(item) {
    const {
      date,
      name,
      rating: { accommodationIndex, dietIndex, locationIndex, serviceIndex },
      description,
    } = item;
    const renderRating = [
      {
        title: 'Ubytování',
        value: accommodationIndex,
      },
      {
        title: 'Strava',
        value: dietIndex,
      },
      {
        title: 'Poloha',
        value: locationIndex,
      },
      {
        title: 'Služby',
        value: serviceIndex,
      },
    ];

    return (
      <div style={styles.reviewBox}>
        <div style={styles.reviewBox.time}>Přidáno: {formatDateLong(date)}</div>
        <div style={styles.reviewBox.ratingWrapper}>
          {renderRating.map((rate) => this.renderStarsBox(rate.title, rate.value))}
        </div>
        <div>
          <span>
            <span style={styles.blue}>{name}</span> napsal/a:{' '}
          </span>
          {description}
        </div>
      </div>
    );
  }

  renderStarsBox(title, value) {
    return (
      <li style={styles.starsWrapper}>
        <div style={styles.starsWrapper.titleBox}>{title}:</div>
        <span style={styles.starsWrapper.starsBox}>
          <span style={styles.starsWrapper.starsBox.starsOver}>
            <span style={[styles.starsWrapper.starsBox.stars, { width: '' + value * 20 + '%' }]} />
          </span>
        </span>
      </li>
    );
  }
}

Reviews.propTypes = {
  tourDetail: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
});

export default connect(mapStateToProps)(Radium(Reviews));
