import { getEatingPrice } from "./Summary/rowItems/Eating";
import cookie from "react-cookie";
import { szEmployee } from "../../../actions/HP/defaultTourlist";

export const occupationWithDiscounts = (calculator) => {
  const {
    data: { availableCustomers },
    settings: { occupation }
  } = calculator;
  const usedCustomers = [];

  Object.keys(occupation).map((key) => {
    let baseUsedCount = occupation[key];

    const specialCustomers = availableCustomers.filter((customer) => {
      return customer.baseCustomer === key;
    });

    if (specialCustomers) {
      specialCustomers.forEach((specialCustomer) => {
        if (specialCustomer.enableOnCustomerCount <= occupation[key]) {
          specialCustomer.timesUsed = 1;
          baseUsedCount--;
          usedCustomers.push(specialCustomer);
        }
      });
    }

    const baseCustomer = availableCustomers.find((customer) => {
      return customer.code === key;
    });

    baseCustomer.timesUsed = baseUsedCount;

    usedCustomers.push(baseCustomer);
  });

  return usedCustomers;
};

export const childrenPriceByCustomerIndex = (calculator, index, useGrant) => {
  const {
    data: { availableCustomers },
    settings: { childrenAge, eating },
    data
  } = calculator;
  const children = availableCustomers.filter((customer) => {
    return customer.type === "children";
  });

  let price = 0;
  let discountedPrice = 0;

  const childCustomers = childrenByAge(children, parseInt(childrenAge[index - 1]) || 999).sort(
    (a, b) => {
      return a.enableOnCustomerCount - b.enableOnCustomerCount;
    }
  );

  childCustomers.forEach((customer) => {
    if (
      customer.enableOnCustomerCount <= childrenByAgeUsed(childrenAge, childCustomers[0], index - 1)
    ) {
      price = getPriceDiscounted(customer, useGrant);
    }
  });

  if (eating) {
    price += getEatingPrice(eating, data, "children");
  }

  price += handleAdditionalPrices(calculator, index - 1, "children");

  /* if (useGrant) { OLD way of calculation
     discountedPrice = handleDiscount(price, calculator);
   }*/

  return { price, discountedPrice };
};

const childrenByAgeUsed = (selectedAges, childCustomer, index) => {
  let timeUsed = 0;

  Object.keys(selectedAges).forEach((key) => {
    if (selectedAges[key] < childCustomer.maxAge && key <= index) {
      timeUsed++;
    }
  });

  return timeUsed;
};

export const isGrantUsed = (index, type, grants) => {
  const grant = grants.find((grant) => {
    return grant.index === index && type === grant.type;
  });

  if (!grant) {
    return false;
  }

  return grant.enabled;
};

const childrenByAge = (children, age) => {
  let maxAge = 0;

  children
    .sort((a, b) => {
      return a.enableOnCustomerCount - b.enableOnCustomerCount;
    })
    .forEach((child) => {
      if (child.maxAge > maxAge && child.maxAge <= age + 1) {
        maxAge = child.maxAge;
      }
    });

  return children.filter((child) => {
    return child.maxAge === maxAge;
  });
};

export const adultPriceByCustomerIndex = (calculator, index, useGrant) => {
  const {
    data: { availableCustomers },
    settings: { eating },
    data
  } = calculator;
  let price = 0;
  let discountedPrice = 0;

  const adultCustomers = availableCustomers
    .filter((customer) => {
      return customer.type === "adult";
    })
    .sort((a, b) => {
      return a.enableOnCustomerCount - b.enableOnCustomerCount;
    });

  adultCustomers.forEach((customer) => {
    if (customer.enableOnCustomerCount <= index) {
      price = price = getPriceDiscounted(customer, useGrant);
    }
  });

  if (eating) {
    price += getEatingPrice(eating, data, "adult");
  }

  price += handleAdditionalPrices(calculator, index - 1, "adult");

  /*if (useGrant) {
    discountedPrice = handleDiscount(price, calculator);
  }*/

  return { price, discountedPrice };
};

const handleAdditionalPrices = (calculator, index, type) => {
  const { additionalPrice } = calculator;
  let updatePriceBy = 0;

  const additionalPricesEnabledForUser = additionalPrice.filter((price) => {
    return price.type === type && price.index === index && price.enabled;
  });

  additionalPricesEnabledForUser.forEach((price) => {
    if (price.additionalPriceType === "discount") {
      updatePriceBy -= price.additionalPrice.price;
    } else {
      updatePriceBy += price.additionalPrice.price;
    }
  });

  return updatePriceBy;
};

const getPriceDiscounted = (customer, useDiscount) => {
  const user = cookie.load('user');

  if (!useDiscount) {
    return customer.price;
  }

  return customer.price - (user === szEmployee
    ? customer.szSubsidy
    : customer.subsidy);
};

const handleDiscount = (totalPrice, calculator) => {
  const {
    data: {
      grant: { maxGrant, grantPerDay },
      lengthInDays
    }
  } = calculator;
  let discounted = totalPrice;

  let grant = 0;

  if (totalPrice > 300) {
    grant = grantPerDay * lengthInDays > maxGrant ? maxGrant : grantPerDay * lengthInDays;

    if (grant > totalPrice / 2) {
      grant = totalPrice / 2;
    }
  }

  discounted = discounted - grant;

  return discounted;
};

export const formatCurrency = (price) => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " Kč";
};
