export const SET_RESERVATION_PARAM = 'SET_RESERVATION_PARAM';
export const SET_CUSTOMER_PARAM = 'SET_CUSTOMER_PARAM';

export const setParam = (param, value) => ({
  type: SET_RESERVATION_PARAM,
  param,
  value,
});

export const setCustomerSettings = (param, value, id) => ({
  type: SET_CUSTOMER_PARAM,
  param,
  value,
  id,
});
