import Radium from 'radium';
import Helmet from 'react-helmet';
import cookie from 'react-cookie';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { hashHistory } from 'react-router';
import { zajezdyDetail } from '../../../utils/routeConfig';
import LoadingIndicator from 'react-loading-indicator';
import { Container } from '../../../libs/radium-grid';

import OrangeButton from '../../shared/OrangeButton';
import constants from '../../../styles/constants.js';
import { searchTitle } from '../../../utils/titleConfig'; // eslint-disable-line
import { fetchCMS } from '../../../actions/CMS/fetchCMS';
import formatCurrency from '../../shared/formatCurrency';
import { fetchSearchList, fetching } from '../../../actions/Search/search';
import { fetchTourList, fetchLocations } from '../../../actions/TourList/fetchTourList';

const styles = {
  wrapper: {
    padding: '50px 0 120px 0',
    backgroundImage: 'url("https://s3.eu-central-1.amazonaws.com/cdtravel-2/custom/hp-bg+(1).jpg")',
    minHeight: 'calc(100vh - 360px)',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    overflow: 'auto',
  },
  groupTitle: {
    marginTop: '0',
    paddingBottom: '5px',
    borderBottom: '1px solid #333',
  },
  loader: {
    width: 140,
    marginTop: '3px',
    height: 'auto',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'inline-block',
    padding: '10px 15px 7px 15px',
    borderRadius: '10px',
    background: '#fff',

    text: {
      float: 'right',
      width: 'auto',
      display: 'inline-block',
      paddingTop: '5px',
      paddingLeft: '10px',
      color: '#7f7f7f',
    },
  },
  bottomLoading: {
    clear: 'both',
    paddingTop: '20px',
  },
  halfBox: {
    width: '46%',
    display: 'inline-block',
    margin: '1%',
    padding: '1%',
    float: 'left',
    background: '#fff',

    [constants.breakpoint850]: {
      width: '98%',
      margin: '0 0 3% 0',
    },

    item: {
      padding: '8px 5px',
      borderBottom: '1px solid #f2f2f2',
      overflow: 'auto',
      cursor: 'pointer',

      imageBox: {
        float: 'left',
        display: 'inline-block',
        width: '90px',
        height: '75px',
        overflow: 'hidden',
        position: 'relative',

        image: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          height: '102%',
          width: 'auto',
        },
      },

      descBox: {
        float: 'left',
        display: 'inline-block',
        width: 'calc(100% - 104px)',
        marginLeft: '10px',
        fontSize: '1.1em',
        position: 'relative',
        height: '75px',
        lineHeight: '1.15em',
        textTransform: 'uppercase',

        [constants.breakpointMobile]: {
          fontSize: '0.9em',
          height: '95px',
        },

        title: {
          fontWeight: '600',
          marginBottom: '3px',
          color: [constants.mainBlue],
        },

        bottomLine: {
          position: 'absolute',
          bottom: '0',
          left: '0',
          width: '100%',

          price: {
            float: 'right',
            display: 'inline-block',
            width: '35%',
            color: [constants.mainOrange],
            fontWeight: '600',
            textAlign: 'right',
            textTransform: 'capitalize',

            [constants.breakpointMobile]: {
              width: '40%',
            },
          },

          term: {
            float: 'left',
            display: 'inline-block',
            width: '65%',

            [constants.breakpointMobile]: {
              width: '60%',
            },
          },
        },
      },

      [':hover']: {
        background: '#f6f6f6',
        borderBottom: '1px solid #fff',
      },
    },
  },
  loading: {
    display: 'block',
    width: '200px',
    margin: '0 auto',
    justifyContent: 'center',
    marginTop: 120,
  },
  moreButton: {
    background: [constants.orange],
    padding: '8px 0',
    textAlign: 'center',
    marginTop: '2%',
    transition: '0.3s',
    cursor: 'pointer',
    color: '#fff',

    ':hover': {
      background: [constants.mainOrangeDark],
    },
  },
  searchWrapper: {
    width: '350px',
    margin: '0 auto 30px auto',

    [constants.breakpointMobile]: {
      width: '300px',
      width: '300px',
    },
  },
  searchLabel: {
    fontSize: '1.2em',
    fontWeight: 'bold',
  },
  input: {
    padding: '5px 10px 6px 10px',
    fontSize: '1.2em',
    width: 'calc(100% - 135px)',
    marginRight: '10px',
    border: '1px solid #ccc',
  },
};

const groupsTranslations = {
  country: 'Země',
  hotel: 'Hotel',
  location: 'Oblast',
};

class Search extends Component {
  componentDidMount() {
    this.init();
  }

  render() {
    const { searchTerm } = this.state;

    return (
      <div>
        <Helmet title={searchTitle} />
        <div style={styles.wrapper} className="search">
          <Container>
            <div style={styles.searchWrapper}>
              <input
                style={styles.input}
                placeholder="Hledaný výraz"
                onChange={(event) => {
                  this.save(event);
                }}
                value={searchTerm}
              />

              <OrangeButton onClick={() => this.handleFetch(searchTerm)} key="1">
                Vyhledat
              </OrangeButton>
            </div>

            {this.renderSearch()}
          </Container>
        </div>
      </div>
    );
  }

  renderSearch() {
    const {
      search: { items, state },
    } = this.props;

    if (!items) {
      <div>{state === fetching && this.loader()}</div>;
    }

    return (
      <div>
        {Object.keys(items).map((key) => this.renderSearchGroup(key))}

        <div style={styles.bottomLoading}>{state === fetching && this.loader()}</div>
      </div>
    );
  }

  renderSearchGroup(key) {
    const {
      search: { items },
    } = this.props;
    const groupItems = items[key];

    if (!Array.isArray(groupItems)) {
      return null;
    }

    return (
      <div key={'SearchGroup-' + key} style={styles.halfBox}>
        <h3 style={styles.groupTitle}>{groupsTranslations[key]}</h3>
        {groupItems.length ? (
          this.renderItems(groupItems, key, items.nextIndex[key])
        ) : (
          <span style={styles.notFound}>Nebyly nalezeny žádné výsledky</span>
        )}
      </div>
    );
  }

  renderItems(groupItems, key, nextIndex) {
    const { searchTerm } = this.state;

    return (
      <div className="items-wrapper">
        {groupItems.map((item) => this.renderSearchItem(item, key))}
        {nextIndex > 0 && (
          <div style={styles.moreButton} onClick={() => this.handleFetch(searchTerm)}>
            Načíst další zájezdy
          </div>
        )}
      </div>
    );
  }

  renderSearchItem(tour, key) {
    const { hotel, id, imgSrc, country, price, termFrom, termTo, location } = tour;

    return (
      <div
        onClick={() => this.redirect(id)}
        className="item"
        style={styles.halfBox.item}
        key={hotel + key + Math.random().toString(36)}
      >
        <div style={styles.halfBox.item.imageBox}>
          <img style={styles.halfBox.item.imageBox.image} src={imgSrc} />
        </div>

        <div style={styles.halfBox.item.descBox}>
          <div style={styles.halfBox.item.descBox.title}>
            {country}, <span>{location}</span>
          </div>

          <div style={styles.hotel}>{hotel}</div>

          <div style={styles.halfBox.item.descBox.bottomLine}>
            <div style={styles.halfBox.item.descBox.bottomLine.term}>
              {termFrom} - {termTo}
            </div>

            <div style={styles.halfBox.item.descBox.bottomLine.price}>{formatCurrency(price)}</div>
          </div>
        </div>
      </div>
    );
  }

  redirect(idTour) {
    hashHistory.push(zajezdyDetail + idTour);
    window.location.reload();
  }

  loader() {
    return (
      <div>
        <div style={styles.loader}>
          <LoadingIndicator segmentWidth="3" />
          <div style={styles.loader.text}> Načítám zájezdy</div>
        </div>
      </div>
    );
  }

  init() {
    const {
      search: { searchTerm },
    } = this.props;

    if (searchTerm.length > 2) {
      this.setState({ searchTerm });
      this.handleFetch(searchTerm);
    }
  }

  save(event) {
    const searchTerm = event.target.value;

    if (searchTerm.length > 2) {
      this.handleFetch(searchTerm);
    }

    this.setState({ searchTerm });
  }

  handleFetch(searchTerm) {
    const {
      fetchSearchList,
      search: {
        items: { nextIndex },
      },
    } = this.props;

    const searchParams = {
      user: cookie.load('user'),
      word: searchTerm,
      count: {
        country: nextIndex && nextIndex.country > 0 ? nextIndex.country : 0,
        hotel: nextIndex && nextIndex.hotel > 0 ? nextIndex.hotel : 0,
        location: nextIndex && nextIndex.location > 0 ? nextIndex.location : 0,
      },
    };

    fetchSearchList(searchParams);
  }
}

Search.propTypes = {
  fetchCMS: PropTypes.any,
  fetchSearchList: PropTypes.any,
  search: PropTypes.any,
  fetchState: PropTypes.any,
  saveSearchTerm: PropTypes.any,
  saveRequestSearchTerm: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourList: state.tourList,
  search: state.search,
  fetchState: state.fetchState,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSearchList: (searchParams) => dispatch(fetchSearchList(searchParams)),
  fetchLocations: () => dispatch(fetchLocations()),
  fetchCMS: () => dispatch(fetchCMS()),
  fetchTourList: () => dispatch(fetchTourList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(Search));
