import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addTourForComparement } from '../../../actions/TourDetail/fetchTourDetail';
import LoaderInModalWindow from '../../shared/Loading/LoaderInModalWindow';
import OrangeButton from '../../shared/OrangeButton';
import { hashHistory } from 'react-router';
import formatDateShort from '../../shared/Moment/formatDateShort';
import formatDateLong from '../../shared/Moment/formatDateLong';
import formatCurrency from '../../shared/formatCurrency';

import './Comparement.scss';

class Comparement extends Component {
  state = {
    allToursAreFetched: false,
  };

  componentDidMount() {
    const {
      params: { r },
    } = this.props;

    if (!r) {
      hashHistory.push('/porovnani/1');
      window.location.reload();
    }

    this.checkLoadedTourDetails();
    this.loadAllTourDetails();
  }

  componentWillReceiveProps(props) {
    this.checkLoadedTourDetails(props);
    this.loadAllTourDetails(props);
  }

  loadAllTourDetails(props = this.props) {
    const {
      tourComparement: { tourDetails, tours },
      addTourForComparement,
    } = props;

    tours.forEach((tour) => {
      let tourDetailExists = false;

      tourDetails.forEach((tourDetail) => {
        if (tour.idTour === tourDetail.id) {
          tourDetailExists = true;
        }
      });

      if (!tourDetailExists) {
        addTourForComparement(tour.idTour);
      }
    });
  }

  checkLoadedTourDetails(props = this.props) {
    const {
      tourComparement: { tourDetails, tours },
    } = props;
    let tourDetailsLoaded = true;

    tours.forEach((tour) => {
      let tourDetailExists = false;

      tourDetails.forEach((tourDetail) => {
        if (tour.idTour === tourDetail.id) {
          tourDetailExists = true;
        }
      });

      if (!tourDetailExists) {
        tourDetailsLoaded = false;
      }
    });

    this.setState({ allToursAreFetched: tourDetailsLoaded });
  }

  render() {
    const {
      tourComparement: { tourDetails },
    } = this.props;
    const { allToursAreFetched } = this.state;

    if (!allToursAreFetched) {
      return <LoaderInModalWindow />;
    }

    return (
      <div className="comparement-wrapper">
        <h1 className="comparement-page-title">Porovnání zájezdů</h1>

        <div className="comparement-table-scrollbox">
          <table className="comparement-table">
            <tbody>
              <tr>
                <td className="comparement-table-title">název</td>
                {tourDetails.map((tour) => this.renderHeaderLink(tour))}
              </tr>
              <tr>
                <td className="comparement-table-title">cena</td>
                {tourDetails.map((tour) => {
                  const price = tour.isSoldOut ? 'Vyprodáno' : formatCurrency(tour.price);
                  return this.renderComparementRow(price);
                })}
              </tr>
              <tr>
                <td className="comparement-table-title">datum</td>
                {tourDetails.map((tour) => this.renderDates(tour))}
              </tr>
              <tr>
                <td className="comparement-table-title">lastminute</td>
                {tourDetails.map((tour) => this.renderComparementRowBoolean(tour.isLastMinute))}
              </tr>
              <tr>
                <td className="comparement-table-title">doprava</td>
                {tourDetails.map((tour) => this.renderTransport(tour.transport))}
              </tr>
              <tr>
                <td className="comparement-table-title">Kapacity</td>
                {tourDetails.map((tour) => this.renderIcons(tour.capacities))}
              </tr>
              <tr>
                <td className="comparement-table-title">Strava</td>
                {tourDetails.map((tour) => this.renderFood(tour.foodDetail))}
              </tr>
              <tr>
                <td className="comparement-table-title">Poznámky</td>
                {tourDetails.map((tour) => this.renderTourNotes(tour.tourNotes))}
              </tr>
              <tr>
                <td className="comparement-table-title">pokoje</td>
                {tourDetails.map((tour) => this.renderRooms(tour))}
              </tr>
            </tbody>
          </table>
        </div>

        <OrangeButton link={'/zajezdy/vypis'}>Zpět na zájezdy</OrangeButton>
      </div>
    );
  }

  renderTransport(transport) {
    return (
      <td className="comparement-column">
        <div className="comparement-icon-wrapper">{this.getFormattedTransportText(transport)}</div>
      </td>
    );
  }

  getFormattedTransportText(transport) {
    let formattedText = '';
    transport.forEach((trans, i) => {
      if (transport.length - 1 !== i) {
        formattedText += trans.title + ', ';
      } else {
        formattedText += trans.title;
      }
    });

    return formattedText;
  }

  renderDates(tour) {
    const { dateFrom, dateTo } = tour;

    return (
      <td className="comparement-column">
        {formatDateShort(dateFrom) + ' - ' + formatDateLong(dateTo)}
      </td>
    );
  }

  renderTourNotes(notes) {
    return <td className="comparement-column">{notes.map((note) => this.renderNote(note))}</td>;
  }

  renderNote(note) {
    return <div>{note.description}</div>;
  }

  renderFood(foodDetail) {
    return (
      <td className="comparement-column">
        {foodDetail
          .map((food) => {
            if (food.type === 'children') {
              return null;
            }

            return food.description;
          })
          .filter((x) => x != null)}
      </td>
    );
  }

  renderIcons(items) {
    return (
      <td className="comparement-column">
        <div className="comparement-icon-wrapper">
          {items.map((trans) => this.renderIcon(trans))}
        </div>
      </td>
    );
  }

  renderIcon(transportItem) {
    return (
      <div>
        <img src={transportItem.imgSrc} className="comparement-icon" />
      </div>
    );
  }

  renderRooms(tour) {
    const {
      occupation: { tours, headers },
      pickedTour,
    } = tour;

    const pickedTourOccupation = tours
      .map((tour) => {
        if (tour.tourId === pickedTour) {
          return tour;
        }

        return null;
      })
      .filter((x) => x != null)
      .shift();

    return (
      <td className="comparement-column">
        {pickedTourOccupation.codes.occupied.map((occupied, index) =>
          this.renderSigleOccupation(occupied, headers, index),
        )}
      </td>
    );
  }

  renderSigleOccupation(occupied, headers, index) {
    const value = !occupied ? 'check' : 'close';

    return (
      <div className="comparement-occupation">
        <div>{headers[index]} </div>
      </div>
    );
  }

  renderComparementRowBoolean(parameter) {
    const value = parameter ? 'check' : 'close';

    return (
      <td className="comparement-column">
        <i className={'material-icons ' + value}>{value}</i>
      </td>
    );
  }

  renderHeaderLink(tour, additionalClass = '') {
    return (
      <td className="comparement-column">
        <a href={`#/zajezdy/detail/${tour.id}`} className="comparement-column-header">
          {tour.title}
        </a>
      </td>
    );
  }

  renderComparementRow(parameter, additionalClass = '') {
    return <td className={'comparement-column ' + additionalClass}>{parameter}</td>;
  }
}

const mapStateToProps = (state) => ({
  tourComparement: state.tourComparement,
});

const mapDispatchToProps = (dispatch) => ({
  addTourForComparement: (idTour) => dispatch(addTourForComparement(idTour)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Comparement);
