import OrangeButton from '../shared/OrangeButton.jsx';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { close, fetchAdvertising } from '../../actions/Advertising/fetchAdvertising';
import { fetchFilterSettings } from '../../actions/TourList/filterSettings';
import { connect } from 'react-redux';
import cookie from 'react-cookie';

const customModalStyles = {
  overlay: {
    position: 'fixed',
    zIndex: '9999',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0, 0.75)',
  },
  content: {
    width: '600px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

class AdvertisingMessage extends Component {
  componentDidMount() {
    const { fetchAdvertising, fetchFilterSettings } = this.props;

    fetchAdvertising();
    fetchFilterSettings();
  }

  render() {
    const {
      advertising: {
        advertisingMessage: { title, text },
        closed,
      },
    } = this.props;
    const cookieLoaded = cookie.load('adMessageOpened');

    return (
      <Modal
        isOpen={!closed && title && (!cookieLoaded || typeof cookieLoaded === 'undefined')}
        style={customModalStyles}
      >
        <b> {title}</b>
        <p dangerouslySetInnerHTML={{ __html: text }} />
        <OrangeButton type="whiteText" onClick={() => this.handleForm()}>
          {' '}
          Zavřít{' '}
        </OrangeButton>
      </Modal>
    );
  }

  handleForm() {
    const { close } = this.props;
    cookie.save('adMessageOpened', false, { maxAge: 60 * 60 * 24 * 3 });
    close();
  }
}

AdvertisingMessage.propTypes = {
  fetchAdvertising: PropTypes.any,
  advertising: PropTypes.any,
  fetchFilterSettings: PropTypes.any,
  close: PropTypes.any,
};

const mapStateToProps = (state) => ({
  advertising: state.advertising,
});

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(close()),
  fetchAdvertising: () => dispatch(fetchAdvertising()),
  fetchFilterSettings: () => dispatch(fetchFilterSettings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvertisingMessage);
