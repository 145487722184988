import crossImg from '../../../../../../img/icons/cancel.png'; // eslint-disable-line
import checkImg from '../../../../../../img/icons/checked-symbol.png'; // eslint-disable-line

import ReactTooltip from 'react-tooltip';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OccupationLegend from './OccupationLegend';
import constants from '../../../../../../styles/constants';
import { connect } from 'react-redux';

const styles = {
  wrapper: {
    clear: 'both',
    paddingBottom: '15px',
    width: '92%',
    padding: '0 4%',

    [constants.breakpointTablet]: {
      marginTop: '0',
    },
  },
  leftAlign: {
    textAlign: 'left',
  },
  rightAlign: {
    textAlign: 'right',
  },
  sectionTitle: {
    marginTop: '30px',
    marginBottom: '40px',
    textTransform: 'uppercase',
    lineHeight: '23px',
    fontWeight: '700',
    padding: '1px 5px 1px 4px',
    color: [constants.yellow],
    display: 'inline-block',
    fontSize: '1.1em',
    borderBottom: '2px solid #ededed',
    width: '100%',
  },
  table: {
    borderCollapse: 'collapse',
    fontSize: '1em',
    maxWidth: '1000px',
    margin: '20px auto 20px',
    width: '100%',

    [constants.breakpointMobile]: {
      fontSize: '0.8em',
    },
    tbody: {
      backgroundColor: 'white',
    },
    head: {
      textAlign: 'left',
      backgroundColor: 'transparent',
      fontWeight: 600,
    },
    centered: {
      textAlign: 'center',
    },
    line: {
      width: '780',
      borderBottom: '1px solid rgba(194, 194, 194, .17)',
    },
    infoBottom: {
      marginTop: '5px',
      width: '700px',
      margin: '0 auto',
    },
    infoBottomWrapper: {
      marginTop: '20px',
    },
  },
  tourNotices: {
    padding: ' 0 10px',
  },
  loading: {
    display: 'block',
    width: '200px',
    margin: '0 auto',
    justifyContent: 'center',
    marginTop: 120,
  },
  hotelTitle: {
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '1.5em',
    marginTop: '40px',
  },
  chooseHotel: {
    textAlign: 'center',
    fontWeight: '600',
    marginTop: '100px',
    fontSize: '1.2em',
  },
  statusIcon: {
    width: '13px',
  },
};

class Occupation extends Component {
  render() {
    const {
      tourDetail: {
        occupation: { tours, headerCodes, headers },
      },
    } = this.props;

    return (
      <div style={styles.wrapper} className="cms-page">
        <OccupationLegend />
        <div className="occupation">
          <table style={styles.table}>
            <tbody style={styles.table.tbody}>
              <tr style={styles.table.head}>
                <th>poznámka</th>
                <th style={styles.table.centered}>termín</th>
                {headerCodes.map((headerCode, i) => this.renderHeader(headerCode, i, headers))}
              </tr>
              {tours.map((tour) => this.renderRow(tour, headerCodes))}
            </tbody>
          </table>
        </div>
        <ReactTooltip html={Boolean(true)} />
      </div>
    );
  }

  renderHeader(headerCode, i, headers) {
    return (
      <th data-tip={headers[i]} style={styles.table.centered}>
        {headerCode}
      </th>
    );
  }

  renderRow(tour, headerCodes) {
    const {
      isSoldOut,
      notes,
      termFrom,
      termTo,
      codes: { code, occupied },
    } = tour;

    return (
      <tr>
        <th style={styles.table.centered}>{notes}</th>
        <th style={styles.table.centered}>{termFrom + ' - ' + termTo}</th>
        {headerCodes.map((headerCode, i) =>
          this.renderTableColumns(headerCode, i, code, occupied, isSoldOut),
        )}
      </tr>
    );
  }

  renderTableColumns(headerCode, i, codes, occupied, isSoldOut) {
    if (codes.includes(headerCode) && !occupied[codes.indexOf(headerCode)]) {
      return (
        <th style={styles.table.centered}>
          <img src={checkImg} style={styles.statusIcon} />
        </th>
      );
    }

    return (
      <th style={styles.table.centered}>
        <img src={crossImg} style={styles.statusIcon} />
      </th>
    );
  }
}

Occupation.propTypes = {
  fetchState: PropTypes.any,
  tourDetail: PropTypes.any,
  fetchOccupationHotels: PropTypes.any,
  fetchLocations: PropTypes.any,
  fetchTourFilter: PropTypes.any,
  resetAllFilters: PropTypes.any,
  occupationHotelState: PropTypes.any,
  occupation: PropTypes.any,
  closeTabs: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
});

export default connect(mapStateToProps)(Occupation);
