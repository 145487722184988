import OrangeButton from '../../../../shared/OrangeButton';
import styles from '../../../../shared/forms/modalFormStyles/modalFormStyles';
import constants from '../../../../../styles/constants.js';
import crosLineImg from '../../../../../img/crossLine.png'; // eslint-disable-line
import Dialog from 'material-ui/Dialog';
import { employee } from '../../../../../actions/HP/defaultTourlist';
import { generatePDF } from '../../../../../actions/PDF/generatePDF';
import PickerPriceList, { pickerPrintTable } from './PickerPriceList';
import getPDFreadyPriceList from '../../../../shared/TourDetail/getPdfReadyPriceList';
import tourPriceListTemplate from '../../../../shared/TourDetail/tourPriceListTemplate';
import { pickTour } from '../../../../../actions/TourDetail/tourDetail';
import { printSeparatedPriceList } from '../../../../../utils/routeConfig';
import base64 from 'base-64';
import utf8 from 'utf8';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import cookie from 'react-cookie';

const customStyles = {
  buttonMarged: {
    margin: '20px 10px 0px 10px',

    [constants.breakpointMobile]: {
      margin: '10px',
    },
  },
  mobileHide: {
    [constants.breakpoint600]: {
      display: 'none',
    },
  },
  closeModal: {
    padding: 0,
    margin: 0,
  },

  wrapper: {
    clear: 'both',
    width: '99%',
    maxWidth: '600px',
    marginTop: '10px',
  },
  table: {
    borderCollapse: 'collapse',
    fontSize: '1em',
    margin: '0 auto',
    marginTop: '5px',
    width: '100%',

    [constants.breakpointMobile]: {
      fontSize: '0.8em',
    },
    tbody: {
      backgroundColor: 'white',
      color: [constants.mainBlue],
    },
    head: {
      textAlign: 'left',
      backgroundColor: [constants.mainBlue],
      color: '#fff',
    },
    subsidyHead: {
      textAlign: 'left',
      backgroundColor: [constants.green],
      color: '#fff',
      fontWeight: 'bold',
      paddingBottom: '10px',
    },
    subsidyTitleHead: {
      color: 'white',
      backgroundColor: [constants.green],
      fontSize: '1.1em',
      fontWeight: '500',
      position: 'fixed',
      boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
      top: '30px',
      left: '-10',
      zIndex: 999,
      textAlign: 'center',
      padding: '20px',
    },
    subsidyTitle: {
      fontSize: '13px',
      textTransform: 'uppercase',
      color: 'black',
    },
    line: {
      width: '780',
    },
    description: {},
    priceFor: {
      textAlign: 'right',
      minWidth: '60px',
    },
    crossLine: {
      backgroundImage: `url(${crosLineImg})`,
      backgroundPositionY: '50%',
      backgroundRepeat: 'repeat-x',
      color: '#999',
    },
    price: {
      textAlign: 'right',
      minWidth: '80px',
    },
    tourPrice: {
      fontWeight: 'bold',
      color: [constants.mainOrange],
    },
    tagWrapper: {
      position: 'absolute',
      marginTop: -2,
    },
    tagLastMinuteWapper: {
      width: 87,
      position: 'relative',
      right: 10,
    },
    tagSpecialOfferWapper: {
      width: 115,
      position: 'relative',
      right: 25,
    },
    tagBothWrapper: {
      width: 200,
      position: 'relative',
      right: 65,
    },
    tagLastMinute: {
      position: 'relative',
      transform: 'rotate(-3deg)',
      display: 'inline-block',
      margin: 1,
      marginRight: 5,
    },
    tagSpecialOffer: {
      position: 'relative',
      transform: 'rotate(-3deg)',
      display: 'inline-block',
    },
    infoBottom: {
      marginTop: '5px',
      width: '700px',
      margin: '0 auto',
    },
    infoBottomWrapper: {
      marginTop: '20px',
    },
  },
  floatLeft: {
    float: 'left',
    marginRight: 10,
    marginBottom: 10,
  },
  stick: {
    padding: '3px 6px',
    fontSize: '0.8em',
    color: '#fff',
    display: 'inline-block',

    lastMinute: {
      background: '#e21a22',
      marginBottom: '1px',
    },
    soldOut: {
      background: '#000',
      marginBottom: '1px',
    },

    specialOffer: {
      background: [constants.green],
    },
  },
};

class TourPicker extends Component {
  componentDidMount() {
    this.props.openByDefault && this.handlePickerPriceListClose(true);
  }

  render() {
    const {
      tourDetail: { pickedTour, id },
      defaultState,
      withoutAction,
      invertedShow,
      extraStyle,
    } = this.props;
    const { opened } = this.state;
    const show = invertedShow ? !pickedTour : pickedTour;

    if (defaultState && !opened) {
      this.setState({ opened: true });
    }

    return (
      <div style={!invertedShow ? [customStyles.floatLeft, extraStyle] : [customStyles.extraStyle]}>
        {show && !withoutAction && this.renderOpenButton(invertedShow, extraStyle)}

        {this.renderForm(id)}
      </div>
    );
  }

  renderOpenButton(invertedShow, extraStyle) {
    return (
      <OrangeButton onClick={() => this.setState({ opened: true })} extraStyle={extraStyle}>
        {invertedShow ? 'Termíny a ceny' : 'Vybrat jiný termín'}
      </OrangeButton>
    );
  }

  handlePickerPriceListClose(value) {
    this.setState({ opened: value });
  }

  handleOpenAlert(alertOpened) {
    this.setState({ alertOpened });
  }

  renderForm(id) {
    const { opened, status } = this.state;

    return (
      <Dialog
        actions={this.getActions(id)}
        modal={false}
        title="Vybrat termín"
        onRequestClose={() => this.close()}
        open={opened}
        autoScrollBodyContent="true"
        titleStyle={{ borderBottom: 'none' }}
        footerStyle={{ background: 'red' }}
        bodyStyle={{
          borderBottom: '3px solid #fff',
          bottom: '-1px',
          position: 'relative',
          paddingTop: '10px',
        }}
        contentClassName="dialog-box"
        actionsContainerClassName="actions-box"
        bodyClassName="content-box"
        titleClassName="title-box"
      >
        <div style={styles.formWrapper}>
          <p style={styles.status}>{status}</p>
          <p>Klikem vyberte termín</p>
          <PickerPriceList
            dataFetched={Boolean(true)}
            id="printed"
            onClick={this.handlePickerPriceListClose.bind(this)}
          />
        </div>
      </Dialog>
    );
  }

  generatePDF(html) {
    const { headersLength } = this.state;
    const {
      generatePDF,
      tourDetail: { hotelTitle, country },
    } = this.props;
    const user = cookie.load('user');

    const header =
      '<p style="padding-top: 15px; font-size: 14px; font-weight: bold">' +
      hotelTitle +
      ', ' +
      country +
      (user === employee
        ? ' | <span style="text-transform: uppercase; color: #afc836">dotované ceny</span>'
        : '');
    ('</p>');

    const pdf = tourPriceListTemplate
      .replace('{content}', html.outerHTML)
      .replace('{header}', header);

    const bytes = utf8.encode(pdf);

    const encoded = base64.encode(bytes);

    const requestData = { base64Encoded: encoded };
    const name = 'Cenik_' + hotelTitle.replace(/ /g, '_').replace(/\*/g, '');

    generatePDF(requestData, name.endsWith('_') ? name.substr(0, name.length - 1) : name);
  }

  getActions(id) {
    return (
      <div>
        <OrangeButton
          onClick={() => this.generatePDF(document.getElementById(pickerPrintTable))}
          target="_blank"
          extraStyle={styles.buttonMarged}
          icon="picture_as_pdf"
        >
          Vygenerovat PDF
        </OrangeButton>

        <OrangeButton
          onClick={() => this.close()}
          submit="submit"
          extraStyle={styles.buttonMarged}
          type="inversion"
          icon="close"
        >
          Zavřít
        </OrangeButton>
      </div>
    );
  }

  close() {
    this.setState({ opened: false });
  }
}

TourPicker.propTypes = {
  tourDetail: PropTypes.any,
  generatePDF: PropTypes.any,
  postNewBooking: PropTypes.any,
  pickTour: PropTypes.any,
  withoutAction: PropTypes.any,
  invertedShow: PropTypes.any,
  openByDefault: PropTypes.any,
  onClick: PropTypes.any,
  extraStyle: PropTypes.any,
  defaultState: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
  postFormStatus: state.postFormStatus,
});

const mapDispatchToProps = (dispatch) => ({
  pickTour: (idTour) => dispatch(pickTour(idTour)),
  generatePDF: (params, name) => dispatch(generatePDF(params, name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(TourPicker));
