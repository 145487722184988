import { combineReducers } from 'redux';
import tourDetail from './tourDetail';
import tourFilter from './tourFilter';
import paginator from './paginator';
import togetherTravelingFormStatus from './togetherTravelingFormStatus';
import weather from './weather';
import HPTile from './HPTile';
import reservation from './reservation';
import autocomplete from './autocomplete';
import header from './header';
import search from './search';
import calculator from './calculator';
import employeeInstructions from './employeeInstructions';
import error from './error';
import termList from './termList';
import tourDetailFilterOpen from './tourDetailFilterOpen';
import CMS from './CMS';
import contacts from './contacts';
import selectedLocations from './selectedLocations';
import fetchState from './fetchState';
import filteredTours from './filteredTours';
import advertising from './advertising';
import selectedHotels from './selectedHotels';
import defaultTourListFilter from './defaultTourListFilter';
import postFormStatus from './postFormStatus';
import filterSettings from './filterSettings';
import searchedTourList from './searchedTourList';
import similarTours from './similarTours';
import specialTourList from './specialTourList';
import tourList from './tourList';
import filter from './filter';
import filterOpen from './filterOpen';
import occupation from './occupation';
import passengers from './passengers.js';
import occupationHotelState from './occupationHotelState';
import passengersFilter from './passengersFilter.js';
import passengerOptions from './passengerOptions';
import tourDetailReview from './tourDetailReview';
import visibilityFilter from './visibilityFilter';
import tourComparement from './tourComparement';
import pdfDownload from './pdfDownload';
import hotelsMap from './hotelsMap';
import catalogue from './catalogue';

const webApp = combineReducers({
  CMS,
  HPTile,
  reservation,
  pdfDownload,
  error,
  autocomplete,
  calculator,
  catalogue,
  employeeInstructions,
  search,
  togetherTravelingFormStatus,
  searchedTourList,
  selectedHotels,
  filteredTours,
  advertising,
  termList,
  occupation,
  similarTours,
  contacts,
  header,
  fetchState,
  occupationHotelState,
  specialTourList,
  weather,
  tourDetailFilterOpen,
  visibilityFilter,
  defaultTourListFilter,
  tourDetail,
  postFormStatus,
  filterSettings,
  tourFilter,
  tourList,
  filter,
  filterOpen,
  selectedLocations,
  paginator,
  passengers,
  passengersFilter,
  passengerOptions,
  tourDetailReview,
  hotelsMap,
  tourComparement,
});

export default webApp;
