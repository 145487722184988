import PDF from 'react-pdf-js';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import StyledCircleLoader from '../../shared/Loading/StyledCircleLoader';
import tourRenderCatalogueTemplate from '../../shared/TourDetail/tourPdfCatalogueTemplate';
import ReactDOMServer from 'react-dom/server';
import { renderPriceList } from '../../shared/TourDetail/renderPriceList';
import { getPDFContent, renderPDFHeaderIcons } from '../../shared/TourDetail/pdfHeaderUtils';
import ReactTooltip from 'react-tooltip';
import Slider from 'react-slick';

import './PdfList.scss';

class PdfItem extends Component {
  state = {
    zoom: 100,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { render } = this.state;

    if (!render) {
      this.setState({ render: true });
    }
  }

  render() {
    const {
      pdfData: {
        id,
        pdfProps: { strippedInstructions },
      },
    } = this.props;
    const { zoom, render } = this.state;
    const pdfHtml = this.generatePDFWithTemplate();

    return (
      <div>
        <div style={{ zoom: zoom + '%' }}>
          <div className="hotel-pdf-description" id={`hotel-pdf-description-${id}`} />

          <div id="render-pdf" dangerouslySetInnerHTML={{ __html: pdfHtml }} />
        </div>
      </div>
    );
  }

  generatePDFWithTemplate() {
    const {
      pdfData: {
        id,
        hotelTitle,
        guarantee: { name, mobile },
        country,
        photos,
        capacities,
        transport,
        tripTypes,
        pdfProps: { strippedInstructions, croppedImage },
        terms,
      },
    } = this.props;
    const { pdfHtml, render } = this.state;

    if (!render || !photos) {
      return null;
    }

    document.getElementById(`hotel-pdf-description-${id}`).innerHTML = strippedInstructions;

    const headerIcons = renderPDFHeaderIcons(capacities, transport, tripTypes);
    const content = getPDFContent(photos, strippedInstructions, `hotel-pdf-description-${id}`);
    const priceList = ReactDOMServer.renderToString(renderPriceList(terms, hotelTitle));

    const pdf = tourRenderCatalogueTemplate
      .replace('{country}', country)
      .replace('{hotel}', hotelTitle)
      .replace('{mainImage}', croppedImage ? croppedImage : photos[0] ? photos[0].imgSrc : '')
      .replace('{availabilityIcons}', headerIcons)
      .replace('{guaranteeName}', name)
      .replace('{guaranteePhone}', mobile)
      .replace('{content}', content)
      .replace('{priceList}', priceList);

    return pdf;
  }
}

const mapStateToProps = (state) => ({
  catalogue: state.catalogue,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PdfItem);
