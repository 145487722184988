import constants from '../../../styles/constants.js';
import crossImg from '../../../img/icons/cancel.png'; // eslint-disable-line
import checkImg from '../../../img/icons/checked-symbol.png'; // eslint-disable-line
import React, { Component } from 'react';
import Radium from 'radium';

const styles = {
  wrapper: {
    width: '100%',
    maxWidth: '700px',
    margin: '30px auto 0 auto',

    [constants.breakpointTablet]: {
      margin: '70px auto 0 auto',
      position: 'relative',
      top: '150px',
    },
  },
  iconBox: {
    width: '50%',
    float: 'left',
    textAlign: 'center',
    display: 'inline-block',

    icon: {
      width: '24px',
      marginRight: '10px',
      position: 'relative',
      top: '4px',
    },

    text: {
      color: '#fff',
      fontSize: '1.1em',
    },
  },
};

class OccupationLegend extends Component {
  render() {
    return (
      <div style={styles.wrapper}>
        <div style={styles.iconBox}>
          <img src={checkImg} style={styles.iconBox.icon} />
          <span style={styles.iconBox.text}>Volno</span>
        </div>
        <div style={styles.iconBox}>
          <img src={crossImg} style={styles.iconBox.icon} />
          <span style={styles.iconBox.text}>Obsazeno</span>
        </div>
      </div>
    );
  }
}

export default Radium(OccupationLegend);
