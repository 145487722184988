import {
  SET_PASSENGERS,
  SET_SORTED_PASSENGERS,
} from '../actions/TogetherTraveling/fetchPassengers';
const initialState = [
  {
    id: 'empty',
    added: '',
    sex: '',
    country: '',
    age: '',
    note: '',
    dateFrom: '',
    dateTo: '',
  },
];

const tourDetail = (state = initialState, action) => {
  switch (action.type) {
    case SET_PASSENGERS:
      return action.result;

    case SET_SORTED_PASSENGERS:
      if (action.passengers.length === state.length) {
        return action.passengers;
      }
      return state;

    default:
      return state;
  }
};

export default tourDetail;
