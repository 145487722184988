import { SET_HEADER } from '../actions/Header/fetchHeader';
const initialState = {};

const tourDetail = (state = initialState, action) => {
  switch (action.type) {
    case SET_HEADER:
      return action.result;

    default:
      return state;
  }
};

export default tourDetail;
