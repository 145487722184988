import { SET_SPECIAL_LIST, SAVE_SPECIAL_FETCHING } from '../actions/TourList/fetchTourList';
import { loading } from './filteredTours';
const initialState = {};

const filter = (state = initialState, action) => {
  switch (action.type) {
    case SET_SPECIAL_LIST:
      return action.result;

    case SAVE_SPECIAL_FETCHING:
      return { ...state, dataLoading: loading };

    default:
      return state;
  }
};

export default filter;
