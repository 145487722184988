import { SAVE_CONTACTS } from '../actions/Contacts/fetchContacts';

const initialState = {};

const filter = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CONTACTS:
      return action.result;

    default:
      return state;
  }
};

export default filter;
