import { postJSON } from '../../utils/fetch';
import FileSaver from 'file-saver';
import base64 from 'base-64';
import utf8 from 'utf8';
export const GENERATING_PDF = 'GENERATING_PDF';

export const generatePdfWithCatch = (formatFunc, paramObject) => {
  const { pdf, saveName } = formatFunc(paramObject);
  const {
    pdfProps: { strippedInstructions },
  } = paramObject;

  let attempts = 0;
  let fontSize = 13;

  let call = function (dispatch, html) {
    const bytes = utf8.encode(html);
    const encoded = base64.encode(bytes);

    const requestData = {
      base64Encoded: encoded,
    };

    postJSON('post-generate-pdf/', requestData)
      .then((result) => {
        const blob = b64toBlob(result.response, 'data:application/pdf');
        FileSaver.saveAs(blob, saveName + '.pdf');

        dispatch({
          type: GENERATING_PDF,
          generating: false,
        });
      })
      .catch((error) => {
        if (attempts < 5) {
          attempts++;
          fontSize--;

          const { pdf } = formatFunc(paramObject, fontSize);

          call(dispatch, pdf);
        }
      });
  };

  return (dispatch) => {
    dispatch({
      type: GENERATING_PDF,
      generating: true,
    });

    call(dispatch, pdf);
  };
};

export const generatePDF = (requestData, name) => {
  return (dispatch) => {
    dispatch({
      type: GENERATING_PDF,
      generating: true,
    });
    postJSON('post-generate-pdf/', requestData).then((result) => {
      const blob = b64toBlob(result.response, 'data:application/pdf');
      FileSaver.saveAs(blob, name + '.pdf');
      dispatch({
        type: GENERATING_PDF,
        generating: false,
      });
    });
  };
};

export const getPdfDetail = (id, name) => {
  return (dispatch) => {
    dispatch({
      type: GENERATING_PDF,
      generating: true,
    });
    postJSON('pdf-detail/', { id }).then((result) => {
      const blob = b64toBlob(result.response, 'data:application/pdf');
      FileSaver.saveAs(blob, name + '.pdf');
      dispatch({
        type: GENERATING_PDF,
        generating: false,
      });
    });
  };
};

function b64toBlob(b64Data, contentType) {
  const sliceSize = 512;

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}
