import { postJSON, getJSON, getWeather } from '../../utils/fetch';
import cookie from 'react-cookie';
import { hashHistory } from 'react-router';
import { SET_ERROR } from '../Error/error';
import { ADD_TOUR_FOR_COMPAREMENT } from '../TourDetail/tourDetail';
import { eatingParam } from '../../reducers/calculator';
import { getTotalPrice } from '../../components/Pages/Calculator/Summary/TotalPrice';

export const SET_TOUR_CALCULATOR_SETINGS = 'SET_TOUR_CALCULATOR_SETINGS';
export const SET_SETTINGS_PARAMETER = 'SET_SETTINGS_PARAMETER';
export const SET_CALCULATOR_OCCUPATION = 'SET_CALCULATOR_OCCUPATION';
export const RESET_CALCULATOR_OCCUPATION = 'RESET_CALCULATOR_OCCUPATION';
export const SET_INFANTS_COUNT = 'SET_INFANTS_COUNT';
export const SET_CHILDREN_AGE = 'SET_CHILDREN_AGE';
export const TOGGLE_GRANT = 'TOGGLE_GRANT';
export const RESET_CALCULATOR = 'RESET_CALCULATOR';
export const TOGGLE_ADDITIONAL_PRICE = 'TOGGLE_ADDITIONAL_PRICE';
export const SENDING_RESERVATION = 'SENDING_RESERVATION';
export const RESERVATION_SENT = 'RESERVATION_SENT';
export const CLEAN_OCCUPATION = 'CLEAN_OCCUPATION';

export const fetchCalculatorSettings = (id, isSubsidy) => {
  return (dispatch) => {
    getJSON(`/price-calculator/${id}?isSubsidy=${+isSubsidy}`).then((result) => {
      dispatch({
        type: SET_TOUR_CALCULATOR_SETINGS,
        result,
      });

      const defaultEating = result.eating.find((eating) => {
        return eating.isDefault === true && eating.type === 'adult';
      });

      if (result.transports.length === 1) {
        dispatch({
          type: SET_SETTINGS_PARAMETER,
          object: result.transports[0],
          parameter: 'transport',
        });
      }

      dispatch({
        type: SET_SETTINGS_PARAMETER,
        object: isSubsidy,
        parameter: 'isSubsidy',
      });

      dispatch({
        type: SET_SETTINGS_PARAMETER,
        object: defaultEating,
        parameter: eatingParam,
      });
    });
  };
};

export const resetOccupation = (customerType) => {
  return {
    type: RESET_CALCULATOR_OCCUPATION,
    customerType,
  };
};

let setAdditionalData = function (reservation, customer, isSubsidy) {
  const customerAdditionalData = {
    ...reservation.customerSettings[customer.id],
  };

  if (!isSubsidy) {
    delete customerAdditionalData.specification;
    delete customerAdditionalData.pensionerCompany;
    delete customerAdditionalData.pensionerCompanyEnd;
  }

  customer.basicInfo = customerAdditionalData;
};
export const sendReservation = (props, state, idDetail) => {
  const { calculator, reservation, isPensioner, isSubsidy } = props;
  const data = { ...calculator };

  delete data.data;
  delete data.grants;
  delete data.reservationSent;
  delete data.sendingReservation;
  delete data.additionalPrice;
  delete data.settings.adultDotations;

  data.occupation.adult.forEach((customer) => {
    setAdditionalData(reservation, customer, isSubsidy);
  });
  data.occupation.children.forEach((customer) => {
    setAdditionalData(reservation, customer, isSubsidy);
  });

  if (isSubsidy) {
    data.settings.isInTestTime = state.declared;
    data.settings.company = state.company;
  }

  const request = {
    totalPrice: getTotalPrice(props),
    reservation: data,
    customer: props.reservation.values,
    idTour: idDetail,
  };

  return (dispatch) => {
    dispatch({
      type: SENDING_RESERVATION,
    });

    postJSON(`/reservation/`, request)
      .then((result) => {
        dispatch({
          type: RESERVATION_SENT,
          result: 'ok',
        });
      })
      .catch((e) => {
        dispatch({
          type: RESERVATION_SENT,
          result: 'error',
        });
      });
  };
};

export const resetCalculator = () => {
  return {
    type: RESET_CALCULATOR,
  };
};

export const toggleGrant = (type, index) => {
  return {
    type: TOGGLE_GRANT,
    index,
    userType: type,
  };
};

export const toggleAdditionalPrice = (type, index, additionalPrice, additionalPriceType) => {
  return {
    type: TOGGLE_ADDITIONAL_PRICE,
    index,
    userType: type,
    additionalPrice,
    additionalPriceType,
  };
};

export const cleanOccupation = () => {
  return {
    type: CLEAN_OCCUPATION,
  };
};

export const setInfantOccupation = (customerCode, count) => {
  return {
    type: SET_INFANTS_COUNT,
    customerCode,
    count,
  };
};

export const setChildrenAgeByRenderIndex = (index, age) => {
  return {
    type: SET_CHILDREN_AGE,
    age,
    index,
  };
};

export const setOccupation = (customerType, count) => {
  return {
    type: SET_CALCULATOR_OCCUPATION,
    customerType,
    count,
  };
};

export const setSettingsParameter = (object, parameter) => {
  return {
    type: SET_SETTINGS_PARAMETER,
    object,
    parameter,
  };
};
