import { SET_TERMS } from '../actions/TourList/fetchTourList';
import { SAVE_FILTER } from '../actions/TourList/filter';

const initialState = {};

const filter = (state = initialState, action) => {
  switch (action.type) {
    case SET_TERMS:
      return action.result;

    case SAVE_FILTER:
      return { ...state, filterChanged: true };

    default:
      return state;
  }
};

export default filter;
