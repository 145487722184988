const getNextPage = (page, actualPage) => {
  let pageToSet = page;
  if (page === '<') {
    pageToSet = actualPage - 1;
  }
  if (page === '>') {
    pageToSet = actualPage + 1;
  }

  return pageToSet;
};

export default getNextPage;
