import {
  SAVE_FILTERED_LIST,
  SAVE_FILTERED_FETCHING,
  SAVE_FILTERED_FETCHING_FILTER,
} from '../actions/TourList/fetchTourList';
const initialState = {
  tours: [],
  toursCount: '',
};

export const loading = 'načítám';

const tourDetail = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_FILTERED_LIST: {
      if (action.filters === state.filters) {
        return action.result;
      }

      return { ...state };
    }

    case SAVE_FILTERED_FETCHING:
      return { ...state, dataLoading: loading, filters: action.filters };

    case SAVE_FILTERED_FETCHING_FILTER:
      return { ...state, filterLoading: loading };

    default:
      return state;
  }
};

export default tourDetail;
