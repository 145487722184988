import { SET_OCCUPATION } from '../actions/Occupation/fetchOccupation';
const initialState = [];

const tourDetail = (state = initialState, action) => {
  switch (action.type) {
    case SET_OCCUPATION:
      return action.result;

    default:
      return state;
  }
};

export default tourDetail;
