import { hashHistory } from 'react-router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import Radium from 'radium';
import OrangeButton from '../../shared/OrangeButton';

import { setComparemetTours, removeComparedTour } from '../../../actions/TourDetail/tourDetail';
import './ComparementSideBar.scss';

class ComparementSideBar extends Component {
  state = {
    opened: false,
    isEmpty: true,
  };

  componentDidMount() {
    const {
      tourComparement: { tours },
      setComparemetTours,
    } = this.props;

    if (!tours.length) {
      setComparemetTours();
    }
  }

  componentWillReceiveProps(props) {
    const {
      tourComparement: { tours },
    } = this.props;
    const { isEmpty } = this.state;

    if (props.tourComparement.tours.length === 0) {
      this.setState({ opened: false, isEmtpy: true, hasBeenOpened: false });
    }

    if (isEmpty && props.tourComparement.tours.length > 0) {
      setTimeout(() => {
        this.setState({ opened: true, isEmtpy: false });
      }, 1000);
    }
  }

  render() {
    const {
      tourComparement: { tours },
      showComparementSideBar,
    } = this.props;
    const { opened } = this.state;
    const canCompare = tours.length > 1;

    if (!showComparementSideBar) {
      return null;
    }

    return (
      <div className={'comparement-side-bar ' + this.getClassForOpenState()}>
        <div
          className="comparement-open-wrapper"
          onClick={() => this.setState({ opened: !opened, hasBeenOpened: true })}
        >
          <i className="material-icons open-comparement">
            {opened ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}
          </i>

          <div className="comparement-open-title">Porovnání zájezdů</div>
        </div>

        <div className="comparement-info">
          <div className="comparement-tours">
            {tours.map((tour) => this.renderTourForComparement(tour))}
          </div>

          <div className="comparement-bottom-bar">
            {canCompare ? this.renderCompareButton() : this.renderCantCompareMessage()}
          </div>
        </div>
      </div>
    );
  }

  renderCompareButton() {
    return (
      <OrangeButton link={'/porovnani'} onClick={() => this.setState({ opened: false })}>
        Porovnat
      </OrangeButton>
    );
  }

  renderCantCompareMessage() {
    return <div>Porovnat můžete dva nebo tři zájezdy</div>;
  }

  renderTourForComparement(tour) {
    const { removeComparedTour } = this.props;
    const { hotelTitle, dates, idTour } = tour;

    return (
      <div className="comparement-hotel">
        <div>
          <div>
            <strong>{hotelTitle}</strong>
          </div>

          <div>{dates}</div>
        </div>

        <div>
          <i
            className="material-icons comparement-close"
            onClick={() => removeComparedTour(idTour)}
          >
            close
          </i>
        </div>
      </div>
    );
  }

  getClassForOpenState() {
    const {
      tourComparement: { tours },
    } = this.props;
    const { opened, hasBeenOpened } = this.state;

    if (!opened && hasBeenOpened && tours.length) {
      return 'closed';
    } else if (opened) {
      return 'opened';
    } else if (tours.length) {
      return 'shown';
    } else {
      return 'hidden';
    }
  }
}

const mapStateToProps = (state) => ({
  tourComparement: state.tourComparement,
});

const mapDispatchToProps = (dispatch) => ({
  setComparemetTours: () => dispatch(setComparemetTours()),
  removeComparedTour: (idTour) => dispatch(removeComparedTour(idTour)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(ComparementSideBar));
