import constants from '../../../../../../styles/constants.js';
import DayBox from './DayBox';
import OrangeButton from '../../../../../shared/OrangeButton';
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/cs';
import { connect } from 'react-redux';

const styles = {
  wrapper: {
    marginBottom: '15px',
    width: '92%',
    padding: '0 4%',
    overflow: 'auto',

    [constants.breakpointTablet]: {
      marginTop: '0',
    },

    [constants.breakpointMobile]: {
      fontSize: '0.9em',
    },
  },
  buttonBox: {
    clear: 'both',
    paddingTop: '15px',
  },
  tourNotices: {
    padding: ' 0 10px',
  },
  regionTitle: {
    textTransform: 'uppercase',
    marginBottom: '20px',
    textAlign: 'left',
    fontSize: '1.2em',
  },
};

class WeatherForecast extends Component {
  state = {
    showAll: false,
  };

  render() {
    const {
      tourDetail: { location },
      weather: { list },
    } = this.props;
    const { showAll } = this.state;

    return (
      <div style={styles.wrapper}>
        <div>
          <div style={styles.regionTitle}>
            <strong>Region: {location}</strong>
          </div>
          {list && list.map((item, i) => this.renderBox(item, i))}
        </div>
        <div style={styles.buttonBox}>
          <OrangeButton type="centered" onClick={() => this.setState({ showAll: !showAll })}>
            {showAll ? 'Zobrazit méně' : 'Zobrazit více'}
          </OrangeButton>
        </div>
      </div>
    );
  }

  renderBox(item, index) {
    const { showAll } = this.state;
    const {
      humidity,
      speed,
      temp: { day, night },
      weather,
    } = item;
    const weatherIcon = '//openweathermap.org/img/w/' + weather[0].icon + '.png';
    const date = moment().add(index, 'day').format('DD/MM/YYYY');
    if ((!showAll && index < 3) || showAll) {
      return (
        <DayBox
          date={date}
          image={weatherIcon}
          tempMax={day}
          tempMin={night}
          humidity={humidity}
          wind={speed}
          key={index}
        />
      );
    }

    return null;
  }
}

WeatherForecast.propTypes = {
  tourDetail: PropTypes.any,
  weather: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
  weather: state.weather,
});

export default connect(mapStateToProps)(Radium(WeatherForecast));
