import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { setParam } from '../../../../actions/Calculator/reservation';
import './Reservation.scss';
import OrangeButton from '../../../shared/OrangeButton';
import UsersDetail from './UsersDetail';
import ReservationComplete from './ReservationComplete';
import Agreement from './Agreement';
import ReactTooltip from 'react-tooltip';

const customModalStyles = {
  overlay: {
    position: 'fixed',
    zIndex: 120,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0, 0.75)',
    overflow: 'auto',
  },
  content: {
    width: '840px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

class Reservation extends Component {
  state = {};

  render() {
    return (
      <Modal isOpen={this.props.render} style={customModalStyles}>
        {this.renderModalContent()}
      </Modal>
    );
  }

  renderInputs() {
    const {
      isSubsidy,
      combinationAllowed,
      isReadyToSubmit,
      onClose,
      reservation: { values, trans },
      setParam,
    } = this.props;

    return (
      <div>
        <div className="row">
          {this.renderDataInput('title')}
          {this.renderDataInput('name')}
          {this.renderDataInput('surname')}
        </div>

        <div className="reservation-fields">
          <div className="row">
            {this.renderDataInput('street')}
            {this.renderDataInput('streetNum')}
            {this.renderDataInput('city')}
            {this.renderDataInput('zipCode')}
          </div>

          <div className="row">
            {this.renderDataInput('birthDate')}
            {this.renderDataInput('email')}
            {this.renderDataInput('phone')}
          </div>

          <div className="row">{this.renderDataInput('note', 'textArea')}</div>

          <Agreement
            onChange={(value) => setParam('agrees', value)}
            values={values}
            param={'agrees'}
            trans={trans}
            isSubsidy={isSubsidy}
          />
        </div>

        <OrangeButton
          dataTooltip="Vyplňte všechna pole označná znakem * a zaškrtněte souhlas se zpracováním osobních údajů"
          disabled={!combinationAllowed || !isReadyToSubmit}
          onClick={() => this.setState({ secondStep: true })}
        >
          Pokračovat
        </OrangeButton>

        <OrangeButton onClick={() => onClose()} type="inversion">
          Zavřít
        </OrangeButton>

        <ReactTooltip html={Boolean(true)} />
      </div>
    );
  }

  renderModalContent() {
    const {
      calculator: { reservationSent },
      idDetail,
      combinationAllowed,
      onClose,
    } = this.props;
    const { secondStep } = this.state;

    if (reservationSent === 'ok') {
      return <ReservationComplete idDetail={idDetail} />;
    }

    if (!secondStep) {
      return (
        <div className={'reservation-wrapper ' + (!combinationAllowed ? 'disabled' : '')}>
          {this.renderInputs()}
        </div>
      );
    }

    if (secondStep) {
      return (
        <UsersDetail
          idDetail={idDetail}
          closeModal={() => onClose()}
          goBack={() => this.setState({ secondStep: false })}
        />
      );
    }
  }

  renderDataInput(param, type = 'input') {
    const {
      reservation: { values, trans, required, requiredValidation },
      setParam,
    } = this.props;
    const regex = requiredValidation[param];
    const isInvalid = values[param] && regex && !new RegExp(regex).test(values[param]);

    return (
      <div className={'reservation-input ' + (isInvalid ? 'validation-error' : '')}>
        <label>
          {required.includes(param) && '* '}
          {trans[param]}{' '}
        </label>
        {type === 'input'
          ? this.renderInput(param, values, setParam)
          : this.renderTextArea(param, values, setParam)}
      </div>
    );
  }

  renderInput(param, values, setParam) {
    return (
      <input
        name={param}
        autocomplete="on"
        value={values[param]}
        onChange={(event) => setParam(param, event.target.value)}
      />
    );
  }

  renderTextArea(param, values, setParam) {
    return (
      <textarea
        content={values[param]}
        onChange={(event) => setParam(param, event.target.value)}
        maxLength={255}
      />
    );
  }
}

let isReadyToSubmit = function (state) {
  return (
    state.reservation.required.findIndex((key) => {
      const regex = state.reservation.requiredValidation[key];
      const value = state.reservation.values[key];

      if (value && regex && !new RegExp(regex).test(value)) {
        return key;
      }

      return !value;
    }) === -1
  );
};

const mapStateToProps = (state) => {
  return {
    reservation: state.reservation,
    calculator: state.calculator,
    isSubsidy: state.calculator.settings.isSubsidy,
    isReadyToSubmit: isReadyToSubmit(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setParam: (param, value) => dispatch(setParam(param, value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Radium(Reservation));
