import { SET_TOUR_LIST } from '../actions/TourList/fetchTourList';
const initialState = {
  list: [],
};

const filter = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOUR_LIST:
      return action.result;

    default:
      return state;
  }
};

export default filter;
