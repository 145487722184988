import { SAVE_FILTER_SETTINGS } from '../actions/TourList/filterSettings';

const initialState = {
  filterName: 'Lastminute',
  primaryColor: 'defaultColor',
  secondaryColor: 'defaultColor',
};

const filter = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_FILTER_SETTINGS:
      return action.result;

    default:
      return state;
  }
};

export default filter;
