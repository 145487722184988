export const CDNameTitle = 'ČD Travel - cestovní kancelář';
export const vypisTitle = 'Nabídka zajezdu | ' + CDNameTitle;
export const searchTitle = 'vyhledávání | ' + CDNameTitle;
export const contactTitle = 'Kontakty | ' + CDNameTitle;
export const offerTitle = 'Výhodné nabídky | ' + CDNameTitle;
export const togetherTitle = 'Hledám spolucestujícího | ' + CDNameTitle;
export const aboutTitle = 'O společnosti | ' + CDNameTitle;
export const faqTitle = 'Časté otázky a odpovědi | ' + CDNameTitle;
export const croatiaTravelTitle = 'Doprava do Chorvatska | ' + CDNameTitle;
export const ticketsTitle = 'Jízdenky | ' + CDNameTitle;
export const lastMinuteTitle = 'Last minute | ' + CDNameTitle;
