import backgroundImg from '../../../../img/tours-detail/bg.jpg'; // eslint-disable-line
import placeIcon from '../../../../img/tours-detail/icons/place.png'; // eslint-disable-line
import dateIcon from '../../../../img/tours-detail/icons/date.png'; // eslint-disable-line
import constants from '../../../../styles/constants';
import { connect } from 'react-redux';
import projectColors from '../../../../styles/projectColors';

import formatDateLong from '../../../shared/Moment/formatDateLong';
import formatDateShort from '../../../shared/Moment/formatDateShort';
import daySuffix from '../../../shared/daySuffix';
import nightSuffix from '../../../shared/nightSuffix';
import checkZero from '../../../shared/checkZero';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container } from '../../../../libs/radium-grid'; // eslint-disable-line
import Radium from 'radium';

const styles = {
  wrapper: {
    width: '100%',
    height: '170px',
    overflow: 'hidden',
    backgroundImage: 'url(' + backgroundImg + ')',
    backgroundSize: '100%',
    fontSize: '1.3em',
    position: 'relative',

    [constants.breakpoint768Min]: {
      position: 'fixed',
      zIndex: '8',
    },

    [constants.breakpointTablet]: {
      height: 'auto',
    },

    [constants.breakpointDesktop]: {
      backgroundSize: '1500px',
    },
  },
  text: {
    color: '#fff',
  },
  tourInfo: {
    color: '#fff',
    fontWeight: '500',
    fontSize: '0.7em',
    margin: '60px 0 0 130px',
    textTransform: 'uppercase',

    [constants.breakpointTablet]: {
      fontSize: '0.5em',
    },
  },

  icon: {
    position: 'relative',
    top: '9px',
    width: '37px',
    height: '37px',
    marginRight: '3px',
    marginLeft: 0,
    display: 'inline-block',

    [constants.breakpointTablet]: {
      width: '28px',
      height: 'auto',
      top: '8px',
    },

    [constants.breakpointMobile]: {
      display: 'none',
    },
  },

  title: {
    color: '#fff',
    fontWeight: '700',
    fontSize: '1.6em',
    margin: '40px 0 0 0',
    textTransform: 'uppercase',

    [constants.breakpointTablet]: {
      fontSize: '1.1em',
      margin: '40px 0 0 0',
    },
  },
  tourDetailTitle: {
    color: [constants.darkBlue],
    fontWeight: '700',
    fontSize: '1.6em',
    padding: '8px 0',
    margin: '0',
    background: '#f2f2f2',
    textTransform: 'uppercase',

    [constants.breakpointTablet]: {
      fontSize: '1.1em',
      margin: '0 0 5px 0',
      paddingBottom: '5px',
      borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
      textAlign: 'center',
    },
  },
  tourDays: {
    color: '#fff',
    fontWeight: '600',
    fontSize: '1.2em',
    margin: '5px 0 0 0',
    position: 'relative',
    textTransform: 'uppercase',

    [constants.breakpointTablet]: {
      fontSize: '0.9em',
      margin: '0',
    },

    [constants.breakpointMobile]: {
      lineHeight: '1.3em',
      textAlign: 'center',
      margin: '10px 0 0 0',
    },
  },
  orange: {
    color: [constants.mainOrange],
  },
  tourSticker: {
    color: '#fff',
    fontWeight: '700',
    fontSize: '0.9em',
    margin: '0px 0 0 0',
    textTransform: 'uppercase',
    background: '#2DA0E6',
    display: 'inline-block',
    padding: 7,
    transform: 'rotate(4deg)',
    position: 'absolute',
    right: '0',
    top: '33px',

    [constants.breakpointTablet]: {
      fontSize: '0.8em',
      textAlign: 'center',
      position: 'relative',
      transform: 'none',
      width: 'calc(100%  + 30px)',
      top: 'inherit',
      left: '-15px',
      marginTop: '13px',
    },

    soldOut: {
      background: 'red',
    },
  },

  smallerDays: {
    fontSize: '0.8em',
    color: '#fff',
    paddingLeft: '5px',

    [constants.breakpointMobile]: {
      display: 'block',
      width: '100%',
    },
  },

  relative: {
    position: 'relative',
  },

  headerBox: {
    paddingBottom: '15px',
  },

  countryLine: {
    width: 'auto',
    transform: 'rotate(4deg)',
    left: '-17px',
    padding: '6px 5px 5px 7px',
    position: 'relative',
    textAlign: 'left',
    background: [constants.yellow],
    display: 'inline-block',
    textTransform: 'uppercase',
    color: '#fff',
    top: '3px',
    fontWeight: '600',
    fontSize: '0.9em',

    [constants.breakpointTablet]: {
      left: '-5px',
    },
  },
};

class TourHeader extends Component {
  render() {
    return (
      <div style={styles.wrapper} className="tour-header">
        {this.getHeaderData()}
      </div>
    );
  }

  getHeaderData() {
    const {
      term,
      term: {
        isSoldOut,
        isCanceled,
        dateFrom,
        dateTo,
        lengthInDays,
        lengthInNights,
        dayStartName,
        dayEndName,
      },
    } = this.props;
    const { type } = this.getSticker(term);
    const {
      tourDetail: { title, country, location, pickedTour },
    } = this.props;

    const visit = dayStartName
      ? checkZero(dayStartName) + ' - ' + checkZero(dayEndName) + ','
      : 'Délka zájezdu dle vlastního výběru';
    const tourLength = lengthInNights
      ? checkZero(lengthInNights) +
        nightSuffix(lengthInNights) +
        '; ' +
        checkZero(lengthInDays) +
        daySuffix(lengthInDays)
      : '';

    if (pickedTour) {
      return (
        <div className="tour-header-box" style={styles.headerBox}>
          <h1 style={styles.tourDetailTitle}>
            <Container>{'Ubytování: ' + title}</Container>
          </h1>
          <Container>
            <div style={styles.relative}>
              <h2 style={styles.tourDays}>
                <img style={styles.icon} src={placeIcon} /> {country + ', ' + location}
              </h2>
              <h2 style={[styles.tourDays, styles.orange]}>
                <img style={styles.icon} src={dateIcon} />{' '}
                {formatDateShort(dateFrom) + ' - ' + formatDateLong(dateTo)}
                <span style={styles.smallerDays}> ({visit + ' ' + tourLength})</span>
              </h2>

              {type || isSoldOut ? (
                <h2
                  style={[
                    styles.tourSticker,
                    (isSoldOut || isCanceled) && styles.tourSticker.soldOut,
                  ]}
                  className="sticker"
                >
                  <span style={styles.text}>
                    {isSoldOut ? 'Zájezd vyprodán' : this.termSpecialStateText(term)}
                  </span>
                </h2>
              ) : (
                ''
              )}
            </div>
          </Container>
        </div>
      );
    }

    return (
      <div>
        <h1 style={styles.title}>
          {title}, {location}
        </h1>
        <div style={styles.countryLine}>{country}</div>
      </div>
    );
  }

  termSpecialStateText(term) {
    const { isCanceled, isLastMinuteOrCustom, isSpecialOffer } = term;
    const {
      filterSettings: { filterName },
    } = this.props;

    if (isCanceled) {
      return 'Zrušeno';
    } else if (isLastMinuteOrCustom) {
      return filterName;
    } else if (isSpecialOffer) {
      return 'Výhodná nabídka';
    }

    return null;
  }

  getSticker(term) {
    const {
      filterSettings: { filterName },
    } = this.props;
    if (term.isCanceled) {
      return { type: 'isCanceled', value: 'Zrušeno' };
    } else if (term.isLastMinuteOrCustom) {
      return { type: 'lastminute', value: filterName };
    } else if (term.isSpecialOffer) {
      return { type: 'offer', value: 'Výhodná nabídka' };
    }

    return { type: null, value: null };
  }
}

TourHeader.propTypes = {
  tourDetail: PropTypes.any,
  term: PropTypes.any,
  filterSettings: PropTypes.any,
  filter: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
  fetchState: state.fetchState,
  termList: state.termList,
  filter: state.filter,
  filterSettings: state.filterSettings,
  tourDetailReview: state.tourDetailReview,
});

export default connect(mapStateToProps)(Radium(TourHeader));
