import { fetchCalculatorSettings, resetCalculator } from '../../../actions/Calculator/calculator';
import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import leftArrow from '../../../img/icons/back-arrow.png';
import { Link } from 'react-router';
import { visitor } from '../../../actions/HP/defaultTourlist';
import RoomPicker from './Pickers/RoomPicker';
import EatingPicker from './Pickers/EatingPicker';
import PeoplePicker from './Pickers/PeoplePicker';
import SurchargesPicker from './Pickers/SurchargesPicker';
import DiscountPicker from './Pickers/DiscountPicker';
import OccupantSummary from './Summary/OccupantSummary';
import Icons from './Icons';
import TotalPrice from './Summary/TotalPrice';
import InfantsPicker from './Pickers/InfantsPicker';
import './Calculator.scss';
import formatDateShort from '../../shared/Moment/formatDateShort';
import formatDateLong from '../../shared/Moment/formatDateLong';
import Reservation from './Reservation/Reservation';
import OrangeButton from '../../shared/OrangeButton';
import ReactTooltip from 'react-tooltip';
import TransportPicker, { getDeparturePlaces } from './Pickers/TransportPicker';
import constants from '../../../styles/constants';

const styles = {
  arrow: {
    width: '11px',
    height: 'auto',
    marginRight: '7px',
    position: 'relative',
    top: '6px',
  },
  backLink: {
    position: 'fixed',
    top: 430,
    left: 30,
    cursor: 'pointer',

    [constants.breakpointDesktop]: {
      display: 'none',
    },

    text: {
      textDecoration: 'none',
      color: [constants.mainBlue],
      fontSize: '1.1em',
      fontWeight: '600',

      [constants.breakpointDesktop]: {
        padding: '10px',
        backgroundColor: '#fff',
      },

      ':hover': {
        textDecoration: 'underline',
      },
    },

    [constants.breakpoint850]: {
      display: 'none',
    },
  },
};

const isSelectedCustomersOkByCombinations = (props) => {
  const {
    calculator: {
      settings: { customerCombination, occupation },
      data: { customerCombinations },
    },
  } = props;

  if (!customerCombination) {
    return null;
  }

  const combinationExist = customerCombinations[customerCombination].findIndex((combination) => {
    return (
      combination.childCount === (occupation['children'] || 0) &&
      combination.adultsCount === (occupation['adult'] || 0)
    );
  });

  return combinationExist > -1;
};

class Calculator extends Component {
  componentDidMount() {
    const {
      params: { idDetail },
      fetchCalculatorSettings,
      resetCalculator,
    } = this.props;
    const user = cookie.load('user');

    resetCalculator();
    fetchCalculatorSettings(idDetail, user !== visitor);
  }

  render() {
    const {
      calculator: { data },
    } = this.props;
    const { renderReservation } = this.state;

    if (!Object.keys(data).length) {
      return null;
    }

    const {
      params,
      calculator: {
        data: {
          hotel: { hotel, country, location, from, till },
        },
        specialAdult,
      },
    } = this.props;
    const combinationAllowed = isSelectedCustomersOkByCombinations(this.props);

    return (
      <div className="calcurator-wrapper">
        <div className="back-to-list-wrapper">
          <Link style={styles.backLink.text} to={'/zajezdy/detail/' + params.idDetail}>
            <img src={leftArrow} style={styles.arrow} /> zpět zájezd
          </Link>
        </div>
        <div className="title-row">
          <div className="address">
            <div className="hotel">{hotel}</div>
            <div className="country">
              {country} {location}
            </div>
          </div>

          <div className="term">
            <span>{formatDateShort(from)} - </span>
            <span>{formatDateLong(till)}</span>
          </div>

          <div className="capacity">
            <Icons />
          </div>
        </div>

        <div className={'picker-row' + (specialAdult ? ' special-adult' : ' ')}>
          <RoomPicker />

          {!specialAdult && <PeoplePicker combinationAllowed={combinationAllowed} />}

          <InfantsPicker />

          <EatingPicker />
        </div>

        <div className="aditional-prices-wrapper">
          <TransportPicker />
        </div>

        <div className="aditional-prices-wrapper">
          <div className="picker-row">
            <SurchargesPicker />

            <DiscountPicker />
          </div>
        </div>

        <OccupantSummary />

        {this.renderReservation(combinationAllowed, renderReservation)}

        <ReactTooltip html={Boolean(true)} />
      </div>
    );
  }

  renderReservation(combinationAllowed, renderReservation) {
    const user = cookie.load('user');

    if (user !== visitor) {
      return (
        <div className="summary-info-wrapper">
          <TotalPrice />
        </div>
      );
    }

    return (
      <div>
        <div className="summary-info-wrapper">
          <TotalPrice />

          <OrangeButton
            dataTooltip="Zadejte všechny povinné položky"
            type="whiteText"
            disabled={!combinationAllowed || !this.transportSelected()}
            onClick={() => this.setState({ renderReservation: true })}
          >
            Objednat
          </OrangeButton>
        </div>

        <Reservation
          render={renderReservation}
          onClose={() => this.setState({ renderReservation: false })}
          idDetail={this.props.params.idDetail}
          combinationAllowed={combinationAllowed}
        />
      </div>
    );
  }

  transportSelected() {
    const {
      calculator: {
        settings: { departureFrom, transport },
      },
    } = this.props;
    const departures = getDeparturePlaces(this.props);

    if (!transport) {
      return false;
    }

    if (departures && departures.length && !departureFrom) {
      return false;
    }

    return true;
  }
}

const mapStateToProps = (state) => ({
  calculator: state.calculator,
  tourDetail: state.tourDetail,
  isSubsidy: state.calculator.settings.isSubsidy,
});

const mapDispatchToProps = (dispatch) => ({
  resetCalculator: () => dispatch(resetCalculator()),
  fetchCalculatorSettings: (idTour, isSubsidy) =>
    dispatch(fetchCalculatorSettings(idTour, isSubsidy)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Radium(Calculator));
