import FormGroup from '../../../../../shared/forms/FormGroup';
import OrangeButton from '../../../../../shared/OrangeButton';
import SelectBox from '../../../../../shared/forms/SelectBox';
import styles from '../../../../../shared/forms/modalFormStyles/modalFormStyles';
import Dialog from 'material-ui/Dialog';
import emailVerify from '../../../../../shared/emailVerify';
import InputModalAlert from '../../../../../shared/InputModalAlert';
import { connect } from 'react-redux';
import { postNewBooking, postSucceeded } from '../../../../../../actions/TourDetail/postTourDetail';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Radium from 'radium';

const customStyles = {
  floatLeft: {
    float: 'left',
    marginRight: 10,
    marginBottom: 10,
  },
};

class BookingForm extends Component {
  state = {
    status: '',
    alertOpened: false,
    opened: false,
    personCount: 1,
    name: '',
    email: '',
    phone: '',
    address: '',
    note: '',
    options: [
      {
        id: 1,
        value: 1,
      },
      {
        id: 2,
        value: 2,
      },
      {
        id: 3,
        value: 3,
      },
      {
        id: 4,
        value: 4,
      },
      {
        id: 5,
        value: 5,
      },
    ],
  };

  componentWillReceiveProps(props) {
    const {
      postFormStatus: { postNewBooking },
    } = props;

    this.setState({ status: postNewBooking });
  }

  render() {
    const { alertOpened } = this.state;
    const {
      tourDetail: { pickedTour },
    } = this.props;

    return (
      <div>
        <InputModalAlert
          opened={alertOpened}
          onClick={this.handleOpenAlert.bind(this)}
          message="Zkontrolujte, prosím, zda jsou všechny položky vyplněné a jsou vyplněné správně."
        />

        {pickedTour && (
          <OrangeButton
            onClick={() => this.setState({ opened: true })}
            extraStyle={customStyles.floatLeft}
          >
            Nezávazná poptávka
          </OrangeButton>
        )}

        {this.renderForm()}
      </div>
    );
  }

  handleOpenAlert(alertOpened) {
    this.setState({ alertOpened });
  }

  renderForm() {
    const { opened, status } = this.state;

    return (
      <Dialog
        title="Přidat novou poptávku"
        actions={this.getActions()}
        modal={false}
        open={opened}
        autoScrollBodyContent="true"
        titleStyle={{ borderBottom: 'none' }}
        bodyStyle={{
          borderBottom: '3px solid #fff',
          bottom: '-1px',
          position: 'relative',
        }}
        contentClassName="dialog-box"
        actionsContainerClassName="actions-box"
        bodyClassName="content-box"
        titleClassName="title-box"
      >
        <div style={styles.formWrapper}>
          <p style={styles.status}>{status}</p>
          {this.renderDialogItems(status)}
        </div>
      </Dialog>
    );
  }

  renderDialogItems(status) {
    const { options, name, email, phone, address, note } = this.state;
    const {
      tourDetail: { hotelTitle },
    } = this.props;

    if (status === postSucceeded) {
      return null;
    }

    return (
      <div>
        <p style={styles.onlyText}>
          Mám zájem o bližší informace k tomuto zájezdu. Souhlasím, aby mě kontaktoval zaměstnanec
          cestovní kanceláře. Zároveň beru na vědomí, že tento mail není závaznou rezervací ani
          objednávkou.
        </p>

        <div>
          <FormGroup title="Hotel">
            <div style={styles.onlyText}>{hotelTitle}</div>
          </FormGroup>

          <FormGroup title="* Počet osob">
            <SelectBox
              name="personCount"
              options={options}
              type={'small'}
              onClick={this.handleSelect.bind(this)}
            />
          </FormGroup>

          <FormGroup title="* Jméno a příjmení">
            <input
              style={styles.input}
              value={name}
              onChange={(event) => {
                this.setState({ name: event.target.value });
              }}
            />
          </FormGroup>

          <FormGroup title="* E-Mail">
            <input
              style={styles.input}
              value={email}
              onChange={(event) => {
                this.setState({ email: event.target.value });
              }}
            />
          </FormGroup>

          <FormGroup title="* Telefon">
            <input
              style={styles.input}
              value={phone}
              onChange={(event) => {
                this.setState({ phone: event.target.value });
              }}
            />
          </FormGroup>

          <FormGroup title="* Adresa">
            <input
              style={styles.input}
              value={address}
              onChange={(event) => {
                this.setState({ address: event.target.value });
              }}
            />
          </FormGroup>

          <FormGroup title="* Poznámka">
            <textarea
              value={note}
              style={styles.area}
              onChange={(text) => this.setState({ note: text.target.value })}
            />
          </FormGroup>
        </div>
      </div>
    );
  }

  handleSelect(value, name) {
    this.setState({ [name]: value });
  }

  postInsertion() {
    const {
      postNewBooking,
      term: { idTour },
    } = this.props;
    const { personCount, name, email, phone, address, note } = this.state;
    const requestData = {
      idTour,
      personCount,
      name,
      email,
      phone,
      address,
      note,
    };

    const requiredFields = [name, email, phone, address, note, personCount];

    const error = requiredFields.filter((fieldName) => fieldName === '');

    if (error.length || !emailVerify(email)) {
      this.setState({ alertOpened: true });
    } else {
      this.setState({
        status: 'Odesílám formulář',
        id: '',
        personCount: '',
        name: '',
        email: '',
        phone: '',
        address: '',
        note: '',
      });
      postNewBooking(requestData);
    }
  }

  close() {
    this.setState({ status: '', opened: false });
  }

  getActions() {
    const { status } = this.state;

    if (status === postSucceeded) {
      return (
        <OrangeButton onClick={() => this.close()} submit="submit" type="inversion">
          Zavřít
        </OrangeButton>
      );
    }

    return (
      <div>
        <OrangeButton onClick={() => this.close()} submit="submit" type="inversion">
          Zrušit
        </OrangeButton>

        <OrangeButton onClick={() => this.postInsertion()} submit="submit" type="normal">
          Potvrdit
        </OrangeButton>
      </div>
    );
  }
}

BookingForm.propTypes = {
  tourDetail: PropTypes.any,
  term: PropTypes.any,
  postNewBooking: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
  postFormStatus: state.postFormStatus,
});

const mapDispatchToProps = (dispatch) => ({
  postNewBooking: (requestData) => dispatch(postNewBooking(requestData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(BookingForm));
