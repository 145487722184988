import React, { Component } from 'react';
import Radium from 'radium';

import menuCloseImg from '../../../img/menu-close2.png'; // eslint-disable-line

const styles = {
  wrapper: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '40px',
    padding: '13px',
    opacity: 0.7,
  },
  closeToggle: {
    opacity: '0.9',
    transition: '0.2s',
    cursor: 'pointer',
    width: '40px',
    height: '40px',

    ':hover': {
      opacity: '1',
      transform: 'rotate(90deg)',
    },
  },
};

class CloseButton extends Component {
  render() {
    return (
      <div style={styles.wrapper}>
        <img style={styles.closeToggle} src={menuCloseImg} />
      </div>
    );
  }
}

export default Radium(CloseButton);
