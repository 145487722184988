import AdList from './AdList';
import Filter from './Filter';
import greyBg from '../../../img/grey-bg.png'; // eslint-disable-line
import TogetherTravelingForm from './TogetherTravellingForm';
import OrangeButton from '../../shared/OrangeButton';

import SectionImage from '../../shared/SectionImage';
import sectionImg from '../../../img/section-headers/together.jpg'; // eslint-disable-line
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container } from '../../../libs/radium-grid'; // eslint-disable-line
import { togetherTitle } from '../../../utils/titleConfig';
import { fetchPassengers } from '../../../actions/TogetherTraveling/fetchPassengers';
import { postNewInsertion } from '../../../actions/TogetherTraveling/postPassengers';
import { fetchTourFilter } from '../../../actions/TourList/tourFilter';
import {
  setNewInsertionForm,
  toggleTogetherTravelingForm,
} from '../../../actions/TogetherTraveling/passengerFilter';
import Helmet from 'react-helmet';
import Radium from 'radium';

const styles = {
  sectionHead: {
    width: '100%',
  },
  contentWrapper: {
    overflow: 'hidden',
    zIndex: 6,
    position: 'relative',
    padding: '50px 0 50px 0',
    backgroundImage: 'url(' + greyBg + ')',
    height: '100%',
  },
  newAdBox: {
    margin: '20px 0',
  },
};

export const newAdvertising = 'TogetherTravelling';

class TogetherTravelling extends Component {
  state = {
    toggle: false,
    opened: false,
  };

  componentDidMount() {
    const { fetchPassengers, fetchTourFilter } = this.props;
    fetchPassengers();
    fetchTourFilter();
  }

  render() {
    return (
      <div>
        <Helmet title={togetherTitle} />
        <SectionImage actualImage={sectionImg} actualTitle={'Najděte společníka na cesty'} />

        <div style={styles.contentWrapper}>
          <Container>{this.renderContent()}</Container>
        </div>
      </div>
    );
  }

  renderContent() {
    const {
      togetherTravelingFormStatus: { newAdvertisingMessageOpened },
    } = this.props;

    return (
      <div>
        <Filter />
        <div style={styles.newAdBox}>
          <OrangeButton onClick={() => this.openTogetherTravelingForm()} type="centered" key="1">
            Přidat nový inzerát
          </OrangeButton>

          {newAdvertisingMessageOpened && (
            <TogetherTravelingForm
              opened={newAdvertisingMessageOpened}
              sourceOfRequest={newAdvertising}
            />
          )}
        </div>
        <AdList />
      </div>
    );
  }

  openTogetherTravelingForm() {
    const { toggleTogetherTravelingForm } = this.props;

    toggleTogetherTravelingForm(newAdvertising, true);
  }
}

TogetherTravelling.propTypes = {
  fetchPassengers: PropTypes.any,
  fetchTourFilter: PropTypes.any,
  togetherTravelingFormStatus: PropTypes.any,
  toggleTogetherTravelingForm: PropTypes.any,
};

const mapStateToProps = (state) => ({
  passengers: state.passengers,
  togetherTravelingFormStatus: state.togetherTravelingFormStatus,
  passengersFilter: state.passengersFilter,
});

const mapDispatchToProps = (dispatch) => ({
  postNewInsertion: (requestData) => dispatch(postNewInsertion(requestData)),
  fetchPassengers: () => dispatch(fetchPassengers()),
  fetchTourFilter: () => dispatch(fetchTourFilter()),
  toggleTogetherTravelingForm: (formType, isFormOpened) =>
    dispatch(toggleTogetherTravelingForm(formType, isFormOpened)),
  setNewInsertionForm: (status) => dispatch(setNewInsertionForm(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(TogetherTravelling));
