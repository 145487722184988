import React, { Component } from "react";
import Radium from "radium";
import { connect } from "react-redux";
import {
  adultPriceByCustomerIndex,
  childrenPriceByCustomerIndex,
  formatCurrency,
  isGrantUsed
} from "../calculations";
import cookie from "react-cookie";

export const getTotalPrice = (props) => {
  const {
    calculator,
    calculator: {
      settings: { eating, occupation, discount, surcharge },
      grants,
      data
    }
  } = props;
  let totalPrice = 0;

  for (let i = 0; i < occupation['children']; i++) {
    const grantUsed = isGrantUsed(i, "children", grants);
    const { price, discountedPrice } = childrenPriceByCustomerIndex(calculator, i + 1, grantUsed);

    totalPrice += discountedPrice || price;
  }

  for (let i = 0; i < occupation['adult']; i++) {
    const grantUsed = isGrantUsed(i, "adult", grants);
    const { price, discountedPrice } = adultPriceByCustomerIndex(calculator, i + 1, grantUsed);

    totalPrice += discountedPrice || price;
  }

  const {
    calculator: {
      settings: { infants },
      data: { availableCustomers }
    }
  } = props;

  const infantCustomers = availableCustomers.filter((customer) => {
    return !customer.fullCalculation;
  });

  Object.keys(infants).forEach((key) => {
    const infanetObject = infantCustomers.find((i) => {
      return i.code === key;
    });

    totalPrice += infants[key] * infanetObject.price;
  });

  discount.forEach((discountObject) => {
    if (discountObject.type === 1) {
      totalPrice += discountObject.price;
    }
  });

  surcharge.forEach((surchargeObject) => {
    if (surchargeObject.type === 1) {
      totalPrice += surchargeObject.price;
    }
  });

  return totalPrice;
};

const subsidyClasses = {
  employee: 'subsidy',
  szEmployee: 'szSubsidy',
};

class TotalPrice extends Component {
  render() {
    const {
      isSubsidy,
    } = this.props;
    const user = cookie.load("user");

    return (
      <div>
        <div>
          <strong>Doprava</strong>
          <div className={"departure" + (isSubsidy ? " " + (subsidyClasses[user]): "")}>{this.transportValue()}</div>
        </div>

        <div>
          <strong>Celková cena zájezdu</strong>
          <div className={"total-price" + (isSubsidy ? " " + (subsidyClasses[user]) : "")}>
            {formatCurrency(getTotalPrice(this.props))}
          </div>
        </div>
      </div>
    );
  }

  transportValue() {
    const {
      calculator: {
        settings: { departureFrom, transport }
      }
    } = this.props;
    if (!transport) {
      return <div>Vyberte dopravu</div>;
    }

    return (
      <div>
        {transport && transport.title} {departureFrom && ` - ${departureFrom.title}`}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  calculator: state.calculator,
  isSubsidy: state.calculator.settings.isSubsidy
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(TotalPrice));
