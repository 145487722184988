import { SET_CMS } from '../actions/CMS/fetchCMS';

const initialState = [
  {
    title: '',
    url: '',
    content: '',
  },
];

const filter = (state = initialState, action) => {
  switch (action.type) {
    case SET_CMS:
      return action.result;

    default:
      return state;
  }
};

export default filter;
