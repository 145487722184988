import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchCatalogueFilter } from '../../actions/TourList/tourFilter';
import { getLocationPdf } from '../../actions/Catalogue/catalogue';
import StyledCircleLoader from '../shared/Loading/StyledCircleLoader';
import cookie from 'react-cookie';
import { hashHistory } from 'react-router';
import PdfList from './PdfList/PdfList';
import windowDimensions from 'react-window-dimensions';
import { COUNTRIES_SORTED_BY_NAME } from '../../reducers/tourFilter';
import MultiCheckbox from './MultiCheckbox';
import CountryDependentCheckboxfrom from './CountryDependentCheckbox';
import LoaderInModalWindow from '../shared/Loading/LoaderInModalWindow';
import { closeTabs } from '../../actions/TourList/filterOpenStatus';
import { fetchCMS } from '../../actions/CMS/fetchCMS';

import './Catalogue.scss';

class Widget extends Component {
  state = {
    loaded: false,
  };

  componentDidMount() {
    const { fetchCatalogueFilter, fetchCMS } = this.props;

    fetchCatalogueFilter();
    fetchCMS();
  }

  getSelectedText() {
    const {
      catalogue: { countries },
    } = this.props;
    const { selectedCountry } = this.state;

    const country = countries.find((formattedCountry) => {
      return formattedCountry.code === selectedCountry;
    });

    if (!country) {
      return null;
    }

    return country.value;
  }

  render() {
    const { closeTabs, width } = this.props;
    const {
      catalogue: { countries, locationsInCountries },
      generatingPDF,
    } = this.props;
    const { loaded } = this.state;

    if (!locationsInCountries) {
      return (
        <div className="catalogue-loader">
          <StyledCircleLoader />
        </div>
      );
    }

    return (
      <div className="catalogue-wrapper" onClick={() => closeTabs()}>
        {this.renderPhoneSelect()}

        {generatingPDF && this.loader()}

        <div>{loaded ? <PdfList /> : this.renderCMS()}</div>
      </div>
    );
  }

  renderCMS() {
    const { CMS } = this.props;
    const page = CMS.find((page) => {
      return page.url === 'katalog';
    });

    if (!page) {
      return null;
    }

    return (
      <div className="catalogue-cms-wrapper">
        <p dangerouslySetInnerHTML={{ __html: page.content }} />
      </div>
    );
  }

  renderDesktopSelect(selectedLocations) {
    return (
      <div className="options-wrapper">
        {Object.keys(selectedLocations).map((key) => this.renderCountry(key))}
      </div>
    );
  }

  renderPhoneSelect() {
    const {
      catalogue: { countries, locationsInCountries },
    } = this.props;
    const { selectedCountry, selectedLocationName } = this.state;
    const { closeTabs } = this.props;
    const selectedText = this.getSelectedText();

    return (
      <div className="options-wrapper">
        <MultiCheckbox
          onSelect={(code) => this.handleCountrySelect(code)}
          selected={selectedCountry}
          selectedText={selectedText}
          reset={() => this.setState({ selectedCountry: null, selectedLocationName: null })}
          data={countries}
          type={COUNTRIES_SORTED_BY_NAME}
          box="textbox"
          defaultText="vyberte zemi"
        />

        <CountryDependentCheckboxfrom
          data={locationsInCountries}
          onRef={(ref) => (this.countryDepednentCheckBox = ref)}
          defaultTextBold={Boolean(true)}
          closeTabs={closeTabs}
          reset={() => this.setState({ selectedLocationName: null })}
          selectedLocationName={selectedLocationName}
          selectedCountry={selectedText}
          onSelect={this.handleLocationSelect.bind(this)}
          type="location"
          box="textbox"
          defaultText="vyberte oblast"
        />
      </div>
    );
  }

  handleCountrySelect(country) {
    this.setState({ selectedCountry: country });

    this.countryDepednentCheckBox.open();
  }

  handleLocationSelect(code, countryCode) {
    const {
      catalogue: { locationsInCountries },
    } = this.props;
    const { closeTabs } = this.props;

    const selectedLocation = locationsInCountries[countryCode].find((location) => {
      return location.code == code;
    });

    this.selectLocation(selectedLocation.id);

    this.setState({ selectedLocationName: selectedLocation.value });
    closeTabs();
  }

  renderCountry(key) {
    const { selectedLocations } = this.props;
    const locations = selectedLocations[key];
    const { selected } = this.state;

    return (
      <div onClick={() => this.setState({ selected: key })}>
        <div className={'catalogue-country' + ' ' + cookie.load('user')}>{key}</div>
        {selected === key && this.renderLocations(locations)}
      </div>
    );
  }

  renderLocations(locations) {
    return (
      <div className="catalogue-locations-wrapper">
        {locations.map((location) => this.renderLocation(location))}
      </div>
    );
  }

  renderLocation(location) {
    const { selectedLocation } = this.state;
    const additionalClass = location.id === selectedLocation ? ' selected ' : '';

    return (
      <div
        className={'catalogue-location' + additionalClass + ' ' + cookie.load('user')}
        onClick={() => this.selectLocation(location.id)}
      >
        {location.value}
      </div>
    );
  }

  loader() {
    return (
      <div>
        <LoaderInModalWindow />
      </div>
    );
  }

  selectLocation(key) {
    const { getLocationPdf } = this.props;

    this.setState({ selectedLocation: key, loaded: true });
    getLocationPdf(key);
  }
}

const mapStateToProps = (state) => ({
  catalogue: state.catalogue,
  generatingPDF: state.fetchState.generatingPDF,
  CMS: state.CMS,
});

const mapDispatchToProps = (dispatch) => ({
  getLocationPdf: (locationId) => dispatch(getLocationPdf(locationId)),
  closeTabs: () => dispatch(closeTabs()),
  fetchCMS: () => dispatch(fetchCMS()),
  fetchCatalogueFilter: () => dispatch(fetchCatalogueFilter()),
});
export default connect(mapStateToProps, mapDispatchToProps)(windowDimensions()(Widget));
