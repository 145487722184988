import React, { Component } from 'react';
import { Link } from 'react-router';
import Radium from 'radium';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import { formatCurrency } from '../../calculations';

export const getEatingPrice = (eating, data, type) => {
  if (type === 'children') {
    const childrenEating = data.eating.find((item) => {
      return eating.code === item.baseCode && item.type === 'children';
    });

    if (childrenEating) {
      return childrenEating.price;
    }
  }

  return eating.price;
};

class Eating extends Component {
  render() {
    const {
      calculator: {
        settings: { eating },
        occupation,
      },
      type,
      index,
    } = this.props;

    if (!eating || !occupation[type][index]) {
      return null;
    }

    return (
      <div className="eating-type-wrapper">
        {eating.title} {formatCurrency(occupation[type][index].eating)}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  calculator: state.calculator,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Radium(Eating));
