import React, { Component } from 'react';
import DatePicker from 'material-ui/DatePicker';
import Radium from 'radium';
import { connect } from 'react-redux';
import { setParam, setCustomerSettings } from '../../../../actions/Calculator/reservation';
import { sendReservation } from '../../../../actions/Calculator/calculator';
import './Reservation.scss';
import TotalPrice from '../Summary/TotalPrice';
import StyledCircleLoader from '../../../shared/Loading/StyledCircleLoader';
import OrangeButton from '../../../shared/OrangeButton';
import Agreement from './Agreement';
import { customStyles } from '../../../shared/BaseSelect/BaseSelect';
import ReactTooltip from 'react-tooltip';
import cookie from 'react-cookie';
import { visitor } from '../../../../actions/HP/defaultTourlist';

const getIsSubsidy = (adult, children) => {
  const subsidyAdultIndex = adult.findIndex((customer) => {
    return customer.grantUsed;
  });

  const subsidyChildrenIndex = children.findIndex((customer) => {
    return customer.grantUsed;
  });

  return subsidyAdultIndex > -1 || subsidyChildrenIndex > -1;
};

class Reservation extends Component {
  state = {
    company: 'cd',
    declared: true,
    specifications: {
      employee: 'Zaměstnanec',
      familyMember: 'Rodinný příslušník',
      children: 'Dítě',
      pensioner: 'Důchodce',
      other: 'Cizí',
    },
  };

  componentDidMount() {
    const {
      setCustomerSettings,
      calculator: {
        occupation: { adult, children },
      },
      reservation: {
        customerSettings,
        values: { name, surname, birthDate, title },
      },
    } = this.props;

    adult.concat(children).forEach((customer) => {
      if (!customerSettings[customer.id]) {
        setCustomerSettings('name', '', customer.id);
      }
    });

    const firstAdult = adult[0];

    setCustomerSettings('title', title, firstAdult.id);
    setCustomerSettings('name', name, firstAdult.id);
    setCustomerSettings('surname', surname, firstAdult.id);
    setCustomerSettings('birthDate', birthDate, firstAdult.id);
  }

  render() {
    const {
      calculator: {
        occupation: { adult, children },
        settings: { occupation },
        sendingReservation,
        reservationSent,
      },
      reservation: { trans, values },
      isSubsidyLocal,
      goBack,
      isSubsidy,
      setParam,
    } = this.props;

    return (
      <div className="users-detail-wrapper">
        <div className="back-button" onClick={() => goBack()}>
          <i className="material-icons">keyboard_arrow_left</i>
          Zpět
        </div>

        {this.renderDiscountApliance()}

        <div className="responsive-table">
          <div className="customer-row custommers-header">
            <div className="reservation-input title">{trans.title}</div>
            <div className="reservation-input">{trans.name}*</div>
            <div className="reservation-input">{trans.surname}*</div>
            <div className="reservation-input">{trans.birthDate}*</div>
            {isSubsidyLocal && <div className="reservation-input">{trans.specification}</div>}
          </div>

          {adult.map((adult, index) => this.renderCustomer(adult, index + 1, 'adult'))}
          {children.map((child, index) =>
            this.renderCustomer(child, index + occupation.adult + 1, 'children'),
          )}
        </div>

        <Agreement
          onChange={(value) => setParam('agreesPolicy', value)}
          values={values}
          param={'agreesPolicy'}
          trans={this.getAgreesPolicy()}
          isSubsidy={isSubsidy}
        />
        {this.renderButtons()}

        {reservationSent === 'error' && this.renderError()}

        {sendingReservation && this.renderLoader()}

        <ReactTooltip html={Boolean(true)} />
      </div>
    );
  }

  getAgreesPolicy() {
    const user = cookie.load('user');

    if (user !== visitor) {
      return {
        agreesPolicy: (
          <div>
            <span>
              *Souhlasím s{' '}
              <a
                href="https://cdtravel-2.s3.eu-central-1.amazonaws.com/documentsUpload/a8f978b7Vseobecne_podminky.pdf"
                target="_blank"
              >
                Všeobecnými a záručními podmínkami
              </a>{' '}
              cestovní kanceláře ČD travel (případně pořádající CK) a
              <a
                href="https://cdtravel-2.s3.eu-central-1.amazonaws.com/documentsUpload/71f76c5eERV2018.pdf"
                target="_blank"
              >
                {' '}
                Pojistnými podmínkami
              </a>{' '}
              ERV Evropské Pojišťovny.
            </span>
          </div>
        ),
      };
    }

    return {
      agreesPolicy: (
        <div>
          <span>
            *Souhlasím se{' '}
            <a
              href="https://cdtravel-2.s3.eu-central-1.amazonaws.com/documentsUpload/a8f978b7Vseobecne_podminky.pdf"
              target="_blank"
            >
              Všeobecnými a záručními podmínkami
            </a>{' '}
            cestovní kanceláře ČD travel a
            <a
              href="https://cdtravel-2.s3.eu-central-1.amazonaws.com/documentsUpload/71f76c5eERV2018.pdf"
              target="_blank"
            >
              {' '}
              Pojistnými podmínkami ERV
            </a>{' '}
            Evropské Pojišťovny.
          </span>
        </div>
      ),
    };
  }

  renderError() {
    return <div className="sendingError">rezervaci nebylo možné odeslat</div>;
  }

  renderLoader() {
    return (
      <div className="sending-loader">
        <StyledCircleLoader />
      </div>
    );
  }

  renderButtons() {
    const { closeModal, isReadyToSubmit, sendReservation, idDetail } = this.props;

    return (
      <div className="reservation-customer-summary">
        <div className="summary-info-wrapper">
          <TotalPrice />

          <div>
            <OrangeButton onClick={() => closeModal()} type="inversion">
              Zavřít
            </OrangeButton>

            <OrangeButton
              dataTooltip="Vyplňte všechna pole označná znakem * a zaškrtněte souhlas s podmínkami"
              disabled={!isReadyToSubmit}
              onClick={() => sendReservation(this.props, this.state, idDetail)}
            >
              Dokončit objednávku
            </OrangeButton>
          </div>
        </div>
      </div>
    );
  }

  renderDiscountApliance() {
    const { company } = this.state;
    const { isSubsidyLocal, isSubsidy } = this.props;

    if (!isSubsidyLocal) {
      return null;
    }

    return (
      <div>
        <div className="appliance-type row">
          O příspěvek na rekreaci žádám z C-SF:
          <div>
            <label className={'custom-checkbox' + (isSubsidy ? ' subsidy' : '')}>
              ČD
              <input
                type="radio"
                onClick={() => this.setState({ company: 'cd' })}
                checked={company === 'cd'}
              />
              <span className="checkmark" />
            </label>

            <label className={'custom-checkbox' + (isSubsidy ? ' subsidy' : '')}>
              ČD Cargo
              <input
                type="radio"
                onClick={() => this.setState({ company: 'cdCargo' })}
                checked={company === 'cdCargo'}
              />
              <span className="checkmark" />
            </label>
          </div>
        </div>

        <div className="declaration row">
          <span>Prohlašuji, že</span>
          {this.simpleSelect({ nejsem: false, jsem: true }, 'declared')}
          <span>ve zkušební době.</span>
        </div>
      </div>
    );
  }

  simpleSelect(options, stateParam) {
    const { state } = this;

    return (
      <div>
        <select
          className="formControl text-select-input"
          onChange={(event) => {
            state[stateParam] = event.target.value;
            this.setState({ ...state });
          }}
        >
          {Object.keys(options).map((key) => {
            return <option value={options[key]}>{key}</option>;
          })}
        </select>
      </div>
    );
  }

  pensionerSelect(customer) {
    const options = { ČD: 'cd', 'ČD Cargo': 'cdCargo' };
    const { setCustomerSettings } = this.props;

    return (
      <div>
        <select
          className="formControl text-select-input"
          onChange={(event) =>
            setCustomerSettings('pensionerCompany', event.target.value, customer.id)
          }
        >
          {Object.keys(options).map((key) => {
            return <option value={options[key]}>{key}</option>;
          })}
        </select>
      </div>
    );
  }

  renderCustomer(customer, index, type) {
    const {
      isSubsidyLocal,
      setCustomerSettings,
      reservation: { customerSettings },
    } = this.props;
    const { specifications } = this.state;
    const settings = customerSettings[customer.id];
    const isFirstAdult = index === 1 && type === 'adult';

    return (
      <div>
        <div className="customer-row">
          <div className="customer-row-label">
            {index}. {type === 'adult' ? 'Dospělá osoba' : 'Dítě'}
          </div>

          {this.renderDataInput('title', customer, type === 'children' || isFirstAdult)}
          {this.renderDataInput('name', customer, isFirstAdult)}
          {this.renderDataInput('surname', customer, isFirstAdult)}
          {this.renderDataInput('birthDate', customer, isFirstAdult)}
          {isSubsidyLocal && (
            <div className="reservation-input">
              <select
                style={customStyles.selectBox}
                className="custom-select"
                onChange={(event) =>
                  setCustomerSettings('specification', event.target.value, customer.id)
                }
              >
                {Object.keys(specifications).map((key) => {
                  return <option value={key}>{specifications[key]}</option>;
                })}
              </select>
            </div>
          )}
        </div>

        {this.renderPensionerApliance(customer, settings, isFirstAdult)}
      </div>
    );
  }

  renderPensionerApliance(customer, settings, render) {
    const {
      setCustomerSettings,
      reservation: { settingsValidations },
    } = this.props;

    if (!settings || settings.specification !== 'pensioner' || !render) {
      return null;
    }

    const value = settings.pensionerCompanyEnd;
    const regex = settingsValidations.pensionerCompanyEnd;
    const isInvalid = value && regex && !new RegExp(regex).test(value);

    return (
      <div className="pensior-apliance">
        <span>
          <strong>Čestné prohlášení (povinné pro důchodce)</strong>
        </span>

        <div className="row">
          <span>Prohlašuji, že jsem ukončil/a pracovní poměr u společnosti</span>

          {this.pensionerSelect(customer)}

          <span>dne</span>

          <div className={isInvalid ? 'error' : ''}>
            <input
              className={'text-select-input'}
              name={'pensionerCompanyEnd'}
              autocomplete="on"
              value={settings.pensionerCompanyEnd}
              onChange={(event) =>
                setCustomerSettings('pensionerCompanyEnd', event.target.value, customer.id)
              }
            />
          </div>
        </div>
      </div>
    );
  }

  renderDataInput(param, customer, disabled = false) {
    const {
      reservation: { customerSettings, settingsValidations },
      setCustomerSettings,
    } = this.props;
    const customerSetting = customerSettings[customer.id];
    const regex = settingsValidations[param];
    const isInvalid =
      customerSetting &&
      customerSetting[param] &&
      regex &&
      !new RegExp(regex).test(customerSetting[param]);

    return (
      <div
        className={
          'reservation-input ' +
          (disabled ? 'disabled ' : '') +
          (param === 'title' ? 'title ' : '') +
          (isInvalid ? 'validation-error' : '')
        }
      >
        <input
          value={customerSetting ? customerSetting[param] : ''}
          disabled={disabled}
          onChange={(event) => setCustomerSettings(param, event.target.value, customer.id)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    calculator: {
      occupation: { adult, children },
    },
    reservation: {
      customerSettings,
      requiredSettings,
      settingsValidations,
      requiredPensionerSettings,
    },
  } = state;
  let isReadyToSubmit = true;

  Object.keys(customerSettings).forEach((key) => {
    const isPensioner = customerSettings[key].specification === 'pensioner';

    const allFilled = (isPensioner && Object.keys(customerSettings)[0] === key
      ? requiredPensionerSettings
      : requiredSettings
    ).findIndex((required) => {
      const regex = settingsValidations[required];
      const value = customerSettings[key][required];

      if (value && regex && !new RegExp(regex).test(value)) {
        return key;
      }

      return !value;
    });

    if (allFilled !== -1) {
      isReadyToSubmit = false;
    }
  });

  const filledSecondStep =
    state.reservation.requiredSecondStep.findIndex((key) => {
      return !state.reservation.values[key];
    }) === -1;

  return {
    isReadyToSubmit: isReadyToSubmit && filledSecondStep,
    reservation: state.reservation,
    calculator: state.calculator,
    isSubsidy: state.calculator.settings.isSubsidy,
    isSubsidyLocal: getIsSubsidy(adult, children),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setParam: (param, value) => dispatch(setParam(param, value)),
  sendReservation: (props, state, idDetail) => dispatch(sendReservation(props, state, idDetail)),
  setCustomerSettings: (param, value, id) => dispatch(setCustomerSettings(param, value, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(Reservation));
