import constants from '../../../styles/constants.js';
import DropdownMenuItem from './DropdownMenuItem';
import Radium from 'radium';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

const styles = {
  wrapper: {
    paddingLeft: 0,
    clear: 'both',
    position: 'relative',
    margin: 0,
    zIndex: '100',

    [constants.breakpoint1024]: {
      top: '70px',
    },
  },
  link: {
    types: {
      desktop: {
        width: 'auto',
        float: 'left',
        position: 'relative',
        letterSpacing: '0px',
        padding: '15px 3px 21px 3px',
        margin: '0',
        cursor: 'pointer',
        transition: '0.2s',
        textDecoration: 'none',
        borderBottom: 'none',
        color: constants.black,
        fontWeight: '400',
        fontSize: '1.2em',
      },
      responsive: {
        width: '90%',
        padding: '15px 0',
        overflow: 'auto',
        display: 'block',
        cursor: 'pointer',
        transition: '0.2s',
        color: constants.black,
        textDecoration: 'none',
        background: 'transparent',
        borderBottom: 'solid 1px' + constants.grey,
        position: 'relative',
        left: '5%',
      },
    },
  },
  item: {
    listStyle: 'none',
    height: 'auto',
    position: 'relative',
    float: 'left',
    transition: '0.1s',

    types: {
      desktop: {
        display: 'inline-block',
        padding: '0 3px',
        margin: 0,

        ':hover': {
          color: [constants.mainBlue],
        },

        [constants.breakpointLargeTablet]: {
          padding: '0',
        },
      },
      responsive: {
        display: 'block',
        clear: 'both',
        position: 'relative',
        width: '100%',
        padding: '0 0 0 0',
        marginBottom: '1px',
      },
    },
  },
};

class MainMenuItems extends Component {
  state = {
    hovered: false,
    opened: true,
  };

  render() {
    const {
      fetchState: { headerFetched },
      header,
    } = this.props;

    return (
      <ul style={styles.wrapper}>
        {headerFetched ? header.header.map((headerItem) => this.renderHead(headerItem)) : ''}
      </ul>
    );
  }

  renderHead(headerItem) {
    const { type } = this.props;
    const { id, newWindow, title, link } = headerItem;

    switch (newWindow) {
      case null:
        return <DropdownMenuItem title={title} key={id} parent={id} type={type} />;

      case 1:
        return this.renderExternalItem(title, link, id);

      case 0:
        return this.renderItem(title, link, id);

      default:
        return '';
    }
  }

  renderItem(title, url, key) {
    const { type } = this.props;

    return (
      <span>
        <li style={{ ...styles.item, ...styles.item.types[type] }} key={key}>
          <Link style={styles.link.types[type]} key={4} to={url}>
            {title}
          </Link>
        </li>
      </span>
    );
  }

  renderExternalItem(title, url, key) {
    const { type } = this.props;

    return (
      <span>
        <li style={[styles.item, styles.item.types[type]]} key={key}>
          <a style={styles.link.types[type]} key={4} href={url} target="_blank">
            {title}
          </a>
        </li>
      </span>
    );
  }
}

MainMenuItems.propTypes = {
  type: PropTypes.string,
  closeMenu: PropTypes.func,
  fetchState: PropTypes.any,
  header: PropTypes.any,
};

const mapStateToProps = (state) => ({
  fetchState: state.fetchState,
  header: state.header,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(MainMenuItems));
