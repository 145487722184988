import MenuItems from './MenuItems';
import constants from '../../../styles/constants.js';
import Radium from 'radium';
import React, { Component } from 'react';

const styles = {
  wrapper: {
    width: 'auto',
    position: 'absolute',
    right: '30px',
    top: '33px',
    listStyle: 'none',
    display: 'block',
    fontSize: '0.8em',

    [constants.breakpointLargeTablet]: {
      right: '5px',
    },

    [constants.breakpoint1040]: {
      display: 'none',
    },
  },
};

class MenuDesktop extends Component {
  render() {
    return (
      <div className="mainmenu-desktop" style={styles.wrapper}>
        <MenuItems type="desktop" />
      </div>
    );
  }
}

export default Radium(MenuDesktop);
