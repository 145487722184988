import MultiCheckbox from './components/MultiCheckbox';
import FromToDatePicker from './components/FromToDatePicker';
import CountryDependentCheckboxfrom from './components/CountryDependentCheckbox';
import Radium from 'radium';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { COUNTRIES_SORTED_BY_NAME } from '../../../../reducers/tourFilter';
import { closeTabs, selecting, toggle } from '../../../../actions/TourList/filterOpenStatus';
import { saveFilter } from '../../../../actions/TourList/filter';
import PropTypes from 'prop-types';

const styles = {
  wrapper: {},
};

class ToursFilter extends Component {
  componentDidMount() {
    const { closeTabs } = this.props;

    closeTabs();
  }

  componentWillReceiveProps(props) {
    const { selectedLocations, tourFilter } = props;
    if (tourFilter && selectedLocations) {
      this.setState({ fetched: true });
    }
  }

  render() {
    const { selectedLocations } = this.props;
    const {
      tourFilter: { countrySortedByName, transport, diet, days, capacity },
      fetchState: { filterFetched },
    } = this.props;

    const { fetched } = this.state;

    return (
      <div style={styles.wrapper} className={'tours-list-filter-wrapper'}>
        {fetched && (
          <div className="col-4">
            <MultiCheckbox
              data={filterFetched ? countrySortedByName : []}
              type={COUNTRIES_SORTED_BY_NAME}
              box="textbox"
              defaultText="země"
            />
          </div>
        )}

        {fetched && (
          <div className="col-4">
            <CountryDependentCheckboxfrom
              data={filterFetched ? selectedLocations : []}
              type="location"
              defaultText="oblast"
            />
          </div>
        )}

        {fetched && (
          <div className="col-4">
            <MultiCheckbox
              data={filterFetched ? transport : []}
              type="transport"
              box="checkbox"
              defaultText="doprava"
            />
          </div>
        )}

        {fetched && (
          <div className="col-3">
            <MultiCheckbox
              data={filterFetched ? diet : []}
              type="diet"
              box="checkbox"
              defaultText="strava"
              style="inputBoxShorter"
            />
          </div>
        )}

        {fetched && (
          <div className="col-3">
            <MultiCheckbox
              data={filterFetched ? days : []}
              type="days"
              box="checkbox"
              defaultText="doba zájezdu"
              style="inputBoxShorter"
            />
          </div>
        )}

        {fetched && (
          <div className="col-3">
            <MultiCheckbox
              data={filterFetched ? capacity : []}
              type="capacity"
              box="checkbox"
              defaultText="kapacita"
              style="inputBoxShorter"
            />
          </div>
        )}

        <div className="col-3">{fetched && <FromToDatePicker style="inputBoxShorter" />}</div>
      </div>
    );
  }
}

ToursFilter.propTypes = {
  closeTabs: PropTypes.any,
  selectedLocations: PropTypes.any,
  fetchState: PropTypes.any,
  tourFilter: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  toggle: (designation) => dispatch(toggle(designation)),
  selecting: () => dispatch(selecting()),
  saveFilter: (filter) => dispatch(saveFilter(filter)),
  closeTabs: () => dispatch(closeTabs()),
});

const mapStateToProps = (state) => ({
  filter: state.filter,
  defaultTourListFilter: state.defaultTourListFilter,
  selectedLocations: state.selectedLocations,
  filterOpen: state.filterOpen,
  tourList: state.tourList,
  fetchState: state.fetchState,
  tourFilter: state.tourFilter,
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(ToursFilter));
