import selectArrow from '../../img/select-arrow2.png'; // eslint-disable-line
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const styles = {
  selectBox: {
    width: '100%',
    height: '41px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#ccc',
    display: 'inline',
    backgroundImage: 'url(' + selectArrow + ')',
    backgroundPosition: 'right center',
    backgroundRepeat: 'no-repeat',
    padding: '11px',
    color: '#444',
    fontSize: '0.9em',
    fontWeight: '500',
  },
};

export default class FilterSelect extends Component {
  render() {
    const { renderData, defaultValue, name } = this.props;

    return (
      <select
        onChange={this.handleChange.bind(this)}
        style={styles.selectBox}
        id="month"
        name={name}
        className="blue-select"
      >
        <option value="">{defaultValue}</option>
        {renderData && renderData.map((item) => this.renderOption(item))}
      </select>
    );
  }

  handleChange(event) {
    const { name } = this.props;
    const value = event.target.value;

    this.props.onClick(value, name);
  }

  renderOption(item) {
    const { id, value } = item;

    return <option value={id}>{value}</option>;
  }
}

FilterSelect.propTypes = {
  renderData: PropTypes.any.isRequired,
  defaultValue: PropTypes.any.isRequired,
  name: PropTypes.any.isRequired,
  onClick: PropTypes.any,
};
