import constants from '../../../../../../styles/constants.js';
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SeparatedPriceList from './SeparatedPriceList';
import { Column } from '../../../../../../libs/radium-grid'; // eslint-disable-line
import { connect } from 'react-redux';

const styles = {
  col: {
    padding: '0',

    [constants.breakpointTablet]: {
      padding: 'inherit',
    },
  },
  wrapper: {
    marginTop: '25px',
  },
  block: {
    overflow: 'auto',
  },
  soldOut: {
    color: 'red',
  },
  capacities: {
    marginTop: '31px',
  },
  rightBox: {
    position: 'relative',

    [constants.breakpointTablet]: {
      marginTop: '30px',
    },
  },
  iconAvailability: {
    height: '30px',
    width: 'auto',
    padding: '3px 25px 3px 0',
  },
  iconType: {
    position: 'relative',
    top: '8px',
    height: '20px',
    width: 'auto',
    padding: '3px 10px 3px 0',
  },
  infoTable: {
    marginTop: '10px',
    title: {
      width: '50%',
      fontWeight: '600',
    },
    value: {
      width: '50%',
    },

    [constants.breakpointMobile]: {
      fontSize: '0.9em',
    },
  },
  availability: {
    fontSize: '0.8em',
    marginTop: '20px',

    image: {
      width: '35px',
      height: 'auto',
      paddingRight: '7px',
    },
    text: {
      padding: '3px 0',
      position: 'relative',
      top: '-6px',
    },
  },
};

class TripInformations extends Component {
  render() {
    const { term } = this.props;

    return (
      <div>
        <div style={styles.wrapper}>
          <SeparatedPriceList term={term} />
        </div>
      </div>
    );
  }

  renderIcons(items) {
    return items.map((trans) => this.renderIcon(trans));
  }

  renderIcon(transportItem) {
    return (
      <div>
        <img src={transportItem.imgSrc} style={styles.iconType} />
        {transportItem.title}
      </div>
    );
  }

  renderTravelIcon(icon) {
    const { title, imgSrc } = icon;

    return <img src={imgSrc} style={styles.iconAvailability} alt={title} key={title} />;
  }

  renderTableRow(title, value) {
    return (
      <tr key={title}>
        <td style={styles.infoTable.title}>{title}:</td>
        <td style={styles.infoTable.value}>{value}</td>
      </tr>
    );
  }
}

TripInformations.propTypes = {
  tourDetail: PropTypes.any,
  term: PropTypes.any,
};

const mapStateToProps = (state) => ({
  termList: state.termList,
  tourDetail: state.tourDetail,
});

export default connect(mapStateToProps)(Radium(TripInformations));
