import constants from '../../../../../../styles/constants.js';
import PropTypes from 'prop-types';
import formatDateLong from '../../../../../shared/Moment/formatDateLong';
import React, { Component } from 'react';
import Radium from 'radium';

const styles = {
  wrapper: {
    marginTop: '25px',
    clear: 'both',
    paddingBottom: '15px',
  },
  contentWrapper: {
    width: '92%',
    padding: '10px 4%',
  },
  link: {
    color: '#444',
    textDecoration: 'none',
  },
  textCentered: {
    textAlign: 'center',
    marginTop: 30,
  },
  table: {
    borderCollapse: 'collapse',
    fontSize: '0.9em',
    maxWidth: '1000px',
    margin: '0 auto',
    minWidth: '500px',

    [constants.breakpointMobile]: {
      fontSize: '0.8em',
    },
    tbody: {
      backgroundColor: 'white',
    },
    head: {
      textAlign: 'left',
      backgroundColor: [constants.yellow],
      color: '#fff',
    },
    subsidyHead: {
      textAlign: 'left',
      backgroundColor: [constants.green],
      color: '#fff',
    },
    line: {
      width: '780',
      borderBottom: '1px solid rgba(194, 194, 194, .17)',
    },
    description: {
      fontWeight: '600',
    },
    priceFor: {
      textAlign: 'right',
      fontWeight: '600',
      minWidth: '60px',
    },
    price: {
      textAlign: 'right',
      fontWeight: '600',
      minWidth: '80px',
    },
    infoBottom: {
      marginTop: '5px',
      width: '700px',
      margin: '0 auto',
    },
    infoBottomWrapper: {
      marginTop: '20px',
    },
  },
  termLine: {
    color: [constants.mainBlue],
    fontWeight: 600,
  },
  bigPhoto: {
    width: '99%',
    margin: '0 0.5% 0.5% 0.5%',
    height: '380px',
    overflow: 'hidden',
    cursor: 'pointer',

    [constants.breakpointTablet]: {
      height: '500px',
    },
    [constants.breakpoint600]: {
      height: '380px',
    },
    bigImg: {
      width: '100%',
      height: 'auto',
      position: 'relative',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',

      [constants.breakpointLargeTablet]: {
        width: 'auto',
        height: '100%',
      },
    },
  },
};

class DepartureTable extends Component {
  render() {
    const { departures } = this.props;

    return <div>{this.renderTable(departures)}</div>;
  }

  renderTable(departures) {
    if (!departures.length) {
      return (
        <div style={styles.contentWrapper}>
          <p>Zájezd nemá uvedené odjezdy</p>
        </div>
      );
    }

    return (
      <div>
        <table style={styles.table}>
          <tbody style={styles.table.tbody}>
            {this.renderPriceListHead()}
            {departures.map((departure) => this.renderPrices(departure))}
          </tbody>
        </table>

        <p style={styles.textCentered}>Změna jízdního řádu vyhrazena.</p>
      </div>
    );
  }

  renderPrices(departure) {
    const {
      title,
      date,
      timeDeparture,
      timeArrival,
      travelTime,
      arrivalLocation,
      backTitle,
      backDate,
    } = departure;
    return (
      <tr>
        <th>{title}</th>
        <th>{arrivalLocation}</th>
        <th>{formatDateLong(date)}</th>
        <th>{timeDeparture} h</th>
        <th>{backTitle}</th>
        <th>{formatDateLong(backDate)}</th>
        <th>{timeArrival} h</th>
        <th>{travelTime} h</th>
      </tr>
    );
  }

  renderPriceListHead() {
    return (
      <tr style={styles.table.head}>
        <th>Místo odjezdu tam</th>
        <th>Místo příjezdu</th>
        <th>Datum tam</th>
        <th>Odjezd tam</th>
        <th>Místo odjezdu zpět</th>
        <th>Datum zpět</th>
        <th>Odjezd zpět</th>
        <th>Doba Cesty</th>
      </tr>
    );
  }
}

DepartureTable.propTypes = {
  departures: PropTypes.any,
};

export default Radium(DepartureTable);
