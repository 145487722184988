import { getJSON } from '../../utils/fetch';
import cookie from 'react-cookie';

export const SET_CATALOGUE_PDF = 'SET_CATALOGUE_PDF';

export const getLocationPdf = (locationId) => {
  return (dispatch) => {
    dispatch({
      type: SET_CATALOGUE_PDF,
      result: [],
    });

    getJSON(`pdf-detail/?location=${locationId}&user=${cookie.load('user')}`).then((result) => {
      dispatch({
        type: SET_CATALOGUE_PDF,
        result,
      });
    });
  };
};
