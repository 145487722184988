export const SAVE_PAGE = 'SAVE_PAGE';
export const SAVE_TOUR_SEARCH_PAGE = 'SAVE_TOUR_SEARCH_PAGE';
export const SAVE_TOUR_ARTICLE_PAGE = 'SAVE_TOUR_ARTICLE_PAGE';
export const SET_COST = 'SET_COST';
export const TOGGLE_TILES = 'TOGGLE_TILES';

export const savePage = (page, paginatorType) => ({
  type: SAVE_PAGE,
  page,
  paginatorType,
});

export const setCost = (min, max) => ({
  type: SET_COST,
  min,
  max,
});

export const saveSearchTourPage = (page, search) => ({
  type: SAVE_TOUR_SEARCH_PAGE,
  page,
  search,
});

export const toggleTiles = (nextState) => ({
  type: TOGGLE_TILES,
  nextState,
});
