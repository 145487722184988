import {
  TOGGLE_NEW_AD_TOGETHER_TRAVELING_FORM,
  TOGGLE_REPLY_TOGETHER_TRAVELING_FORM,
} from '../actions/TogetherTraveling/passengerFilter';
const initialState = [
  {
    newAdvertisingMessageOpened: false,
    advertisingMessageReplyOpened: false,
  },
];

const tourDetail = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_NEW_AD_TOGETHER_TRAVELING_FORM:
      return { ...state, newAdvertisingMessageOpened: action.isFormOpened };

    case TOGGLE_REPLY_TOGETHER_TRAVELING_FORM:
      return { ...state, advertisingMessageReplyOpened: action.isFormOpened };

    default:
      return state;
  }
};

export default tourDetail;
