export const getMapCenterOffset = (zoom) => {
  let centerOffset = 0;

  switch (zoom) {
    case 3:
      centerOffset = 18;
      break;
    case 4:
      centerOffset = 8;
      break;
    case 5:
      centerOffset = 4;
      break;
    case 6:
      centerOffset = 2.5;
      break;
    case 7:
      centerOffset = 1.4;
      break;
    case 8:
      centerOffset = 0.7;
      break;
    case 9:
      centerOffset = 0.3;
      break;
    case 10:
      centerOffset = 0.15;
      break;
    case 11:
      centerOffset = 0.1;
      break;
    case 12:
      centerOffset = 0.05;
      break;
    case 13:
      centerOffset = 0.019;
      break;
    case 14:
      centerOffset = 0.012;
      break;
    case 15:
      centerOffset = 0.005;
      break;
  }

  return centerOffset;
};
