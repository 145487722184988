import cookie from 'react-cookie';
import {connect} from 'react-redux';
import {hashHistory} from 'react-router';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import HeaderContacts from './HeaderContacts';
import Logo from './Logo';
import SectionType from './SectionType';
import constants from '../../styles/constants.js';
import MainSearchInput from './MainSearchInput';
import {fetchHeader} from '../../actions/Header/fetchHeader';
import LoaderInModalWindow from '../shared/Loading/LoaderInModalWindow';
import ComparementSideBar from './ComparementSideBar/ComparementSideBar';
import MenuDesktop from './MainMenu/MenuDesktop';
import MenuResponsive from './MainMenu/MenuResponsive';

const styles = {
  wrapper: {
    width: '100%',
    height: '80px',
    overflow: 'visible',
    background: '#fff',
    position: 'fixed',
    zIndex: '10',
    boxShadow: '0px -11px 23px',

    [constants.breakpoint600]: {
      height: '58px',
    },
  },
  box: {
    position: 'relative',
  },
  responsiveMenu: {
    zIndex: '8',
  },
};

class Header extends Component {
  componentDidMount() {
    const {
      fetchHeader,
      fetchState: {headerFetched},
      location: {pathname, query},
    } = this.props;

    const user = cookie.load('user');
    const ignoreUrlUser = cookie.load('ignoreUrlUser');

    if (query.user && query.user !== user && ignoreUrlUser !== 'true') {
      cookie.save('userChangedFromMenu', false);
      cookie.save('user', query.user);
      cookie.save('userChanged', true);

      window.location.reload();
      return;
    }

    if (!user) {
      if (pathname.indexOf('katalog') > 0) {
        cookie.save('backLink', '/katalog');
      }

      hashHistory.push('/');
    } else {
      !headerFetched && fetchHeader(user);
    }
  }

  render() {
    const {
      fetchState: {headerFetched},
    } = this.props;
    const user = cookie.load('user');

    const showComparementSideBar = this.props.location.pathname.indexOf('porovnani') < 0;

    return (
      <div style={styles.wrapper}>
        {!headerFetched && <LoaderInModalWindow/>}
        <Logo/>

        <MainSearchInput/>

        <div className="section-types-wrapper">
          <SectionType location={this.props.location}/>
        </div>

        <HeaderContacts/>

        <MenuDesktop/>

        <MenuResponsive/>

        <ComparementSideBar showComparementSideBar={showComparementSideBar}/>
      </div>
    );
  }
}

Header.propTypes = {
  fetchHeader: PropTypes.any,
  fetchState: PropTypes.any,
};

const mapStateToProps = (state) => ({
  fetchState: state.fetchState,
});

const mapDispatchToProps = (dispatch) => ({
  fetchHeader: (user) => dispatch(fetchHeader(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(Header));
