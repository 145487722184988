import constants from '../../../../styles/constants';

const styles = {
  input: {
    padding: '10px 9px',
    background: '#fff',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#ccc',
    width: 'calc(100% - 20px)',
    fontSize: '0.9em',
  },
  pickButton: {
    marginBottom: '45px',
  },
  area: {
    padding: '9px',
    background: '#fff',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#ccc',
    color: '#444',

    width: 'calc(100% - 20px)',
    marginBottom: '-2px',
    fontSize: '0.9em',
    minHeight: '65px',
  },
  wrapper: {
    width: '100%',
    margin: '0 auto',
  },
  col: {
    width: '44%',
    margin: '0 3%',
    float: 'left',

    [constants.breakpointTablet]: {
      width: '100%',
      margin: 0,
    },
  },
  row: {
    margin: '0 -3%',

    [constants.breakpointTablet]: {
      margin: 0,
    },
  },
  noticeBox: {
    width: '94%',
    margin: '0 3%',
    paddingTop: '30px',
    clear: 'both',

    [constants.breakpointTablet]: {
      width: '100%',
      margin: 0,
    },

    title: {
      width: '15.6%',
      float: 'left',
      fontWeight: '500',
      fontSize: '0.9em',
      paddingBottom: 0,
      color: 'rgb(51, 51, 51)',

      [constants.breakpointTablet]: {
        width: '100%',
        paddingBottom: '8px',
      },
    },
    inputBox: {
      width: '84.4%',
      float: 'left',

      [constants.breakpointTablet]: {
        width: '100%',
      },
    },
  },
  status: {
    fontSize: '1em',
    fontWeight: '600',
    textAlign: 'center',
    margin: 0,
    clear: 'both',
    color: '#afc836',
    marginBottom: 10,
  },
  datePicker: {
    overflow: 'hidden',
    background: '#fff',
    borderWidth: '1px',
    borderStyle: 'solid',
    zIndex: 9999,
    borderColor: '#ccc',

    text: {
      color: '#444',
      width: '100%',
      height: '39px',
      padding: '0px 0px 4px 10px',
      marginBottom: '-4px',
      fontSize: '14px',
      fontWeight: '500',
    },
  },
  titleBox: {
    fontWeight: '500',
    float: 'left',
    display: 'inline-block',
    padding: '9px 0',

    [constants.breakpointTablet]: {
      padding: '10px 0 5px 0',
    },
  },
};

export default styles;
