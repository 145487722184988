const headerTemplate = `
<!DOCTYPE html>
<html>
<head>
    <meta http-equiv="content-type" content="text/html; charset=utf-8">
</head>
<style>

    @page {
        size: landscape;
        margin: 3mm;
    }

    * {
        padding: 0;
        margin: 0;
    }

    .pricelist-table {
        margin: 0 auto;
        margin-top: 5px;
        width: 100%;
    }

    a {
        text-decoration: underline;
    }

    .table-tbody {
        background-color: white;
        color: #287fb8;
    }

    .table-tourPrice {
        text-align: center;
        font-weight: bold;
        color: #edb700
    }

    .table-centered {
        text-align: center;
    }

    body {
        font-family: Open Sans, sans-serif;
        font-size: {dynamicFontSize}px;
        line-height: 15px;
        margin: 0;
    }

    p {
        padding: 0px 10px;
        font-size: 12px;
        color: #444;
    }

    table {

        font-weight: 300 !important;
        font-size: 13px;

    }

    .pricelist-table td {
        color: #555;
    }

    .table-head th {
    }

    table td,
    table th {
        border-bottom: solid 1px #dddddd;
        padding: 10px 6px;
    }

    table th {
        color: #fff;
        font-weight: bold;
        border: none;
    }

    table td:first-child {
        font-weight: bold;
    }

    .employee  {
        background-color: #afc836 !important;
        color: white;
    }
    
    .visitor {
        background-color: #edb700 !important;
        color: white;
    }
    
    .szEmployee {
        background-color: #FF5200 !important;
        color: white;
    }

    .term-sold-out td {
        color: #999;
    }

    .term-sold-out .price {

    }

    .td-soldOut {
        width: 500px;
    }

    .days-count {
        width: 40px;
    }
</style>

<div>
    <div style="height: 350px;background-repeat: no-repeat;  background-image: url({mainImage});
background-size: 100%;">
        <div style="height: 30px;">
            <div style=" line-height: 30px; font-weight: bold;">
                <table style="padding: 10px; margin: 0px; background: rgba(51,93,135,0.71); color: white">
                    <tr>
                        <th style=" border-bottom: none; padding: 0px 0px 5px; font-size: 21px; text-align: left;">
                            {country}
                        </th>
                    </tr>

                    <tr>
                        <th style="border-bottom: none; padding: 0px; font-weight: normal; font-size: 21px; text-align: left;">
                            {hotel}
                        </th>
                    </tr>
                </table>
            </div>
        </div>
    </div>

    <div style="height: 40px; background: linear-gradient(to left, #e4e1ed 0%,#dce3e8 37%,#f8f2ff 93%);">
        <div style="display: inline-block; width: 480px; padding-top: 14px; margin-left: 10px">
            {availabilityIcons}
        </div>

        <div style="display: inline-block; width: 260px; float: right;  margin-top: -29px; padding-bottom: 6px;">
            <div style="margin-left: 10px; width: 120px; float: left;">
                <div style="font-size: 12px; height: 30px; padding-top: 5px;text-transform: uppercase; color: #335d87">
                    Garant zájezdu:
                </div>
                <div style="font-size: 12px; height: 12px;  margin-top: -14px;"><strong>{guaranteeName}</strong></div>
            </div>
            <div style="height: 0px; width: 120px; float: left; margin-top: -2px">
                <div style="height: 20px; margin-top: 13px; margin-right: 10px; width: 30px;float: left; display: inline-block;">
                    <img style="height: 20px; width: auto;"
                         src="//www.cdtravel.cz/www/images/detail/icons/telephone.png"/>
                </div>

                <div style="float: left; display: inline-block; font-weight: bold; margin-top: 2px;">{guaranteePhone}
                </div>
            </div>

        </div>
    </div>

     <columns style="margin-top: -10px; " column-count="2" vAlign="J" column-gap=0/>
        <div style="background: white; height: 685px; width: 370px">
            {content}
        </div>
    </columns>
</div>

<div class="price-list">

    <columns style="margin-top: -10px; " column-count="1" vAlign="J" column-gap=0/>
    {priceList}
    </columns>
</div>
</body>
</html>
`;

export default headerTemplate;
