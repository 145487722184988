import { SET_ADVERTISING, CLOSE_ADVERTISING } from '../actions/Advertising/fetchAdvertising';

const initialState = {
  closed: false,
  advertisingMessage: {},
};

const filter = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADVERTISING:
      return { ...state, advertisingMessage: action.result };

    case CLOSE_ADVERTISING:
      return { ...state, closed: true };

    default:
      return state;
  }
};

export default filter;
